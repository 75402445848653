import { GST_FAILURE,GST_REQUEST,GST_SUCCESS } from './gst-constants';

const gstRequest = (urlParams) => ({
  type: GST_REQUEST,
  urlParams,
});

export const gstSuccess = (data, totalRecords) => ({
  data,
  totalRecords,
  type: GST_SUCCESS,
});

export const gstFailure = () => ({
  type: GST_FAILURE,
});

export const gst = async (urlParams, dispatch) => {
  dispatch(gstRequest(urlParams));
};
