import {
  GET_PRODUCT_SETTINGS_REQUEST,
  GET_PRODUCT_SETTINGS_FAILURE,
  GET_PRODUCT_SETTINGS_SUCCESS
} from './profile-constants';

const productSettingsRequest = (id) => ({
  id,
  type: GET_PRODUCT_SETTINGS_REQUEST,
});

export const productSettingsSuccess = (data) => ({
  data,
  type: GET_PRODUCT_SETTINGS_SUCCESS,
});
export const productSettingsFailure = () => ({
  type: GET_PRODUCT_SETTINGS_FAILURE,
});

export const productSettings = async (id, dispatch) => {
  dispatch(productSettingsRequest(id));
};
