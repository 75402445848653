import {
  SECURED_LOAN_INFO_REQUEST,
  SECURED_LOAN_INFO_SUCCESS,
  SECURED_LOAN_INFO_FAILURE,
} from './secured-loan-information-constants';

const securedloanInfoRequest = (id) => ({
  id,
  type: SECURED_LOAN_INFO_REQUEST,
});

export const securedloanInfoSuccess = (data) => ({
  data,
  type: SECURED_LOAN_INFO_SUCCESS,
});

export const securedloanInfoFailure = () => ({
  type: SECURED_LOAN_INFO_FAILURE,
});

export const securedLoanInfo = async (id, dispatch) => {
  dispatch(securedloanInfoRequest(id));
};
