import React, { useState, useEffect } from 'react';
import { Form, Modal } from 'react-bootstrap';
import CloseIconWhite from '../../../../images/icons/closeIconWhite.svg';

import FormField from '../../form-elements';
import {
  setInitialFormValues,
  onChangeHandler,
  getFormDataValue,
  validateForm,
} from '../../../utils/form-helper';
import { addbankFormConstant } from './add-bank-constant';

const AddBank = ({
  closeNewBankPopup,
  addBankPopup,
  setAddBank,
  bankData,
  addBankHandler,
}) => {
  const [addBankForm, setAddBankForm] = useState([]);
  const [isDefaultBank, setIsDefaultBank] = useState(false);

  useEffect(() => {
    setInitialFormValues(addbankFormConstant, setAddBankForm);
    setIsDefaultBank(false);
  }, [addBankPopup]);

  const handleAddBank = () => {
    const [newFormWithErr, result] = validateForm(addBankForm);

    setAddBankForm(newFormWithErr);
    if (result) {
      const newBankObj = getFormDataValue(addBankForm);

      addBankHandler(newBankObj);
      closeNewBankPopup();
    }
    // else {
    // }
  };

  return (
    <Modal
      show={addBankPopup}
      onHide={() => setAddBank(false)}
      animation={false}
      className="delete-reason-popup"
    >
      <div className="common-image">
        <div className="common-img" onClick={() => setAddBank(false)}>
          <img alt="close" src={CloseIconWhite} />
        </div>
      </div>
      <Modal.Body>
        <div className="editaddress-sec">
          <div className="editaddress-header">
            <h2>ADD NEW Bank</h2>
          </div>
          <div className="editaddress-form-sec">
            <Form className="add-new-address-form">
              <div className="edit-address-form-wrapper">
                {addBankForm.map((formItem, index) => (
                  <FormField
                    inputType={formItem.type}
                    key={index}
                    lableName={formItem.label}
                    name={formItem.name}
                    className={index === 2 ? 'column-width-double' : ''}
                    placeholder={formItem.placeholder}
                    value={formItem.value}
                    options={
                      formItem.name === 'bankId' ? bankData : formItem.options
                    }
                    onChangeHandler={(targetValue) => {
                      onChangeHandler(
                        addBankForm,
                        formItem.name,
                        targetValue,
                        setAddBankForm
                      );
                    }}
                    isValid={formItem.isValid}
                    errorMsg={formItem.errorMsg}
                    isDisabled={formItem.isDisabled}
                    isTouched={formItem.isTouched}
                  />
                ))}
              </div>
            </Form>
            {/* <span>*Field are required</span> */}
          </div>
          <div className="editaddress-bottom">
            <button
              className={`black-border-btn ${
                isDefaultBank ? 'set-default-active' : ''
              }`}
              onClick={() => {
                setIsDefaultBank(!isDefaultBank);
              }}
            >
              Set as Default
            </button>
            <div className="editaddress-select">
              <button
                className="cancel-btn btn-align"
                onClick={closeNewBankPopup}
              >
                Cancel
              </button>
              <button className="black-border-btn" onClick={handleAddBank}>
                OK
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddBank;
