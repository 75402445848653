import { call, put, takeEvery } from 'redux-saga/effects';

import HttpHelper from '../../utils/http-helper';
import { EMI_CHIP_URL } from '../../utils/urls';

import { EMI_CHIP_REQUEST } from './emi-constants';

import { emiChipSuccess, emiChipFailure } from './emi-chip-action';

const { getRequest } = new HttpHelper();

export function* emiChip(action) {
  try {
    const response = yield call(getRequest, {
      url: EMI_CHIP_URL,
    });

    yield put(emiChipSuccess(response.data));
  } catch (error) {
    yield put(emiChipFailure());
  }
}

export function* watchEmiChip() {
  yield takeEvery(EMI_CHIP_REQUEST, emiChip);
}
