import { call, put, takeEvery } from 'redux-saga/effects';
import * as Toastr from 'toastr';

import HttpHelper from '../../utils/http-helper';
import { bankDetailsAddEditURL, getBankNameDetailsURL,bankDetailsEditStatmentUrl } from '../../utils/urls';
import {
  ADD_NEW_BANK_DETAILS_REQUEST,
  EDIT_BANK_DETAILS_REQUEST,
  GET_BANK_NAME_DETAILS_REQUEST,
  EDIT_BANK_STATEMENT_REQUEST
} from './profile-constants';
import { bankRequestFailure, bankRequestSuccess } from './bank-action';

const { postRequest, patchRequest, getRequest } = new HttpHelper();

export function* addBank(action) {
  try {
    const url = bankDetailsAddEditURL();

    const response = yield call(postRequest, {
      data: action.bankDetails,
      url,
    });

    if (response.error) {
      const { data } = response.error.response;

      Toastr.error(data.details[0].message, 'Failure');
    }else{
      yield put(bankRequestSuccess(response.data));
      action.callback();
    }
  } catch (error) {
    yield put(bankRequestFailure());
  }
}

export function* editBank(action) {
  try {
    const url = bankDetailsAddEditURL();

    const response = yield call(patchRequest, {
      data: action.bankDetails,
      url,
    });

    yield put(bankRequestSuccess(response.data));
    action.callback();
  } catch (error) {
    yield put(bankRequestFailure());
  }
}

export function* editBankStatement(action) {
  try {
    const url = bankDetailsEditStatmentUrl();

    const response = yield call(postRequest, {
      data: action.bankDetails,
      url,
    });

    yield put(bankRequestSuccess(response.data));
    action.callback();
  } catch (error) {
    yield put(bankRequestFailure());
  }
}

export function* getBankNameDetails(action) {
  try {
    const url = getBankNameDetailsURL();
    const response = yield call(getRequest, {
      url
    });

    action.callback(response.data);
  } catch (error) {
    yield put(bankRequestFailure());
  }
}

export function* watchAddBank() {
  yield takeEvery(ADD_NEW_BANK_DETAILS_REQUEST, addBank);
  yield takeEvery(EDIT_BANK_DETAILS_REQUEST, editBank);
  yield takeEvery(EDIT_BANK_STATEMENT_REQUEST, editBankStatement);
  yield takeEvery(GET_BANK_NAME_DETAILS_REQUEST, getBankNameDetails);

}
