import { call, put, takeEvery } from 'redux-saga/effects';
import HttpHelper from '../../utils/http-helper';
import { CALL_USERS } from '../../utils/urls';
import { USERS_CALL_REQUEST } from './users-constants';
import { UsersCallSuccess, UsersCallFailure } from './users-call-action';
import * as Toastr from 'toastr';

const { postRequest } = new HttpHelper();

export function* callUsers(action) {

  try {
    const response = yield call(postRequest, {
      data: {
        mobileNumber: action.mob,
        myOperatorUserID: localStorage.getItem('myoperatorUserId'),
      },
      url: CALL_USERS,
    });

    if(response.data.status==="success")
    {
      Toastr.success('Request accepted successfully', 'Success');
    }
    yield put(UsersCallSuccess(response.data));
  } catch (error) {
    yield put(UsersCallFailure());
  }
}

export function* watchcallUsers() {
  yield takeEvery(USERS_CALL_REQUEST, callUsers);
}
