import React, { useState, useEffect } from 'react';
import { Form, Modal } from 'react-bootstrap';
import CloseIconWhite from '../../../../images/icons/closeIconWhite.svg';

import FormField from '../../form-elements';
import { setInitialFormValues, onChangeHandler, getFormDataValue, validateForm } from '../../../utils/form-helper';
import { editBankStatementFormConstant } from './edit-bank-statement-const';

const EditBankStatement = ({ closeEditBankPopup, editBankStatementPopup, setEditBankStatement, bankData, editBankStatementHandler,financialDocument }) => {
  const [editBankStatementForm, setEditBankStatementForm] = useState([]);

  useEffect(() => {
    setInitialFormValues(editBankStatementFormConstant, setEditBankStatementForm);
  }, [editBankStatementPopup]);

  const handleEditBankStatement = () => {
    const [newForm, isValid] = validateForm(editBankStatementForm);

    setEditBankStatementForm(newForm);
    if (isValid) {
      const newBankObj = getFormDataValue(editBankStatementForm);

      financialDocument.map((docItem)=>{
        if(docItem.documentType === 'Bank Statement'){
          newBankObj.bankStatementFileId = docItem.documentFileId;
        }
        return false;
      })
      editBankStatementHandler(newBankObj);
      closeEditBankPopup();
    }
  };

  return (
    <Modal
      show={editBankStatementPopup}
      onHide={() => setEditBankStatement(false)}
      animation={false}
      className="delete-reason-popup"
    >
      <div className="common-image">
        <div className="common-img" onClick={() => setEditBankStatement(false)}>
          <img alt="close" src={CloseIconWhite} />
        </div>
      </div>
      <Modal.Body>
        <div className="editaddress-sec">
          <div className="editaddress-header">
            <h2>Edit Bank Statement</h2>
          </div>
          <div className="editaddress-form-sec">
            <Form className="add-new-address-form">
              <div className="edit-address-form-wrapper">
                {
                  editBankStatementForm.map((formItem, index) =>
                    <FormField
                      inputType={formItem.type}
                      key={index}
                      lableName={formItem.label}
                      name={formItem.name}
                      placeholder={formItem.placeholder}
                      value={formItem.value}
                      options={formItem.name === 'bankId' ? bankData : formItem.options}
                      onChangeHandler={(targetValue) => { onChangeHandler(editBankStatementForm, formItem.name, targetValue, setEditBankStatementForm) }}
                      isValid={formItem.isValid}
                      errorMsg={formItem.errorMsg}
                      isDisabled={formItem.isDisabled}
                      isTouched={formItem.isTouched}
                    />)
                }
              </div>
            </Form>
            {/* <span>*Field are required</span> */}
          </div>
          <div className="editaddress-bottom">
            <div className="editaddress-select">
              <button
                className="cancel-btn btn-align"
                onClick={closeEditBankPopup}
              >
                Cancel
              </button>
              <button className="black-border-btn" onClick={handleEditBankStatement}>
                OK
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EditBankStatement;
