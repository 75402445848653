/* eslint-disable max-lines */
/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import DataTable from 'moneyloji-react-data-table';
import { Link } from 'react-router-dom';

import { Breadcrumb } from 'react-bootstrap';

import { useSelector, useDispatch } from 'react-redux';

import { callLogsAction } from '../profile/call-logs-action';
import { allocationFilters } from './allocation-constants';
import Header from '../../components/header';
// import SideBar from '../../components/side-bar';
import SidebarIcon from '../../components/sidebar-icon';
// import ProgressBar from '../../components/progress-bar';
import Footer from '../../components/footer';

import { DEFAULT_PAGE_SIZE, CURRENT_DEFAULT_PAGE } from '../../constants';
// import { loanExportUrl } from '../../utils/urls';
import { setDefaultFilter } from '../../utils/default-filter-helper';
// import { userExportUrl } from '../../utils/urls';
import TableLoader from '../../components/Loaders/table-loader';

const Allocation = () => {
  const [show, setShow] = useState(false);

  const dispatch = useDispatch();

  const getAllocationData = (urlParams) => {
    callLogsAction(urlParams, dispatch);
  };

  useEffect(() => {
    const urlParams = {
      pageNumber: CURRENT_DEFAULT_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
      'sorting[0][direction]': ['DESC'],
      'sorting[0][key]': ['createdAt'],
    };
    // eslint-disable-next-line no-restricted-globals
    const searchParam = location.search;

    if (searchParam) {
      const filterKey = new URLSearchParams(searchParam);
      const value = filterKey.get('value');
      const filterOnValue = filterKey.get('filterOnValue');

      setDefaultFilter(allocationFilters, value, filterOnValue);
    } else {
        getAllocationData(urlParams);
    }
    // dashboardCard(dispatch);
  }, []);

  const {  callLogsDetails, isFetching, totalRecords } = useSelector(
    (state) => state.callLogs
  );

 // const { dashboardCardDetails } = useSelector((state) => state.dashboardCard);

  const callLogsList =
  callLogsDetails &&
  callLogsDetails.map((allocationData, index) => {
      const id = index + 1;

      return {
        ...allocationData,
        id,
      };
    });

  function userName(cell, row) {
    return <Link to={`/users/${row.userId}`}>{cell}</Link>;
  }

  const addClassCallBack = () => {
    setShow(!show);
  };

  const tableHeaderData = [
    {
      columnClassName: '',
      dataField: 'id',
      dataFormat: (cell) => cell,
      dataSort: false,
      isKey: true,
      name: '#',
      width: '40',
    },
    {
      columnClassName: '',
      dataField: 'name',
      dataFormat: userName,
      dataSort: true,
      isKey: false,
      name: 'Name',
      width: '170',
    },
    {
      columnClassName: '',
      dataField: 'email',
      dataFormat: (cell)=>cell,
      dataSort: false,
      isKey: false,
      name: 'Email',
      width: '200',
    },
    {
      columnClassName: '',
      dataField: 'role',
      dataFormat: (cell)=>cell,
      dataSort: false,
      isKey: false,
      name: 'Role',
      width: '140',
    },
    {
      columnClassName: '',
      dataField: 'freshLeads',
      dataFormat: (cell)=>cell || '0',
      dataSort: false,
      isKey: false,
      name: 'Total Count',
      width: '140',
    },
    {
      columnClassName: '',
      dataField: 'completed',
      dataFormat: (cell) => cell || '0',
      dataSort: false,
      isKey: false,
      name: 'Complete Count',
      width: '150',
    },
    {
      columnClassName: '',
      dataField: 'followUp',
      dataFormat: (cell)=>cell || '0',
      dataSort: false,
      isKey: false,
      name: 'Follow up Count',
      width: '150',
    },
    {
      columnClassName: '',
      dataField: 'rejected',
      dataFormat: (cell)=>cell || '0',
      dataSort: false,
      isKey: false,
      name: 'Rejected Count',
      width: '150',
    },
    {
      columnClassName: '',
      dataField: 'notWorkedOn',
      dataFormat: (cell)=>cell || '0',
      dataSort: false,
      isKey: false,
      name: 'Not Worked Count',
      width: '140',
    },
  ];

  // const { loans } = dashboardCardDetails;

  return (
    <div>
      <Header />
      <div className="common-container">
        <SidebarIcon addClassCallBack={addClassCallBack} show={show} />
        <div className={`common-wrapper ${show ? 'active' : ''} `}>
          <div className="col-md-12 mpad">
            <div className="common-heading">
              <h1>Allocations</h1>
              <Breadcrumb>
                <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>Allocation</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          <div className="loan-container">
            <DataTable
              tableData={callLogsList}
              tableHeaderData={tableHeaderData}
              totalRecords={totalRecords}
              isFetching={isFetching}
              getTableData={getAllocationData}
              // exportUrl={userExportUrl}
              tableTitle="ALLOCATIONS"
              pageFilters={allocationFilters}
              defaultSortKey={'createdAt'}
              defaultSortDirection={'desc'}
              TableLoader={TableLoader}
            />
          </div>
        </div>
        <Footer show={show} />
        {/* <div className={`common-side-bar ${show ? 'active' : ''} `}>
          <SideBar addClassCallBack={addClassCallBack} show={show} />
        </div> */}
      </div>
    </div>
  );
};

export default Allocation;
