import React from 'react';
import { Link } from 'react-router-dom';
import {
  toDateFormat,
  // toDateTimeFormat,
  toNumberWithCommaAndDecimal,
} from '../../utils/formattor';

// payments
const LoanInformationCard = ({ loanInfoDetails, profile,disbursement }) => {
  return(
    <div>
      <div class="row loan-inform">
        <div className="col-md-4 col-sm-12 justify-content-start">
          <div className="loan-content">
            <label className="loan-content-name"><Link to={`/users/${loanInfoDetails.userId}`}>{profile && profile.name}</Link></label><br />
            <span>{`( ${loanInfoDetails.number} )`}</span>
          </div>
        </div>
      </div>
      <div className="row border-right-1 loan-inform">
        <div className="col-md-4 col-sm-12 loan-tabel">
          <ul>
            <li>
              <label>Loan Status</label>
              <span>{loanInfoDetails.status}</span>
            </li>
            <li>
              <label>Loan Amount</label>
              <span>{toNumberWithCommaAndDecimal(loanInfoDetails.amount)}</span>
            </li>
            <li>
              <label>Loan Tenure</label>
              <span>{loanInfoDetails.tenure}</span>
            </li>
            <li>
              <label>Interest Rate</label>
              <span>{`${loanInfoDetails.interestRate}%`}</span>
            </li>
            <li>
              <label>Bank Name</label>
              <span>{profile && profile.bankName ? profile.bankName : '-'}</span>
            </li>
            <li>
              <label>AC Number</label>
              <span>
                {profile && profile.bankDetail.length > 0 ? profile.bankDetail[0].accountNumber : '-'}
              </span>
            </li>
            <li>
              <label>AC Holder Name</label>
              <span>{profile && profile.name ? profile.name : '-'}</span>
            </li>
            <li>
              <label>Loan Purpose</label>
              <span>{loanInfoDetails && loanInfoDetails.loanPurpose ? loanInfoDetails.loanPurpose : '-'}</span>
            </li>
            <li>
              <label>IFSC</label>
              <span>{profile && profile.bankDetail.length > 0 ? profile.bankDetail[0].ifsc : '-'}</span>
            </li>
            <li>
              <label>Last Salry Date</label>
              <span>
                {profile && profile.lastMonthSalaryDate
                  ? toDateFormat(profile.lastMonthSalaryDate)
                  : '-'}
              </span>
            </li>
            <li>
              <label>Bounce Charges</label>
              <span>{loanInfoDetails.bounceCharges}</span>
            </li>
          </ul>
        </div>
        <div className="col-md-4 col-sm-12 loan-tabel">
          <div className="loan-content">
            <span>EMANDATE</span>
          </div>
          <ul>
            <li>
              <label>Razor pay Emandate ID</label>
              <span className="tooltip-loan" data-tooltip={loanInfoDetails.razorpayEmandateId}>
                {loanInfoDetails.razorpayEmandateId ? loanInfoDetails.razorpayEmandateId : '-'}
              </span>
            </li>
            <li>
              <label>Razor Payout ID</label>
              <span className="tooltip-loan" data-tooltip={loanInfoDetails.razorpayPayoutId}>
                {loanInfoDetails.razorpayPayoutId ? loanInfoDetails.razorpayPayoutId : '-'}
              </span>
            </li>
            <li>
              <label>AC Number</label>
              <span>
                {profile && profile.accountNumber ? profile.accountNumber : '-'}
              </span>
            </li>
            <li>
              <label>AC Holder Name</label>
              <span>{profile && profile.name ? profile.name : '-'}</span>
            </li>
          </ul>
        </div>
        <div className="col-md-4 col-sm-12 loan-tabel">
          <div className="border-bottom-0 loan-content">
            <span>DISBURSAL</span>
          </div>
          <ul>
            <li>
              <label>PayOut Id</label>
              <span className="tooltip-loan"
                data-tooltip={disbursement && disbursement.publicId}>
                {disbursement && disbursement.publicId ? disbursement.publicId : '-'}</span>
            </li>
            <li>
              <label>Disbursed Date</label>
              <span>{disbursement && disbursement.createdAt ? toDateFormat(disbursement.createdAt) : '-'}</span>
            </li>
            <li>
              <label>Loan Amount</label>
              <span>{toNumberWithCommaAndDecimal(loanInfoDetails.amount)}</span>
            </li>
            <li>
              <label>Processing Fees</label>
              <span>{toNumberWithCommaAndDecimal(loanInfoDetails.processingAmount)}</span>
            </li>
            <li>
              <label>Disbursed Amount</label>
              <span>{toNumberWithCommaAndDecimal(disbursement && disbursement.amount ? (disbursement.amount) : '-')}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default LoanInformationCard;
