import { call, put, takeEvery } from 'redux-saga/effects';

import HttpHelper from '../../../utils/http-helper';
import { manualPaymentUrl, autoPaymentUrl } from '../../../utils/urls';
import { AUTO_PAYMENT_REQUEST, MANUAL_PAYMENT_REQUEST } from './payment-constant';
import { autoPaymentFailure, autoPaymentSuccess, manualPaymentFailure, manualPaymentSuccess } from './payment-action';

const { postRequest } = new HttpHelper();

export function* autoPayment(action) {
  try {
    const url = autoPaymentUrl(action.id);

    const {  error } = yield call(postRequest, {
      url,
      data: action.body
    });

    if(error){
      yield put(autoPaymentFailure(error && error.response && error.response.data));
    }else{
      yield put(autoPaymentSuccess({ msg: "Success" }));
    }
  } catch (error) {
    yield put(autoPaymentFailure(error));
  }
}

export function* manualPayment(action) {
  try {
    const url = manualPaymentUrl(action.id);
    const {  error } = yield call(postRequest, {
      url,
      data: action.body
    });

    if(error){
      yield put(manualPaymentFailure(error && error.response && error.response.data));
    }else{
      yield put(manualPaymentSuccess({ msg: "Success" }));
    }
  } catch (error) {
    yield put(manualPaymentFailure(error));
  }
}
export function* watchPayment() {
  yield takeEvery(AUTO_PAYMENT_REQUEST, autoPayment);
  yield takeEvery(MANUAL_PAYMENT_REQUEST, manualPayment);

}
