import {
  LOAN_ACTION_FAILURE,
  LOAN_ACTION_SUCCESS,
  LOAN_CANCEL_REQUEST,
  LOAN_CLOSE_REQUEST,
  LOAN_WAIVER_REQUEST,
} from './loan-actions-constants';

const initialState = {
  isFetching: false,
  isSuccess: false,
  isFailure: false,
  successData: {},
  failureData: {},
};

const loanAction = (state = initialState, action) => {
  switch (action.type) {
    case LOAN_CLOSE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case LOAN_CANCEL_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case LOAN_WAIVER_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case LOAN_ACTION_FAILURE:
      return {
        ...state,
        isFailure: true,
        failureData: action.data,
        isFetching: false,
      };
    case LOAN_ACTION_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isFetching: false,
        successData: action.data,
      };
    default:
      return state;
  }
};

export default loanAction;
