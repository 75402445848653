import { call, put, takeEvery } from 'redux-saga/effects';

import HttpHelper from '../../utils/http-helper';
import { loanBlockDashboardURL } from '../../utils/urls';
import { LOAN_BLOCK_REQUEST } from './dashboard-constants';

import { loanBlockFailure, loanBlockSuccess } from './loan-block-action';

const { getRequest } = new HttpHelper();

export function* loanBlock(action) {
  try {
    const originalRequestParam = {
      pageNumber: 1,
      pageSize: 10,
    };
    const responseForTotal = yield call(getRequest, {
      url: loanBlockDashboardURL(originalRequestParam),
    });
    const total = responseForTotal.headers['x-coreplatform-total-records'];
    const response = yield call(getRequest, {
      url: loanBlockDashboardURL(action.urlParams),
    });
    const testCaseTotal = response.headers['x-coreplatform-total-records'];

    yield put(loanBlockSuccess({ total, testCaseTotal }));
  } catch (error) {
    yield put(loanBlockFailure());
  }
}

export function* watchLoanBlock() {
  yield takeEvery(LOAN_BLOCK_REQUEST, loanBlock);
}
