import React, { useState } from 'react';
import Utils from '../../../utils/index';

const LeftProfileInfo = ({
  profileData,
  getUserProfilePic,
  setFileUploadPopup,
  fileUploadPopup,
  setDeleteWithReason,
  deleteWithReason,
  isUserBlocked,
  handleReload,
  getUserReportUrl,
  updateCrifScore,
  getCrif,
  getPreApprovedAmount,
  onCrifScoreDropdownValue,
}) => {
  const [role, setRole] = React.useState('system-admin');
  const [crifDropdownValue, setCrifDropdownValue] = useState('');
  const handleChange = (e) => {
    onCrifScoreDropdownValue(e.target.value);
  };
  React.useEffect(() => {
    const getRole = new Utils();

    setRole(getRole.getUserRole());
  }, [role]);

  return (
    <React.Fragment>
      <div className="profile-details-container">
        <div className="profile-image">
          {profileData && profileData.profilePicId && (
            <img
              alt="Profile Image"
              className="user-profile-image"
              src={getUserProfilePic(profileData.profilePicId)}
            />
          )}
        </div>
        <label className="profile-referal">
          Referal code
          <span>
            &nbsp;
            {(profileData && profileData.referralCode) || '-'}
          </span>
        </label>
        {/* <label className="profile-referal">
                      Referal by<span>&nbsp;Siddhant</span>
                    </label> */}
      </div>
      <div className="profile-crif-score">
        <div className="profile-crif-score-container">
          <label>Crif Score</label>
          <span>{(profileData && profileData.crifScore) || '-'}</span>
        </div>

        <div
          className="profile-crif-score-btn-container"
          style={{ marginBottom: '10px' }}
        >
          {role !== 'call-center-staff' && role !== 'collection-staff' && (
            <button
              onClick={updateCrifScore}
              className="black-border-btn black-border-reload"
            >
              Update Crif score
            </button>
          )}
          <br />
          <div>
            <select
              onChange={handleChange}
              required
              className="black-border-btn black-border-reload"
            >
              <option>select value</option>
              <option value="601">601</option>
              <option value="701">701</option>
            </select>
          </div>
        </div>
        <div className="profile-crif-score-btn-container">
          <button
            onClick={handleReload}
            className="black-border-btn black-border-reload"
          >
            Generate
          </button>
          <button className="black-border-btn black-border-reload">
            <a
              href={getUserReportUrl(profileData.panNumber)}
              target="_blank"
              style={{ color: 'inherit' }}
            >
              Download
            </a>
          </button>
        </div>
      </div>
      <div className="profile-crif-score-btn-container">
        <button
          className="black-border-btn black-border-reload"
          onClick={() => getCrif(profileData.publicId)}
        >
          Extract Crif Score
        </button>
        <button
          className="black-border-btn black-border-reload"
          onClick={() => getPreApprovedAmount(profileData.publicId)}
        >
          Pre approved amount
        </button>
      </div>
      <div className="profile-upload-document">
        <h5>UPLOAD DOCUMENT</h5>
        <p>only .jpg, .png, .pdf file allowed</p>
        <div className="profile-upload-document-btn">
          <button
            onClick={() => setFileUploadPopup(!fileUploadPopup)}
            className="black-border-btn black-border-browse"
          >
            Browse
          </button>
        </div>
      </div>
      <button
        className="black-border-btn black-border-block"
        onClick={() => setDeleteWithReason(!deleteWithReason)}
      >
        {!isUserBlocked ? 'Block User' : 'Unblock User'}
      </button>
    </React.Fragment>
  );
};

export default LeftProfileInfo;
