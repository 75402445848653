import {
  AUTO_PAYMENT_REQUEST,
  AUTO_PAYMENT_SUCCESS,
  AUTO_PAYMENT_FAILURE,
  MANUAL_PAYMENT_REQUEST,
  MANUAL_PAYMENT_SUCCESS,
  MANUAL_PAYMENT_FAILURE
} from './payment-constant';

const autoPaymentRequest = (id,body) => ({
  id,
  body,
  type: AUTO_PAYMENT_REQUEST,
});

export const autoPaymentSuccess = () => ({
  type: AUTO_PAYMENT_SUCCESS,
});

export const autoPaymentFailure = (errorObj) => ({
  errorObj,
  type: AUTO_PAYMENT_FAILURE,
});

const manualPaymentRequest = (id,body) => ({
  id,
  body,
  type: MANUAL_PAYMENT_REQUEST,
});

export const manualPaymentSuccess = (data) => ({
  data,
  type: MANUAL_PAYMENT_SUCCESS,
});

export const manualPaymentFailure = (errorObj) => ({
  errorObj,
  type: MANUAL_PAYMENT_FAILURE,
});

export const manualPayment = async (id,body,dispatch) => {
  dispatch(manualPaymentRequest(id,body));
};

export const autoPayment = async (id,body, dispatch) => {
  dispatch(autoPaymentRequest(id,body));
};
