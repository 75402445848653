import React from 'react';
import { Link } from 'react-router-dom';
import './styles.css';
import { getTotalPercentage } from '../../../utils/percentageNumber';
import { toNumberWithComma } from '../../../utils/formattor';
import ProgressBar from '../../progress-bar-new';
import Loader from '../../Loaders/table-loader';

const CircularProgressBarLayout = ({
  title,
  textToDisplay,
  total,
  testCaseTotal,
  name,
  link,
  isFetching,
  next,
  pre
}) => {
  const percentage = Math.ceil(getTotalPercentage(testCaseTotal, total));
  const getPercentageColor = (percent) => {
    if (percent < 30) {
      return 'red-color';
    } else if (percent < 65) {
      return 'blue-color';
    }
    return 'green-color';

  };

  return (
    <React.Fragment>
      <div className="block-dashboard-wrapper">
        {!isFetching?<React.Fragment><span className="left-arrow-block-dashboard" onClick={()=>{pre()}}></span>
          <div className="progress-bar-container-wrapper">
            <h3 className="title-block-dashboard">{title}</h3>
            <Link to={link}>
              <ProgressBar
                percent={percentage}
                amount={toNumberWithComma(testCaseTotal)}
                text={textToDisplay}
                color={getPercentageColor(percentage)}
                disbursement={`${toNumberWithComma(
                  testCaseTotal
                )} of ${toNumberWithComma(total)} ${name}`}
              />
            </Link>
          </div>
          <span className="right-arrow-block-dashboard" onClick={()=>{next()}}></span></React.Fragment>:<Loader></Loader>}
      </div>
    </React.Fragment>
  );
};

export default CircularProgressBarLayout;
