import React from 'react';
import './partner-details.css';
import FieldValue from '../field-key-value';

const PartnerDetails = ({ partnerDetails,partnerDetailsKeylabelMap }) => {
  return (
    <React.Fragment>
      <div className="partner-details-containers">
        <div className="partner-details-title">
                    Partner Details
        </div>
        <div className='partner-field-value-container'>
          {partnerDetailsKeylabelMap.map((item, index) =>
            <FieldValue
              key={index}
              fieldName={item.label}
              fieldValue={partnerDetails && partnerDetails[item.key]}
              type={item.type}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

export default PartnerDetails