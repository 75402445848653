import { call, put, takeEvery } from 'redux-saga/effects';
import * as Toastr from 'toastr';

import HttpHelper from '../../utils/http-helper';
import { preApprovedAmountUrl } from '../../utils/urls';
import { PRE_APPROVED_AMOUNT_REQUEST } from './profile-constants';
import { preApprovedAmountSuccess, preApprovedAmountFailure } from './pre-approved-amount-action';

const { getRequest } = new HttpHelper();

export function* preApprovedAmount(action) {
  try {
    const response = yield call(getRequest, {
      url: preApprovedAmountUrl(action.urlParams),
    });

    if (response.error) {
      const { data } = response.error.response;

      yield put(preApprovedAmountFailure());
      Toastr.error(data.details[0].message, 'Failure');
    }else{
      yield put(preApprovedAmountSuccess(response.data));
      window.location.pathname = `users/${action.urlParams}`;
    }
  } catch (error) {
    yield put(preApprovedAmountFailure());
  }
}

export function* watchPreApprovedAmount() {
  yield takeEvery(PRE_APPROVED_AMOUNT_REQUEST, preApprovedAmount);
}
