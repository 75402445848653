import { call, put, takeEvery } from 'redux-saga/effects';
import * as Toastr from 'toastr';

import HttpHelper from '../../utils/http-helper';

import { ADD_NEW_USER_URL } from '../../utils/urls';

import { ADD_NEW_USER_REQUEST } from './users-constants';

import {  addNewUserFailure , getOtpUserSuccess } from './add-user-action';

const { postRequest } = new HttpHelper();

export function* addNewUser(action) {

  try {
    const response = yield call(postRequest, {
      data: {
        mobileNumber: action.data.mobileNo,
      },
      url: ADD_NEW_USER_URL,
    });

    if (response.error) {
      const { data } = response.error.response;

      Toastr.error(data.details[0].message, 'Failure');
      yield put(addNewUserFailure());

      action.data.addUserFailureCallback({
        field: data.details[0].name,
        message: data.details[0].message,
      });
    } else {
      Toastr.success(response.headers.message, 'Success Response');
      yield put(getOtpUserSuccess());

      // action.data.addUserSuccessCallback(newUserData);
    }
  } catch (error) {
    Toastr.error('Change Password Failed', 'Failure Response');
  }
}

export function* watchaddNewUser() {
  yield takeEvery(ADD_NEW_USER_REQUEST, addNewUser);
}
