import { call, put, takeEvery } from 'redux-saga/effects';
import * as Toastr from 'toastr';

import HttpHelper from '../../utils/http-helper';
import { ADD_USER_PROFILE,MOBILE_VERIFICATION_URL } from '../../utils/urls';

import { SET_OTP_REQUEST } from './users-constants';

import { sendotpfaliure , sendotpSuccess } from './send-otp-action';

const { postRequest } = new HttpHelper();

export function* sendOtpUser(action) {
  try {
    const response = yield call(postRequest, {
      data: {
        mobileNumber: action.data.mobileNo,
        otp: action.data.otp
      },
      headers: {
        'x-platform-deviceid': action.data.device_id,
      },
      url: MOBILE_VERIFICATION_URL,
    });

    if (response.error) {
      const { data } = response.error.response;

      Toastr.error(data.details[0].message, 'Failure');
      yield put(sendotpfaliure());

      action.data.sendOtpUserFailureCallBack({
        field: data.details[0].name,
        message: data.details[0].message,
      });
    } else {
      const { userId } = JSON.parse(atob(response.headers.token.split('.')[1]));

      const response1 = yield call(postRequest, {
        data: {
          publicId: userId,
          primaryEmail: action.data.email,
          name: action.data.name,
          fatherName: action.data.fathersName,
          gender: action.data.gender,
          maritalStatus: action.data.martialStatus,
          dob: action.data.dob
        },
        url: ADD_USER_PROFILE,
      });

      if(response1.error){
        const { data } = response1.error.response;

        Toastr.error(data.details[0].message, 'Failure');
        yield put(sendotpfaliure());
        action.data.sendOtpUserFailureCallBack({
          field: data.details[0].name,
          message: data.details[0].message,
        });
      }
      else{
        yield put(sendotpSuccess());
        Toastr.success(response1.headers.message, 'Success');
        action.data.sendOtpUserSuccessCallBack();
      }
    }
  } catch (error) {
    Toastr.error( error, 'Failure Response');
  }
}

export function* watchsendOtpUser() {
  yield takeEvery(SET_OTP_REQUEST, sendOtpUser);
}
