import { VERIFY_TEXT } from '../../../constants/field-value-key';

export const kycDetailsKeyValueMap = [
  {
    label: "PAN Number",
    key: "panNumber",
    type: VERIFY_TEXT,
    verifyStatusKey: "panDocumentStatus",
  },
  {
    label: "Aadhar Number",
    key: "aadharNumber",
    type: VERIFY_TEXT,
    verifyStatusKey: "aadharDocumentStatus",
  },
  {
    label: "Voter Card",
    key: "voterCardNumber",
    type: VERIFY_TEXT,
    verifyStatusKey: "voterCardDocumentStatus",
  },
  {
    label: "Driving Licence Number",
    key: "drivingLicenceNumber",
    type: VERIFY_TEXT,
    verifyStatusKey: "drivingLicenceDocumentStatus",
  },
  {
    label: "Passport",
    key: "passportNumber",
    type: VERIFY_TEXT,
    verifyStatusKey: "passportDocumentStatus",
  }
]