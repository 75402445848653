import {
  PRE_APPROVED_AMOUNT_FAILURE,
  PRE_APPROVED_AMOUNT_REQUEST,
  PRE_APPROVED_AMOUNT_SUCCESS
} from './profile-constants';

const initialState = {
  data: [],
  error: false,
  isFetching: false,
};

const preApprovedAmount = (state = initialState, action) => {
  switch (action.type) {
    case PRE_APPROVED_AMOUNT_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case PRE_APPROVED_AMOUNT_FAILURE:
      return {
        ...state,
        error: true,
        isFetching: false,
      };
    case PRE_APPROVED_AMOUNT_SUCCESS:
      return {
        ...state,
        data: action.data,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default preApprovedAmount;
