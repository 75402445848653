import { TEXT } from '../../../constants/field-value-key';

export const userDetailsKeyValueMap = [
  {
    label: 'Account Type',
    key: 'accountType',
    type: TEXT
  },
  {
    label: 'Account Number',
    key: 'accountNumber',
    type: TEXT
  },
  {
    label: 'Account Holder Name',
    key: 'accountHolderName',
    type: TEXT
  },

  {
    label: 'Bank Name',
    key: 'bankName',
    type: TEXT
  },
  {
    label: 'IFSC Code',
    key: 'ifsc',
    type: TEXT
  }
]