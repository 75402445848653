import React from 'react';
import Table from 'react-bootstrap/Table';

const ResponsiveTableCustom = ({ tableHeaderData, tableData }) => {
  return (
    <Table responsive className="profile-details-table">
      <thead className="profile-details-head">
        <tr>
          {
            tableHeaderData.map((thItem, index) => (
              <th key={index}>{thItem.name}</th>
            ))
          }
        </tr>
      </thead>
      <tbody className="profile-details-body">
        {tableData.map((tableRowItem, upperIndex) => (
          <tr  key={upperIndex}>
            {tableHeaderData.map((tableHeaderItem, innerIndex)=>(<td key={innerIndex}>{
              (tableHeaderItem.dataFormat(tableRowItem[tableHeaderItem.dataKey],tableRowItem)||'-')
            }</td>))}
          </tr>
        ))}
      </tbody>
    </Table >
  )
}

export default ResponsiveTableCustom;