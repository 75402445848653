import {
  USER_BLOCK_SUCCESS,
  USER_BLOCK_FAILURE,
  USER_BLOCK_REQUEST
} from './dashboard-constants';

const initialState = {
  isFetchUserBlock: false,
  totalUser: 0,
  totalTestCaseUser: 0
};

const userBlock = (state = initialState, action) => {
  switch (action.type) {
    case USER_BLOCK_REQUEST:
      return {
        ...state,
        isFetchUserBlock: true,
      };
    case USER_BLOCK_FAILURE:
      return {
        ...state,
        isFetchUserBlock: false,
      };
    case USER_BLOCK_SUCCESS:
      return {
        ...state,
        isFetchUserBlock: false,
        totalUser: action.data.total,
        totalTestCaseUser: action.data.testCaseTotal
      };
    default:
      return state;
  }
};

export default userBlock;
