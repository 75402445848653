import React from 'react';
import { kycDetailsKeyValueMap } from './kyc-details-constant';
import FieldValue from '../../field-key-value';
import './kyc-details.css';

const KYCDetails = ({ profileData }) => {
  return (
    <React.Fragment>
      <div className="personal-kyc">
        <h4 className="personal-common-heading">KYC Details</h4>
        <div className="kyc-details-key-value-container">
          {kycDetailsKeyValueMap.map((item, index) => (
            <FieldValue
              key={index}
              fieldName={item.label}
              fieldValue={
                item.key === 'aadharNumber'
                  ? `XXXX XXXX ${(profileData[item.key] || '').substring(
                    profileData[item.key] ? profileData[item.key].length - 4 : 1
                  )}`
                  : profileData && profileData[item.key]
              }
              verifyStatus={profileData && profileData[item.verifyStatusKey]}
              type={item.type}
            />
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};

export default KYCDetails;
