/* eslint-disable max-lines */
import React, { useState, useEffect } from 'react';
import DataTable from 'moneyloji-react-data-table';

import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { Breadcrumb } from 'react-bootstrap';
// import { addNewUser } from './add-user-action';
// import { sendOtpUser } from './send-otp-action';
import { SecuredA } from './secured-loans-action';

import Header from '../../components/header';
// import SideBar from '../../components/side-bar';
import Footer from '../../components/footer';
// import SidebarIcon from '../../components/sidebar-icon';
import { usersFilters } from './secured-loans-constants';

// import { userExportUrl } from '../../utils/urls';
import { setDefaultFilter } from '../../utils/default-filter-helper';
import { DEFAULT_PAGE_SIZE, CURRENT_DEFAULT_PAGE } from '../../constants';
import { toNumberWithCommaAndDecimal } from '../../utils/formattor';
import TableLoader from '../../components/Loaders/table-loader';

const SecuredLoans = ({ location }) => {
  // Filter State
  const [show, setShow] = useState(false);
  // const [cities, setCities] = useState('');

  const { isFetching, users, totalRecords } = useSelector((state) => state.securedloans);
  // const [otp,setOtp] = useState();

  const usersList =
    users &&
    users.map((user, index) => {
      const id = index + 1;

      return {
        ...user,
        id,
      };
    });

  const dispatch = useDispatch();

  const getUsersData = (urlParams) => {
    SecuredA(urlParams, dispatch);
  };

  // useEffect(() => {
  //   setOtp(getOtp)
  // },[getOtp]);

  useEffect(() => {
    const urlParams = {
      pageNumber: CURRENT_DEFAULT_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
      'sorting[0][direction]': ['DESC'],
      'sorting[0][key]': ['createdAt'],
    };

    if (location.search) {
      const filterKey = new URLSearchParams(location.search);
      const value = filterKey.get('value');
      const filterOnValue = filterKey.get('filterOnValue');
      const sortValue = filterKey.get('sortBy');

      if(sortValue){
        urlParams['sorting[0][key]'] = sortValue;
        getUsersData(urlParams);
      }
      setDefaultFilter(usersFilters, value, filterOnValue);
    } else {
      getUsersData(urlParams);
    }
  }, []);

  function loanNumberHandle(cell, row) {
    return <Link to={`/secured-loan-information/${row.publicId}`}>{cell || '-'}</Link>;
  }

  // function userMobileNo(cell, row) {
  //   return <Link to={`/users/${row.publicId}`}>{cell}</Link>;
  // }

  function dateTimeFormatter(cell) {
    return <label>{cell?moment(cell).format('DD-MM-YYYY hh:mm:ss'):'-'}</label>;
  }
  // function valueFormatter(cell) {
  //   if (cell === 'true') {
  //     return <label className="label-status-paid">True</label>;
  //   }
  //   return <label className="status-unpaid">False</label>;
  // }

  // function statusFormatter(cell) {
  //   if (cell === 'verified') {
  //     return <label className="label-status-paid">{cell}</label>;
  //   }
  //   if (cell === 'pending') {
  //     return <label className="label-status-paid">{cell}</label>;
  //   }
  //   return <label className="status-unpaid">{cell || 'not-uploaded'}</label>;
  // }

  // function callStatusFormatter(cell) {
  //   if (cell === 'verified') {
  //     return <label className="label-status-paid">{cell}</label>;
  //   }
  //   if (cell === 'pending') {
  //     return <label className="label-status-paid">{cell}</label>;
  //   }
  //   return <label className="status-unpaid">{cell || 'NULL'}</label>;
  // }

  // function registrationFeeExpiryStatus(cell) {
  //   if (cell === 'valid') {
  //     return <label className="label-status-paid">{cell}</label>;
  //   }
  //   if (cell === 'expired') {
  //     return <label className="label-status-paid">{cell}</label>;
  //   }
  //   return <label className="status-unpaid">{cell || 'NULL'}</label>;
  // }

  function buttonFormatter(cell) {
    if (cell === 'closed') {
      return <label className="status-unpaid">{cell}</label>;
    } else if (cell === 'active') {
      return <label className="label-status-paid">{cell}</label>;
    }
    return <label className="label-status-initiated">{cell}</label>;
  }

  // function registrationFeeStatus(cell) {
  //   if (cell === 'captured') {
  //     return <label className="label-status-paid">{cell}</label>;
  //   }
  //   if (cell === 'failed') {
  //     return <label className="status-unpaid">{cell}</label>;
  //   }
  //   if (cell === 'incomplete') {
  //     return <label className="status-partialpaid">{cell}</label>;
  //   }
  //   if (cell === 'initiated') {
  //     return <label className="label-status-initiated">{cell}</label>;
  //   }
  //   return <label className="status-unpaid">{cell || 'NULL'}</label>;
  // }

  const numberFormatter = (cell) => {
    if (cell) {
      return toNumberWithCommaAndDecimal(cell);
    }
    return '-';
  };

  const addClassCallBack = () => {
    setShow(!show);
  };

  const tableHeaderData = [
    {
      columnClassName: 'col-grey',
      dataField: 'number',
      dataFormat: loanNumberHandle,
      dataSort: false,
      isKey: true,
      name: '#',
      width: '200',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'amount',
      dataFormat: numberFormatter,
      dataSort: false,
      isKey: false,
      name: 'Amount',
      width: '140',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'paidAmount',
      dataFormat: numberFormatter,
      dataSort: false,
      isKey: false,
      name: 'Paid',
      width: '140',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'interestAmount',
      dataFormat: numberFormatter,
      dataSort: false,
      isKey: false,
      name: 'Interest Amount',
      width: '140',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'tenure',
      dataFormat: (cell) => cell,
      dataSort: false,
      isKey: false,
      name: 'Tenure',
      width: '140',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'startDate',
      dataFormat: dateTimeFormatter,
      dataSort: true,
      isKey: false,
      name: 'Start Date',
      width: '140',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'expectedEndDate',
      dataFormat: dateTimeFormatter,
      dataSort: true,
      isKey: false,
      name: 'Expected End Date',
      width: '140',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'interestRateUnit',
      dataFormat: (cell) => cell,
      dataSort: true,
      isKey: false,
      name: 'Interest Rate Unit',
      width: '140',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'status',
      dataFormat: buttonFormatter,
      dataSort: true,
      isKey: false,
      name: 'Status',
      width: '140',
    },
  ];

  return (
    <div>
      <Header />
      <div className="common-container">
        {/* <SidebarIcon addClassCallBack={addClassCallBack} show={show} /> */}
        <div className={`common-wrapper ${show ? 'active' : ''} `}>
          <div className="col-md-12 mpad">
            <div className="common-heading">
              <h1>Users</h1>
              <Breadcrumb>
                <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>Secured Loans</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          <div className="loan-container mar0">
            <div className="row">
              <div className="col-lg-12">
                <DataTable
                  tableData={usersList}
                  tableHeaderData={tableHeaderData}
                  totalRecords={totalRecords}
                  isFetching={isFetching}
                  getTableData={getUsersData}
                  // exportUrl={userExportUrl}
                  tableTitle="Secured Loans"
                  pageFilters={usersFilters}
                  defaultSortKey={'createdAt'}
                  defaultSortDirection={'desc'}
                  TableLoader={TableLoader}
                />
              </div>
            </div>
          </div>
          <Footer show={show} />
          {/* <div className={`common-side-bar ${show ? 'active' : ''} `}>
          <SideBar addClassCallBack={addClassCallBack} show={show} />
        </div> */}
        </div>
      </div>
    </div>
  );
};

export default SecuredLoans;
