
import moment from 'moment';
import { CONSTANT, DATE, DATERANGE, NUMBER, NUMBERRANGE } from '../constants/form-type';

// Get the initial value for the filter
// @param type : The type for the filter field
export const getInitialValueForForm = (formType) => {
  switch (formType) {
    // [startDate, endDate]
    case DATERANGE: return [moment().subtract(29, 'days'), moment()];
    // [min, max]
    case NUMBERRANGE: return ['', ''];
    case DATE: return new Date();
    default: return ''
  }
}

// To set the initialForm Values
// @Param formFields: An object with the basic field data i.e lable, name, etc
// @Param setForm: A function to set the newly created form to the state
// @Param an ObjContaining the inital State for the form
export const setInitialFormValues = (formFields, setForm, intialValue = {}) => {
  if (formFields && formFields.length) {
    const newForm = [];

    formFields.map((formItemParam) => {
      const formItem = formItemParam;

      formItem.value = intialValue[formItem.name] ? intialValue[formItem.name] : getInitialValueForForm(formItem.type);
      formItem.isValid = false;
      newForm.push({ ...formItem });
      return false;
    });
    setForm(newForm);
  }
  return 0;
}
// To set the form value with given object value
// @Param formFields: An object with the basic field data i.e lable, name, etc
// @Param setForm: A function to set the newly created form to the state
// @Param an ObjContaining the value for the form
export const setFormWithValues = (formFields, setForm, formValues) => {
  if (formFields && formFields.length) {
    const newForm = [];

    formFields.map((formItemParam) => {
      const formItem = formItemParam;

      if (formValues[formItem.name]) {
        formItem.value = formValues[formItem.name];
        formItem.isValid = true;
      }
      newForm.push({ ...formItem });
      return false;
    });
    setForm(newForm);
  }
}

// To get the final form data field value map json object
// @Param formFields: An object containing the complete form
// @Return new object having the key value pair of form name and value.
export const getFormDataValue = (formFields) => {
  const newFormData = {};

  if (formFields && formFields.length) {
    formFields.map((formItem) => {
      switch (formItem.type) {
        case CONSTANT:
          break;
        case DATE:
          newFormData[`${formItem.name}`] = moment(new Date(formItem.value)).format('YYYY-MM-DD')
          break;
        case NUMBER:
          newFormData[`${formItem.name}`] = parseFloat(formItem.value);
          break;
        default:
          newFormData[`${formItem.name}`] = formItem.value;
      }
      return false;
    });
  }
  return newFormData
}

// Check for the form Field value is valid or not
// @param validationRules: an object containes the validation rule for the field
// @param value: updated value for the field
// @return isValid: boolean defining wheter the form is valid or not
export const formFieldValidator = (validationRules, value) => {
  let isValid = true;

  if (validationRules.isRequired) {
    if (!value) {
      isValid = false;
      return [isValid, 'This field is required'];
    }
  }

  if (validationRules.minLength) {
    isValid = value.length >= validationRules.minLength;
    if(!isValid)
      return [isValid, `Please enter Min. ${  validationRules.minLength  } length.`];
  }

  if (validationRules.maxLength) {
    isValid = value.length <= validationRules.maxLength;
    if(!isValid)
      return [isValid, `Please enter Max. ${  validationRules.maxLength  } length.`];

  }
  if (validationRules.isEmail) {
    // eslint-disable-next-line  no-useless-escape, max-len
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    isValid = re.test(String(value).toLowerCase());
    if(!isValid)
      return [isValid, 'Please enter a valid E-Mail address.'];
  }
  return [isValid, ''];
}

// Handle the on change for the Form
// @param form: The object containing the complete form
// @param formField: The name of the form field for which value is updated
// @param formFieldValue: The updated form field value
// @param setIsFormValid: Set based on the updated value whether the form is valid.
export const onChangeHandler = (form, formField, formFieldValue, setForm) => {
  const newForm = JSON.parse(JSON.stringify(form));

  if (newForm && Array.isArray(newForm)) {
    newForm.map((formItemParam) => {
      const formItem = formItemParam

      if (formItem.name === formField) {
        formItem.value = formFieldValue;
        formItem.isTouched = true;
        const [isValid, result] = formFieldValidator(formItem.validation, formItem.value);

        formItem.isValid = isValid;
        formItem.errorMsg = result;
      }
      return false;
    });
    setForm(newForm);
  }
}
// Function to check for the validity of each field and the complete form
// @param form: the Object containing the complete form
// @return result: boolean defining the wheter the form is valid or not
export const validateForm = (newForm) => {
  const form = JSON.parse(JSON.stringify(newForm));
  let result = true;

  if (form && Array.isArray(form)) {
    form.map((formItemParam) => {
      const formItem = formItemParam;

      const [isValid, msg] = formFieldValidator(formItem.validation, formItem.value);

      formItem.isValid = isValid;
      formItem.errorMsg = msg;
      formItem.isTouched = true;
      result = result && isValid;
      return false;
    });
  }
  return [form, result];
}
