import { call, put, takeEvery } from 'redux-saga/effects';

import HttpHelper from '../../utils/http-helper';
import { DISBURSEMENT_CHIP_URL } from '../../utils/urls';
import { DISBURSMENT_CHIP_REQUEST } from './disbursment-constants';
import { disbursmentChipSuccess, disbursmentChipFailure } from './disbursment-chip-action';

const { getRequest } = new HttpHelper();

export function* disbursmentChip() {
  try {
    const response = yield call(getRequest, {
      url: DISBURSEMENT_CHIP_URL,
    });  
    yield put(disbursmentChipSuccess(response.data));
  } catch (error) {
    yield put(disbursmentChipFailure());
  }
}

export function* watchDisbursmentChip() {
  yield takeEvery(DISBURSMENT_CHIP_REQUEST, disbursmentChip);
}


