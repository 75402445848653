import {
  ADD_MANAGE_ALLOCATION_REQUEST,
  ADD_MANAGE_ALLOCATION_SUCCESS,
  ADD_MANAGE_ALLOCATION_FAILURE,
} from './manage-allocations-constants';

const addManageAllocationRequest = (data) => ({
  data,
  type: ADD_MANAGE_ALLOCATION_REQUEST,
});

export const addManageAllocationSuccess = () => ({
  type: ADD_MANAGE_ALLOCATION_SUCCESS,
});

export const addManageAllocationFailure = (data) => ({
  data,
  type: ADD_MANAGE_ALLOCATION_FAILURE,
});

export const addManageAllocation = (
  userDetails,
  addManageAllocationFailureCallback,
  dispatch
) => {
  dispatch(
    addManageAllocationRequest({
      ...userDetails,
      addManageAllocationFailureCallback,
    })
  );
};
