import { call, put, takeEvery } from 'redux-saga/effects';

import HttpHelper from '../../utils/http-helper';
import { UsersUrl } from '../../utils/urls';
import { USERS_REQUEST } from './users-constants';
import { UsersSuccess, UsersFailure } from './users-action';

const { getRequest } = new HttpHelper();

export function* UsersA(action) {

  try {
    const response = yield call(getRequest, {
      url: UsersUrl(action.urlParams),
    });

    const totalRecords = response.headers['x-coreplatform-total-records'];

    yield put(UsersSuccess(response.data, totalRecords));
  } catch (error) {
    yield put(UsersFailure());
  }
}

export function* watchUsers() {
  yield takeEvery(USERS_REQUEST, UsersA);
}
