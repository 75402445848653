import React, { useState } from 'react';

import { Form,Modal } from 'react-bootstrap';
import CloseIconWhite from '../../../../images/icons/closeIconWhite.svg';

const DeleteWithReason = ({
  closeDeleteWithReasonPopup,
  handleDeleteWithreason,
  deleteWithReason,
  setDeleteWithReason
}) => {
  const [value, setValue] = useState('');

  return (
    <Modal
      show={deleteWithReason}
      onHide={() => setDeleteWithReason(false)}
      animation={false}
      className="delete-reason-popup"
    >
      <div className="common-image">
        <div
          className="common-img"
          onClick={() => setDeleteWithReason(false)}
        >
          <img alt="close" src={CloseIconWhite} />
        </div>
      </div>
      <Modal.Body>
        <div>
          <div className="block-user">
            <h1>DELETE</h1>
            <Form className="block-user-reason">
              <div className="custom-input">
                <label>Reason to delete</label>
                <Form.Control
                  as="textarea"
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                  placeholder="write reason to delete data"
                />
              </div>
            </Form>

            <div className="delete-reason-button">
              <label>
                <button className="cancel-btn" onClick={closeDeleteWithReasonPopup}>
                  Cancel
                </button>
              </label>
              <span>
                <button
                  className="black-border-btn"
                  onClick={() => handleDeleteWithreason(value)}
                >
                  OK
                </button>
              </span>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteWithReason;
