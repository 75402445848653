import { SELECT, TEXTAREA ,TEXT, NUMBER } from '../../../constants/form-type';

export const addCallLogsConstant = [
  {
    label: 'Bank Name',
    name: 'userBankDetailId',
    validation: { isRequired: true },
    options: [],
    isDisabled: false,
    type: SELECT,
  },
  {
    label: 'Address',
    name: 'userAddressId',
    validation: { isRequired: true },
    options: [],
    isDisabled: false,
    type: SELECT,
  },
  {
    label: 'Product',
    name: 'productId',
    validation: { isRequired: true },
    options: [],
    isDisabled: false,
    type: SELECT,
  },
  {
    label: 'Loan Purpose',
    name: 'loanPurpose',
    placeholder: 'Loan Purpose',
    validation: { isRequired: true },
    options: [],
    isDisabled: false,
    type: SELECT,
  },
  {
    label: 'Amount',
    name: 'amount',
    placeholder: 'Details goes here',
    validation: { isRequired: true },
    isDisabled: false,
    type: NUMBER,
  },
  {
    label: 'Tenure',
    name: 'tenure',
    placeholder: 'Tenure',
    validation: { isRequired: true },
    isDisabled: false,
    type: NUMBER,
  },
  {
    label: 'Interest Rate',
    name: 'interestRate',
    placeholder: 'Interest Rate',
    validation: { isRequired: true },
    isDisabled: true,
    type: TEXT,
  },
  {
    label: 'Paid Amount',
    name: 'paidAmount',
    placeholder: 'Paid Amount',
    validation: { isRequired: true },
    isDisabled: true,
    type: TEXT,
  },
  {
    label: 'Interest amount',
    name: 'interestAmount',
    placeholder: 'interest Amount',
    validation: { isRequired: true },
    isDisabled: true,
    type: TEXT,
  },
  {
    label: 'Net Amount',
    name: 'netAmount',
    placeholder: 'Net Amount',
    validation: { isRequired: true },
    isDisabled: true,
    type: TEXT,
  },
  {
    label: 'Processing amount',
    name: 'processingAmount',
    placeholder: 'Processing Amount',
    validation: { isRequired: true },
    isDisabled: true,
    type: TEXT,
  },
  {
    label: 'Transfer amount',
    name: 'transferAmount',
    placeholder: 'Transfer Amount',
    validation: { isRequired: true },
    isDisabled: true,
    type: TEXT,
  },
  {
    label: 'Bounce Charges',
    name: 'bounceCharges',
    placeholder: 'Bounce Charges',
    validation: { isRequired: true },
    isDisabled: true,
    type: TEXT,
  },
  {
    label: 'Penal Rate',
    name: 'penalRate',
    placeholder: 'Penal Rate',
    validation: { isRequired: true },
    isDisabled: true,
    type: TEXT,
  },
  {
    label: 'Balance Principal Amount',
    name: 'balancePrincipalAmount',
    placeholder: 'balance principal amount',
    validation: { isRequired: true },
    isDisabled: true,
    type: TEXT,
  },
  {
    label: 'Balance Due Amount',
    name: 'balanceDueAmount',
    placeholder: 'balance due amount',
    validation: { isRequired: true },
    isDisabled: true,
    type: TEXT,
  },
  {
    label: 'Status',
    name: 'status',
    placeholder: 'Rejected',
    validation: { isRequired: true },
    options: [
      { name: 'Initiated', value: 'initiated' },
      { name: 'loan-agreement-signed', value: 'loan-agreement-signed' },
      { name: 'active', value: 'active' },
      { name: 'closed', value: 'closed' },
      { name: 'reversed', value: 'reversed' },
      { name: 'pre-closed', value: 'pre-closed' },
      { name: 'fore-closed', value: 'fore-closed' },
      { name: 'settlement', value: 'settlement' },
    ],
    isDisabled: false,
    type: SELECT,
  },
  {
    label: 'Remarks',
    name: 'remarks',
    placeholder: 'remarks',
    validation: { isRequired: true },
    isDisabled: false,
    type: TEXTAREA,
  },
];
