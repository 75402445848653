import React from 'react';

import { Form } from 'react-bootstrap';
import Select from 'react-select';
import { options ,options1 } from './add-user-constant';
import Loader from '../../Loaders/table-loader'

const AddUser = ({
  closeAddUserPopup,
  addUserDetails,
  setAddUserDetails,
  handleAddUser,
  validated,
  errorResponse,
  setErrorResponse,
  getOtp,
  handleAddOtp,
  addOtp,
  setAddOtp,
  isLoading,
}) => {
  const customStyles = {
    control: (base, state) => ({
      ...base,
      '&:hover': {
        border: state.isFocused ? 'solid 1px #dfdfdf' : 'solid 1px #dfdfdf',
      },
      border: state.isFocused ? 'solid 1px #dfdfdf' : 'solid 1px #dfdfdf',
      // This line disable the blue border
      boxShadow: state.isFocused ? 'solid 1px #dfdfdf' : 'solid 1px #dfdfdf',
    }),
  };

  return (
    <div className="editpopup-sec">
      <div className="editpopup-header">
        <h2>ADD USER</h2>
      </div>
      <div className="editpopup-form-sec">
        {isLoading ?<Loader/> :<Form noValidate validated={validated}>
          <div className="editpopup-input-value">
            <label className="editpopup-input-title">
              Mobile No <span>*</span>
            </label>
            <Form.Control
              required
              type="number"
              placeholder="9998989898"
              name="mobileNo"
              value={addUserDetails.mobileNo}
              onInput={(e) => {
                // eslint-disable-next-line radix
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 10);
              }}
              className={
                errorResponse.field === 'mobileNumber' && 'error-custom-border'
              }
              onChange={(e) => {
                setAddUserDetails({
                  ...addUserDetails,
                  mobileNo: e.target.value,
                });
                setErrorResponse({});
              }}
            />

            <Form.Control.Feedback type="invalid">
              Please enter a mobile number.
            </Form.Control.Feedback>

            {errorResponse && errorResponse.field === 'mobileNumber' && (
              <span className="custom-error-msg">{errorResponse.message}</span>
            )}
          </div>
          {getOtp && <div className="editpopup-input-value mt-1">
            <label className="editpopup-input-title">
              Name <span>*</span>
            </label>
            <Form.Control
              required
              type="text"
              placeholder="Pradeep kumawat"
              name="name"
              value={addOtp.name}
              maxLength={20}
              onChange={(e) =>
                setAddOtp({ ...addOtp, name: e.target.value })
              }
            />
            <Form.Control.Feedback type="invalid">
              Please enter a name.
            </Form.Control.Feedback>
            {errorResponse && errorResponse.field === 'name' && (
              <span className="custom-error-msg">{errorResponse.message}</span>
            )}
          </div>}
          {getOtp && <div className="editpopup-input-value mt-1">
            <label className="editpopup-input-title">
              Father's Name <span>*</span>
            </label>
            <Form.Control
              required
              type="text"
              placeholder="Pradeep kumawat"
              name="fatherName"
              value={addOtp.fathersName}
              maxLength={20}
              onChange={(e) =>
                setAddOtp({ ...addOtp, fathersName: e.target.value })
              }
            />
            <Form.Control.Feedback type="invalid">
              Please enter father's name.
            </Form.Control.Feedback>
            {errorResponse && errorResponse.field === 'fatherName' && (
              <span className="custom-error-msg">{errorResponse.message}</span>
            )}
          </div>}
          {getOtp && <div className="editpopup-input-value mt-1">
            <label className="editpopup-input-title">
            Marital Status <span>*</span>
            </label>
            <Select
              onChange={(e) =>
                setAddOtp({ ...addOtp, martialStatus: e.value })
              }
              styles={customStyles}
              options={options}
              name="maritalStatus"
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: '#d8d8d8',
                  primary25: 'neutral10',
                },
              })}
            />
          </div>}
          {getOtp && <div className="editpopup-input-value mt-1">
            <label className="editpopup-input-title">
              Gender <span>*</span>
            </label>
            <Select
              onChange={(e) =>
                setAddOtp({ ...addOtp, gender: e.value })
              }
              styles={customStyles}
              options={options1}
              name = "gender"
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: '#d8d8d8',
                  primary25: 'neutral10',
                },
              })}
            />
          </div>}
          {getOtp && <div className="editpopup-input-value">
            <label className="editpopup-input-title">
              Email ID <span>*</span>
            </label>
            <Form.Control
              required
              type="email"
              placeholder="pradeep@nextfin.com"
              name="emailId"
              value={addOtp.email}
              className={
                errorResponse.field === 'email' && 'error-custom-border'
              }
              maxLength={30}
              onChange={(e) => {
                setAddOtp({ ...addOtp, email: e.target.value });
                setErrorResponse({});
              }}
            />
            {addUserDetails.email === '' ? (
              <Form.Control.Feedback type="invalid">
                Please enter a email address.
              </Form.Control.Feedback>
            ) : (
              <Form.Control.Feedback type="invalid">
                Please enter a valid email address.
              </Form.Control.Feedback>
            )}

            {errorResponse && errorResponse.field === 'email' && (
              <span className="custom-error-msg">{errorResponse.message}</span>
            )}
          </div> }
          {getOtp && <div className="editpopup-input-value mt-1">
            <label className="editpopup-input-title">
              DOB <span>*</span>
            </label>
            <Form.Control
              required
              type="date"
              name="dob"
              value={addOtp.dob}
              maxLength={20}
              onChange={(e) =>
                setAddOtp({ ...addOtp, dob: e.target.value })
              }
            />
            <Form.Control.Feedback type="invalid">
              Please enter a Date.
            </Form.Control.Feedback>
            {errorResponse && errorResponse.field === 'dob' && (
              <span className="custom-error-msg">{errorResponse.message}</span>
            )}
          </div>}
          {getOtp &&
          <div className="editpopup-input-value">
            <label className="editpopup-input-title">
              OTP <span>*</span>
            </label>
            <Form.Control
              required
              type="number"
              placeholder="123456"
              name="otp"
              value={addOtp.otp}
              className={
                errorResponse.field === 'otp' && 'error-custom-border'
              }
              onChange={(e) => {
                setAddOtp({ ...addOtp,otp: e.target.value });
                // setErrorResponse({});
              }}
            />
            {errorResponse && errorResponse.field === 'otp' && (
              <span className="custom-error-msg">{errorResponse.message}</span>
            )}
          </div>
          }
          <div className="editpopup-input-value">
          </div>
        </Form>
        }
      </div>
      <div className="editpopup-bottom">
        <button className="cancel-btn" onClick={closeAddUserPopup}>
          Cancel
        </button>
        <button className="black-border-btn " onClick={!getOtp ? handleAddUser : handleAddOtp }>
          OK
        </button>
      </div>
    </div>
  );
};

export default AddUser;
