import {
  EMANDATE_INFO_REQUEST,
  EMANDATE_INFO_SUCCESS,
  EMANDATE_INFO_FAILURE,
} from './emandate-constants';

const emandateInfoRequest = (data) => ({
  data,
  type: EMANDATE_INFO_REQUEST,
});

export const emandateInfoSuccess = (data) => ({
  data,
  type: EMANDATE_INFO_SUCCESS,
});

export const emandateInfoFailure = (data) => ({
  data,
  type: EMANDATE_INFO_FAILURE,
});

export const emandate = async (data, dispatch) => {
  dispatch(emandateInfoRequest(data));
};
