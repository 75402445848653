import { call, put, takeEvery } from 'redux-saga/effects';

import HttpHelper from '../../utils/http-helper';
import { SecuredLoansUrl } from '../../utils/urls';
import { SECURED_LOANS_REQUEST } from './secured-loans-constants';
import { SecuredSuccess, SecuredFailure } from './secured-loans-action';

const { getRequest } = new HttpHelper();

export function* SecuredA(action) {

  try {
    const response = yield call(getRequest, {
      url: SecuredLoansUrl(),
    });

    const totalRecords = response.headers['x-coreplatform-total-records'];

    yield put(SecuredSuccess(response.data, totalRecords));
  } catch (error) {
    yield put(SecuredFailure());
  }
}

export function* watchSecured() {
  yield takeEvery(SECURED_LOANS_REQUEST, SecuredA);
}
