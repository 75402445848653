import { TEXT } from '../../constants/form-type';

export const paytmSettingsForm = [
  {
    label: 'Business name',
    name: 'bussinessName',
    placeholder: 'Enter Bussiness name',
    validation: { isRequired: true, isEmail: false, isMobile: false },
    type: TEXT,
  },
  {
    label: 'Payout account number',
    name: 'payoutAccountNumber',
    placeholder: 'Enter account number',
    validation: { isRequired: true, isEmail: false, isMobile: false },
    type: TEXT,
  },
  {
    label: 'Key',
    name: 'key',
    placeholder: 'Enter key',
    validation: { isRequired: true, isEmail: false, isMobile: false },
    type: TEXT,
  },
  {
    label: 'Hashed secret',
    name: 'hashedSecret',
    placeholder: 'Enter Hashed secret',
    validation: { isRequired: true, isEmail: false, isMobile: false },
    type: TEXT,
  },
  {
    label: 'Secret key',
    name: 'secret',
    placeholder: 'Enter secret key',
    validation: { isRequired: true, isEmail: false, isMobile: false },
    type: TEXT,
  },
  {
    label: 'Hashed wallet id',
    name: 'walletId',
    placeholder: 'Enter Wallet Id',
    validation: { isRequired: true, isEmail: false, isMobile: false },
    type: TEXT,
  }
]
export const razorPaysettingsForm = [
  {
    label: 'Business name',
    name: 'bussinessName',
    placeholder: 'Enter Bussiness name',
    validation: { isRequired: true, isEmail: false, isMobile: false },
    type: TEXT,
  },
  {
    label: 'Payout account number',
    name: 'payoutAccountNumber',
    placeholder: 'Enter account number',
    validation: { isRequired: true, isEmail: false, isMobile: false },
    type: TEXT,
  },
  {
    label: 'Key',
    name: 'key',
    placeholder: 'Enter key',
    validation: { isRequired: true, isEmail: false, isMobile: false },
    type: TEXT,
  },
  {
    label: 'Hashed secret',
    name: 'hashedSecret',
    placeholder: 'Enter Hashed secret',
    validation: { isRequired: true, isEmail: false, isMobile: false },
    type: TEXT,
  },
  {
    label: 'Secret key',
    name: 'secret',
    placeholder: 'Enter secret key',
    validation: { isRequired: true, isEmail: false, isMobile: false },
    type: TEXT,
  },
  {
    label: 'Hashed wallet id',
    name: 'walletId',
    placeholder: 'Enter Wallet Id',
    validation: { isRequired: true, isEmail: false, isMobile: false },
    type: TEXT,
  }
]