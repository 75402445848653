import { TEXT, TEXTAREA, SELECT, FILE,SWITCH } from '../../../constants/form-type';

export const addProductFormFields = [
  {
    label: 'Product code',
    name: 'productCode',
    placeholder: 'Enter code',
    validation: { isRequired: true, isEmail: false, isMobile: false },
    type: TEXT,
  }, {
    label: 'Product name',
    name: 'productName',
    placeholder: 'Enter name',
    validation: { isRequired: true, isEmail: false, isMobile: false },
    type: TEXT,
  }, {
    label: 'Partner name',
    name: 'partnerName',
    placeholder: 'Enter name',
    validation: { isRequired: true, isEmail: false, isMobile: false },
    type: TEXT,
  }, {
    label: 'Logo',
    name: 'logo',
    placeholder: 'Select a file/Upload',
    validation: { isRequired: true, isEmail: false, isMobile: false },
    type: FILE,
  }, {
    label: 'website',
    name: 'website',
    placeholder: 'Enter url',
    validation: { isRequired: true, isEmail: false, isMobile: false },
    type: TEXT,
  }, {
    label: 'Terms & Condition',
    name: 'terms',
    placeholder: 'Yes/No',
    validation: { isRequired: true, isEmail: false, isMobile: false },
    type: TEXT,
  },
  {
    label: 'Minimum loan amount',
    name: 'minLoanAmount',
    placeholder: 'Enter the min. loan amount',
    validation: { isRequired: true, isEmail: false, isMobile: false },
    type: TEXT,
  }, {
    label: 'Maximum loan amount',
    name: 'maxLoanAmount',
    placeholder: 'Enter the max. loan amount',
    validation: { isRequired: true, isEmail: false, isMobile: false },
    type: TEXT,
  }, {
    label: 'Minimum loan Day',
    name: 'minLoanDay',
    placeholder: 'Enter the min. loan day',
    validation: { isRequired: true, isEmail: false, isMobile: false },
    type: TEXT,
  }, {
    label: 'Maximum loan Day',
    name: 'maxLoanDay',
    placeholder: 'Enter the max. loan day',
    validation: { isRequired: true, isEmail: false, isMobile: false },
    type: TEXT,
  }, {
    label: 'Minimum Processing Fee',
    name: 'minProcessingFee',
    placeholder: 'Enter the min. processing fee',
    validation: { isRequired: true, isEmail: false, isMobile: false },
    type: TEXT,
  }, {
    label: 'Minimum Interest Rate',
    name: 'minInterRate',
    placeholder: 'Enter the min. interest Rate',
    validation: { isRequired: true, isEmail: false, isMobile: false },
    type: TEXT,
  }, {
    label: 'Maximum Interest Rate',
    name: 'maxInterRate',
    placeholder: 'Enter the max. interest Rate',
    validation: { isRequired: true, isEmail: false, isMobile: false },
    type: TEXT,
  }, {
    label: 'Interest Rate Unit',
    name: 'interestRateUnit',
    placeholder: 'Enter the interest rate unit',
    validation: { isRequired: true, isEmail: false, isMobile: false },
    type: TEXT,
  }, {
    label: 'GST Rate ',
    name: 'gst',
    placeholder: 'Enter the gst rate',
    validation: { isRequired: true, isEmail: false, isMobile: false },
    type: TEXT,
  }, {
    label: 'Penal Chanrges Rate',
    name: 'penalCharges',
    placeholder: 'Enter the penal charges',
    validation: { isRequired: true, isEmail: false, isMobile: false },
    type: TEXT,
  }, {
    label: 'Penal Chanrges Unit',
    name: 'penalChargesUnit',
    placeholder: 'Enter the penal charges unit',
    validation: { isRequired: true, isEmail: false, isMobile: false },
    type: TEXT,
  }, {
    label: 'Minimum Bounce Charges',
    name: 'minBounceCharges',
    placeholder: 'Enter the minimum bounce charges',
    validation: { isRequired: true, isEmail: false, isMobile: false },
    type: TEXT,
  }, {
    label: 'Maximum Bounce Charges',
    name: 'maxBounceCharges',
    placeholder: 'Enter the maximum bounce charges',
    validation: { isRequired: true, isEmail: false, isMobile: false },
    type: TEXT,
  }, {
    label: 'Product Type',
    name: 'productType',
    placeholder: 'Enter the product type',
    validation: { isRequired: true, isEmail: false, isMobile: false },
    type: TEXT,
  }, {
    label: 'Minimum Tenure',
    name: 'minTenure',
    placeholder: 'minimum Tenure',
    validation: { isRequired: true, isEmail: false, isMobile: false },
    type: TEXT,
  }, {
    label: 'Maximum Tenure',
    name: 'maxTenure',
    placeholder: 'maximum Tenure',
    validation: { isRequired: true, isEmail: false, isMobile: false },
    type: TEXT,
  }, {
    label: 'Salary Mode',
    name: 'salaryMode',
    placeholder: 'Select',
    validation: { isRequired: true, isEmail: false, isMobile: false },
    options: [{ name: 'salaried', value: 'salaried' }],
    type: SELECT,
  }, {
    label: 'Employment type',
    name: 'employment type',
    placeholder: 'Select',
    validation: { isRequired: true, isEmail: false, isMobile: false },
    options: [{ name: 'salaried', value: 'salaried' }],
    type: SELECT,
  },
  {
    label: 'Pin code validation',
    name: 'pinValidation',
    placeholder: 'Select',
    validation: { isRequired: true, isEmail: false, isMobile: false },
    type: SWITCH,
  }, {
    label: 'Company validation',
    name: 'companyValidation',
    placeholder: 'Select',
    validation: { isRequired: true, isEmail: false, isMobile: false },
    type: SWITCH,
  }, {
    label: 'Descriptions',
    name: 'description',
    placeholder: 'details goes here',
    validation: { isRequired: true, isEmail: false, isMobile: false },
    type: TEXTAREA,
  }
];
