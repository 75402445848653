import {
  SET_OTP_FALIURE,
  SET_OTP_REQUEST,
  SET_OTP_SUCCESS,
} from './users-constants';

const initialState = {
  isLoading: false,
  Success: false,
  error: false,
};

const sendOtpUser = (state = initialState, action) => {
  switch (action.type) {
    case SET_OTP_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case SET_OTP_FALIURE:
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    case SET_OTP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
      };
    default:
      return state;
  }
};

export default sendOtpUser;
