import {
  DISBURSE_LOAN_FAILURE,
  DISBURSE_LOAN_NOW_REQUEST,
  DISBURSE_LOAN_SUCCESS
} from './disburse-loan-constants';

const disburseLoanRequest = (data) => ({
  data,
  type: DISBURSE_LOAN_NOW_REQUEST,
});

export const disburseLoanSuccess = (data) => ({
  data,
  type: DISBURSE_LOAN_SUCCESS,
});

export const disburseLoanFailure = (data) => ({
  data,
  type: DISBURSE_LOAN_FAILURE,
});

export const disburseLoan = async (data, dispatch) => {
  dispatch(disburseLoanRequest(data));
};
