import {
  GET_DEFAULT_REPORTS_REQUEST,
  GET_DEFAULT_REPORTS_SUCCESS,
  GET_DEFAULT_REPORTS_FAILURE,
} from './defaults-constants';

const getDefaultReportsRequest = (urlParams) => ({
  type: GET_DEFAULT_REPORTS_REQUEST,
  urlParams,
});

export const getDefaultReportsSuccess = (data) => ({
  data,
  type: GET_DEFAULT_REPORTS_SUCCESS,
});

export const getDefaultReportsFailure = () => ({
  type: GET_DEFAULT_REPORTS_FAILURE,
});

export const getDefaultReports = async (dispatch) => {
  dispatch(getDefaultReportsRequest());
};
