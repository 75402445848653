import { call, put, takeEvery } from 'redux-saga/effects';

import HttpHelper from '../../utils/http-helper';
import { viewPartnerInfo, viewProducts, partnerRazorPayInfo, partnerPaytmInfo } from '../../utils/urls';
import { VIEW_PARTNER_REQUEST } from './view-partner-constants';
import { viewPartnerFailure, viewPartnerSuccess } from './view-partner-action';

const { getRequest } = new HttpHelper();

export function* viewPartner(action) {
  try {
    const url = viewPartnerInfo(action.id);
    const response = yield call(getRequest, {
      url,
    });
    const urlProduct = viewProducts();
    const responseProduct = yield call(getRequest, {
      url: urlProduct,
    });

    const urlRazor = partnerRazorPayInfo(action.id);
    const responseRazor = yield call(getRequest, {
      url: urlRazor,
    });

    const urlPaytm = partnerPaytmInfo(action.id);
    const responsePaytm = yield call(getRequest, {
      url: urlPaytm,
    });

    yield put(viewPartnerSuccess(response.data, responseProduct.data, responseRazor.data, responsePaytm.data));
  } catch (error) {
    yield put(viewPartnerFailure());
  }
}

export function* watchViewPartner() {
  yield takeEvery(VIEW_PARTNER_REQUEST, viewPartner);
}
