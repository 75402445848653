import {
  TEXT,
  TEXTAREA,
  MULTISELECT,
  FILE,
} from '../../../constants/form-type';

export const addPartnerFormFields = [
  {
    label: 'Partner code',
    name: 'partnerCode',
    placeholder: 'Enter code',
    validation: { isRequired: true, isEmail: false, isMobile: false },
    type: TEXT,
  },
  {
    label: 'Partner name',
    name: 'partnerName',
    placeholder: 'Enter name',
    validation: { isRequired: true, isEmail: false, isMobile: false },
    type: TEXT,
  },
  {
    label: 'Logo',
    name: 'logo',
    placeholder: 'Select a file/Upload',
    validation: { isRequired: true, isEmail: false, isMobile: false },
    type: FILE,
  },
  {
    label: 'Partner Tags',
    name: 'tags',
    placeholder: '',
    validation: { isRequired: true, isEmail: false, isMobile: false },
    type: MULTISELECT,
    options: [
      { label: 'Personal Loan', value: 'personal-loan' },
      { label: 'Business Loan', value: 'business-loan' },
      { label: 'Health Insurance', value: 'health-insurance' },
      { label: 'Life Insurance', value: 'life-insurance' },
    ],
  },
  {
    label: 'Descriptions',
    name: 'description',
    placeholder: 'details goes here',
    validation: { isRequired: true, isEmail: false, isMobile: false },
    type: TEXTAREA,
  },
];
