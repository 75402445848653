import {
  MANAGE_PARTNER_FAILURE,
  MANAGE_PARTNER_REQUEST,
  MANAGE_PARTNER_SUCCESS
} from './manage-partner-constants';

const managePartnerRequest = (urlParams) => ({
  type: MANAGE_PARTNER_REQUEST,
  urlParams,
});

export const managePartnerSuccess = (data, totalRecords) => ({
  data,
  totalRecords,
  type: MANAGE_PARTNER_SUCCESS,
});

export const managePartnerFailure = () => ({
  type: MANAGE_PARTNER_FAILURE,
});

export const managePartner = async (urlParams, dispatch) => {
  dispatch(managePartnerRequest(urlParams));
};
