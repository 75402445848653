import { GST_FAILURE,GST_SUCCESS,GST_REQUEST } from './gst-constants';

const initialState = {
  isFetching: false,
  gstDetails: [],
  totalRecords: '',
};

const gst = (state = initialState, action) => {
  switch (action.type) {
    case GST_REQUEST:
      return {
        ...state,
        isFetching: true,
        gstDetails: [],
      };
    case GST_FAILURE:
      return {
        ...state,
        isFetching: false,
        gstDetails: [],
      };
    case GST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        gstDetails: action.data,
        totalRecords: action.totalRecords,
      };
    default:
      return state;
  }
};

export default gst;
