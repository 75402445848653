// This is used to determine if a user is authenticated and
// if they are allowed to visit the page they navigated to.

// If they are: they proceed to the page
// If not: they are redirected to the login page.
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import User from '../../utils/user';
import NotFound404 from '../../public/not-found-404';

import Storage from '../../utils/storage';

const { isAuthenticated } = new User();
const storage = new Storage();

const PrivateRoutes = ({ component: Component, permissions ,path,...rest }) => {
  const userLoggedIn = isAuthenticated();
  const userToken = storage.get('token');

  if(path !== '/resetpassword'){
    if(userToken !== '{}'){
      const getUserRole = () => {
        const { role } = JSON.parse(atob(userToken.split('.')[1]));

        return role
      };
      const role = getUserRole();

      if(!permissions.includes('All')){
        if(!permissions.includes(role)){
          return <Route exact path="*" component={NotFound404} />
        }
      }
    }
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        userLoggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: '/signin', state: { from: props.location } }}
          />
        )
      }
    />
  );
};

export default PrivateRoutes;