import React, { useState, useEffect } from 'react';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import signout from '../../actions/signout-action';

import ProfileImage from '../../../images/icons/default-user-profile.svg';
import HeaderLogo1 from '../../../images/header-logo-1.jpg';
import ArrowIconDownGray from '../../../images/icons/arrowIcondownGray.png';
import config from '../../../config';
import {
  LINK,
  DROPDOWN,
  HeadLinksAdmin,
  HeaderLinksCreditManager,
  HeaderLinksCashCollection,
  HeaderLinksCallCenterStaff,
} from './header-constant';
import { toDateTimeFormat } from '../../utils/formattor';

import Utils from '../../utils/index';

const Header = () => {
  const [activePage, setActivePage] = useState('');
  const [innerActivePage, setInnerActivePage] = useState('');
  const [HeaderLinks, setHeaderLinks] = useState(HeadLinksAdmin);
  const [currentDateAndTime, setCurrentTimeAndDate] = useState(new Date());
  const [role, setRole] = useState('system-admin');

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTimeAndDate(new Date());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  React.useEffect(() => {
    const getRole = new Utils();

    setRole(getRole.getUserRole());
    if (role !== 'system-admin') {
      if (role === 'call-center-staff') {
        setHeaderLinks(HeaderLinksCallCenterStaff);
      } else if (role === 'credit-manager' || role === 'credit-head') {
        setHeaderLinks(HeaderLinksCreditManager);
      } else if (role === 'collection-staff') {
        setHeaderLinks(HeaderLinksCashCollection);
      }
    }
  }, [role, HeaderLinks]);

  // To Check for the Path in the Dropdown links and get the index if present;
  // @param Absolute path of the current location href;
  // return index of the Header nav item or -1 if not present;
  const checkForIndexOfCurrentRouteInDropDown = (path) => {
    let result = -1;

    HeaderLinks.map((item, index) => {
      if (item.type === DROPDOWN) {
        item.dropdownItem.map((dropdownitem) => {
          if (dropdownitem.href === path) {
            result = index;
          }
          return false;
        });
      }
      return false;
    });
    return result;
  };

  React.useEffect(() => {
    const path = window.location.pathname;
    const isPathInDropDown = checkForIndexOfCurrentRouteInDropDown(path);

    if (isPathInDropDown !== -1) {
      setActivePage(HeaderLinks[isPathInDropDown].label);
      setInnerActivePage(path);
    } else {
      setActivePage(path);
    }
  });
  const downloadDPDAndCRIFCSV = (label) => {
    if (label === 'DPD') {
      window.location.href = `${
        config.REPORTING_SERVICE_URL
      }/dpd/export/csv?access_token=${localStorage.getItem('token')}`;
    }
    if (label === 'CRIF') {
      window.location.href = `${
        config.REPORTING_SERVICE_URL
      }/crif/export/csv?access_token=${localStorage.getItem('token')}`;
    }
  };

  // To get the header link and dropdown based on the common json obj
  const getHeaderLinks = () => {
    return HeaderLinks.map((item, index) => {
      switch (item.type) {
        case LINK:
          return (
            <Link
              key={index}
              to={item.href}
              className={`${activePage === item.href && 'active'} nav-link`}
            >
              {item.label}
            </Link>
          );
        case DROPDOWN:
          return (
            <div className="web-header-nav-drop-down" key={index}>
              <div className="web-header-nav-drop-down-img">
                <img src={ArrowIconDownGray} />
              </div>
              <NavDropdown
                title={item.label}
                id="basic-nav-dropdown"
                className={`${activePage === item.label && 'active'}`}
              >
                {item.dropdownItem &&
                  item.dropdownItem.map((dropdownItem, dropdownIndex) => {
                    return (
                      <React.Fragment key={dropdownIndex}>
                        {dropdownItem.label === 'DPD' ||
                        dropdownItem.label === 'CRIF' ? (
                          <span
                            onClick={() =>
                              downloadDPDAndCRIFCSV(dropdownItem.label)
                            }
                          >
                            <Link
                              to={''}
                              className={`${
                                innerActivePage === dropdownItem.href &&
                                'active'
                              } dropdown-item`}
                            >
                              {dropdownItem.label}
                            </Link>
                          </span>
                        ) : (
                          <Link
                            to={dropdownItem.href}
                            className={`${
                              innerActivePage === dropdownItem.href && 'active'
                            } dropdown-item`}
                          >
                            {dropdownItem.label}
                          </Link>
                        )}

                        {dropdownIndex !== item.dropdownItem.length && (
                          <NavDropdown.Divider />
                        )}
                      </React.Fragment>
                    );
                  })}
              </NavDropdown>
            </div>
          );
        default:
          return null;
      }
    });
  };

  return (
    <div className="header-section">
      <Navbar bg="light" expand="lg">
        <div className="header-logo">
          <Navbar.Brand>
            <Link to={'/dashboard'}>
              <img
                alt="app-logo"
                width="70"
                height="70"
                src={HeaderLogo1}
                alt="HeaderLogo"
              />
            </Link>
          </Navbar.Brand>
          <div className="date-time-custom">
            {toDateTimeFormat(currentDateAndTime)}
          </div>
        </div>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="header-menu">
            {getHeaderLinks()}
            <Nav.Link className="mobile-nav" href="/changepassword">
              Change Password
            </Nav.Link>
            <Nav.Link className="mobile-nav" href="/signin" onClick={signout}>
              Logout
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
        <div
          className="header-user"
          id="basic-nav-dropdown"
          style={{ fontSize: 'medium' }}
        >
          <div>
            <div className="profile-img">
              <img
                alt="default-profile"
                src={ProfileImage}
                alt="ProfileImage"
              />
            </div>
            <NavDropdown title="profile">
              <NavDropdown.Item href="/changepassword" className="header-item">
                Change Password
              </NavDropdown.Item>

              <NavDropdown.Divider />
              <NavDropdown.Item
                href="/signin"
                onClick={signout}
                className="header-item"
              >
                Logout
              </NavDropdown.Item>
            </NavDropdown>
          </div>
        </div>
      </Navbar>
    </div>
  );
};

export default Header;
