import React from 'react';

const ErrorSmall = () => (
  <svg width="33px" height="30px" viewBox="0 0 33 30" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
    <title>alert</title>
    <desc>Created with Sketch.</desc>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="desktopUserDetailsRemark" transform="translate(-327.000000, -165.000000)" fill="#DC3545" fill-rule="nonzero">
        <g id="Group-7-Copy-3" transform="translate(311.000000, 147.000000)">
          <g id="alert" transform="translate(16.000000, 18.203125)">
            <path d="M32.5011029,25.134756 L18.9318965,1.74410762 C18.308025,0.689771217 17.4112096,0.064979273 16.4754023,0.064979273 C15.500603,0.064979273 14.6037876,0.689771217 14.018908,1.74410762 L0.527685618,25.134756 C-0.0961859388,26.1890924 -0.174169883,27.2824783 0.332725756,28.1025178 C0.800629423,28.9225572 1.77542873,29.3911511 3.02317184,29.3911511 L30.0056167,29.3911511 C31.2143678,29.3911511 32.1891671,28.9225572 32.6960627,28.1025178 C33.1639664,27.2824783 33.0859825,26.1890924 32.5011029,25.134756 Z M31.2143678,27.2824783 C31.0194079,27.5948743 30.5904962,27.7510723 29.9666247,27.7510723 L2.98417987,27.7510723 C2.36030831,27.7510723 1.89240465,27.5558248 1.73643676,27.2824783 C1.5414769,26.9700824 1.61946084,26.5014884 1.93139662,25.9547955 L15.422619,2.56414705 C15.7345548,2.0174541 16.1244745,1.70505813 16.4754023,1.70505813 C16.82633,1.70505813 17.2162497,2.0174541 17.5281855,2.56414705 L31.0194079,25.9547955 C31.3313437,26.5014884 31.4093277,26.9700824 31.2143678,27.2824783 Z" id="Shape"></path>
            <path d="M16.5,7.6059949 C16.0285714,7.6059949 15.675,7.95948001 15.675,8.43079348 L15.675,19.3495557 C15.675,19.8208692 16.0285714,20.1743543 16.5,20.1743543 C16.9714286,20.1743543 17.325,19.8208692 17.325,19.3495557 L17.325,8.43079348 C17.325,7.95948001 16.9714286,7.6059949 16.5,7.6059949 Z" id="Path"></path>
            <ellipse id="Oval" cx="16.9125" cy="23.1069715" rx="1.2375" ry="1.25683594"></ellipse>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default ErrorSmall;