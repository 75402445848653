import {
  VIEW_PARTNER_FAILURE,
  VIEW_PARTNER_REQUEST,
  VIEW_PARTNER_SUCCESS
} from './view-partner-constants';

const viewPartnerRequest = (id) => ({
  id,
  type: VIEW_PARTNER_REQUEST,
});

export const viewPartnerSuccess = (data, productProduct, razorPay, paytm) => ({
  data,
  productProduct,
  razorPay,
  paytm,
  type: VIEW_PARTNER_SUCCESS,
});

export const viewPartnerFailure = () => ({
  type: VIEW_PARTNER_FAILURE,
});

export const viewPartnerInfo = async (id, dispatch) => {
  dispatch(viewPartnerRequest(id));
};
