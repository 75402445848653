import React, { useState, useEffect } from 'react';
import { userDetailsKeyValueMap } from './user-details-const';
import './user-details-basic.css';
import FieldValue from '../../field-key-value';
import { getValueForKeyInObj } from '../../../utils/table-data-helper';
import './style.css';

const UserDetailsBasicExtra = ({
  userProfileData,
  updateProfileDetails,
  companyData,
}) => {
  const [keyValueMap, setKeyValueMap] = useState(userDetailsKeyValueMap);
  const [userWorkEmail, setUserWorkEmail] = useState('');
  const [companyOptions, setCompanyOptions] = useState([]);

  useEffect(() => {
    const companyOptionsArray = [];

    // eslint-disable-next-line no-unused-expressions
    companyData &&
      companyData.map((item1) => {
        companyOptionsArray.push({ name: item1.name, value: item1.name });
      });

    setCompanyOptions(companyOptionsArray);
  }, [companyData]);

  const onChangeHandler1 = (value) => {
    setUserWorkEmail(value);
  };

  const onChangeHandler = (value, index) => {
    const newUserState = JSON.parse(JSON.stringify(userDetailsKeyValueMap));

    if (newUserState[index].key !== 'workEmail') {
      newUserState[index].value = value;
      setKeyValueMap(newUserState);
      const payload = {};

      if (newUserState[index].name2) {
        payload[`${newUserState[index].name}`] = parseInt(
          value.split('-')[0].trim(),
          10
        );
        payload[`${newUserState[index].name2}`] = parseInt(
          value.split('-')[1].trim(),
          10
        );
      } else {
        payload[`${newUserState[index].name}`] = value;
      }
      updateProfileDetails(payload);
    }
  };

  const onSubmitHandler = (value, index) => {
    const newUserState = JSON.parse(JSON.stringify(userDetailsKeyValueMap));

    if (userWorkEmail !== '') {
      newUserState[index].value = userWorkEmail;
      setKeyValueMap(newUserState);
      const payload = {};

      payload.workEmail = userWorkEmail;
      updateProfileDetails(payload);
    }
    return false;
  };

  return (
    <React.Fragment>
      <div className="user-details-basic-extra-wrapper">
        <div className="d-flex align-items-baseline">
          <h4 className="personal-common-heading mt-3">User Details</h4>
        </div>
        <div className="personal-heading-container mt-4"></div>
        {keyValueMap.map((item, index) => (
          <FieldValue
            key={index}
            fieldName={item.label}
            fieldValue={
              getValueForKeyInObj(userProfileData, item.key) +
              (item.key2
                ? ` - ${getValueForKeyInObj(userProfileData, item.key2)}`
                : '')
            }
            type={item.type}
            options={item.key === 'companyName' ? companyOptions : item.options}
            verifyStatus={
              userProfileData &&
              item.verifyStatusKey &&
              userProfileData[item.verifyStatusKey]
            }
            valueForForm={item.value}
            onChangeHandler={
              item.key !== 'workEmail'
                ? (e) => onChangeHandler(e, index)
                : (e) => onChangeHandler1(e, index)
            }
            onSubmitHandler={(e) => {
              onSubmitHandler(e, index);
            }}
          />
        ))}
        <div className="col-md-12" style={{ left: '-15px' }}>
          <div className="field-value-wrapper">
            <div className="field-name">Total Work Experience</div>
            <div className="field-value">
              {userProfileData.content
                ? `${userProfileData.content.totalWorkExperience.year} year ${userProfileData.content.totalWorkExperience.month} month` ||
                  '-'
                : '-'}
            </div>
          </div>
        </div>
        <div className="col-md-12" style={{ left: '-15px' }}>
          <div className="field-value-wrapper">
            <div className="field-name">Current Work experience</div>
            <div className="field-value">
              {userProfileData.content
                ? `${userProfileData.content.workExperienceInCurrentCompany.year} year ${userProfileData.content.workExperienceInCurrentCompany.month} month` ||
                  '-'
                : '-'}
            </div>
          </div>
        </div>
        <div className="col-md-12" style={{ left: '-15px' }}>
          <div className="field-value-wrapper">
            <div className="field-name">Salary Mode</div>
            <div className="field-value">
              {userProfileData.salaryMode ? userProfileData.salaryMode : '-'}
            </div>
          </div>
        </div>
        <div className="col-md-12" style={{ left: '-15px' }}>
          <div className="field-value-wrapper">
            <div className="field-name">Father's Name</div>
            <div className="field-value">
              {userProfileData.fatherName ? userProfileData.fatherName : '-'}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UserDetailsBasicExtra;
