import React, { useState, useEffect } from 'react';
import { Form, Modal } from 'react-bootstrap';
import CloseIconWhite from '../../../../images/icons/closeIconWhite.svg';
import './add-new-loan.css';
import FormField from '../../form-elements';
import {
  setInitialFormValues,
  formFieldValidator,
  getFormDataValue,
  validateForm,
} from '../../../utils/form-helper';
import { addCallLogsConstant } from './add-new-loan-constants';

const roundOff02Decimal = (amount) => {
  if (amount >= -0.099 && amount <= 0.089) {
    return Math.round(amount);
  }

  return amount;
};

const AddNewLoans = ({
  closeaddNewLoansPopup,
  addNewLoans,
  setAddnewLoan,
  addNewLoanshandler,
  bankDetail,
  address,
  productData,
  loanPurposeData,
  getProductSettings,
  productSettingsData,
}) => {
  const [addCallLogsForm, setAddCallLogsForm] = useState([]);
  const [bankDetailOptions,setBankDetailOptions] = useState([]);
  const [addressDetailOptions,setAddressDetailOptions] = useState([]);
  const [productDetailsOptions,setProducrDetailsOptions] = useState([]);
  const [loanPurposeDetailsOptions,setLoanPurposeDetailsOptions] = useState([]);
  const [postInputValues,setPostInputValues] = useState([]);
  const [inputData , setInputData] = useState({
    amount: '',
    tenure: ''
  });

  useEffect (()=>{
    const bankDetailArray = [];
    const addressDetailArray = [];
    const productDataArray = [];
    const loanPurposeDetailArray = [];
    bankDetail && bankDetail.map((item1) => {
      bankDetailArray.push({name:item1.bankName,value:item1.publicId})
    });
    
    setBankDetailOptions(bankDetailArray);

    address && address.map((item2) => {
      addressDetailArray.push({name:`${item2.addressLine1},${item2.districtName},${item2.stateName}`,value:item2.publicId})
    });
    
    setAddressDetailOptions(addressDetailArray);
    
    productData.length > 0 && productDataArray.push({name:productData[0].type,value:productData[0].publicId})
    
    setProducrDetailsOptions(productDataArray);
    
    loanPurposeData && loanPurposeData.map((item3) => {
      loanPurposeDetailArray.push({name:item3.name,value:item3.name})
    });
    setLoanPurposeDetailsOptions(loanPurposeDetailArray);

  },[bankDetail,address,productData,loanPurposeData]);

  useEffect(() => {
    addCallLogsConstant.map((item) => {
      if(item.name === 'userBankDetailId'){
       item.options = bankDetailOptions;
      }
      if(item.name === 'userAddressId'){
       item.options = addressDetailOptions; 
      }
      if(item.name === 'productId'){
       item.options = productDetailsOptions;
      };
      if(item.name === 'loanPurpose'){
        item.options = loanPurposeDetailsOptions;
      };
    });

    setInitialFormValues(addCallLogsConstant, setAddCallLogsForm);
  }, [addNewLoans]);
 
  useEffect(() => {
    // console.log(productSettingsData)
  
    const { amount ,tenure } = inputData;
    const getFields = () => {
      if(productSettingsData.settings && amount !== '' && tenure !== '' ){
        const {
          settings: {
            bounceCharges: { amount: bounceCharge }, loan: [ {
              interestRate,
              interestRateUnit,
            } ], processingCharges: [ {
              processingFee,
              processingRate,
            } ],
            penalCharges: { penalRate, penalRateUnit },
          },
        } = productSettingsData;
          let roi = interestRate;
      
          let simpleInterestAmountPerDay = ((parseInt(amount) * roi * parseInt(tenure) * 0.01) / 30);
      
          if (interestRateUnit === 'month') {
            roi = interestRate * 30;
            simpleInterestAmountPerDay = ((parseInt(amount) * roi * parseInt(tenure) * 0.01) / 30);
          }
      
          if (interestRateUnit === 'year') {
            simpleInterestAmountPerDay = parseInt(amount) + ((parseInt(amount) * roi * parseInt(tenure) * 0.01) / 360 / 30);
          }
          const totalprocessingFee = roundOff02Decimal(processingFee + (processingFee * processingRate * 0.01));
          const interestAmount = roundOff02Decimal(simpleInterestAmountPerDay * parseInt(tenure));
          const transferAmount = roundOff02Decimal(parseInt(amount) - totalprocessingFee);
          const netAmount = parseInt(amount) + roundOff02Decimal(simpleInterestAmountPerDay * parseInt(tenure));
          const processingAmount = totalprocessingFee;
          const bounceCharges = bounceCharge;
          const balanceDueAmount = parseInt(amount) + roundOff02Decimal(simpleInterestAmountPerDay * parseInt(tenure));
          const balancePrincipalAmount = parseInt(amount);
          return [{totalprocessingFee,interestAmount,transferAmount,netAmount,processingAmount,bounceCharges,balanceDueAmount,balancePrincipalAmount,penalRate,penalRateUnit}]
      }
    }
    setPostInputValues(getFields());
    
  },[inputData]);

  useEffect (() => {
    console.log(postInputValues)
    // postInputValues && addCallLogsConstant.map(({name,label}) => {
    //   if(postInputValues[0]){
    //     if(postInputValues[0].hasOwnProperty(name)){
    //       label = postInputValues[0][name];
    //     }
    //   }
    // });    
  },[postInputValues])

  const handleAddAddress = () => {
    const [newAddCallLogForm, isValid] = validateForm(addCallLogsForm);

    setAddCallLogsForm(newAddCallLogForm);
    if (isValid) {
      const addCallObj = getFormDataValue(addCallLogsForm);

      addNewLoanshandler({ ...addCallObj });
      closeaddNewLoansPopup();
    }
  };

  const onChangeHandler = (form, formField, formFieldValue, setForm) => {
    const newForm = JSON.parse(JSON.stringify(form));
    
    if(formField === "productId"){
      getProductSettings(formFieldValue);
    };

    if(formField === "amount"){
      setInputData({...inputData,['amount']:formFieldValue})
    }

    if(formField === "tenure"){
      setInputData({...inputData,['tenure']:formFieldValue})
    }

    if (newForm && Array.isArray(newForm)) {
      newForm.map((formItemParam) => {
        const formItem = formItemParam
  
        if (formItem.name === formField) {
          formItem.value = formFieldValue;
          formItem.isTouched = true;
          const [isValid, result] = formFieldValidator(formItem.validation, formItem.value);
  
          formItem.isValid = isValid;
          formItem.errorMsg = result;
        }
        return false;
      });
      setForm(newForm);
    }
  }

  return (
    <Modal
      show={addNewLoans}
      onHide={() => setAddnewLoan(false)}
      animation={false}
      className="delete-reason-popup"
    >
      <div className="common-image">
        <div className="common-img" onClick={() => setAddnewLoan(false)}>
          <img alt="close" src={CloseIconWhite} />
        </div>
      </div>
      <Modal.Body>
        <div className="editaddress-sec">
          <div className="editaddress-header">
            <h2>Add New Loan</h2>
          </div>
          <div className="editaddress-form-sec">
            <Form className="add-new-address-form">
              <div className="add-call-log-form-wrapper">
                <div className='row'>
                {addCallLogsForm.map((formItem, index) => (
                  <div className='col-md-6'>
                  <FormField
                    inputType={formItem.type}
                    key={index}
                    lableName={formItem.label}
                    name={formItem.name}
                    className={`no-bottom-margin-${formItem.name}`}
                    placeholder={formItem.placeholder}
                    value={formItem.value}
                    options={formItem.options}
                    onChangeHandler={(targetValue) => {
                      onChangeHandler(
                        addCallLogsForm,
                        formItem.name,
                        targetValue,
                        setAddCallLogsForm
                      );
                    }}
                    isValid={formItem.isValid}
                    errorMsg={formItem.errorMsg}
                    isDisabled={formItem.isDisabled}
                    isTouched={formItem.isTouched}
                  />
                </div>
                ))}
               </div>
              </div>
            </Form>
            {/* <span>*Field are required</span> */}
          </div>
          <div className="editaddress-bottom">
            <div className="editaddress-select">
              <button
                className="cancel-btn btn-align"
                onClick={closeaddNewLoansPopup}
              >
                Cancel
              </button>
              <button className="black-border-btn" onClick={handleAddAddress}>
                OK
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddNewLoans;
