import {
  TEXT,
  DATE_RANGE_TYPE,
  NUMBER,
} from '../../constants/data-table';

export const GST_REQUEST = 'GST_REQUEST';
export const GST_SUCCESS = 'GST_SUCCESS';
export const GST_FAILURE = 'GST_FAILURE';

export const loanFilters = [
  {
    name: 'Loan Number',
    value: 'number',
    filterCondition: 'eq',
    placeholder: 'ML-001230',
    type: TEXT,
  },
  {
    name: 'Name',
    value: 'name',
    filterCondition: 'ilike',
    placeholder: 'Rahul Kumar',
    type: TEXT,
  },
  {
    name: 'Mobile Number',
    value: 'mobileNumber',
    filterCondition: 'eq',
    placeholder: '9876543263',
    type: NUMBER,
  },
  {
    name: 'Disbursed Date',
    value: 'disbursedDate',
    filterCondition: 'eq',
    type: DATE_RANGE_TYPE,
    startDatevalue: 'disbursedDate',
    startDateFilterCondition: 'gte',
    endDateFilterCondition: 'lte',
    endDatevalue: 'disbursedDate',
  }
];
