import { TEXTAREA } from '../../../constants/form-type';

export const disburseLoanRemarkFormFields = [
  {
    label: 'Remarks',
    name: 'remarks',
    placeholder: 'Please enter your remark',
    validation: { isRequired: true },
    type: TEXTAREA,
  },
];
