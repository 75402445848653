import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import CloseIconWhite from '../../../../images/icons/closeIconWhite.svg';
import Button from '../../../components/form-elements/button';
import FormElement from '../../../components/form-elements';
import { disburseLoanRemarkFormFields } from './disburse-loan-form-constant';
import { onChangeHandler, setInitialFormValues, getFormDataValue, validateForm } from '../../../utils/form-helper';
import { disburseLoan } from '../disburse-loan-action';

const DisburseLoan = ({ showDisburseRemark, closeDisburseRemark, loanId }) => {
  const [disburseLoanRemarkForm, setDisburseLoanRemarkForm] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    setInitialFormValues(disburseLoanRemarkFormFields, setDisburseLoanRemarkForm);
  }, []);

  const disburseNowButtonHandler = () => {
    const [newForm, isValid] = validateForm(disburseLoanRemarkForm);

    setDisburseLoanRemarkForm(newForm);
    if (isValid) {
      const { remarks } = getFormDataValue(disburseLoanRemarkForm);
      const disburseObj = {
        remarks,
        loanId
      };

      disburseLoan(disburseObj, dispatch);
      closeDisburseRemark();
    }
  }

  return (
    <React.Fragment>
      <Modal
        size="lg"
        show={showDisburseRemark}
        onHide={closeDisburseRemark}
        backdropClassName="loan-modal-overlay-background"
        dialogClassName="auto-payment-modal-dialoag"
        aria-labelledby="auto-payment-pop-up"
        centered
      >
        <Modal.Body>
          <div className="auto-payment-modal-container">
            <span className="loan-modal-close-button" onClick={closeDisburseRemark}> <img src={CloseIconWhite} /></span>
            <div className="loan-modal-title">
                            Disburse Loan
            </div>
            {disburseLoanRemarkForm.map((formItem, index) =>
              <div className={""} key={index}>
                <FormElement
                  inputType={formItem.type}
                  lableName={formItem.label}
                  name={formItem.name}
                  placeholder={formItem.placeholder}
                  value={formItem.value}
                  onChangeHandler={(targetValue) => { onChangeHandler(disburseLoanRemarkForm, formItem.name, targetValue, setDisburseLoanRemarkForm) }}
                  isValid={formItem.isValid}
                  errorMsg={formItem.errorMsg}
                  isDisabled={formItem.isDisabled}
                  isTouched={formItem.isTouched}
                />
                <Button text="Disburse Now" click={() => { disburseNowButtonHandler() }} />
                <Button text="Cancel" click={() => { closeDisburseRemark() }} isMarginLeft={true} />
                <br></br>
                <br></br>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}

export default DisburseLoan;