import {
  ADD_NEW_USER_REQUEST,
  ADD_NEW_USER_SUCCESS,
  ADD_NEW_USER_FAILURE,
  GET_USER_OTP_USER_SUCCESS,
} from './users-constants';

const initialState = {
  isLoading: false,
  getOtp: false,
};

const addNewUser = (state = initialState, action) => {
  switch (action.type) {
    case ADD_NEW_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ADD_NEW_USER_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case GET_USER_OTP_USER_SUCCESS:
      return {
        ...state,
        getOtp: true,
        isLoading: false
      };
    case ADD_NEW_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default addNewUser;
