import React, { useState, useEffect } from 'react';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import './user-bank-details.css';
import { userDetailsKeyValueMap } from './user-bank-details-constant';
import FieldValue from '../../field-key-value';

const UserBankDetails = ({ userBankDetails, setViewBankDetail }) => {

  const [bankDetailPopup, setBankDetailPopup] = useState(false);
  const [defaultBank, setDefaultBank] = useState({});

  useEffect(() => {
    let newBankDetails;

    userBankDetails.map((item) => {
      if (item.isPrimary) {
        newBankDetails = item
      }
      return false;
    });
    if (!newBankDetails) {
      [newBankDetails] = userBankDetails
    }
    setDefaultBank(newBankDetails);
  }, []);

  const viewAllHanler = () => {
    setBankDetailPopup(!bankDetailPopup);
    setViewBankDetail(true);
  };

  return (
    <React.Fragment>
      <div className="personal-bank-details">
        <div className="personal-heading-container">
          <div className="d-flex align-items-baseline">
            <h4 className="personal-common-heading">
                            Bank Details
            </h4>
            <label className="ml-3 grey-label">Default</label>
          </div>
          <MoreVertIcon
            onClick={() => setBankDetailPopup(!bankDetailPopup)}
            className="more-vertical-icon"
          />
        </div>
        {/* VIEW/EDIT */}
        {bankDetailPopup && (
          <div className="profile-edit-view">
            <p>
              <label onClick={() => { viewAllHanler() }}>View All</label>
              {/* <label>Edit</label> */}
            </p>
          </div>
        )}
        <div className="user-bank-details-wrapper">
          {userDetailsKeyValueMap.map((item, index) =>
            <FieldValue
              key={index}
              fieldName={item.label}
              fieldValue={defaultBank && defaultBank[item.key]}
              type={item.type}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

export default UserBankDetails;