import { call, takeEvery } from 'redux-saga/effects';

import HttpHelper from '../../utils/http-helper';
import { GET_LOAN_PURPOSE_URL } from '../../utils/urls';
import { GET_LOAN_PURPOSE_REQUEST } from './profile-constants';

const { getRequest } = new HttpHelper();

export function* loanPurposeList(action) {
  try {
    const response = yield call(getRequest, {
      url: GET_LOAN_PURPOSE_URL,
    });

    action.data.loanPurposeListCallback(response.data);
  } catch (err) {
    throw new Error(err);
  }
}

export function* watchLoanPurposeList() {
  yield takeEvery(GET_LOAN_PURPOSE_REQUEST, loanPurposeList);
}
