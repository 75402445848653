import React from 'react';
import { Table } from 'react-bootstrap';
import { getFieldValueByKeyType } from '../../utils/table-data-helper';
import './product-table.css';

const ProductTable = ({ productTableConstant, productTableDetails }) => {

  return (
    <React.Fragment>
      <div className="product-table-container">
        <div className="product-table-title">
                    Products
        </div>
        <div className="repayment-sec-table">
          <Table responsive>
            <thead>
              <tr>
                {productTableConstant.map((item, index) => (
                  <th key={index}>{item.label}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {productTableDetails &&
                                productTableDetails.map((loan, upperindex) => (
                                  <tr key={upperindex}>
                                    {productTableConstant.map((item, index) => {
                                      if (item.label === "#")
                                        return <td key={index} >{upperindex+1}</td>
                                      return <td key={index} >{getFieldValueByKeyType(loan, item.key, item.type)}</td>
                                    })}
                                  </tr>
                                ))}
            </tbody>
          </Table>
        </div>
      </div>
    </React.Fragment >
  )
}

export default ProductTable;