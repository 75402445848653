import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import CloseIconWhite from '../../../images/icons/closeIconWhite.svg';
import FormElement from '../../components/form-elements';
// import { closeLoanFormFields } from './close-loan-constant';
import { waiverLoanFormFields } from './waiver-loan-constant';
import {
  onChangeHandler,
  setInitialFormValues,
  validateForm,
  getFormDataValue,
} from '../../utils/form-helper';
import Button from '../../components/form-elements/button';

const WaiverLoan = ({ showWaiverLoan, CloseWaiverLoan, okButtonHandler }) => {
  const [waiverLoanForm, setWaiverLoanForm] = useState([]);

  useEffect(() => {
    setInitialFormValues(waiverLoanFormFields, setWaiverLoanForm);
  }, []);

  const okButtonHandlerInner = () => {
    const [newForm, isValid] = validateForm(waiverLoanForm);

    setWaiverLoanForm(newForm);
    if (isValid) {
      const formdata = getFormDataValue(newForm);

      // formdata.status = 'closed';
      okButtonHandler(formdata);
      CloseWaiverLoan();
    }
  };

  return (
    <React.Fragment>
      <Modal
        size="lg"
        show={showWaiverLoan}
        onHide={CloseWaiverLoan}
        backdropClassName="loan-modal-overlay-background"
        dialogClassName="close-loan-payment-modal-dialoag"
        aria-labelledby="close-loan-payment-pop-up"
        centered
      >
        <Modal.Body>
          <div className="close-loan-modal-container">
            <span className="loan-modal-close-button" onClick={CloseWaiverLoan}>
              {' '}
              <img src={CloseIconWhite} />
            </span>
            <div className="loan-modal-title">Waive</div>
            <div>
              {waiverLoanForm.map((formItem, index) => (
                <FormElement
                  key={index}
                  inputType={formItem.type}
                  lableName={formItem.label}
                  name={formItem.name}
                  placeholder={formItem.placeholder}
                  value={formItem.value}
                  onChangeHandler={(targetValue) => {
                    onChangeHandler(
                      waiverLoanForm,
                      formItem.name,
                      targetValue,
                      setWaiverLoanForm
                    );
                  }}
                  isValid={formItem.isValid}
                  errorMsg={formItem.errorMsg}
                  isDisabled={formItem.isDisabled}
                  isTouched={formItem.isTouched}
                />
              ))}
            </div>
            <div className="close-loan-modal-button-container">
              <Button
                text={'Cancel'}
                click={CloseWaiverLoan}
                isGreyedOut={true}
              />
              <Button
                text={'OK'}
                click={okButtonHandlerInner}
                isMarginLeft={true}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default WaiverLoan;
