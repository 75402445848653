import { call, put, takeEvery } from 'redux-saga/effects';
import * as Toastr from 'toastr';

import HttpHelper from '../../utils/http-helper';
import { crifExtractionUrl } from '../../utils/urls';
import { CRIF_EXTRACTION_REQUEST } from './profile-constants';
import { crifExtractionSuccess, crifExtractionFailure } from './get-crif-extraction-action';

const { getRequest } = new HttpHelper();

export function* crifExtraction(action) {
  try {
    const response = yield call(getRequest, {
      url: crifExtractionUrl(action.urlParams),
    });

    if (response.error) {
      const { data } = response.error.response;

      yield put(crifExtractionFailure());
      Toastr.error(data.details[0].message, 'Failure');
    }else{
      yield put(crifExtractionSuccess(response.data));
      window.location.pathname = `users/${action.urlParams}`;
    }
  } catch (error) {
    yield put(crifExtractionFailure());
  }
}

export function* watchCrifExtraction() {
  yield takeEvery(CRIF_EXTRACTION_REQUEST, crifExtraction);
}
