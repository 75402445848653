import {
  SECURED_LOANS_FAILURE,
  SECURED_LOANS_SUCCESS,
  SECURED_LOANS_REQUEST,
} from './secured-loans-constants';

const SecuredLoansRequest = (urlParams) => ({
  type: SECURED_LOANS_REQUEST,
  urlParams,
});

export const SecuredSuccess = (data, totalRecords) => ({
  data,
  totalRecords,
  type: SECURED_LOANS_SUCCESS,
});
export const SecuredFailure = () => ({
  type: SECURED_LOANS_FAILURE,
});

export const SecuredA = async (urlParams, dispatch) => {
  dispatch(SecuredLoansRequest(urlParams));
};
