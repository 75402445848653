import {
  LEADS_CALL_REQUEST,
  LEADS_CALL_SUCCESS,
  LEADS_CALL_FAILURE,
} from './my-leads-constants';

const LeadsCallRequest = (mob) => ({
  type: LEADS_CALL_REQUEST,
  mob,
});

export const LeadsCallSuccess = (data) => ({
  data,
  type: LEADS_CALL_SUCCESS,
});
export const LeadsCallFailure = () => ({
  type: LEADS_CALL_FAILURE,
});

export const callLeads = async (mob, dispatch) => {
  dispatch(LeadsCallRequest(mob));
};
