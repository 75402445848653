import {
  DATE_RANGE_TYPE,
} from '../../constants/data-table';

export const allocationFilters = [
  {
    name: 'Created on',
    value: 'createdAt',
    filterCondition: 'eq',
    type: DATE_RANGE_TYPE,
    startDatevalue: 'createdAt',
    startDateFilterCondition: 'gte',
    endDateFilterCondition: 'lte',
    endDatevalue: 'createdAt',
  },
];