import React from 'react';

import HeaderLogo1 from './../../../images/header-logo-1.jpg';

const TableLoader = () => {
  return (
    <div className="table-loader">
      <div className="table-loader-img">
        <img alt="loader" src={HeaderLogo1} />
      </div>
      <div className="table-loader-text">
        <h1>Loading...</h1>
      </div>
    </div>
  );
};

export default TableLoader;
