import React from 'react';
import { toCapitalizeFirstLetter } from '../../../utils/formattor';

const BasicUserInfo = ({ profileData }) => {
  return (
    <React.Fragment>
      <div className="personal-basic">
        <h2 className="personal-username">
          {(profileData && profileData.name) || '-'}
        </h2>
        <label className="personal-gender grey-label">
          {(profileData &&
                        toCapitalizeFirstLetter(profileData.gender)) ||
                        '-'}
        </label>
        <label className="personal-gender grey-label" style={{ "marginLeft": '5px' }}>
          {(profileData &&
                           profileData.platform==='AN'? 'ANDROID': 'IOS' )  ||
                        '-'}
        </label>
      </div>
    </React.Fragment>
  )
}

export default BasicUserInfo;