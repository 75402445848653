import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import CloseIconWhite from '../../../images/icons/closeIconWhite.svg';
import Button from '../form-elements/button';
import AlertImg from '../../../images/alert-fill-icon.svg';
import { toNumberWithCommaAndDecimal } from '../../utils/formattor';
import './alert-popup.css';

const ConfirmPopup = ({ show, close, data, click, setAmount }) => {
  // const [inputType,setInputType] = useState('text');
  const [inputValue,setvalue] = useState('');
  const [edit,setedit] = useState(false);
  const onClick = () => {
    const toggleEdit = () => {
      setedit((val) => !val)
    }

    toggleEdit();
    if(edit){
      setvalue(toNumberWithCommaAndDecimal(inputValue));
    }
  };

  const validateField = (e) => {
    const { value } = e.target;

    setvalue(value);
  }

  const setDefaultState = () => {
    setvalue(data);
    setedit(false);
  }

  useEffect(() => {
    if(data){
      setvalue(data)
    }
  },[data]);

  return (
    <React.Fragment>
      <Modal
        size="lg"
        show={show}
        onHide={close}
        backdropClassName="alert-overlay-background"
        dialogClassName="alert-modal-dialog"
        aria-labelledby="alert-pop-up"
        centered
      >
        <Modal.Body>
          <div className="alert-modal-container">
            <span className="alert-modal-close-button" onClick={close}> <img src={CloseIconWhite} onClick={setDefaultState}/></span>
            <img src={AlertImg} alt="succes-img" />
            <div className="d-flex justify-content-around container">
              <div className="alert-modal-title-edit">
                        BALANCE DUE AMOUNT
              </div>
              <div className="alert-box-button-container-edit">
                <button className="common-black-border-btn" onClick ={() => {onClick();}}>
                  <span>{edit ? 'Done' : 'Edit'}</span></button>
              </div>
            </div>
            {!edit ? <div className="alert-message">
              <h3>&#8377;{inputValue}</h3>
            </div> :
              <div className="d-flex justify-content-around container">
                <div className="form-group">
                  <div className="col-xs-2">
                    <input className="form-control" value={inputValue} onChange={validateField} type="text"/>
                  </div>
                </div>
              </div>
            }
            <div className="d-flex justify-content-center">
              <div className="alert-box-button-container">
                {!edit && <Button text="Confirm" click={() => {close(); click(true); setAmount(inputValue)}} />}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}

export default ConfirmPopup;