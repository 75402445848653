export const MANAGE_PARTNER_REQUEST = 'MANAGE_PARTNER_REQUEST';
export const MANAGE_PARTNER_SUCCESS = 'MANAGE_PARTNER_SUCCESS';
export const MANAGE_PARTNER_FAILURE = 'MANAGE_PARTNER_FAILURE';

export const managePartnerFilter = [
  {
    name: 'Partner Name',
    value: 'partnerName',
    filterCondition: 'eq',
    placeholder: 'Enter Partner name',
    type: 'text',
  },
  {
    name: 'Product Name',
    value: 'productName',
    filterCondition: 'ilike',
    placeholder: 'Enter product name',
    type: 'text',
  },
  {
    name: 'Product Code',
    value: 'productCode',
    filterCondition: 'eq',
    placeholder: 'Enter product Code',
    type: 'text',
  },
  {
    name: 'Product Type',
    value: 'productType',
    filterCondition: 'eq',
    placeholder: 'Enter Product type',
    type: 'text',
  }
];
