import { call, put, takeEvery } from 'redux-saga/effects';
import * as Toastr from 'toastr';

import HttpHelper from '../../utils/http-helper';

// import { ADD_MANAGE_ALLOCATION_URL } from '../../utils/urls';

import { ADD_MANAGE_ALLOCATION_REQUEST } from './manage-allocations-constants';

import {  addManageAllocationFailure} from './add-manage-allocaton-action';

const { getRequest } = new HttpHelper();

export function* addManageAllocation(action) {

  try {
    const response = yield call(getRequest, {
      url: `https://uufn2i0zq7.execute-api.ap-south-1.amazonaws.com/Prod/LeadAllocation?pastDays=${action.data.pastDays}&emails=${action.data.emailAddress}`,
    });
    if (response.error) {
      const { data } = response.error.response;

      Toastr.error(data.details[0].message, 'Failure');
      yield put(addManageAllocationFailure());

      action.data.addManageAllocationFailureCallback({
        field: data.details[0].name,
        message: data.details[0].message,
      });
    }
     else {
      Toastr.success(response.headers.message, response.data);
      // yield put(getOtpUserSuccess());

      // action.data.addUserSuccessCallback(newUserData);
    }
  } catch (error) {
    Toastr.error('error', error);
  }
}

export function* watchaddManageAllocation() {
  yield takeEvery(ADD_MANAGE_ALLOCATION_REQUEST, addManageAllocation);
}
