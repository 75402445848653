import {
  VIEW_PARTNER_REQUEST,
  VIEW_PARTNER_SUCCESS,
  VIEW_PARTNER_FAILURE
} from './view-partner-constants';

const initialState = {
  isFetching: false,
  viewPartnerDetails: {},
  partnerProduct: [],
  razorPay: {},
  paytm: {}
};

const viewPartner = (state = initialState, action) => {
  switch (action.type) {
    case VIEW_PARTNER_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case VIEW_PARTNER_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case VIEW_PARTNER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        viewPartnerDetails: action.data,
        partnerProduct: action.productProduct,
        razorPay: action.razorPay,
        paytm: action.paytm
      }
    default:
      return state;
  }
};

export default viewPartner;
