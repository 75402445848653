import { TEXTAREA, NUMBER } from '../../constants/form-type';

export const waiverLoanFormFields = [
  // {
  //   label: 'Current Outstanding Amount',
  //   name: 'currentOutstandingAmount',
  //   placeholder: '0.00',
  //   validation: { isRequired: true },
  //   errMsg: "",
  //   type: NUMBER,
  // },
  {
    label: 'Amount',
    name: 'amount',
    placeholder: 'Details goes here',
    validation: { isRequired: true },
    errMsg: '',
    type: NUMBER,
  },
  {
    label: 'Remark',
    name: 'remarks',
    placeholder: 'Details goes here',
    validation: { isRequired: true },
    errMsg: '',
    type: TEXTAREA,
  },
];
