/* eslint-disable sort-keys */
module.exports = {
  PORT: process.env.PORT || 3001,
  IDENTITY_SERVICE_URL: process.env.IDENTITY_SERVICE_URL || 'https://master-service.nextfin.in',
  FILE_SERVICE_URL: process.env.FILE_SERVICE_URL || 'https://master-service.nextfin.in',
  LOAN_SERVICE_URL: process.env.LOAN_SERVICE_URL || 'https://master-service.nextfin.in',
  BANKING_SERVICE_URL: process.env.BANKING_SERVICE_URL || 'https://master-service.nextfin.in',
  PARTNER_SERVICE_URL: process.env.PARTNER_SERVICE_URL || 'https://master-service.nextfin.in',
  REPORTING_SERVICE_URL: process.env.REPORTING_SERVICE_URL || 'https://reporting-service.nextfin.in',
};
