export const getStateListOption =(statelist)=>{
  const result = [];

  statelist.map((state)=>{
    const obj = {
      name: state.name,
      value: state.name
    };

    result.push(obj);
    return false;
  });
  return result;
}

export const getBankListOption =(banklist)=>{
  const result = [];

  banklist.map((state)=>{
    const obj = {
      name: state.name,
      value: state.publicId
    };

    result.push(obj);
    return false;
  });
  return result;
}