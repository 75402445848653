import { call, put, takeEvery } from 'redux-saga/effects';

import HttpHelper from '../../utils/http-helper';
import { userBlockDashboardURL } from '../../utils/urls';
import { USER_BLOCK_REQUEST } from './dashboard-constants';

import { userBlockFailure, userBlockSuccess } from './user-block-action';

const { getRequest } = new HttpHelper();

export function* userBlock(action) {
  try {
    const originalRequestParam = {
      pageNumber: 1,
      pageSize: 10,
    };
    const responseForTotal = yield call(getRequest, {
      url: userBlockDashboardURL(originalRequestParam),
    });
    const total = responseForTotal.headers['x-coreplatform-total-records'];
    const response = yield call(getRequest, {
      url: userBlockDashboardURL(action.urlParams),
    });
    const testCaseTotal = response.headers['x-coreplatform-total-records'];

    yield put(userBlockSuccess({ total, testCaseTotal }));
  } catch (error) {
    yield put(userBlockFailure());
  }
}

export function* watchUserBlock() {
  yield takeEvery(USER_BLOCK_REQUEST, userBlock);
}
