import {
  SECURED_LOANS_REQUEST,
  SECURED_LOANS_FAILURE,
  SECURED_LOANS_SUCCESS,
} from './secured-loans-constants';

const initialState = {
  error: false,
  isFetching: false,
  totalRecords: '',
  users: [],
};

const securedloans = (state = initialState, action) => {
  switch (action.type) {
    case SECURED_LOANS_REQUEST:
      return {
        ...state,
        isFetching: true,
        users: [],
      };
    case SECURED_LOANS_FAILURE:
      return {
        ...state,
        error: true,
        isFetching: false,
        users: [],
      };
    case SECURED_LOANS_SUCCESS:
      return {
        ...state,
        error: false,
        isFetching: false,
        totalRecords: action.totalRecords,
        users: action.data,
      };
    default:
      return state;
  }
};

export default securedloans;
