import {
  SELECT,
  TEXT,
  DATE_RANGE_TYPE,
  NUMBER_RANGE_TYPE,
  NUMBER,
} from '../../constants/data-table';

export const LOAN_REQUEST = 'LEADS_LOAN_REQUEST';
export const LOAN_SUCCESS = 'LEADS_LOAN_SUCCESS';
export const LOAN_FAILURE = 'LEADS_LOAN_FAILURE';

export const loanFilters = [
  {
    name: 'Reference Number',
    value: 'referenceNumber',
    filterCondition: 'eq',
    placeholder: 'format: XXXXXXX',
    type: TEXT,
  },
  {
    name: 'Name',
    value: 'name',
    filterCondition: 'ilike',
    placeholder: 'Rahul Kumar',
    type: TEXT,
  },
  {
    name: 'Product Name',
    value: 'productName',
    filterCondition: 'ilike',
    placeholder: 'Product Name',
    type: TEXT,
  },
  {
    name: 'Product type',
    value: 'productType',
    filterCondition: 'ilike',
    placeholder: 'Product type',
    type: TEXT,
  },
  {
    name: 'Mobile Number',
    value: 'mobileNumber',
    filterCondition: 'eq',
    placeholder: '9876543263',
    type: NUMBER,
  },
  {
    name: 'Loan Tenure',
    value: 'loanTenure',
    filterCondition: 'eq',
    placeholder: '12',
    type: NUMBER,
  },
  {
    name: 'Loan Amount',
    value: 'loanAmount',
    filterCondition: 'eq',
    minPlaceholder: 'min value',
    maxPlaceholder: 'max value',
    minFilterCondition: 'gte',
    maxFilterCondition: 'lte',
    type: NUMBER_RANGE_TYPE,
  },
  {
    name: 'Status',
    value: 'status',
    placeholder: 'active',
    filterCondition: 'eq',
    type: SELECT,
    options: [
      { name: 'Active', value: 'active' },
      { name: 'Initiated', value: 'initiated' },
      { name: 'Emandate Set', value: 'emandate-set' },
      { name: 'Borrower Signed', value: 'borrower-signed' },
      { name: 'Closed', value: 'closed' },
      { name: 'Cancelled', value: 'cancelled' }
    ],
  },
  {
    name: 'Date',
    value: 'createdAt',
    filterCondition: 'eq',
    type: DATE_RANGE_TYPE,
    startDatevalue: 'createdAt',
    startDateFilterCondition: 'gte',
    endDateFilterCondition: 'lte',
    endDatevalue: 'createdAt',
  }
];
