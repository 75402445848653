import React from 'react';
import { Modal } from 'react-bootstrap';
import CloseIconWhite from '../../../images/icons/closeIconWhite.svg';
import Button from '../form-elements/button';

import AlertImg from '../../../images/alert-fill-icon.svg';
import './alert-popup.css';

const ErrorPopup = ({ show, close,msg }) => {
  return (
    <React.Fragment>
      <Modal
        size="lg"
        show={show}
        onHide={close}
        backdropClassName="alert-overlay-background"
        dialogClassName="alert-modal-dialog"
        aria-labelledby="alert-pop-up"
        centered
      >
        <Modal.Body>
          <div className="alert-modal-container">
            <span className="alert-modal-close-button" onClick={close}> <img src={CloseIconWhite} /></span>
            <img src={AlertImg} alt="error-img" />
            <div className="alert-modal-title">
                        ALERT
            </div>
            <div className="alert-message">
              {msg}
            </div>
            <div className="alert-box-button-container">
              <Button text="CLOSE" click={() => { close() }} />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}

export default ErrorPopup;