import { call, put, takeEvery } from 'redux-saga/effects';

import HttpHelper from '../../utils/http-helper';
import { DEFAULTS_CHIP_URL } from '../../utils/urls';
import { GET_DEFAULT_REPORTS_REQUEST } from './defaults-constants';
import { getDefaultReportsSuccess, getDefaultReportsFailure } from './defaults-report-action';

const { getRequest } = new HttpHelper();

export function* getDefaultReports() {

  try {
    const response = yield call(getRequest, {
      url: DEFAULTS_CHIP_URL
    });

    yield put(getDefaultReportsSuccess(response.data));
  } catch (error) {
    yield put(getDefaultReportsFailure());
  }
}

export function* watchGetDefaultReports() {
  yield takeEvery(GET_DEFAULT_REPORTS_REQUEST, getDefaultReports);
}
