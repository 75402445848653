import React from 'react';
import './view-wallet-settings.css';
import EditIcon from '../../../images/icons/editIconBlack.svg';

const ViewWalletSetting = ({ title, settings, value,editHandler }) => {
  return (
    <React.Fragment>
      <div className="wallet-setting-container">
        <img src={EditIcon} alt="edit" className="edit-wallet-button" onClick={()=>{editHandler()}}/>
        <div className="wallet-setting-title">
          {title}
        </div>
        <div className="settings-wrapper">
          {settings.map((item, index) =>
            (  <div key={index} className="label-value-wallet-wrapper">
              <div className="label-settings-wallet">
                {item.label}
              </div>
              <div className="label-setting-value">
                {(value && value[item.key] )|| '-'}
              </div>
            </div>)
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

export default ViewWalletSetting;