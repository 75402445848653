import {
  DASHBOARD_REPORTS_REQUEST,
  DASHBOARD_REPORTS_SUCCESS,
  DASHBOARD_REPORTS_FAILURE,
} from './dashboard-constants';

const dashboardReportsRequest = (urlParams) => ({
  type: DASHBOARD_REPORTS_REQUEST,
  urlParams,
});

export const dashboardReportsSuccess = (data) => ({
  data,
  type: DASHBOARD_REPORTS_SUCCESS,
});
export const dashboardReportsFailure = () => ({
  type: DASHBOARD_REPORTS_FAILURE,
});

export const dashboardReportsAction = async (urlParams, dispatch) => {
  dispatch(dashboardReportsRequest(urlParams));
};
