import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import { getTotalPercentage,getPercentageLeft } from '../../utils/percentageNumber';
import {
  toDateFormat,
  // toDateTimeFormat,
  toNumberWithCommaAndDecimal,
} from '../../utils/formattor';
// payments
const LoanInformationCard = ({ loanInfoDetails, user }) => {
  return(
    <div className="">
      <div class="row loan-inform">
        <div className="col-md-4 col-sm-12 justify-content-start">
          <div className="loan-content">
            <label className="loan-content-name">{user && user.name}</label><br />
            <span>{`( ${loanInfoDetails.number} )`}</span>
          </div>
        </div>
        <div className="col-md-4 col-sm-12 mpad">
          <div className="disburment-card blue-color">
            <div className="disburment-card-sec">
              <div className="disburment-card-img">
                <CircularProgressbar value={50} text={`${50}%`} />
              </div>
              <div className="disburment-card-text">
                <h1>
                            &#8377;
                  {toNumberWithCommaAndDecimal(
                    loanInfoDetails.balanceDueAmount
                  )}
                </h1>
                <p>Total Outstanding Amount</p>
                <label className="blue-color">
                  {/* 22.2% more disburment */}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-sm-12 mpad">
          <div className="disburment-card green-color">
            <div className="disburment-card-sec">
              <div className="disburment-card-img">
                <CircularProgressbar
                  value={getTotalPercentage(
                    loanInfoDetails.paidAmount,
                    loanInfoDetails.balanceDueAmount
                  )}
                  text={`${getTotalPercentage(
                    loanInfoDetails.paidAmount,
                    loanInfoDetails.balanceDueAmount
                  )}%`}
                />
              </div>
              <div className="disburment-card-text">
                <h1>
                            &#8377;
                  {toNumberWithCommaAndDecimal(
                    loanInfoDetails.paidAmount
                  )}
                </h1>
                <p>Total Paid Amount</p>
                <label className="green-color">
                  {getPercentageLeft(
                    loanInfoDetails.paidAmount,
                    loanInfoDetails.balanceDueAmount
                  )}
                            % amount left
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row border-right-1 loan-inform">
        <div className="col-md-4 col-sm-12 loan-tabel">
          <ul>
            <li>
              <label>Loan Status</label>
              <span>{loanInfoDetails.status}</span>
            </li>
            <li>
              <label>Loan Amount</label>
              <span>{toNumberWithCommaAndDecimal(loanInfoDetails.amount)}</span>
            </li>
            <li>
              <label>Loan Tenure</label>
              <span>{loanInfoDetails.tenure}</span>
            </li>
            <li>
              <label>Interest Rate</label>
              <span>{`${loanInfoDetails.interestRate}%`}</span>
            </li>
            <li>
              <label>Bank Name</label>
              <span>{user && user.bankName ? user.bankName : '-'}</span>
            </li>
            <li>
              <label>AC Number</label>
              <span>
                {user && user.bankDetail ? user.bankDetail[0].accountNumber : '-'}
              </span>
            </li>
            <li>
              <label>AC Holder Name</label>
              <span>{user && user.bankDetail  ? user.bankDetail[0].accountHolderName : '-'}</span>
            </li>
            <li>
              <label>IFSC</label>
              <span>{user && user.bankDetail[0].ifsc ? user.bankDetail[0].ifsc : '-'}</span>
            </li>
            <li>
              <label>Last Salry Date</label>
              <span>
                {user && user.lastMonthSalaryDate
                  ? toDateFormat(user.lastMonthSalaryDate)
                  : '-'}
              </span>
            </li>
            <li>
              <label>Bounce Charges</label>
              <span>{loanInfoDetails.bounceCharges}</span>
            </li>
          </ul>
        </div>
        <div className="col-md-4 col-sm-12 loan-tabel">
          <div className="loan-content">
            <span>EMANDATE</span>
          </div>
          <ul>
            <li>
              <label>Razor pay Emandate ID</label>
              <span className="tooltip-loan" data-tooltip={loanInfoDetails.razorpayEmandateId}>
                {loanInfoDetails.razorpayEmandateId ? loanInfoDetails.razorpayEmandateId : '-'}
              </span>
            </li>
            <li>
              <label>Razor Payout ID</label>
              <span className="tooltip-loan" data-tooltip={loanInfoDetails.razorpayPayoutId}>
                {loanInfoDetails.razorpayPayoutId ? loanInfoDetails.razorpayPayoutId : '-'}
              </span>
            </li>
            <li>
              <label>AC Number</label>
              <span>
                {user && user.accountNumber ? user.accountNumber : '-'}
              </span>
            </li>
            <li>
              <label>AC Holder Name</label>
              <span>{user && user.name ? user.name : '-'}</span>
            </li>
          </ul>
        </div>
        <div className="col-md-4 col-sm-12 loan-tabel">
          <div className="border-bottom-0 loan-content">
            <span>DISBURSAL</span>
          </div>
          <ul>
            <li>
              <label>PayOut Id</label>
              {/* <span className="tooltip-loan"
              data-tooltip={disbursement && disbursement.publicId}>
              {disbursement && disbursement.publicId ? disbursement.publicId : '-'}</span> */}
            </li>
            <li>
              <label>Disbursed Date</label>
              {/* <span>{disbursement && disbursement.date ? toDateFormat(disbursement.date) : '-'}</span> */}
            </li>
            <li>
              <label>Loan Amount</label>
              <span>{toNumberWithCommaAndDecimal(loanInfoDetails.amount)}</span>
            </li>
            <li>
              <label>Processing Fees</label>
              <span>{toNumberWithCommaAndDecimal(loanInfoDetails.processingAmount)}</span>
            </li>
            <li>
              <label>Disbursed Amount</label>
              {/* <span>{toNumberWithCommaAndDecimal(disbursement && disbursement.amount ? (disbursement.amount) : '-')}</span> */}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default LoanInformationCard;
