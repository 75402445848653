import {
  CRIF_EXTRACTION_REQUEST,
  CRIF_EXTRACTION_SUCCESS,
  CRIF_EXTRACTION_FAILURE,
} from './profile-constants';

const initialState = {
  crifScore: '',
  error: false,
  isFetching: false,
};

const crifExtraction = (state = initialState, action) => {
  switch (action.type) {
    case CRIF_EXTRACTION_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case CRIF_EXTRACTION_FAILURE:
      return {
        ...state,
        error: true,
        isFetching: false,
      };
    case CRIF_EXTRACTION_SUCCESS:
      return {
        ...state,
        crifScore: action.data,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default crifExtraction;
