import React from 'react';
import { Alert } from 'react-bootstrap';
import SuccessImg from '../../../images/svgComponents/successSmall';

const SuccessAlertBox = ({ show, successMsg,close,children }) => {
  if (show) {
    return (
      <Alert  onClose={() => close()} className="alert-box-wrapper" dismissible>
        <div className="success-container">
          <SuccessImg/>
          <div>
            <div className="success-heading">
                            SUCCESS
            </div>
            <div className="success-msg">
              {successMsg}
            </div>
            {children}
          </div>
        </div>
      </Alert>
    );
  } return null
}

export default SuccessAlertBox;