import { call, put, takeEvery } from 'redux-saga/effects';

import HttpHelper from '../../utils/http-helper';
import { COLLECTION_CHIP_URL } from '../../utils/urls';
import { COLLECTION_CHIP_REQUEST } from './collections-constants';
import { collectionChipSuccess, collectionChipFailure } from './collections-chip-action';

const { getRequest } = new HttpHelper();

export function* collectionChip() {
  try {
    const response = yield call(getRequest, {
      url:COLLECTION_CHIP_URL,
    });

    yield put(collectionChipSuccess(response.data));
  } catch (error) {
    yield put(collectionChipFailure());
  }
}

export function* watchCollectionChip() {
  yield takeEvery(COLLECTION_CHIP_REQUEST, collectionChip);
}
