import moment from 'moment';
import {
  SELECT,
  TEXT,
  DATE_RANGE_TYPE,
  NUMBER_RANGE_TYPE,
  NUMBER,
} from '../../constants/data-table';

export const DASHBOARD_REQUEST = 'DASHBOARD_REQUEST';
export const DASHBOARD_SUCCESS = 'DASHBOARD_SUCCESS';
export const DASHBOARD_FAILURE = 'DASHBOARD_FAILURE';

export const DASHBOARD_CARD_REQUEST = 'DASHBOARD_CARD_REQUEST';
export const DASHBOARD_CARD_SUCCESS = 'DASHBOARD_CARD_SUCCESS';
export const DASHBOARD_CARD_FAILURE = 'DASHBOARD_CARD_FAILURE';

export const RECENT_ACTIVITY_REQUEST = 'RECENT_ACTIVITY_REQUEST';
export const RECENT_ACTIVITY_SUCCESS = 'RECENT_ACTIVITY_SUCCESS';
export const RECENT_ACTIVITY_FAILURE = 'RECENT_ACTIVITY_FAILURE';

export const NEW_REGISTRATION_REQUEST = 'NEW_REGISTRATION_REQUEST';
export const NEW_REGISTRATION_SUCCESS = 'NEW_REGISTRATION_SUCCESS';
export const NEW_REGISTRATION_FAILURE = 'NEW_REGISTRATION_FAILURE';

export const USER_BLOCK_REQUEST = 'USER_BLOCK_REQUEST';
export const USER_BLOCK_SUCCESS = 'USER_BLOCK_SUCCESS';
export const USER_BLOCK_FAILURE = 'USER_BLOCK_FAILURE';

export const LOAN_BLOCK_REQUEST = 'LOAN_BLOCK_REQUEST';
export const LOAN_BLOCK_SUCCESS = 'LOAN_BLOCK_SUCCESS';
export const LOAN_BLOCK_FAILURE = 'LOAN_BLOCK_FAILURE';

export const EXPORT_CSV_REQUEST = 'EXPORT_CSV_REQUEST';
export const EXPORT_CSV_SUCCESS = 'EXPORT_CSV_SUCCESS';
export const EXPORT_CSV_FAILURE = 'EXPORT_CSV_FAILURE';

export const VIEW_FILE_REQUEST = 'VIEW_FILE_REQUEST';
export const VIEW_FILE_SUCCESS = 'VIEW_FILE_SUCCESS';
export const VIEW_FILE_FAILURE = 'VIEW_FILE_FAILURE';

export const DASHBOARD_REPORTS_REQUEST = 'DASHBOARD_REPORTS_REQUEST';
export const DASHBOARD_REPORTS_SUCCESS = 'DASHBOARD_REPORTS_SUCCESS';
export const DASHBOARD_REPORTS_FAILURE = 'DASHBOARD_REPORTS_FAILURE';

export const dashboardFilters = [
  {
    name: 'EMI Due Date',
    value: 'emiDueDate',
    filterCondition: 'eq',
    type: DATE_RANGE_TYPE,
    defaultFilterValue: [moment().subtract(3, 'days'), moment()],
    startDatevalue: 'dueDate',
    startDateFilterCondition: 'gte',
    endDateFilterCondition: 'lte',
    endDatevalue: 'dueDate',
  },
  {
    name: 'Loan Number',
    value: 'number',
    filterCondition: 'eq',
    placeholder: 'ML-001230',
    type: TEXT,
  },
  {
    name: 'Name',
    value: 'name',
    filterCondition: 'ilike',
    placeholder: 'Rahul Kumar',
    type: TEXT,
  },
  {
    name: 'Mobile Number',
    value: 'mobileNumber',
    filterCondition: 'eq',
    placeholder: '9876543263',
    type: NUMBER,
  },
  {
    name: 'EMI Number',
    value: 'emiNumber',
    filterCondition: 'eq',
    placeholder: '4',
    type: NUMBER,
  },

  {
    name: 'Value',
    value: 'amount',
    filterCondition: 'eq',
    minPlaceholder: 'min value',
    maxPlaceholder: 'max value',
    minFilterCondition: 'gte',
    maxFilterCondition: 'lte',
    type: NUMBER_RANGE_TYPE,
  },
  {
    name: 'Status',
    value: 'status',
    filterCondition: 'eq',
    placeholder: 'active',
    type: SELECT,
    options: [
      { name: 'Active', value: 'active' },
      { name: 'Initiated', value: 'initiated' },
      { name: 'Emandate Set', value: 'emandate-set' },
      { name: 'Borrower Signed', value: 'borrower-signed' },
      { name: 'Closed', value: 'closed' },
      { name: 'Cancelled', value: 'cancelled' },
    ],
  },
  {
    name: 'Date',
    value: 'createdAt',
    filterCondition: 'eq',
    type: DATE_RANGE_TYPE,
    startDatevalue: 'createdAt',
    startDateFilterCondition: 'gte',
    endDateFilterCondition: 'lte',
    endDatevalue: 'createdAt',
  },
];

export const userBlockFilter = [
  {
    value: '',
    filterOnValue: '',
    filterCondition: '',
    text: 'Users with Recent updates',
    link: '/report/users?sortBy=updatedAt',
  },
  {
    value: 'panDocumentStatus',
    filterOnValue: 'verified',
    filterCondition: 'eq',
    text: 'Users with Pan Card Verified',
    link: '/report/users?value=panDocumentStatus&filterOnValue=verified',
  },
  {
    value: 'isKycCompleted',
    filterOnValue: 'true',
    filterCondition: 'eq',
    text: 'Users with completed KYC',
    link: '/report/users?value=isKycCompleted&filterOnValue=true',
  },
  {
    value: 'bankStatementDocumentStatus',
    filterOnValue: 'verified',
    filterCondition: 'ilike',
    text: 'Users with Bank Statement verified',
    link: '/report/users?value=bankStatementDocumentStatus&filterOnValue=verified',
  },
  {
    value: 'isFinancialVerified',
    filterOnValue: 'true',
    filterCondition: 'eq',
    text: 'Users with Financial Check Verified',
    link: '/report/users?value=isFinancialVerified&filterOnValue=true',
  },
];

export const loanBlockFilter = [
  {
    value: 'status',
    filterOnValue: 'active',
    filterCondition: 'eq',
    text: 'Loans with status Active',
    link: '/report/loan?value=status&filterOnValue=active',
  },
  {
    value: 'status',
    filterOnValue: 'initiated',
    filterCondition: 'eq',
    text: 'Loans with status Initiated',
    link: '/report/loan?value=status&filterOnValue=initiated',
  },
  {
    value: 'status',
    filterOnValue: 'emandate-set',
    filterCondition: 'eq',
    text: 'Loans with status Emandate Set',
    link: '/report/loan?value=status&filterOnValue=emandate-set',
  },
  {
    value: 'status',
    filterOnValue: 'borrower-signed',
    filterCondition: 'eq',
    text: 'Loans with status Borrower Signed',
    link: '/report/loan?value=status&filterOnValue=borrower-signed',
  },
  {
    value: 'status',
    filterOnValue: 'closed',
    filterCondition: 'eq',
    text: 'Loans with status Closed',
    link: '/report/loan?value=status&filterOnValue=closed',
  },
  {
    value: 'status',
    filterOnValue: 'cancelled',
    filterCondition: 'eq',
    text: 'Loans with status Cancelled',
    link: '/report/loan?value=status&filterOnValue=cancelled',
  },
  {
    value: 'status',
    filterOnValue: 'loan agreement signed',
    filterCondition: 'eq',
    text: 'Loans with status loan agreement signed',
    link: '/report/loan?value=status&filterOnValue=loan-agreement-signed',
  },
];
