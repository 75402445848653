/* eslint-disable max-lines */
import React, { useState, useEffect } from 'react';
import DataTable from 'moneyloji-react-data-table';
import * as uuid from 'uuid';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import { Breadcrumb } from 'react-bootstrap';
import { getCallLogsReports } from './get-daily-reports-action';
import { LeadsA } from './my-leads-action';

import Header from '../../components/header';
import Footer from '../../components/footer';
import { callLeads } from './my-leads-call-action';
import { addCallLogs } from '../profile/add-call-logs-action';
import { leadsFilters } from './my-leads-constants';

import { userExportUrl } from '../../utils/urls';
import { setDefaultFilter, openInNewTab } from '../../utils/default-filter-helper';
import { DEFAULT_PAGE_SIZE, CURRENT_DEFAULT_PAGE, MY_LEADS_ONLY } from '../../constants';
import { toNumberWithCommaAndDecimal } from '../../utils/formattor';

import CloseIconWhite from '../../../images/icons/closeIconWhite.svg';
import TableLoader from '../../components/Loaders/table-loader';
import phoneDisabled from '../../../images/icons/phoneDisabled.png';
import phoneEnabled from '../../../images/icons/phoneEnabled.png';
import phonePending from '../../../images/icons/phonePending.png';
import phoneSettings from '../../../images/icons/phoneSettings.png';
import AddCallLogs from '../../components/Profile/add-call-logs';

const Leads = ({ location }) => {
  // Filter State
  const [show, setShow] = useState(false);
  // const [cities, setCities] = useState('');
  const [validated, setValidated] = useState(false);
  const [addCallLogsPopUP, setAddCallLogs] = useState(false);
  const [userPublicId, setUserPublicId] = useState(false);
  const { isFetching, leads, totalRecords } = useSelector((state) => state.leads);
  const { data } = useSelector((state) => state.getCallLogsLead);
  const { isLoading }= useSelector((state) => state);

  const usersList =
  leads &&
  leads.map((user, index) => {
      const id = index + 1;

      return {
        ...user,
        id,
      };
    });

  const dispatch = useDispatch();

  const getLeadsData = (urlParams) => {
    LeadsA(urlParams, dispatch);
    if(urlParams['filters[0][key]'] === "followUpDate" || urlParams['filters[0][key]'] === undefined){
      getCallLogsReports(urlParams, dispatch);
    }
  };
  const addCallLogsHandler = (addCallLogsObjParam) => {
    const addCallLogsObj = addCallLogsObjParam

    addCallLogsObj.userId = userPublicId;

    addCallLogs(addCallLogsObj,'', dispatch);
  };
  const callToCustomer =(mob,publicId) =>{
    setUserPublicId(publicId)
    const  myoperatorUserId= localStorage.getItem('myoperatorUserId')

    if(myoperatorUserId!=='null'){
      callLeads(mob,dispatch);
      setAddCallLogs(true);
    }
  }
  useEffect(() => {
    const urlParams = {
      pageNumber: CURRENT_DEFAULT_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
      'sorting[0][direction]': ['DESC'],
      'sorting[0][key]': ['followUpDate'],
    };

    if (location.search) {
      const filterKey = new URLSearchParams(location.search);
      const value = filterKey.get('value');
      const filterOnValue = filterKey.get('filterOnValue');
      const sortValue = filterKey.get('sortBy');

      if(sortValue){
        urlParams['sorting[0][key]'] = sortValue;
        getLeadsData(urlParams);
      }
      setDefaultFilter(leadsFilters, value, filterOnValue);
    } else {
      getLeadsData(urlParams);
    }
  }, []);

  const userNameHandle = (cell, row) => {
    return <a
      // href={`/users/${row.publicId}`}
      target="_blank"
      rel="noopener noreferrer"
      onClick={() => openInNewTab(`/users/${row.publicId}`)}>
      {cell || '-'}
    </a>;
  };

  function userMobileNo(cell, row) {
    return <a onClick={() => callToCustomer(cell,row.publicId,)}>{cell}</a>;
  }
  function userSalaryHandle(cell, row){
    return <label>{row.minMonthlyIncome} - {row.maxMonthlyIncome}</label>;
  }

  function statusFormatter(cell) {
    if (cell === 'verified') {
      return <label className="label-status-paid">{cell}</label>;
    }
    if (cell === 'pending') {
      return <label className="label-status-paid">{cell}</label>;
    }
    return <label className="status-unpaid">{cell || 'not-uploaded'}</label>;
  }

  function callStatusFormatter(cell) {
    if (cell === 'verified') {
      return <label className="label-status-paid">{cell}</label>;
    }
    if (cell === 'pending') {
      return <label className="label-status-paid">{cell}</label>;
    }
    return <label className="status-unpaid">{cell || 'NULL'}</label>;
  }
  function dateTimeFormatter(cell) {
    return <label>{cell?moment(cell).format('DD-MM-YYYY'):'NULL'}</label>;
  }

  
  


  const numberFormatter = (cell) => {
    if (cell) {
      return toNumberWithCommaAndDecimal(cell);
    }
    return '-';
  };


  const tableHeaderData = [
    {
      columnClassName: '',
      dataField: 'id',
      dataFormat: (cell) => cell,
      dataSort: false,
      isKey: true,
      name: '#',
      width: '30',
    },
    {
      columnClassName: '',
      dataField: 'name',
      dataFormat: userNameHandle,
      dataSort: true,
      isKey: false,
      name: 'Name',
      width: '180',
    },
    {
      columnClassName: '',
      dataField: 'mobileNumber',
      dataFormat: userMobileNo,
      dataSort: false,
      isKey: false,
      name: 'Mobile No',
      width: '100',
    },
    {
      columnClassName: '',
      dataField: 'salary',
      dataFormat: userSalaryHandle,
      dataSort: false,
      isKey: false,
      name: 'Salary',
      width: '150',
    },
    {
      columnClassName: '',
      dataField: 'primaryEmail',
      dataFormat: (cell)=>cell,
      dataSort: false,
      isKey: false,
      name: 'User Email',
      width: '150',
    },
    {
      columnClassName: '',
      dataField: 'bankStatementDocumentStatus',
      dataFormat: statusFormatter,
      dataSort: false,
      isKey: false,
      name: 'Bankstatement Status',
      width: '120',
    },
    {
      columnClassName: '',
      dataField: 'gender',
      dataFormat: userMobileNo,
      dataSort: false,
      isKey: false,
      name: 'Gender',
      width: '70',
    },
    {
      columnClassName: '',
      dataField: 'crifScore',
      dataFormat: (cell) => cell,
      dataSort: true,
      isKey: false,
      name: 'Crif Score',
      width: '70',
    },
    {
      columnClassName: '',
      dataField: 'callStatus',
      dataFormat: callStatusFormatter,
      dataSort: false,
      isKey: false,
      name: 'Call Status',
      width: '150',
    },
    {
      columnClassName: '',
      dataField: 'callCategory',
      dataFormat: (cell)=>cell,
      dataSort: false,
      isKey: false,
      name: 'Call Summary',
      width: '150',
    },
    {
      columnClassName: '',
      dataField: 'callDescription',
      dataFormat: (cell) => cell,
      dataSort: false,
      isKey: false,
      name: 'Call Description',
      width: '150',
    },
    {
      columnClassName: '',
      dataField: 'followUpDate',
      dataFormat: dateTimeFormatter,
      dataSort: false,
      isKey: false,
      name: 'Follow Up Date',
      width: '150',
    },
    
  ];

  return (
    <div>
      <Header />
      <div className="common-container">
        {/* <SidebarIcon addClassCallBack={addClassCallBack} show={show} /> */}
        <div className={`common-wrapper ${show ? 'active' : ''} `}>
          <div className='row' style={{ justifyContent: 'space-evenly' }}>
            {data && data.map(({ status,count },index) => (
              <div className='col-md-2'>
                {
                  index <= 5 && <div className="chip">
                    <div className="chip-body">
                      <h5 className="num">{count}</h5>
                      <h3 className="heading">{status}</h3>
                      {status === "pending" && <img src={phonePending} alt='placeholder'/>}
                      {status === "rejected" && <img src={phoneDisabled} alt='placeholder'/>}
                      {status === "completed" && <img src={phoneEnabled} alt='placeholder'/>}
                      {status === "updated" && <img src={phoneSettings} alt='placeholder'/>}
                    </div>
                  </div>
                }
              </div>
            ))
            }
          </div>
          <div className="col-md-12 mpad">
            <div className="common-heading mt-4">
              <h1>My Leads</h1>
              <Breadcrumb>
                <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>My Leads</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          <div className="loan-container mar0">
            <div className="row">
              <div className="col-lg-12">
                <DataTable
                  tableData={usersList}
                  tableHeaderData={tableHeaderData}
                  totalRecords={totalRecords}
                  isFetching={isFetching}
                  getTableData={getLeadsData}
                  exportUrl={userExportUrl}
                  tableTitle="LEADS"
                  pageFilters={leadsFilters}
                  defaultSortKey={'followUpDate'}
                  defaultSortDirection={'desc'}
                  TableLoader={TableLoader}
                />
              </div>
            </div>
          </div>
          <Footer show={show} />
        </div>
      </div>

      {/* Add Call logs Modal */}
      <AddCallLogs
        addCallLogs={addCallLogsPopUP}
        setAddCallLogs={setAddCallLogs}
        closeaddCallLogsPopup={() => setAddCallLogs(false)}
        addCallLogsHandler={addCallLogsHandler}
      />
    </div>
  );
};

export default Leads;
