import { call, put, takeEvery } from 'redux-saga/effects';

import HttpHelper from '../../utils/http-helper';
import {myLeadscallLogStatus } from '../../utils/urls';
import { GET_LEADS_REPORTS_REQUEST } from './my-leads-constants';
import { getCallLogsRequestSuccess, getCallLogsRequestFailure } from './get-daily-reports-action';

const { getRequest } = new HttpHelper();

export function* getCallLogsReports(action) {

  try {
    const response = yield call(getRequest, {
      url: myLeadscallLogStatus(action.urlParams),
    });

    yield put(getCallLogsRequestSuccess(response.data));
  } catch (error) {
    yield put(getCallLogsRequestFailure());
  }
}

export function* watchGetCallLogsReportsLead() {
  yield takeEvery(GET_LEADS_REPORTS_REQUEST, getCallLogsReports);
}
