import { EMI_CHIP_REQUEST, EMI_CHIP_SUCCESS, EMI_CHIP_FAILURE } from './emi-constants';

const emiChipRequest = () => ({
  type: EMI_CHIP_REQUEST,
});

export const emiChipSuccess = (data) => ({
  data,
  type: EMI_CHIP_SUCCESS,
});

export const emiChipFailure = () => ({
  type: EMI_CHIP_FAILURE,
});

export const emiChip = async (dispatch) => {
  dispatch(emiChipRequest());
};
