/* eslint-disable max-lines */
/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import DataTable from 'moneyloji-react-data-table';

import { Link } from 'react-router-dom';

import { Breadcrumb } from 'react-bootstrap';

import { useSelector, useDispatch } from 'react-redux';

import { loan } from './loan-action';
import { loanFilters } from './loan-constants';

import Header from '../../components/header';
// import SideBar from '../../components/side-bar';
import SidebarIcon from '../../components/sidebar-icon';
import Footer from '../../components/footer';
import {
  toNumberWithCommaAndDecimal,
  toDateTimeFormat,
} from '../../utils/formattor';
import { DEFAULT_PAGE_SIZE, CURRENT_DEFAULT_PAGE } from '../../constants';
import { loanLeadsExportUrl } from '../../utils/urls';
import { setDefaultFilter } from '../../utils/default-filter-helper';
import TableLoader from '../../components/Loaders/table-loader';

const Loan = () => {
  const [show, setShow] = useState(false);

  const dispatch = useDispatch();

  const getLoanData = (urlParams) => {
    loan(urlParams, dispatch);
  };

  useEffect(() => {
    const urlParams = {
      pageNumber: CURRENT_DEFAULT_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
      'sorting[0][direction]': ['DESC'],
      'sorting[0][key]': ['createdAt'],
    };
    // eslint-disable-next-line no-restricted-globals
    const searchParam = location.search;

    if (searchParam) {
      const filterKey = new URLSearchParams(searchParam);
      const value = filterKey.get('value');
      const filterOnValue = filterKey.get('filterOnValue');

      setDefaultFilter(loanFilters, value, filterOnValue);
    } else {
      getLoanData(urlParams);
    }
    // dashboardCard(dispatch);
  }, []);

  const { loanDetails, totalRecords, isFetching } = useSelector(
    (state) => state.leadsLoan
  );

 // const { dashboardCardDetails } = useSelector((state) => state.dashboardCard);

  const loanList =
    loanDetails &&
    loanDetails.map((loanData, index) => {
      const id = index + 1;

      return {
        ...loanData,
        id,
      };
    });

  function loanNumberHandle(cell, row) {
    // return <Link to={`/loan/${row.publicId}`}>{cell || '-'}</Link>;
    return cell || '-';
  }

  function userName(cell, row) {
    return <Link to={`/users/${row.userId}`}>{cell}</Link>;
  }

  function productName(cell, row) {
    return cell
  }

  function userMobileNo(cell, row) {
    return <Link to={`/users/${row.userId}`}>{cell}</Link>;
  }

  function buttonFormatter(cell) {
    if (cell === 'closed') {
      return <label className="status-unpaid">{cell}</label>;
    } else if (cell === 'active') {
      return <label className="label-status-paid">{cell}</label>;
    }
    return <label className="label-status-initiated">{cell}</label>;
  }
  const dateFormatter = (cell) => {
    if (cell) {
      return toDateTimeFormat(cell);
    }
    return '-';
  };

  const daysFormatter = (cell) => {
    return <label className="status-partialpaid">{cell}</label>;
  };

  const numberFormatter = (cell) => {
    if (cell) {
      return toNumberWithCommaAndDecimal(cell);
    }
    return '-';
  };

  const addClassCallBack = () => {
    setShow(!show);
  };

  const tableHeaderData = [
    {
      columnClassName: '',
      dataField: 'id',
      dataFormat: (cell) => cell,
      dataSort: false,
      isKey: true,
      name: '#',
      width: '40',
    },
    {
      columnClassName: '',
      dataField: 'referenceNumber',
      dataFormat: loanNumberHandle,
      dataSort: true,
      isKey: false,
      name: 'Reference No',
      width: '100',
    },
    {
      columnClassName: '',
      dataField: 'name',
      dataFormat: userName,
      dataSort: true,
      isKey: false,
      name: 'Name',
      width: '170',
    },
    {
      columnClassName: '',
      dataField: 'mobileNumber',
      dataFormat: userMobileNo,
      dataSort: false,
      isKey: false,
      name: 'Mobile No',
      width: '100',
    },
    {
      columnClassName: '',
      dataField: 'productName',
      dataFormat: productName,
      dataSort: true,
      isKey: false,
      name: 'Product Name',
      width: '150',
    },
    {
      columnClassName: '',
      dataField: 'productType',
      dataFormat: productName,
      dataSort: true,
      isKey: false,
      name: 'Product Type',
      width: '150',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'loanAmount',
      dataFormat: numberFormatter,
      dataSort: true,
      isKey: false,
      name: 'Loan Amount',
      width: '150',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'loanTenure',
      dataFormat: daysFormatter,
      dataSort: true,
      isKey: false,
      name: 'tenure',
      width: '100',
    },
    {
      columnClassName: '',
      dataField: 'status',
      dataFormat: buttonFormatter,
      dataSort: true,
      isKey: false,
      name: 'Status',
      width: '140',
    },
    {
      columnClassName: '',
      dataField: 'createdAt',
      dataFormat: dateFormatter,
      dataSort: true,
      isKey: false,
      name: 'Created At',
      width: '150',
    },
    {
      columnClassName: '',
      dataField: 'updatedAt',
      dataFormat: dateFormatter,
      dataSort: true,
      isKey: false,
      name: 'Updated At',
      width: '150',
    }
  ];

  // const { loans } = dashboardCardDetails;

  return (
    <div>
      <Header />
      <div className="common-container">
        <SidebarIcon addClassCallBack={addClassCallBack} show={show} />
        <div className={`common-wrapper ${show ? 'active' : ''} `}>
          <div className="col-md-12 mpad">
            <div className="common-heading">
              <h1>Loans</h1>
              <Breadcrumb>
                <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>Loans</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          {/* <div className="dashboard-disburment-card mar0 ">
            <div className="col-lg-4 col-md-6 col-sm-12 mpad">
              <ProgressBar
                percent="50"
                // amount="49,34,98,489.00"
                amount={
                  loans &&
                  toNumberWithCommaAndDecimal(loans.totalPrincipalDisbursed)
                }
                text="Total Principal Disbursed Amount"
                disbursement="22.2% more disbursement"
                color="blue-color"
                disbursementLabel=""
                disbursementLabelText=""
                disbursementSpanText=""
              />
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mpad">
              <ProgressBar
                percent="75"
                // amount="49,34,98,489.00"
                amount={
                  loans &&
                  toNumberWithCommaAndDecimal(loans.totalPrincipalDisbursed)
                }
                text="Total Principal Disbursed Amount"
                disbursement="21.9% more disbursement"
                color="green-color"
                disbursementLabel=""
                disbursementLabelText=""
                disbursementSpanText=""
              />
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mpad">
              <ProgressBar
                percent="27"
                // amount="4,98,489.00"
                amount={
                  loans &&
                  toNumberWithCommaAndDecimal(loans.totalDefaultPrincipal)
                }
                text="Total Defaults Amount"
                disbursement="27.1% more default"
                color="red-color"
                disbursementLabel=""
                disbursementLabelText=""
                disbursementSpanText=""
              />
            </div>
          </div> */}

          <div className="loan-container">
            <DataTable
              tableData={loanList}
              tableHeaderData={tableHeaderData}
              totalRecords={totalRecords}
              isFetching={isFetching}
              getTableData={getLoanData}
              exportUrl={loanLeadsExportUrl}
              tableTitle="LOANS LEADS"
              pageFilters={loanFilters}
              defaultSortKey={'createdAt'}
              defaultSortDirection={'desc'}
              TableLoader={TableLoader}
            />
          </div>
        </div>
        <Footer show={show} />
        {/* <div className={`common-side-bar ${show ? 'active' : ''} `}>
          <SideBar addClassCallBack={addClassCallBack} show={show} />
        </div> */}
      </div>
    </div>
  );
};

export default Loan;
