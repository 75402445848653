import {
    DISBURSMENT_CHIP_REQUEST,
    DISBURSMENT_CHIP_SUCCESS,
    DISBURSMENT_CHIP_FAILURE
} from './disbursment-constants';


const disbursmentChipRequest = () => ({
  type: DISBURSMENT_CHIP_REQUEST,
 
});

export const disbursmentChipSuccess = (data) => ({
  data,  
  type: DISBURSMENT_CHIP_SUCCESS,
});

export const disbursmentChipFailure = () => ({
  type: DISBURSMENT_CHIP_FAILURE,
});

export const disbursmentChip = async ( dispatch) => {
  dispatch(disbursmentChipRequest());
};

