import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import CloseIconWhite from '../../../../images/icons/closeIconWhite.svg';
import { GoogleMap, useJsApiLoader ,Marker } from '@react-google-maps/api';

const containerStyle = {
  width: '450px',
  height: '400px'
};
const AddressMap = (props) => { 
  const center = { lat: Number(props.maplogLat.lat), lng: Number(props.maplogLat.lng) }
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyCUL4lmu1SiNKYAltK98XaRwfKiRIzPJBs"
  })
  const [map, setMap] = React.useState(null)
  const onLoad = React.useCallback(function callback(map) {  
    setMap(map)
  }, [])


  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])
  return (
    <Modal
      show={props.viewMapDetail}
      onHide={() => props.setViewMapDetail(false)}
      animation={false}
      className="loan-eligible-reason-popup"
    >
      <div className="common-image">
        <div
          className="common-img"
          onClick={() => props.setViewMapDetail(false)}
        >
          <img alt="close" src={CloseIconWhite} />
        </div>
      </div>
      <Modal.Body>
        <div className="editaddress-sec">
          <div className="filter-header">
            <h2>ADDRESS LOCATION</h2>
         
          </div>
         
          { isLoaded ? (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
     //   defaultCenter={{ lat: 18.559008, lng: -68.388881 }}
        zoom={16}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
     { <Marker position={center} />}
      </GoogleMap>
  ) : ('')}
      

          <div className="filter-bottom">
            <button
              className="cancel-btn filter-btn"
              onClick={() => {
                props.closemapDetailsPopup();
              }}
            >
              Cancel
            </button>
            <button
              className="black-border-btn filter-btn"
              onClick={() => {
                props.closemapDetailsPopup();
              }}
            >
              OK
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddressMap;
