import {
  EMANDATE_INFO_REQUEST,
  EMANDATE_INFO_SUCCESS,
  EMANDATE_INFO_FAILURE,
} from './emandate-constants';

const initialState = {
  error: false,
  isLoading: false,
  success: false
};

const emandatereducer = (state = initialState, action) => {
  switch (action.type) {
    case EMANDATE_INFO_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case EMANDATE_INFO_SUCCESS:
      return {
        ...state,
        success: true,
        isLoading: false,
      };
    case EMANDATE_INFO_FAILURE:
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
};

export default emandatereducer;
