import React, { useEffect, useState } from 'react';
import { profileTabDetailsConst } from './profile-details-tab-constant';
import ResponsiveTableCustom from '../../responsive-normal-data-table';
import EditIcon from '../../../../images/icons/editIconBlack.svg';

const ProfileDetailsTab = ({
  callLogs,
  identityDocument,
  financialDocument,
  loans,
  setEditBankStatement,
  documents,
  setAddCallLogs,
  setAddnewLoan,
  references,
  updateBankStatementToPending,
  fraudReportAnalytics,
  lastMonthLoan,
  addressDocuments,
}) => {
  const [currentTab, setCurrentTab] = useState('IDENTITY_CHECK');
  const [currentTableData, setCurrentTabData] = useState(identityDocument);

  const editButtonStatementHandler = () => {
    setEditBankStatement(true);
  };

  const actionAndEditButton = (cell, tableRowData) => {
    if (cell === 'Bank Statement' && tableRowData.status !== 'verified') {
      // |<img src={ViewIcon} width={20} style={{marginLeft:'5px',cursor:'pointer'}}/>
      return (
        <span>
          <img
            src={EditIcon}
            width={15}
            style={{ marginRight: '5px', cursor: 'pointer' }}
            onClick={() => editButtonStatementHandler()}
          />
        </span>
      );
    }
    if (cell === 'Bank Statement' && tableRowData.status === 'verified') {
      // |<img src={ViewIcon} width={20} style={{marginLeft:'5px',cursor:'pointer'}}/>
      return (
        <span
          onClick={() => updateBankStatementToPending()}
          style={{ cursor: 'pointer' }}
        >
          <img src={EditIcon} width={15} style={{ marginRight: '5px' }} />{' '}
          Change Status to Pending
        </span>
      );
    }
    return null;
  };
  const LoanLink = (cell, tableRowData = null) => (
    <Link to={`/loan/${tableRowData.publicId}`}>{cell || '-'}</Link>
  );

  useEffect(() => {
    profileTabDetailsConst.FINANCIAL_CHECK.tableHeaderData.map((itemParam) => {
      const item = itemParam;

      if (item.name === 'action') {
        item.dataFormat = actionAndEditButton;
      }
      return false;
    });

    profileTabDetailsConst.LOAN_INFO.tableHeaderData.map((itemParam) => {
      const item = itemParam;

      if (item.name === 'number') {
        item.dataFormat = LoanLink;
      }
      return false;
    });
  }, []);

  const selectTabHandler = (listItem) => {
    setCurrentTab(listItem);
    switch (listItem) {
      case 'IDENTITY_CHECK':
        setCurrentTabData(identityDocument);
        break;
      case 'FINANCIAL_CHECK':
        setCurrentTabData(financialDocument);
        break;
      case 'ADDRESS_DOCUMENT':
        setCurrentTabData(addressDocuments);
        break;
      case 'USER_CALL_LOGS':
        setCurrentTabData(callLogs);
        break;
      case 'LOAN_INFO':
        setCurrentTabData(loans);
        break;
      case 'CHECK_LIST':
        setCurrentTabData(documents);
        break;
      case 'REFERENCES':
        setCurrentTabData(references);
        break;
      case 'FRAUD_ANALYSIS':
        setCurrentTabData(fraudReportAnalytics);
        break;
      case 'LAST_MONTH_LOANS':
        setCurrentTabData(lastMonthLoan);
        break;
      default:
        break;
    }
  };

  return (
    <div className="profile-details-tab">
      <ul className="table-name-profile-tab-contanier">
        {Object.keys(profileTabDetailsConst).map((listItem, index) => (
          <li
            key={index}
            onClick={() => selectTabHandler(listItem)}
            className={`${
              currentTab === listItem && 'profile-details-tab-active'
            }`}
          >
            {profileTabDetailsConst[listItem].tableName}
          </li>
        ))}
      </ul>
      {currentTab === 'USER_CALL_LOGS' ? (
        <div className="add-user-call-log-custom">
          <span className="add-user-call-text-custom">
            Here you can check-out the all user call logs history.
          </span>
          <button
            onClick={() => {
              setAddCallLogs(true);
            }}
            className="add-user-call-log-button-custom"
          >
            Add Call logs
          </button>
        </div>
      ) : null}
      {currentTab === 'LOAN_INFO' ? (
        <div className="add-user-call-log-custom">
          <span className="add-user-call-text-custom">
            Here you can add new Loan For user.
          </span>
          <button
            onClick={() => {
              setAddnewLoan(true);
            }}
            className="add-user-call-log-button-custom"
          >
            Add Loan
          </button>
        </div>
      ) : null}
      <ResponsiveTableCustom
        tableHeaderData={profileTabDetailsConst[currentTab].tableHeaderData}
        tableData={currentTableData}
      />
    </div>
  );
};

export default ProfileDetailsTab;
