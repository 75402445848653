import {
    USER_ADDRESS_LOCATION_REQUEST,
   USER_ADDRESS_LOCATION_SUCCESS,
   USER_ADDRESS_LOCATION_FAILURE,
} from './profile-constants';

const userAddressLocationRequest = (id) => ({
  id,
  type: USER_ADDRESS_LOCATION_REQUEST,
});

export const userAddressLocationSuccess = (data) => ({
  data,
  type: USER_ADDRESS_LOCATION_SUCCESS,
});
export const userAddressLocationFailure = () => ({
  type: USER_ADDRESS_LOCATION_FAILURE,
});

export const userAddressLocation = async (id, dispatch) => {
  dispatch(userAddressLocationRequest(id));
};
