import { DATE, TEXT, TEXTAREA, NUMBER } from '../../../constants/form-type';

export const manualPaymentFormFields = [
  {
    label: 'Reference No',
    name: 'referenceId',
    placeholder: 'Enter reference no',
    validation: { isRequired: true, isEmail: false, isMobile: false },
    errMsg: '',
    type: TEXT,
  },
  {
    label: 'Enter Amount',
    name: 'amount',
    placeholder: 'Enter amount',
    validation: { isRequired: true, isEmail: false, isMobile: false },
    errMsg: '',
    type: NUMBER,
  },
  {
    label: 'Payment Date',
    name: 'date',
    placeholder: 'Select date',
    validation: {},
    errMsg: '',
    type: DATE,
  },
  {
    label: 'Descriptions',
    name: 'remarks',
    placeholder: 'Details goes here',
    validation: { isRequired: true },
    errMsg: '',
    type: TEXTAREA,
  },
];
