/* eslint-disable max-len */
/* eslint-disable max-lines */
/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import DataTable from 'moneyloji-react-data-table';

import { Link } from 'react-router-dom';

import { Breadcrumb } from 'react-bootstrap';

import { useSelector, useDispatch } from 'react-redux';

import { collectionFilters } from './collections-constants';
import { collection } from './collections-action';
import { collectionChip } from './collections-chip-action';

import Header from '../../components/header';
// import SideBar from '../../components/side-bar';
import SidebarIcon from '../../components/sidebar-icon';
import Footer from '../../components/footer';
import {
  toNumberWithCommaAndDecimal,
  toDateFormat,
  toNumberWithComma,
} from '../../utils/formattor';
import DisbursementWeekIcon from '../../../images/icons/Disbursement1.png';
import DisbursementLastdayIcon from '../../../images/icons/Disbursement2.png';
import DisbursementThismonthIcon from '../../../images/icons/Disbursement3.png';
import DisbursementLastmonthIcon from '../../../images/icons/Disbursement4.png';
import DisbursementYearIcon from '../../../images/icons/Disbursement5.png';

import { DEFAULT_PAGE_SIZE, CURRENT_DEFAULT_PAGE } from '../../constants';
import { collectionExportUrl } from '../../utils/urls';
import TableLoader from '../../components/Loaders/table-loader';

const Collections = () => {
  const [show, setShow] = useState(false);

  const dispatch = useDispatch();

  const getCollectionData = (urlParams) => {
    collection(urlParams, dispatch);
  };

  useEffect(() => {
    const urlParams = {
      pageNumber: CURRENT_DEFAULT_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
      'sorting[0][direction]': ['DESC'],
      'sorting[0][key]': ['createdAt']
    };

    getCollectionData(urlParams);
    collectionChip(dispatch)
  }, []);

  const { collectionDetails, totalRecords, isFetching } = useSelector(
    (state) => state.collection
  );
  const { collectionChipData } = useSelector((state) => state.collectionChip);

  const collectionList =
  collectionDetails &&
  collectionDetails.map((collectionData, index) => {
    const id = index + 1;

    return {
      amount: collectionData.amount,
      emiNumber: collectionData.totalPaid || '-',
      id,
      mobileNumber: collectionData.mobileNumber,
      name: collectionData.name,
      number: '-',
      paymentDate: collectionData.paymentDate,
      publicId: collectionData.publicId,
      status: collectionData.status,
      userId: collectionData.userId,
      lenderName: collectionData.lenderName,
      source: collectionData.source
    };
  });

  function buttonFormatter(cell) {
    if (cell === 'failed') {
      return <label className="status-unpaid">{cell}</label>;
    } else if (cell === 'captured') {
      return <label className="label-status-paid">{cell}</label>;
    }
    // if (cell === 'Unpaid') {
    //   return <label className="status-unpaid">{cell}</label>;
    // } else if (cell === 'Paid') {
    //   return <label className="label-status-paid">{cell}</label>;
    // }
    else if (cell === 'Partial-Paid') {
      return <label className="status-partialpaid">{cell}</label>;
    } else if (cell === 'Payment-Initiated') {
      return <label className="label-status-initiated">{cell}</label>;
    }

    return '-';
  }

  const numberFormatter = (cell) => {
    if (cell) {
      return toNumberWithCommaAndDecimal(cell);
    }
    return '-';
  };

  const dateFormatter = (cell) => {
    if (cell) {
      return toDateFormat(cell);
    }
    return '-';
  };

  function userName(cell, row) {
    return <Link to={`/users/${row.userId}`}>{cell}</Link>;
  }

  function userMobileNo(cell, row) {
    return <Link to={`/users/${row.userId}`}>{cell}</Link>;
  }

  const addClassCallBack = () => {
    setShow(!show);
  };

  const tableHeaderData = [
    {
      columnClassName: '',
      dataField: 'id',
      dataFormat: (cell) => cell,
      dataSort: false,
      isKey: true,
      name: '#',
      width: '80',
    },
    {
      columnClassName: '',
      dataField: 'emiNumber',
      dataFormat: (cell) => cell,
      dataSort: true,
      isKey: false,
      name: 'EMI No',
      width: '120',
    },
    {
      columnClassName: '',
      dataField: 'name',
      dataFormat: userName,
      dataSort: true,
      isKey: false,
      name: 'Name',
      width: '180',
    },
    {
      columnClassName: '',
      dataField: 'mobileNumber',
      dataFormat: userMobileNo,
      dataSort: false,
      isKey: false,
      name: 'Mobile No',
      width: '150',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'paymentDate',
      dataFormat: dateFormatter,
      dataSort: true,
      isKey: false,
      name: 'payment date',
      width: '150',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'lenderName',
      dataFormat: (cell) => cell,
      dataSort: true,
      isKey: false,
      name: 'Lender Name',
      width: '150',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'amount',
      dataFormat: numberFormatter,
      dataSort: true,
      isKey: false,
      name: 'Value',
      width: '150',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'source',
      dataFormat: (cell) => cell,
      dataSort: true,
      isKey: false,
      name: 'Source',
      width: '150',
    },
    {
      columnClassName: '',
      dataField: 'status',
      dataFormat: buttonFormatter,
      dataSort: true,
      isKey: false,
      name: 'Status',
      width: '140',
    },
  ];

  return (
    <div>
      <Header />
      <div className="common-container">
        <SidebarIcon addClassCallBack={addClassCallBack} show={show} />
        <div className={`common-wrapper ${show ? 'active' : ''} `}>
          <div className="col-md-12 mpad">
            <div className="common-heading">
              <h1>Collections</h1>
              <Breadcrumb>
                <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>Report</Breadcrumb.Item>
                <Breadcrumb.Item active>Collections</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
        <div className='row' style={{ justifyContent: 'space-evenly' }}>
          <div className='col-md-2'>
                    <div className="chip">
                      <div className="chip-body">
                        <h5 className="num-defaults">&#8377;{collectionChipData && collectionChipData.week? toNumberWithComma(collectionChipData.week) :'0.00'}</h5>
                        <h3 className="heading">Week till date Collections</h3>
                        <img src={DisbursementWeekIcon} alt='placeholder' />
                        </div>
                    </div>
                  </div>
                  <div className='col-md-2'>
                    <div className="chip">
                      <div className="chip-body">
                        <h5 className="num-defaults">&#8377;{collectionChipData && collectionChipData.lastDay? toNumberWithComma(collectionChipData.lastDay):'0.00'}</h5>
                        <h3 className="heading">Yesterday Collections</h3>
                        <img src={DisbursementLastdayIcon} alt='placeholder' />
                        </div>
                    </div>
                  </div>

                   <div className='col-md-2'>
                    <div className="chip">
                      <div className="chip-body">
                        <h5 className="num-defaults">&#8377;{collectionChipData && collectionChipData.thisMonth? toNumberWithComma(collectionChipData.thisMonth) :'0.00'}</h5>
                        <h3 className="heading">Month till date Collections</h3>
                        <img src={DisbursementThismonthIcon} alt='placeholder' />
                        </div>
                    </div>
                  </div>

                  <div className='col-md-2'>
                    <div className="chip">
                      <div className="chip-body">
                        <h5 className="num-defaults">&#8377;{collectionChipData && collectionChipData.lastMonth? toNumberWithComma(collectionChipData.lastMonth):'0.00'}</h5>
                        <h3 className="heading">Last Month Collections</h3>
                        <img src={DisbursementLastmonthIcon} alt='placeholder' />
                        </div>
                    </div>
                  </div>
                  <div className='col-md-2'>
                    <div className="chip">
                      <div className="chip-body">
                        <h5 className="num-defaults">&#8377;{collectionChipData && collectionChipData.calenderYear?toNumberWithComma(collectionChipData.calenderYear):'0.00'}</h5>
                        <h3 className="heading">Year till date Collections</h3>
                        <img src={DisbursementYearIcon}  alt='placeholder' />
                        </div>
                    </div>
                  </div>
              </div>
              <div className='row mt-3' style={{ justifyContent: 'space-evenly' }}>
              <div className='col-md-2'>
                    <div className="chip">
                      <div className="chip-body">
                        <h5 className="num-defaults">&#8377;{collectionChipData && collectionChipData.weeklyDue? toNumberWithComma(collectionChipData.weeklyDue) :'0.00'}</h5>
                        <h3 className="heading">Due Week till date</h3>
                        <img src={DisbursementWeekIcon} alt='placeholder' />
                        </div>
                    </div>
                  </div>
                  <div className='col-md-2'>
                    <div className="chip">
                      <div className="chip-body">
                        <h5 className="num-defaults">&#8377;{collectionChipData && collectionChipData.lastDayDue? toNumberWithComma(collectionChipData.lastDayDue):'0.00'}</h5>
                        <h3 className="heading">Due Yesterday</h3>
                        <img src={DisbursementLastdayIcon} alt='placeholder' />
                        </div>
                    </div>
                  </div>

                   <div className='col-md-2'>
                    <div className="chip">
                      <div className="chip-body">
                        <h5 className="num-defaults">&#8377;{collectionChipData && collectionChipData.thisMonthDue? toNumberWithComma(collectionChipData.thisMonthDue) :'0.00'}</h5>
                        <h3 className="heading">Due Month till date </h3>
                        <img src={DisbursementThismonthIcon} alt='placeholder' />
                        </div>
                    </div>
                  </div>

                  <div className='col-md-2'>
                    <div className="chip">
                      <div className="chip-body">
                        <h5 className="num-defaults">&#8377;{collectionChipData && collectionChipData.lastMonthDue? toNumberWithComma(collectionChipData.lastMonthDue):'0.00'}</h5>
                        <h3 className="heading">Due Last Month</h3>
                        <img src={DisbursementLastmonthIcon} alt='placeholder' />
                        </div>
                    </div>
                  </div>
                  <div className='col-md-2'>
                    <div className="chip">
                      <div className="chip-body">
                        <h5 className="num-defaults">&#8377;{collectionChipData && collectionChipData.calenderYearDue?toNumberWithComma(collectionChipData.calenderYearDue):'0.00'}</h5>
                        <h3 className="heading">Due Year till date</h3>
                        <img src={DisbursementYearIcon}  alt='placeholder' />
                        </div>
                    </div>
                  </div>
            </div>
          <div className="today-emi-sec">
          <DataTable
              tableData={collectionList}
              tableHeaderData={tableHeaderData}
              totalRecords={totalRecords}
              isFetching={isFetching}
              getTableData={getCollectionData}
              exportUrl={collectionExportUrl}
              tableTitle="COLLECTIONS"
              pageFilters={collectionFilters}
              defaultSortKey={'createdAt'}
              defaultSortDirection={'desc'}
              TableLoader={TableLoader}
            />
          </div>
        </div>
        <Footer show={show} />
        {/* <div className={`common-side-bar ${show ? 'active' : ''} `}>
          <SideBar addClassCallBack={addClassCallBack} show={show} />
        </div> */}
      </div>
    </div>
  );
};

export default Collections;
