import {
  ADD_NEW_USER_REQUEST,
  ADD_NEW_USER_SUCCESS,
  ADD_NEW_USER_FAILURE,
  GET_USER_OTP_USER_SUCCESS,
} from './users-constants';

const addNewUserRequest = (data) => ({
  data,
  type: ADD_NEW_USER_REQUEST,
});

export const addNewUserSuccess = () => ({
  type: ADD_NEW_USER_SUCCESS,
});

export const getOtpUserSuccess = () => ({
  type: GET_USER_OTP_USER_SUCCESS,
});

export const addNewUserFailure = (data) => ({
  data,
  type: ADD_NEW_USER_FAILURE,
});

export const addNewUser = (
  userDetails,
  addUserFailureCallback,
  dispatch
) => {
  dispatch(
    addNewUserRequest({
      ...userDetails,
      getOtpUserSuccess,
      addUserFailureCallback,
    })
  );
};
