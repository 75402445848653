import React, { useState, useEffect } from 'react';
import './pre-approved-amount.css';
import { preApprovedAmountInfoMap } from './pre-approved-amount-constants';
import FieldValue from '../../field-key-value';

const PreApprovedAmountComponent = ({ preApprovedAmount }) => {

  const [defaultpreApproveAmount, setDefaultpreApproveAmount] = useState({});

  useEffect(() => {
    setDefaultpreApproveAmount(preApprovedAmount);
  }, [preApprovedAmount]);

  return (
    <React.Fragment>
      <div className="personal-bank-details">
        <div className="personal-heading-container">
          <div className="d-flex align-items-baseline">
            <h4 className="personal-common-heading">
                            Pre Approved Amount
            </h4>
          </div>
        </div>
        <div className="pre-approved-amount-wrapper">
          {preApprovedAmountInfoMap.map((item, index) =>
            <FieldValue
              key={index}
              fieldName={item.label}
              fieldValue={defaultpreApproveAmount && defaultpreApproveAmount[item.key]}
              type={item.type}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

export default PreApprovedAmountComponent;