import {
  LOAN_BLOCK_SUCCESS,
  LOAN_BLOCK_FAILURE,
  LOAN_BLOCK_REQUEST
} from './dashboard-constants';

const initialState = {
  isFetchLoanBlock: false,
  totalLoan: 0,
  totalTestCaseLoan: 0
};

const loanBlock = (state = initialState, action) => {
  switch (action.type) {
    case LOAN_BLOCK_REQUEST:
      return {
        ...state,
        isFetchLoanBlock: true,
      };
    case LOAN_BLOCK_FAILURE:
      return {
        ...state,
        isFetchLoanBlock: false,
      };
    case LOAN_BLOCK_SUCCESS:
      return {
        ...state,
        isFetchLoanBlock: false,
        totalLoan: action.data.total,
        totalTestCaseLoan: action.data.testCaseTotal
      };
    default:
      return state;
  }
};

export default loanBlock;
