import {
  AUTO_PAYMENT_REQUEST,
  AUTO_PAYMENT_SUCCESS,
  AUTO_PAYMENT_FAILURE,
  MANUAL_PAYMENT_REQUEST,
  MANUAL_PAYMENT_SUCCESS,
  MANUAL_PAYMENT_FAILURE
} from './payment-constant';

const initialState = {
  isInProgress: false,
  isSuccess: false,
  isFailure: false,
  errorObj: {},
  successObj: {}
};

const loanPayment = (state = initialState, action) => {
  switch (action.type) {
    case AUTO_PAYMENT_REQUEST:
      return {
        ...state,
        isSuccess: false,
        isFailure: false,
        errorObj: {},
        successObj: {},
        isInProgress: true,
      };
    case MANUAL_PAYMENT_REQUEST:
      return {
        ...state,
        isSuccess: false,
        isFailure: false,
        errorObj: {},
        successObj: {},
        isInProgress: true,
      };
    case AUTO_PAYMENT_FAILURE:
      return {
        ...state,
        isInProgress: false,
        isFailure: true,
        errorObj: action.errorObj
      };
    case MANUAL_PAYMENT_FAILURE:
      return {
        ...state,
        isInProgress: false,
        isFailure: true,
        errorObj: action.errorObj
      };
    case AUTO_PAYMENT_SUCCESS:
      return {
        ...state,
        isInProgress: false,
        successObj: action.data,
        isSuccess: true,
      };
    case MANUAL_PAYMENT_SUCCESS:
      return {
        ...state,
        isInProgress: false,
        successObj: action.data,
        isSuccess: true,
      };
    default:
      return state;
  }
};

export default loanPayment;
