import { SELECT, TEXTAREA, DATE } from '../../../constants/form-type';

export const addCallLogsConstant = [
  {
    label: 'Status',
    name: 'status',
    placeholder: 'Rejected',
    validation: { isRequired: true },
    options: [
      { name: 'Updated', value: 'updated' },
      { name: 'Rejected', value: 'rejected' },
      { name: 'Follow up', value: 'pending' },
      { name: 'Completed', value: 'completed' },
    ],
    isDisabled: false,
    type: SELECT,
  },
  {
    label: 'Follow up date',
    name: 'followUpDate',
    placeholder: 'Follow up date',
    validation: { isRequired: false },
    isDisabled: true,
    type: DATE,
  },
  {
    label: 'Summary',
    name: 'summary',
    placeholder: 'low salary',
    validation: { isRequired: true },
    options: [
      // { name: 'Low Salary', value: 'Low Salary' },
      // { name: 'Cash Salary', value: 'Cash Salary' },
      // { name: 'Other Bank', value: 'Other Bank' },
      // { name: 'Out of Service Area', value: 'Out of Service Area' },
      // { name: 'No Salary Slip', value: 'No Salary Slip' },
      // { name: 'Invalid Documents', value: 'Invalid Documents' },
      // { name: 'Credit Check', value: 'Credit Check' },
      // { name: 'Incomplete Documents', value: 'Incomplete Documents' },
      // { name: 'Unable to Upload', value: 'Unable to Upload' },
      // { name: 'Will Mail', value: 'Will Mail' },
      // { name: 'Upload Later', value: 'Upload Later' },
      // { name: 'Not Interested', value: 'Not Interested' },
      // { name: 'Reapply', value: 'Reapply' },
      // { name: 'Fraud', value: 'Fraud' },
      // { name: 'Self Employed', value: 'Self Employed' },
      // { name: 'No Answer', value: 'No Answer' },
      // { name: 'Incomplete Statement', value: 'Incomplete Statement' },
      // { name: 'Loan Closure', value: 'Loan Closure' },
      // { name: 'Loan Approved', value: 'Loan Approved' },
      // { name: 'High Obligation', value: 'High Obligation' },
      // { name: 'No Local Address Proof', value: 'No Local Address Proof' },
      // { name: 'High Bounce Rate', value: 'High Bounce Rate' },
      // { name: 'Salary Variation', value: 'Salary Variation' },
      // { name: 'Low Balance', value: 'Low Balance' },
      // { name: 'Low Credit Score', value: 'Low Credit Score' },
      // { name: 'No Bank Statement', value: 'No Bank Statement' },
      // { name: 'High Interest Rate', value: 'High Interest Rate' },
      // {
      //   name: 'Non-Contactable(telecalling)',
      //   value: 'Non-Contactable(telecalling)',
      // },
      // { name: 'Other Reasons', value: 'Other Reasons' },
    ],
    isDisabled: false,
    type: SELECT,
  },
  {
    label: 'Descriptions',
    name: 'description',
    placeholder: 'Details goes here',
    validation: { isRequired: true },
    isDisabled: false,
    type: TEXTAREA,
  },
 
];

export const Updated = [
  { name: 'Docs Updated', value: 'Docs Updated' },
  { name: 'Unable to Upload', value: 'Unable to Upload' },
  { name: 'Will Mail', value: 'Will Mail' },
];

export const Rejected = [
  { name: 'Cash salary', value: 'Cash salary' },
  { name: 'Low salary', value: 'Low salary' },
  { name: 'Fraudulent Documents', value: 'Fraudulent Documents' },
  { name: 'Other Bank', value: 'Other Bank' },
  { name: 'Invalid Documents', value: 'Invalid Documents' },
  { name: 'Out of service area', value: 'Out of service area' },
  { name: 'Low Crif score', value: 'Low Crif score' },
  { name: 'No Bank statement', value: 'No Bank statement' },
  { name: 'No Local address proof', value: 'No Local address proof' },
  { name: 'No PAN Card', value: 'No PAN Card' },
  { name: 'No Aadhaar Card', value: 'No Aadhaar Card' },
  { name: 'No Salary Slip Uploaded', value: 'No Salary Slip Uploaded' },
  { name: 'Self employed', value: 'Self employed' },
  { name: 'Not Interested', value: 'Not Interested' },
  { name: 'Low Balance', value: 'Low Balance' },
  { name: 'Others', value: 'Others' },
];

export const Pending = [
  { name: 'Incomplete Documents', value: 'Incomplete Documents' },
  { name: 'Non contactable customer', value: 'Non contactable customer' },
  { name: 'Upload Later', value: 'Upload Later' },
];

export const Completed = [
  { name: 'Credit Check', value: 'Credit Check' },
  { name: 'Loan Approved', value: 'Loan Approved' },
]

