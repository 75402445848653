import React from 'react';

import { Form } from 'react-bootstrap';
import Select from 'react-select';
import { options } from './add-manage-allocation-constant';
import Loader from '../../Loaders/table-loader'

const AddManageAllocation = ({
  closeManageAllocationPopup,
  addUserDetails,
  setAddUserDetails,
  handleManageAllocation,
  validated,
  errorResponse,
  setErrorResponse,
 isLoading,
}) => {
  const customStyles = {
    control: (base, state) => ({
      ...base,
      '&:hover': {
        border: state.isFocused ? 'solid 1px #dfdfdf' : 'solid 1px #dfdfdf',
      },
      border: state.isFocused ? 'solid 1px #dfdfdf' : 'solid 1px #dfdfdf',
      // This line disable the blue border
      boxShadow: state.isFocused ? 'solid 1px #dfdfdf' : 'solid 1px #dfdfdf',
      fontSize: state.isFocused ? '12px' : '12px',
    }),
  };


  return (
    <div className="editpopup-sec">
      <div className="editpopup-header">
        <h2>ADD MANAGE ALLOCATIONS</h2>
      </div>
      <div className="editpopup-form-sec">
        {isLoading ?<Loader/> :<Form noValidate validated={validated}>
          <div className="editpopup-input-value">
            <label className="editpopup-input-title">
            Past Days <span>*</span>
            </label>
            <Form.Control
              required
              type="number"
              placeholder="past days"
              name="pastDays"
              value={addUserDetails.pastDays}
              onInput={(e) => {
                // eslint-disable-next-line radix
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 1);
              }}
              className={
                errorResponse.field === 'pastDays' && 'error-custom-border'
              }
              onChange={(e) => {
                setAddUserDetails({
                  ...addUserDetails,
                  pastDays: e.target.value,
                });
                setErrorResponse({});
              }}
            />

            <Form.Control.Feedback type="invalid">
              Please enter a past days.
            </Form.Control.Feedback>

            {/* {errorResponse && errorResponse.field === 'pastDays' && (
              <span className="custom-error-msg">{errorResponse.message}</span>
            )} */}
          </div>
        
       
          <div className="editpopup-input-value mt-1">
            <label className="editpopup-input-title">
            Today's Absent staff
            </label>
            <Select
            isMulti
              onChange={(e) =>
                setAddUserDetails({ ...addUserDetails, emailAddress: e.map(l => l.value).join(",") })
              }
              styles={customStyles}
              options={options}
              name="emailAddress"
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: '#d8d8d8',
                  primary25: 'neutral10',
                },
              })} 
            />
          </div>
         
          
          
         
          <div className="editpopup-input-value">
          </div>
        </Form>
        }
      </div>
      <div className="editpopup-bottom">
        <button className="cancel-btn" onClick={closeManageAllocationPopup}>
          Cancel
        </button>
        <button className="black-border-btn " onClick={handleManageAllocation}>
          OK
        </button>
      </div>
    </div>
  );
};

export default AddManageAllocation;
