import React from 'react';

const Footer = ({ show }) => {
  return (
    <div className={`footer-sec ${show ? 'active' : ''} `}>
      <label>© 2021 Amar Amba Finance Hire Purchase PVT. LTD.</label>
    </div>
  );
};

export default Footer;
