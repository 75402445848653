import React from 'react';
import { Alert } from 'react-bootstrap';
import './alert-box.css';

const InfoAlertBox = ({ show, infoMsg, close, children }) => {
  if (show) {
    return (
      <Alert onClose={() => close()} className="alert-box-wrapper" dismissible>
        <div className="alert-container">
          <div>
            <div className="alert-heading">
                            Alert!
            </div>
            <div className="alert-msg">
              {infoMsg}
            </div>
            {children}
          </div>
        </div>
      </Alert>
    );
  } return null
}

export default InfoAlertBox;