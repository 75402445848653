import { EMI_CHIP_REQUEST, EMI_CHIP_SUCCESS, EMI_CHIP_FAILURE } from './emi-constants';

const initialState = {
  emiChipData: [],
  isFetching: false,
};

const emiChip = (state = initialState, action) => {
  switch (action.type) {
    case EMI_CHIP_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case EMI_CHIP_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case EMI_CHIP_SUCCESS:
      return {
        ...state,
        emiChipData: action.data,
        isFetching: false
      };
    default:
      return state;
  }
};

export default emiChip;
