import { TEXT } from '../../../constants/field-value-key';

export const preApprovedAmountInfoMap = [
  {
    label: 'Minimium Loan Amount',
    key: 'minAmount',
    type: TEXT
  },
  {
    label: 'Maximum Loan Amount',
    key: 'maxAmount',
    type: TEXT
  },
  {
    label: 'status',
    key: 'status',
    type: TEXT
  },
]