import React, { useState, useEffect } from 'react';
import DataTable from 'moneyloji-react-data-table';
import ProfileDetailsTab from '../../components/secured-loan/profile-details-tab';
import { useSelector, useDispatch } from 'react-redux';
import { Breadcrumb } from 'react-bootstrap';
import { securedLoanInfo } from './secured-loan-information-action';
import { uploadDocs } from '../profile/upload-docs-action';
import { uploadFile } from '../profile/file-upload-action';
import Header from '../../components/header';
// import SideBar from '../../components/side-bar';
import SidebarIcon from '../../components/sidebar-icon';
import LoanInformationCard from '../../components/secured-loan/loan-information-card';
import FileUpload from '../../components/secured-loan/file-upload';
// import RePayment from '../../components/Loan/re-payment';
import LoanPaymentAutoManual from '../../components/Loan/load-payment-auto-manual';
import SignAgreements from '../../components/secured-loan/sign-agreements';
// import DisbursementRemarkPopup from './disburse-loan';
import Footer from '../../components/footer';
import {
  toNumberWithCommaAndDecimal,
  toDateFormat,
} from '../../utils/formattor';
import { loanEsignAgreementUrl, paymentExportUrl } from '../../utils/urls';
// import { loanClose, loanCancel } from './loan-actions/loan-actions-action';
// import CloseLoan from './close-loan';
// import CancelLoan from './cancel-loan';
// import Button from '../../components/form-elements/button';
// import SuccessAlertBox from '../../components/alert-box/success-alert-box';
// import ErrorAlertBox from '../../components/alert-box/error-alert-box';
// import InfoAlertBox from '../../components/alert-box/information-alert-box';
// import SuccessPopup from '../../components/alert-popup/success-alert-popup';
// import ErrorPopup from '../../components/alert-popup/error-alert-popup';
// import { DEFAULT_PAGE_SIZE, CURRENT_DEFAULT_PAGE } from '../../constants';
// import { paymentFilters } from './payment-details/payments-constants';
// import { setDefaultFilter } from '../../utils/default-filter-helper';
// import { getPayments } from './payment-details/payments-action';
import Utils from '../../utils/index';

const SecuredLoanInformation = ({ match }) => {
  const [show, setShow] = useState(false);
  const [fileUploadPopup, setFileUploadPopup] = useState(false);

  // const [showCloseLoan, setShowCloseLoan] = useState(false);
  // const [showCancelLoan, setShowCancelLoan] = useState(false);
  const [role, setRole] = useState('');
  // const [isAuthorized, setIsAuthorized] = useState(false);
  // const [showAlertBox, setShowAlertBox] = useState(false);
  const [showSuccessPopUp, setShowSuccessPopUp] = useState(false);
  const [showErrorPopUp, setShowErrorPopUp] = useState(false);
  // const [showDisbursementOption, setShowDisbursementOption] = useState(false);
  // const [showDisburseRemark, setShowDisburseRemark] = useState(false);
  const dispatch = useDispatch();
  const path = window.location.pathname.split('/');
  const loanId = path[path.length - 1];

  // eslint-disable-next-line no-unused-vars
  const { loanInfoDetails, isFetching } = useSelector(
    (state) => state.securedLoanInfo
  );
  // const { isSuccess, isFailure, errorObj } = useSelector(
  //   (state) => state.loanPayment
  // );
  const { user } = loanInfoDetails;

  useEffect(() => {
    const userrole = new Utils();
    const Role = userrole.getUserRole();

    setRole(Role);
  }, []);

  useEffect(() => {
    // if(role === 'system-admin' || role === 'credit-manager'){
    //   setIsAuthorized(true);
    // }
  }, [role]);

  useEffect(() => {
    if (match.params) {
      securedLoanInfo(match.params.id, dispatch);
    } else if (loanId) {
      securedLoanInfo(loanId, dispatch);
    }
  }, []);

  // useEffect(() => {
  //   if (loanInfoDetails.status === 'loan-agreement-signed') {
  //     setShowDisbursementOption(true);
  //     setShowAlertBox(true);
  //   }
  // }, [loanInfoDetails]);

  // useEffect(() => {
  //   if (isSuccess || isFailure) {
  //     setShowAlertBox(true);
  //   }
  // }, [isSuccess, isFailure, errorObj]);

  // const getPaymentsData = (urlParams) => {
  //   getPayments(urlParams, dispatch);
  // };

  // useEffect(() => {
  //   const urlParams = {
  //     pageNumber: CURRENT_DEFAULT_PAGE,
  //     pageSize: DEFAULT_PAGE_SIZE,
  //     'sorting[0][direction]': ['DESC'],
  //     'sorting[0][key]': ['createdAt'],
  //   };

  //   if (match.params.id) {
  //     setDefaultFilter(paymentFilters, 'loanId', match.params.id);
  //   } else {
  //     getPaymentsData(urlParams);
  //   }
  // }, []);

  // const {
  //   paymentDetails,
  //   totalRecordsPayment,
  //   isFetchingPayment,
  // } = useSelector((state) => state.paymentsLoan);

  // const paymentList =
  //   paymentDetails &&
  //   paymentDetails.map((paymentData, index) => {
  //     const id = index + 1;

  //     return {
  //       emiNumber: '-',
  //       id,
  //       ...paymentData,
  //     };
  //   });

  const updateProfileDetails = (payload) => {
    uploadDocs(
      payload,
      match.params.id,
      profileData.concurrencyStamp,
      dispatch,
      handleReload
    );
  };

  const handleUploadFile = (uploadingFileKeyValue) => {
    setFileUploadPopup(!fileUploadPopup);
    updateProfileDetails({ ...uploadingFileKeyValue });
  };

  function buttonFormatter(cell) {
    if (cell === 'received' || cell === 'captured') {
      return <label className="label-status-paid">{cell}</label>;
    } else if (cell === 'failed') {
      return <label className="status-unpaid">{cell}</label>;
    } else if (cell === 'Partial-Paid') {
      return <label className="status-partialpaid">{cell}</label>;
    } else if (cell === 'Payment-Initiated' || cell === 'initiated') {
      return <label className="label-status-initiated">{cell}</label>;
    }

    return <label className="status-partialpaid">{cell}</label>;
  }

  const numberFormatter = (cell) => {
    if (cell) {
      return toNumberWithCommaAndDecimal(cell);
    }
    return '-';
  };

  const dateFormatter = (cell) => {
    if (cell) {
      return toDateFormat(cell);
    }
    return '-';
  };

  const addClassCallBack = () => {
    setShow(!show);
  };

  const tableHeaderData = [
    {
      columnClassName: 'col-grey',
      dataField: 'date',
      dataFormat: dateFormatter,
      dataSort: false,
      isKey: true,
      name: 'Date',
      width: '100',
    },
    {
      columnClassName: '',
      dataField: 'source',
      dataFormat: (cell) => cell,
      dataSort: true,
      isKey: false,
      name: 'Source',
      width: '200',
    },
    {
      columnClassName: '',
      dataField: 'remarks',
      dataFormat: (cell) => cell,
      dataSort: true,
      isKey: false,
      name: 'Remarks',
      width: '200',
    },
    {
      columnClassName: '',
      dataField: 'amount',
      dataFormat: numberFormatter,
      dataSort: false,
      isKey: false,
      name: 'Amount',
      width: '100',
    },
    {
      columnClassName: '',
      dataField: 'principalAmount',
      dataFormat: numberFormatter,
      dataSort: true,
      isKey: false,
      name: 'Principal Amount',
      width: '100',
    },
    {
      columnClassName: '',
      dataField: 'interestAmount',
      dataFormat: numberFormatter,
      dataSort: true,
      isKey: false,
      name: 'Interest Amount',
      width: '100',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'penalAmount',
      dataFormat: numberFormatter,
      dataSort: true,
      isKey: false,
      name: 'Penal Amount',
      width: '100',
    },
    {
      columnClassName: '',
      dataField: 'bounceAmount',
      dataFormat: numberFormatter,
      dataSort: true,
      isKey: false,
      name: 'Bounce Amount',
      width: '100',
    },
    {
      columnClassName: '',
      dataField: 'otherCharges',
      dataFormat: numberFormatter,
      dataSort: true,
      isKey: false,
      name: 'Other Charges',
      width: '100',
    },
    {
      columnClassName: '',
      dataField: 'status',
      dataFormat: buttonFormatter,
      dataSort: true,
      isKey: false,
      name: 'Status',
      width: '100',
    },
    {
      columnClassName: '',
      dataField: 'referenceId',
      dataFormat: (cell) => cell,
      dataSort: true,
      isKey: false,
      name: 'Reference Id',
      width: '300',
    },
    {
      columnClassName: '',
      dataField: 'transactionId',
      dataFormat: (cell) => cell,
      dataSort: true,
      isKey: false,
      name: 'Transaction Id',
      width: '300',
    },
  ];

  // const toggleCloseLoan = () => {
  //   setShowCloseLoan(!showCloseLoan);
  // };
  // const toggleCancelLoan = () => {
  //   setShowCancelLoan(!showCancelLoan);
  // };
  // const CancelLoanHandler = (obj) => {
  //   loanCancel(obj, match.params.id, dispatch);
  // };
  // const CloseLoanHandler = (obj) => {
  //   loanClose(obj, match.params.id, dispatch);
  // };

  return (
    <React.Fragment>
      <div>
        {/* <SuccessPopup
          close={() => {
            setShowSuccessPopUp(false);
          }}
          msg="You have successfully closed loan"
          show={showSuccessPopUp}
        ></SuccessPopup>
        <ErrorPopup
          close={() => {
            setShowErrorPopUp(false);
          }}
          msg={`Before closing loan you must have to pay/waive-off loan amount.
           Click on Manual Payment button to pay/waive off loan amount.`}
          show={showErrorPopUp}
        ></ErrorPopup> */}

        {/* <CloseLoan
          showCloseLoan={showCloseLoan}
          CloseCloseLoan={toggleCloseLoan}
          okButtonHandler={CloseLoanHandler}
        ></CloseLoan>
        <CancelLoan
          CloseCancelLoan={toggleCancelLoan}
          showCancelLoan={showCancelLoan}
          okButtonHandler={CancelLoanHandler}
        ></CancelLoan> */}
        <Header />
        <div className="common-container">
          <SidebarIcon addClassCallBack={addClassCallBack} show={show} />
          <div className={`common-wrapper ${show ? 'active' : ''} `}>
            <div className="col-md-12 mpad">
              <div className="common-heading">
                <h1>Loans Information</h1>
                <Breadcrumb>
                  <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
                  <Breadcrumb.Item active>Loans</Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </div>
            <div className="Loan-info-sec-container row">
              <div className="col-md-12 mt-4 mpad">
                <LoanInformationCard
                  loanInfoDetails={loanInfoDetails}
                  user={user && user}
                  // payments={payments}
                  // disbursement={disbursement}
                />
              </div>
              <div className="col-md-12 mpad">
                {/* {showAlertBox ? (
                  <div>
                    <div className="col-md-12 mpad"> */}
                {/* {isSuccess ? (
                        <SuccessAlertBox
                          show={isSuccess}
                          close={() => {
                            setShowAlertBox(false);
                          }}
                          successMsg={'Loan amount successfully disbursed.'}
                        />
                      ) : null}
                      {isFailure ? (
                        <ErrorAlertBox
                          show={isFailure}
                          close={() => setShowAlertBox(false)}
                          errorMsg={
                            'There is some issue to disburse amount, please click disburse now button to disburse loan amount.'
                          }
                        > */}
                {/* <Button click={() => {}} text={'DISBURSE NOW'} />
                          <Button
                            click={() => {}}
                            isMarginLeft={true}
                            text="CANCEL LOAN"
                          />
                        </ErrorAlertBox>
                      ) : null} */}
                {/* {showDisbursementOption && isAuthorized ? (
                        <InfoAlertBox
                          show={showDisbursementOption}
                          close={() => {
                            setShowAlertBox(false);
                          }}
                          infoMsg="The loan agreement has been signed. Please click on the disbursement button to disburse loan now."
                        >
                          <Button
                            click={() => {
                              setShowDisburseRemark(true);
                            }}
                            text={'DISBURSE NOW'}
                          />
                          <Button
                            click={() => {
                              toggleCancelLoan();
                            }}
                            isMarginLeft={true}
                            text="CANCEL"
                          />
                        </InfoAlertBox>
                      ) : null}
                    </div>
                  </div>
                ) : null} */}
                <div className="container">
                  <div className="loan-info-repay">
                    <div className="col-md-12 mpad close-loan-repayment-container">
                      {/* {loanInfoDetails.status !== 'closed' &&
                    isAuthorized ?
                        <span className="close-button-wrapper">
                          <Button text={'CLOSE LOAN'} click={toggleCloseLoan} />
                          <Button
                            text={'CANCEL LOAN'}
                            isMarginLeft={true}
                            click={toggleCancelLoan} />
                        </span> : ""
                      }
                      <RePayment loanDetails={loanDetails} /> */}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-3 col-sm-12">
                    <div className="profile-upload-document">
                      <h5>UPLOAD DOCUMENT</h5>
                      <p>only .jpg, .png, .pdf file allowed</p>
                      <div className="profile-upload-document-btn">
                        <button
                          onClick={() => setFileUploadPopup(!fileUploadPopup)}
                          className="black-border-btn black-border-browse"
                        >
                          Browse
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-12 loan-info-sign">
                    {/* <SignAgreements
                        loanAgreementInfo={loanAgreementInfo ? loanAgreementInfo : []}
                        getDownloadUrl={loanEsignAgreementUrl}
                      /> */}
                  </div>
                  {loanInfoDetails.status !== 'closed' && (
                    <div className="col-md-3 col-sm-12 loan-payment-auto-manual">
                      {/* <LoanPaymentAutoManual
                          emiAmount={
                            loanInfoDetails && loanInfoDetails.currentOutStanding
                          }
                          totalDueAmount={loanInfoDetails.balanceDueAmount}
                        /> */}
                    </div>
                  )}
                </div>
                <div className="container">
                  <div className="loan-info-repay">
                    <div className="col-md-12 mpad">
                      {/* <DataTable
                        tableData={paymentList}
                        tableHeaderData={tableHeaderData}
                        totalRecords={totalRecordsPayment}
                        isFetching={isFetchingPayment}
                        getTableData={getPaymentsData}
                        exportUrl={paymentExportUrl}
                        tableTitle="Payment"
                        pageFilters={paymentFilters}
                        defaultSortKey={'createdAt'}
                        defaultSortDirection={'desc'}
                      /> */}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <ProfileDetailsTab
                      identityDocument={user ? user.identityDocument : []}
                      callLogs={user ? user.callLogs : []}
                      financialDocument={user ? user.financialDocument : []}
                      loans={user ? user.loans : []}
                      // setEditBankStatement={setEditBankStatement}
                      documents={user ? user.documents : []}
                      //  setAddCallLogs={setAddCallLogs}
                      //   updateBankStatementToPending={
                      //     updateBankStatementToPending
                      //   }
                      references={user ? user.references : []}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer show={show} />
          {/* <div className={`common-side-bar ${show ? 'active' : ''} `}>
            <SideBar addClassCallBack={addClassCallBack} show={show} />
          </div> */}
        </div>
      </div>
      {/* <DisbursementRemarkPopup
        showDisburseRemark={showDisburseRemark}
        closeDisburseRemark={() => {
          setShowDisburseRemark(false);
        }}
        loanId={match.params.id || loanId}
      /> */}
      <FileUpload
        fileUploadPopup={fileUploadPopup}
        setFileUploadPopup={setFileUploadPopup}
        closeUploadDocumentPopup={() => setFileUploadPopup(!fileUploadPopup)}
        handleUploadFile={handleUploadFile}
        uploadFile={uploadFile}
        useDispatch={useDispatch}
      />
    </React.Fragment>
  );
};

export default SecuredLoanInformation;
