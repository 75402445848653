import {
  DISBURSE_LOAN_FAILURE,
  DISBURSE_LOAN_NOW_REQUEST,
  DISBURSE_LOAN_SUCCESS
} from './disburse-loan-constants';

const initialState = {
  isFetching: false,
  successDisburseLoan: [],
  errorDetails: []
};

const disburseLoanNow = (state = initialState, action) => {
  switch (action.type) {
    case DISBURSE_LOAN_NOW_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case DISBURSE_LOAN_FAILURE:
      return {
        ...state,
        errorDetails: action.data,
        isFetching: false,
      };
    case DISBURSE_LOAN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        successDisburseLoan: action.data,
      };
    default:
      return state;
  }
};

export default disburseLoanNow;
