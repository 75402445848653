import {
  COLLECTION_CHIP_REQUEST,
  COLLECTION_CHIP_SUCCESS,
  COLLECTION_CHIP_FAILURE,
} from './collections-constants';

const collectionChipRequest = () => ({
  type: COLLECTION_CHIP_REQUEST,
 
});

export const collectionChipSuccess = (data) => ({
  data,
  type: COLLECTION_CHIP_SUCCESS,
});

export const collectionChipFailure = () => ({
  type: COLLECTION_CHIP_FAILURE,
});

export const collectionChip = async (dispatch) => {
  dispatch(collectionChipRequest());
};
