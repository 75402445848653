import {
  USER_BLOCK_SUCCESS,
  USER_BLOCK_FAILURE,
  USER_BLOCK_REQUEST
} from './dashboard-constants';

const userBlockRequest = (urlParams) => ({
  type: USER_BLOCK_REQUEST,
  urlParams,
});

export const userBlockSuccess = (data) => ({
  data,
  type: USER_BLOCK_SUCCESS,
});

export const userBlockFailure = () => ({
  type: USER_BLOCK_FAILURE,
});

export const getUserBlock = async (urlParams, dispatch) => {
  dispatch(userBlockRequest(urlParams));
};
