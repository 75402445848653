import { call, put, takeEvery } from 'redux-saga/effects';

import HttpHelper from '../../utils/http-helper';
import { securedLoanInfoUrl } from '../../utils/urls';
import { SECURED_LOAN_INFO_REQUEST } from './secured-loan-information-constants';
import { securedloanInfoSuccess,securedloanInfoFailure } from './secured-loan-information-action';

const { getRequest } = new HttpHelper();

export function* securedLoanInfo(action) {
  try {
    const url = securedLoanInfoUrl(action.id);

    const response = yield call(getRequest, {
      url,
    });

    yield put(securedloanInfoSuccess(response.data));
  } catch (error) {
    yield put(securedloanInfoFailure());
  }
}

export function* watchSecuredLoanInfo() {
  yield takeEvery(SECURED_LOAN_INFO_REQUEST, securedLoanInfo);
}
