import {
  GET_LEADS_REPORTS_REQUEST,
  GET_LEADS_REPORTS_SUCCESS,
  GET_LEADS_REPORTS_FAILURE,
} from './my-leads-constants';

const getCallLogsRequest = (urlParams) => ({
  type: GET_LEADS_REPORTS_REQUEST,
  urlParams,
});

export const getCallLogsRequestSuccess = (data) => ({
  data,
  type: GET_LEADS_REPORTS_SUCCESS,
});

export const getCallLogsRequestFailure = () => ({
  type: GET_LEADS_REPORTS_FAILURE,
});

export const getCallLogsReports = async (urlParams,dispatch) => {
  dispatch(getCallLogsRequest(urlParams));
};
