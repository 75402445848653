/* eslint-disable no-unused-vars */
import React from 'react';
import { Link } from 'react-router-dom';
import {
  statusAndVerifiedMarker,
  viewOrDownloadFile,
  viewOrDownloadBackFile,
  viewOrDownloadFrontFile,
  addLinkToField,
} from '../../../utils/table-data-helper';
import { toDateTimeFormat, toDateFormat, toNumberWithComma } from '../../../utils/formattor';

export const profileTabDetailsConst = {
  CHECK_LIST: {
    tableName: 'CHECK LIST',
    tableHeaderData: [
      {
        name: 'Document Type',
        dataFormat: (cell, tableRowData = null) => cell,
        dataKey: 'type',
      },
      {
        name: 'DownLoad Front Pic',
        dataFormat: viewOrDownloadFrontFile,
        dataKey: 'documentFrontId',
      },
      {
        name: 'Download Back Pic',
        dataFormat: viewOrDownloadBackFile,
        dataKey: 'documentBackId',
      },
      // {
      //   name: 'Document Number',
      //   dataFormat: (cell,tableRowData = null) => cell,
      //   dataKey: 'documentNumber'
      // },
      {
        name: 'status',
        dataFormat: statusAndVerifiedMarker,
        dataKey: 'status',
      },
      {
        name: 'created At',
        dataFormat: (cell, tableRowData = null) => toDateTimeFormat(cell),
        dataKey: 'createdAt',
      },
      {
        name: 'Updated At',
        dataFormat: (cell, tableRowData = null) => toDateTimeFormat(cell),
        dataKey: 'updatedAt'
      }
    ],
  },
  IDENTITY_CHECK: {
    tableName: 'IDENTITY CHECK',
    tableHeaderData: [
      {
        name: 'Document Type',
        dataFormat: (cell, tableRowData = null) => cell,
        dataKey: 'documentType',
      },
      {
        name: 'DownLoad Front Pic',
        dataFormat: viewOrDownloadFile,
        dataKey: 'documentFrontId',
      },
      {
        name: 'Download Back Pic',
        dataFormat: viewOrDownloadFile,
        dataKey: 'documentBackId',
      },
      {
        name: 'Document Number',
        dataFormat: (cell, tableRowData = null) => cell,
        dataKey: 'documentNumber',
      },
      {
        name: 'status',
        dataFormat: statusAndVerifiedMarker,
        dataKey: 'status',
      },
    ],
  },

  FINANCIAL_CHECK: {
    tableName: 'FINANCIAL CHECK',
    tableHeaderData: [
      {
        name: 'Document Type',
        dataFormat: (cell, tableRowData = null) => cell,
        dataKey: 'documentType',
      },
      {
        name: 'Download Document',
        dataFormat: viewOrDownloadFile,
        dataKey: 'documentFileId',
      },
      {
        name: 'Validity',
        dataFormat: (cell, tableRowData = null) => toDateFormat(cell),
        dataKey: 'validity',
      },
      {
        name: 'Document Password',
        dataFormat: (cell, tableRowData = null) => cell,
        dataKey: 'documentPassword',
      },
      {
        name: 'ifsc',
        dataFormat: (cell, tableRowData = null) => cell,
        dataKey: 'ifsc',
      },
      {
        name: 'status',
        dataFormat: statusAndVerifiedMarker,
        dataKey: 'status',
      },
      {
        name: 'action',
        dataFormat: (cell, tableRowData = null) => cell,
        dataKey: 'documentType',
      },
    ],
  },
  ADDRESS_DOCUMENT: {
    tableName: 'ADDRESS DOCUMENT',
    tableHeaderData: [
      {
        name: 'Document Type',
        dataFormat: (cell, tableRowData = null) => cell,
        dataKey: 'documentType',
      },
      {
        name: 'Download Document',
        dataFormat: viewOrDownloadFile,
        dataKey: 'documentFrontId',
      },
    ],
  },
  USER_CALL_LOGS: {
    tableName: 'USER CALL LOGS',
    tableHeaderData: [
      {
        name: 'Category',
        dataFormat: (cell, tableRowData = null) => cell,
        dataKey: 'category',
      },
      {
        name: 'Created At',
        dataFormat: (cell, tableRowData = null) => cell,
        dataKey: 'createdAt',
      },
      {
        name: 'Created By',
        dataFormat: (cell, tableRowData = null) => cell,
        dataKey: 'name',
      },
      {
        name: 'Description',
        dataFormat: (cell, tableRowData = null) => cell,
        dataKey: 'description',
      },
      {
        name: 'Status',
        dataFormat: statusAndVerifiedMarker,
        dataKey: 'status',
      },
    ],
  },
  LOAN_INFO: {
    tableName: 'LOAN INFO',
    tableHeaderData: [
      {
        name: 'Loan Number',
        dataFormat: (cell, tableRowData) => (
          <Link to={`/loan/${tableRowData.publicId}`}>{cell || '-'}</Link>
        ),
        dataKey: 'number',
      },
      {
        name: 'Loan Amount',
        dataFormat: (cell, tableRowData = null) => cell,
        dataKey: 'amount',
      },
      {
        name: 'Last Payment Date',
        dataFormat: (cell, tableRowData = null) => toDateTimeFormat(cell),
        dataKey: 'lastPaymentDate',
      },
      {
        name: 'Status',
        dataFormat: statusAndVerifiedMarker,
        dataKey: 'status',
      },
    ],
  },
  REFERENCES: {
    tableName: 'REFERENCES',
    tableHeaderData: [
      {
        name: 'Name',
        dataFormat: addLinkToField,
        dataKey: 'name',
      },
      {
        name: 'Mobile Number',
        dataFormat: (cell, tableRowData = null) => cell,
        dataKey: 'mobileNumber',
      },
      {
        name: 'Email',
        dataFormat: (cell, tableRowData = null) => cell,
        dataKey: 'emailId',
      },
      {
        name: 'Relationship',
        dataFormat: (cell, tableRowData = null) => cell,
        dataKey: 'relationship',
      },
      {
        name: 'status',
        dataFormat: statusAndVerifiedMarker,
        dataKey: 'status',
      },
    ],
  },
  FRAUD_ANALYSIS: {
    tableName: 'FRAUD ANALYISIS',
    tableHeaderData: [
      {
        name: 'Rule',
        dataFormat: (cell, tableRowData = null) => cell,
        dataKey: 'rule',
      },
      {
        name: 'Rule Id',
        dataFormat: (cell, tableRowData = null) => cell,
        dataKey: 'ruleId',
      },
      {
        name: 'status',
        dataFormat: (cell, tableRowData = null) => cell,
        dataKey: 'status',
      },
    ]
  },
  LAST_MONTH_LOANS: {
    tableName: 'LAST MONTH LOANS',
    tableHeaderData: [
      {
        name: 'category',
        dataFormat: (cell, tableRowData = null) => cell,
        dataKey: 'category',
      },
      {
        name: 'amount',
        dataFormat: (cell, tableRowData = null) =>toNumberWithComma(cell),
        dataKey: 'amount',
      },
      {
        name: 'balanceAfterTransaction',
        dataFormat: (cell, tableRowData = null) => toNumberWithComma(cell),
        dataKey: 'balanceAfterTransaction',
      },
      {
        name: 'description',
        dataFormat: (cell, tableRowData = null) => cell,
        dataKey: 'description',
      },
      {
        name: 'date',
        dataFormat: (cell, tableRowData = null) => toDateFormat(cell),
        dataKey: 'date',
      },
    ]
  },
};
