import {
  SELECT,
  TEXT,
  DATE_RANGE_TYPE,
  NUMBER_RANGE_TYPE,
  NUMBER,
} from '../../constants/data-table';

export const LOAN_REQUEST = 'LOAN_REQUEST';
export const LOAN_SUCCESS = 'LOAN_SUCCESS';
export const LOAN_FAILURE = 'LOAN_FAILURE';

export const loanFilters = [
  {
    name: 'Loan Number',
    value: 'number',
    filterCondition: 'eq',
    placeholder: 'ML-001230',
    type: TEXT,
  },
  {
    name: 'Name',
    value: 'name',
    filterCondition: 'ilike',
    placeholder: 'Rahul Kumar',
    type: TEXT,
  },
  {
    name: 'Mobile Number',
    value: 'mobileNumber',
    filterCondition: 'eq',
    placeholder: '9876543263',
    type: NUMBER,
  },
  {
    name: 'EMI Number',
    value: 'emiNumber',
    filterCondition: 'eq',
    placeholder: '4',
    type: NUMBER,
  },
  {
    name: 'EMI Due Date',
    value: 'emiDueDate',
    filterCondition: 'eq',
    type: DATE_RANGE_TYPE,
    startDatevalue: 'startDate',
    startDateFilterCondition: 'gte',
    endDateFilterCondition: 'lte',
    endDatevalue: 'expectedEndDate',
  },
  {
    name: 'Value',
    value: 'amount',
    filterCondition: 'eq',
    minPlaceholder: 'min value',
    maxPlaceholder: 'max value',
    minFilterCondition: 'gte',
    maxFilterCondition: 'lte',
    type: NUMBER_RANGE_TYPE,
  },
  {
    name: 'DPD',
    value: 'dpd',
    filterCondition: 'eq',
    minPlaceholder: 'min value',
    maxPlaceholder: 'max value',
    minFilterCondition: 'gte',
    maxFilterCondition: 'lte',
    type: NUMBER_RANGE_TYPE,
  },
  {
    name: 'Status',
    value: 'status',
    placeholder: 'active',
    filterCondition: 'eq',
    type: SELECT,
    options: [
      { name: 'Active', value: 'active' },
      { name: 'Initiated', value: 'initiated' },
      { name: 'Emandate Set', value: 'emandate-set' },
      { name: 'Borrower Signed', value: 'borrower-signed' },
      { name: 'Closed', value: 'closed' },
      { name: 'Cancelled', value: 'cancelled' },
      { name: 'Loan agreement signed', value: 'loan-agreement-signed' },
      { name: 'Lender signed', value: 'lender-signed' },

    ],
  },
  {
    name: 'Partner Name',
    value: 'partnerName',
    placeholder: 'Partner name',
    filterCondition: 'eq',
    type: SELECT,
    options: [
      { name: 'Mini Loan', value: 'Mini-Loan' },
      { name: 'Jumbo loan', value: 'Jumbo loan' },
      { name: 'Bike loan', value: 'Bike loan(coming soon)' },
    ],
  },
  {
    name: 'Last Payment Date',
    value: 'lastPaymentDate',
    filterCondition: 'eq',
    type: DATE_RANGE_TYPE,
    startDatevalue: 'startDate',
    startDateFilterCondition: 'gte',
    endDateFilterCondition: 'lte',
    endDatevalue: 'expectedEndDate',
  },
  {
    name: 'Loan Date',
    value: 'createdAt',
    filterCondition: 'eq',
    type: DATE_RANGE_TYPE,
    startDatevalue: 'createdAt',
    startDateFilterCondition: 'gte',
    endDateFilterCondition: 'lte',
    endDatevalue: 'createdAt',
  }
];
