import React from 'react';
import { CSVLink } from "react-csv";

const DownloadSms = ({ smsData,profileData }) => {
  return (
    <React.Fragment>
      <div >
        <h2 className="personal-username">
          {/* {(data && data) || '-'} */}
          DOWNLOAD SMS
        </h2>
        <div className="personal-gender">
          {(smsData.userSms && <CSVLink filename={`${profileData.name}_sms`} data={smsData.userSms}><b>Download</b> </CSVLink>) ||'-'}


        </div>
      </div>
    </React.Fragment>
  )
}

export default DownloadSms;