import {
  USER_PROFILE_REQUEST,
  USER_PROFILE_SUCCESS,
  USER_PROFILE_FAILURE,
  USER_PROFILE_UPDATE_REQUEST,
  USER_PROFILE_UPDATE_SUCCESS,
  USER_PROFILE_UPDATE_FAILURE,
} from './profile-constants';

const initialState = {
  error: false,
  isFetching: false,
  userProfileData: {},
  isUpdateInProgress: false,
  isUpdateSuccess: false,
  isUpdateFailure: false,
};

const userProfile = (state = initialState, action) => {
  switch (action.type) {
    case USER_PROFILE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case USER_PROFILE_FAILURE:
      return {
        ...state,
        error: true,
        isFetching: false,
      };
    case USER_PROFILE_SUCCESS:
      return {
        ...state,
        error: false,
        isFetching: false,
        userProfileData: action.data,
      };
    case USER_PROFILE_UPDATE_REQUEST:
      return {
        ...state,
        isUpdateInProgress: true,
        isUpdateSuccess: false,
        isUpdateFailure: false
      }
    case USER_PROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        isUpdateInProgress: false,
        isUpdateSuccess: true,
        isUpdateFailure: false
      }
    case USER_PROFILE_UPDATE_FAILURE:
      return {
        ...state,
        isUpdateInProgress: false,
        isUpdateSuccess: false,
        isUpdateFailure: true
      }
    default:
      return state;
  }
};

export default userProfile;
