import {
  GET_PRODUCT_SETTINGS_REQUEST,
  GET_PRODUCT_SETTINGS_SUCCESS,
  GET_PRODUCT_SETTINGS_FAILURE
} from './profile-constants';

const initialState = {
  error: false,
  isFetchingSettings: false,
  productSettingsData: {},
};

const productSettings = (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCT_SETTINGS_REQUEST:
      return {
        ...state,
        isFetchingSettings: true,
      };
    case GET_PRODUCT_SETTINGS_FAILURE:
      return {
        ...state,
        error: true,
        isFetchingSettings: false,
      };
    case GET_PRODUCT_SETTINGS_SUCCESS:
      return {
        ...state,
        error: false,
        isFetchingSettings: false,
        productSettingsData: action.data,
      };
    default:
      return state;
  }
};

export default productSettings;
