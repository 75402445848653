// @flow
import { combineReducers } from 'redux';

// Authentication Reducers.
import signin from '../containers/sign-in/signin-reducer';
import changePassword from '../containers/change-password/change-password-reducer';
import resetPassword from '../containers/reset-password/reset-password-reducer';

import manageUsers from '../containers/manage-users/manage-users-reducer';
import managePincode from '../containers/manage-pincode/manage-pincode-reducer';
import manageProfession from '../containers/manage-profession/manage-profession-reducer';
import manageBank from '../containers/manage-bank/manage-bank-reducer';
import profileDetails from '../containers/profile/profile-details-reducer';
import addUser from '../containers/manage-users/add-user-reducer';
import addNewUser from '../containers/users/add-user-reducer';
import addCallLogs from '../containers/profile/add-call-logs-reducer';
import callLogs from '../containers/profile/call-logs-reducer';
import editUser from '../containers/manage-users/edit-user-reducer';
import generatePassword from '../containers/manage-users/generate-password-reducer';
import editBank from '../containers/manage-bank/edit-bank-reducer';
import bankDetails from '../containers/manage-bank/bank-details-reducer';
import userAddress from '../containers/profile/user-address-reducer';
import getUserAddressLocation from '../containers/profile/user-address-location-reducer';
import addAddress from '../containers/profile/address-reducer';
import blockUser from '../containers/profile/block-unblock-reducer';
import userProfile from '../containers/profile/user-profile-reducer';
import editPincode from '../containers/manage-pincode/edit-pincode-reducer';
import collection from '../containers/collections/collections-reducer';
import collectionChip from '../containers/collections/collections-chip-reducer';
import defaults from '../containers/defaults/defaults-reducer';
import emi from '../containers/emi/emi-reducer';
import emiChip from '../containers/emi/emi-chip-reducer';
import loan from '../containers/loan/loan-reducer';
import leadsLoan from '../containers/leads-loan/loan-reducer';
import loanInfo from '../containers/loan-information/loan-information-reducer';
import disburseLoanNow from '../containers/loan-information/disburse-loan-reducer';
import payments from '../containers/payments/payments-reducer';
import disbursment from '../containers/disbursment/disbursment-reducer';
import disbursmentChip from '../containers/disbursment/disbursment-chip-reducer';
import dashboard from '../containers/dashboard/dashboard-reducer';
import recentActivity from '../containers/dashboard/recent-activity-reducer';
import newRegistration from '../containers/dashboard/new-registration-reducer';
import exportCsv from '../containers/dashboard/export-csv-reducer';
import viewFile from '../containers/dashboard/view-file-reducer';
import uploadDocs from '../containers/profile/upload-docs-reducer';
import paymentDetails from '../components/side-bar/payment-details-reducer';
import loanPayment from '../containers/loan-information/loan-payment/payment-reducer';
import managePartner from '../containers/manage-partner/manage-partner-reducer';
import viewPartner from '../containers/view-partner/view-partner-reducer';
import users from '../containers/users/users-reducer';
import securedloans from '../containers/secured-loans/secured-loans-reducer';
import addAndEditBank from '../containers/profile/bank-reducer';
import userBlock from  '../containers/dashboard/user-block-reducer';
import loanBlock from  '../containers/dashboard/loan-block-reducer';
import loanAction from '../containers/loan-information/loan-actions/loan-actions-reducer';
import paymentsLoan from '../containers/loan-information/payment-details/payments-reducer';
import sendOtpUser from '../containers/users/send-otp-reducer';
import addNewLoan from '../containers/profile/add-loan-reducer';
import securedLoanInfo from '../containers/secured-loan-information/secured-loan-information-reducer';
import getCallLogs from '../containers/users/get-daily-reports-reducer';
import productSettings from '../containers/profile/get-product-settings-reducer';
import dashboardReportsReducer from '../containers/dashboard/dashboard-reports-reducer';
import emandatereducer from '../containers/loan-information/emandate-reducer';
import getDefaultReports from '../containers/defaults/defaults-report-reducer';
import UsersCall from '../containers/users/users-call-reducer';
import crifExtraction from '../containers/profile/get-crif-extraction-reducer';
import preApprovedAmount from '../containers/profile/pre-approved-amount-reducer';
import gst from '../containers/gst/gst-reducer';
import leads from '../containers/my-leads/my-leads-reducer';
import getCallLogsLead from '../containers/my-leads/get-daily-reports-reducer';
import LeadsCall from '../containers/my-leads/my-leads-call-reducer';
import addManageAllocation from '../containers/manage-allocations/add-manage-allocation-reducer';

// Root Reducer.
const reducers = combineReducers({
  addAddress,
  addCallLogs,
  addUser,
  addNewUser,
  bankDetails,
  blockUser,
  callLogs,
  changePassword,
  collection,
  dashboard,
  defaults,
  disbursment,
  collectionChip,
  emiChip,
  disbursmentChip,
  editBank,
  editPincode,
  editUser,
  emi,
  exportCsv,
  generatePassword,
  loan,
  leadsLoan,
  loanInfo,
  manageBank,
  managePincode,
  manageProfession,
  manageUsers,
  newRegistration,
  paymentDetails,
  payments,
  profileDetails,
  recentActivity,
  resetPassword,
  signin,
  uploadDocs,
  userAddress,
  getUserAddressLocation,
  userProfile,
  viewFile,
  loanPayment,
  managePartner,
  viewPartner,
  users,
  securedloans,
  disburseLoanNow,
  addAndEditBank,
  userBlock,
  loanBlock,
  loanAction,
  paymentsLoan,
  sendOtpUser,
  addNewLoan,
  securedLoanInfo,
  getCallLogs,
  productSettings,
  dashboardReportsReducer,
  emandatereducer,
  getDefaultReports,
  UsersCall,
  crifExtraction,
  preApprovedAmount,
  gst,
  leads,
  getCallLogsLead,
  LeadsCall,
  addManageAllocation
});

export default reducers;
