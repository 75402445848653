import React, { useState, useEffect } from 'react';
import DataTable from 'moneyloji-react-data-table';
import { useSelector, useDispatch } from 'react-redux';
import { Breadcrumb } from 'react-bootstrap';
import { loanInfo } from './loan-information-action';
import Header from '../../components/header';
// import SideBar from '../../components/side-bar';
import SidebarIcon from '../../components/sidebar-icon';
import LoanInformationCard from '../../components/Loan/loan-information-card';
import RePayment from '../../components/Loan/re-payment';
import LoanPaymentAutoManual from '../../components/Loan/load-payment-auto-manual';
import SignAgreements from '../../components/Loan/sign-agreements';
import DisbursementRemarkPopup from './disburse-loan';
import Footer from '../../components/footer';
import {
  toNumberWithCommaAndDecimal,
  toDateFormat,
} from '../../utils/formattor';
import { loanEsignAgreementUrl, paymentExportUrl } from '../../utils/urls';
import {
  loanClose,
  loanCancel,
  loanWaiver,
} from './loan-actions/loan-actions-action';
import { emandate } from './emandate-action';
import CloseLoan from './close-loan';
import CancelLoan from './cancel-loan';
import WaiverLoan from './waiver-loan';
import Button from '../../components/form-elements/button';
import SuccessAlertBox from '../../components/alert-box/success-alert-box';
import ErrorAlertBox from '../../components/alert-box/error-alert-box';
import InfoAlertBox from '../../components/alert-box/information-alert-box';
import SuccessPopup from '../../components/alert-popup/success-alert-popup';
import ErrorPopup from '../../components/alert-popup/error-alert-popup';
import ConfirmPopup from '../../components/alert-popup/confirm-alert-popup';
import { DEFAULT_PAGE_SIZE, CURRENT_DEFAULT_PAGE } from '../../constants';
import { paymentFilters } from './payment-details/payments-constants';
import { getPayments } from './payment-details/payments-action';
import TableLoader from '../../components/Loaders/table-loader';
import Utils from '../../utils/index';

const LoanInformation = ({ match }) => {
  const [show, setShow] = useState(false);
  const [showCloseLoan, setShowCloseLoan] = useState(false);
  const [showCancelLoan, setShowCancelLoan] = useState(false);
  const [showWaiverLoan, setShowWaiverLoan] = useState(false);
  const [role, setRole] = useState('');
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [showAlertBox, setShowAlertBox] = useState(false);
  const [showSuccessPopUp, setShowSuccessPopUp] = useState(false);
  const [showErrorPopUp, setShowErrorPopUp] = useState(false);
  const [showConfirmPopUp, setShowConfirmPopUp] = useState(false);
  const [showDisbursementOption, setShowDisbursementOption] = useState(false);
  const [showDisburseRemark, setShowDisburseRemark] = useState(false);
  const [emandateemi, setEmandateEMI] = useState(false);
  const [balanceDueAmount, setbalanceDueAmount] = useState();
  const [emandateRowData, setemandateRowData] = useState();
  const dispatch = useDispatch();
  const path = window.location.pathname.split('/');
  const loanId = path[path.length - 1];

  // eslint-disable-next-line no-unused-vars
  const { loanInfoDetails, isFetching } = useSelector(
    (state) => state.loanInfo
  );
  const { isSuccess, isFailure, errorObj } = useSelector(
    (state) => state.loanPayment
  );
  const { loanDetails, payments, disbursement, user, loanAgreementInfo } =
    loanInfoDetails;

  useEffect(() => {
    const userrole = new Utils();
    const Role = userrole.getUserRole();

    setRole(Role);
  }, []);

  useEffect(() => {
    if (role === 'system-admin' || role === 'credit-manager') {
      setIsAuthorized(true);
    }
  }, [role]);

  useEffect(() => {
    if (match.params) {
      loanInfo(match.params.id, dispatch);
    } else if (loanId) {
      loanInfo(loanId, dispatch);
    }
  }, []);

  useEffect(() => {
    if (
      loanInfoDetails.status === 'borrower-signed' ||
      loanInfoDetails.status === 'lender-signed'
    ) {
      setShowDisbursementOption(true);
      setShowAlertBox(true);
    }
  }, [loanInfoDetails]);

  useEffect(() => {
    if (isSuccess || isFailure) {
      setShowAlertBox(true);
    }
  }, [isSuccess, isFailure, errorObj]);

  useEffect(() => {
    if (emandateemi) {
      const data = {
        emandateId: emandateRowData.publicId,
        amount:
          balanceDueAmount && parseFloat(balanceDueAmount.replace(/,/g, '')),
      };

      emandate(data, dispatch);
    }
  }, [emandateemi, balanceDueAmount]);

  const getPaymentsData = (urlParams) => {
    getPayments(
      { ...urlParams, 'filters[0][eq]': loanId, 'filters[0][key]': 'loanId' },
      dispatch
    );
  };

  useEffect(() => {
    const urlParams = {
      pageNumber: CURRENT_DEFAULT_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
      'sorting[0][direction]': ['DESC'],
      'sorting[0][key]': ['createdAt'],
    };

    // if (match.params.id) {
    //   setDefaultFilter(paymentFilters, 'loanId', loanId);
    // } else {
    getPaymentsData(urlParams);
  }, []);

  const { paymentDetails, totalRecordsPayment, isFetchingPayment } =
    useSelector((state) => state.paymentsLoan);

  const paymentList =
    paymentDetails &&
    paymentDetails.map((paymentData, index) => {
      const id = index + 1;

      return {
        emiNumber: '-',
        id,
        ...paymentData,
      };
    });

  function buttonFormatter(cell) {
    if (cell === 'received' || cell === 'captured') {
      return <label className="label-status-paid">{cell}</label>;
    } else if (cell === 'failed') {
      return <label className="status-unpaid">{cell}</label>;
    } else if (cell === 'Partial-Paid') {
      return <label className="status-partialpaid">{cell}</label>;
    } else if (cell === 'Payment-Initiated' || cell === 'initiated') {
      return <label className="label-status-initiated">{cell}</label>;
    }

    return <label className="status-partialpaid">{cell}</label>;
  }

  const numberFormatter = (cell) => {
    if (cell) {
      return toNumberWithCommaAndDecimal(cell);
    }
    return '-';
  };

  const dateFormatter = (cell) => {
    if (cell) {
      return toDateFormat(cell);
    }
    return '-';
  };

  const addClassCallBack = () => {
    setShow(!show);
  };

  const tableHeaderData = [
    {
      columnClassName: 'col-grey',
      dataField: 'date',
      dataFormat: dateFormatter,
      dataSort: false,
      isKey: true,
      name: 'Date',
      width: '100',
    },
    {
      columnClassName: '',
      dataField: 'source',
      dataFormat: (cell) => cell,
      dataSort: true,
      isKey: false,
      name: 'Source',
      width: '200',
    },
    {
      columnClassName: '',
      dataField: 'remarks',
      dataFormat: (cell) => cell,
      dataSort: true,
      isKey: false,
      name: 'Remarks',
      width: '200',
    },
    {
      columnClassName: '',
      dataField: 'amount',
      dataFormat: numberFormatter,
      dataSort: false,
      isKey: false,
      name: 'Amount',
      width: '100',
    },
    {
      columnClassName: '',
      dataField: 'principalAmount',
      dataFormat: numberFormatter,
      dataSort: true,
      isKey: false,
      name: 'Principal Amount',
      width: '100',
    },
    {
      columnClassName: '',
      dataField: 'interestAmount',
      dataFormat: numberFormatter,
      dataSort: true,
      isKey: false,
      name: 'Interest Amount',
      width: '100',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'penalAmount',
      dataFormat: numberFormatter,
      dataSort: true,
      isKey: false,
      name: 'Penal Amount',
      width: '100',
    },
    {
      columnClassName: '',
      dataField: 'bounceAmount',
      dataFormat: numberFormatter,
      dataSort: true,
      isKey: false,
      name: 'Bounce Amount',
      width: '100',
    },
    {
      columnClassName: '',
      dataField: 'otherCharges',
      dataFormat: numberFormatter,
      dataSort: true,
      isKey: false,
      name: 'Other Charges',
      width: '100',
    },
    {
      columnClassName: '',
      dataField: 'status',
      dataFormat: buttonFormatter,
      dataSort: true,
      isKey: false,
      name: 'Status',
      width: '100',
    },
    {
      columnClassName: '',
      dataField: 'referenceId',
      dataFormat: (cell) => cell,
      dataSort: true,
      isKey: false,
      name: 'Reference Id',
      width: '300',
    },
    {
      columnClassName: '',
      dataField: 'transactionId',
      dataFormat: (cell) => cell,
      dataSort: true,
      isKey: false,
      name: 'Transaction Id',
      width: '300',
    },
  ];

  const toggleCloseLoan = () => {
    setShowCloseLoan(!showCloseLoan);
  };
  const toggleCancelLoan = () => {
    setShowCancelLoan(!showCancelLoan);
  };
  const toggleWaiverLoan = () => {
    setShowWaiverLoan(!showWaiverLoan);
  };
  const CancelLoanHandler = (obj) => {
    loanCancel(obj, match.params.id, dispatch);
  };
  const CloseLoanHandler = (obj) => {
    loanClose(obj, match.params.id, dispatch);
  };
  const WaiverLoanHandler = (obj) => {
    loanWaiver(obj, match.params.id, dispatch);
  };

  return (
    <React.Fragment>
      <div>
        <SuccessPopup
          close={() => {
            setShowSuccessPopUp(false);
          }}
          msg="You have successfully closed loan"
          show={showSuccessPopUp}
        ></SuccessPopup>
        <ErrorPopup
          close={() => {
            setShowErrorPopUp(false);
          }}
          msg={`Before closing loan you must have to pay/waive-off loan amount.
           Click on Manual Payment button to pay/waive off loan amount.`}
          show={showErrorPopUp}
        ></ErrorPopup>
        <ConfirmPopup
          close={() => {
            setShowConfirmPopUp(false);
          }}
          click={setEmandateEMI}
          data={balanceDueAmount}
          show={showConfirmPopUp}
          setAmount={setbalanceDueAmount}
        ></ConfirmPopup>
        <CloseLoan
          showCloseLoan={showCloseLoan}
          CloseCloseLoan={toggleCloseLoan}
          okButtonHandler={CloseLoanHandler}
        ></CloseLoan>
        <WaiverLoan
          showWaiverLoan={showWaiverLoan}
          CloseWaiverLoan={toggleWaiverLoan}
          okButtonHandler={WaiverLoanHandler}
        ></WaiverLoan>
        <CancelLoan
          CloseCancelLoan={toggleCancelLoan}
          showCancelLoan={showCancelLoan}
          okButtonHandler={CancelLoanHandler}
        ></CancelLoan>
        <Header />
        <div className="common-container">
          <SidebarIcon addClassCallBack={addClassCallBack} show={show} />
          <div className={`common-wrapper ${show ? 'active' : ''} `}>
            <div className="col-md-12 mpad">
              <div className="common-heading">
                <h1>Loans Information</h1>
                <Breadcrumb>
                  <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
                  <Breadcrumb.Item active>Loans</Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </div>
            <div className="Loan-info-sec-container row">
              <div className="col-md-12 mt-4 mpad">
                <LoanInformationCard
                  loanInfoDetails={loanInfoDetails}
                  profile={user && user}
                  payments={payments}
                  disbursement={disbursement}
                />
              </div>
              <div className="col-md-12 mpad">
                {showAlertBox ? (
                  <div>
                    <div className="col-md-12 mpad">
                      {isSuccess ? (
                        <SuccessAlertBox
                          show={isSuccess}
                          close={() => {
                            setShowAlertBox(false);
                          }}
                          successMsg={'Loan amount successfully disbursed.'}
                        />
                      ) : null}
                      {isFailure ? (
                        <ErrorAlertBox
                          show={isFailure}
                          close={() => setShowAlertBox(false)}
                          errorMsg={
                            'There is some issue to disburse amount, please click disburse now button to disburse loan amount.'
                          }
                        >
                          <Button click={() => {}} text={'DISBURSE NOW'} />
                          <Button
                            click={() => {}}
                            isMarginLeft={true}
                            text="CANCEL LOAN"
                          />
                        </ErrorAlertBox>
                      ) : null}
                      {showDisbursementOption && isAuthorized ? (
                        <InfoAlertBox
                          show={showDisbursementOption}
                          close={() => {
                            setShowAlertBox(false);
                          }}
                          infoMsg="The loan agreement has been signed. Please click on the disbursement button to disburse loan now."
                        >
                          <Button
                            click={() => {
                              setShowDisburseRemark(true);
                            }}
                            text={'DISBURSE NOW'}
                          />
                          <Button
                            click={() => {
                              toggleCancelLoan();
                            }}
                            isMarginLeft={true}
                            text="CANCEL"
                          />
                        </InfoAlertBox>
                      ) : null}
                    </div>
                  </div>
                ) : null}
                <div className="mt-4">
                  <div className="row">
                    <div className="col-lg-6 col-md-12 mpad">
                      <div className="disburment-card blue-color">
                        <div className="disburment-card-sec">
                          <div className="disburment-card-text">
                            <h1>
                              &#8377;
                              {toNumberWithCommaAndDecimal(
                                loanInfoDetails.balanceDueAmount
                              )}
                            </h1>
                            <p>Total Outstanding Amount</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 mpad">
                      <div className="disburment-card green-color">
                        <div className="disburment-card-sec">
                          <div className="disburment-card-text">
                            <h1>
                              &#8377;
                              {toNumberWithCommaAndDecimal(
                                loanInfoDetails.paidAmount
                              )}
                            </h1>
                            <p>Total Paid Amount</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-12 mt-4 mpad">
                      <div className="disburment-card blue-color">
                        <div className="disburment-card-sec">
                          <div className="disburment-card-text">
                            <h1>
                              &#8377;
                              {toNumberWithCommaAndDecimal(
                                loanInfoDetails.interestAmount -
                                  loanInfoDetails.balanceInterestAmount
                              )}
                            </h1>
                            <p>Total Net Interest</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 mt-4 mpad">
                      <div className="disburment-card green-color">
                        <div className="disburment-card-sec">
                          <div className="disburment-card-text">
                            <h1>
                              &#8377;
                              {toNumberWithCommaAndDecimal(
                                loanInfoDetails.bounceAmountAccrued -
                                  loanInfoDetails.balanceBounceAmount
                              )}
                            </h1>
                            <p>Total Bounce Amount</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-lg-6 col-md-12 mt-4 mpad">
                      <div className="disburment-card blue-color">
                        <div className="disburment-card-sec">
                          <div className="disburment-card-text">
                            <h1>
                              &#8377;
                              {toNumberWithCommaAndDecimal(
                                loanInfoDetails.interestAmount -
                                  loanInfoDetails.balanceInterestAmount +
                                  (loanInfoDetails.bounceAmountAccrued -
                                    loanInfoDetails.balanceBounceAmount) +
                                  (loanInfoDetails.penalAmountAccrued -
                                    loanInfoDetails.balancePenalAmount) +
                                  parseFloat(loanInfoDetails.processingAmount)
                              )}
                            </h1>
                            <p>Total Net Profit</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 mt-4 mpad">
                      <div className="disburment-card blue-color">
                        <div className="disburment-card-sec">
                          <div className="disburment-card-text">
                            <h1>
                              &#8377;
                              {toNumberWithCommaAndDecimal(
                                loanInfoDetails.penalAmountAccrued -
                                  loanInfoDetails.balancePenalAmount
                              )}
                            </h1>
                            <p>Total Penal Amount</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="loan-info-repay">
                  <div className="col-md-12 mpad close-loan-repayment-container">
                    {loanInfoDetails.status !== 'closed' && isAuthorized ? (
                      <span className="close-button-wrapper">
                        <Button text={'WAIVER'} click={toggleWaiverLoan} />
                        <Button
                          text={'CLOSE LOAN'}
                          isMarginLeft={true}
                          click={toggleCloseLoan}
                        />
                        <Button
                          text={'CANCEL LOAN'}
                          isMarginLeft={true}
                          click={toggleCancelLoan}
                        />
                      </span>
                    ) : (
                      ''
                    )}
                    <RePayment
                      loanDetails={loanDetails}
                      showPopup={setShowConfirmPopUp}
                      balanceDueAmount={setbalanceDueAmount}
                      getRowTableData={setemandateRowData}
                      TableLoader={TableLoader}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-12 loan-info-sign">
                    <SignAgreements
                      loanAgreementInfo={loanAgreementInfo}
                      getDownloadUrl={loanEsignAgreementUrl}
                    />
                  </div>
                  {loanInfoDetails.status !== 'closed' && (
                    <div className="col-md-6 col-sm-12 loan-payment-auto-manual">
                      <LoanPaymentAutoManual
                        emiAmount={
                          loanInfoDetails && loanInfoDetails.currentOutStanding
                        }
                        totalDueAmount={loanInfoDetails.balanceDueAmount}
                      />
                    </div>
                  )}
                </div>
                <div className="loan-info-repay">
                  <div className="col-md-12 mpad">
                    <DataTable
                      tableData={paymentList}
                      tableHeaderData={tableHeaderData}
                      totalRecords={totalRecordsPayment}
                      isFetching={isFetchingPayment}
                      getTableData={getPaymentsData}
                      exportUrl={paymentExportUrl}
                      tableTitle="Payment"
                      pageFilters={paymentFilters}
                      defaultSortKey={'createdAt'}
                      defaultSortDirection={'desc'}
                      TableLoader={TableLoader}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer show={show} />
          {/* <div className={`common-side-bar ${show ? 'active' : ''} `}>
            <SideBar addClassCallBack={addClassCallBack} show={show} />
          </div> */}
        </div>
      </div>
      <DisbursementRemarkPopup
        showDisburseRemark={showDisburseRemark}
        closeDisburseRemark={() => {
          setShowDisburseRemark(false);
        }}
        loanId={match.params.id || loanId}
      />
    </React.Fragment>
  );
};

export default LoanInformation;
