/* eslint-disable max-lines */
import React, { useState, useEffect } from 'react';
import DataTable from 'moneyloji-react-data-table';
import * as uuid from 'uuid';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import { Breadcrumb, Modal } from 'react-bootstrap';
import { addNewUser } from './add-user-action';
import { sendOtpUser } from './send-otp-action';
import { getCallLogsReports } from './get-daily-reports-action';
import { UsersA } from './users-action';

import Header from '../../components/header';
// import SideBar from '../../components/side-bar';
import Footer from '../../components/footer';
// import SidebarIcon from '../../components/sidebar-icon';
import AddUser from '../../components/Users/add-user/index';
import AddNewUser from '../../components/Users/add-new-user';
import { callUsers } from './users-call-action';
import { addCallLogs } from '../profile/add-call-logs-action';
import { usersFilters } from './users-constants';

import { userExportUrl } from '../../utils/urls';
import { setDefaultFilter, openInNewTab } from '../../utils/default-filter-helper';
import { DEFAULT_PAGE_SIZE, CURRENT_DEFAULT_PAGE } from '../../constants';
import { toNumberWithCommaAndDecimal } from '../../utils/formattor';

import CloseIconWhite from '../../../images/icons/closeIconWhite.svg';
import TableLoader from '../../components/Loaders/table-loader';
import phoneDisabled from '../../../images/icons/phoneDisabled.png';
import phoneEnabled from '../../../images/icons/phoneEnabled.png';
import phonePending from '../../../images/icons/phonePending.png';
import phoneSettings from '../../../images/icons/phoneSettings.png';
import AddCallLogs from '../../components/Profile/add-call-logs';

const Users = ({ location }) => {
  // Filter State
  const [show, setShow] = useState(false);
  // const [cities, setCities] = useState('');
  const [adduserpopup, setAdduserpopup] = useState(false);
  const [validated, setValidated] = useState(false);
  const [addCallLogsPopUP, setAddCallLogs] = useState(false);
  const [errorResponse, setErrorResponse] = useState({});
  const [addUserDetails, setAddUserDetails] = useState({
    mobileNo: '',
  });
  const [userPublicId, setUserPublicId] = useState(false);
  const [addOtp, setAddOtp] = useState({
    otp: '',
    device_id: '',
    name: '',
    email: '',
    gender: '',
    martialStatus: '',
    fathersName: '',
    dob: '',
  });
  const { isFetching, users, totalRecords } = useSelector((state) => state.users);
  const { data } = useSelector((state) => state.getCallLogs);
  const { getOtp } = useSelector((state) => state.addNewUser);
  const { isLoading }= useSelector((state) => state);
  // const [otp,setOtp] = useState()

  const usersList =
    users &&
    users.map((user, index) => {
      const id = index + 1;

      return {
        ...user,
        id,
      };
    });

  const dispatch = useDispatch();

  const getUsersData = (urlParams) => {
    UsersA(urlParams, dispatch);
    if(urlParams['filters[0][key]'] === "createdAt" || urlParams['filters[0][key]'] === undefined){
      getCallLogsReports(urlParams, dispatch);
    }
  };

  // const getCallLogsData = (urlParams) => {
  //   getCallLogsReports(urlParams, dispatch);

  // };

  const addCallLogsHandler = (addCallLogsObjParam) => {

    const addCallLogsObj = addCallLogsObjParam

    addCallLogsObj.userId = userPublicId;

    addCallLogs(addCallLogsObj,'', dispatch);
  };

  const handleAdduserPopupClose = () => setAdduserpopup(false);

  const handleAdduserPopupShow = () => setAdduserpopup(true);

  const sendotpSuccess = () => {
    const urlParams = {
      pageNumber: CURRENT_DEFAULT_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
      'sorting[0][direction]': ['DESC'],
      'sorting[0][key]': ['createdAt'],
    };

    getUsersData(urlParams);
    setValidated(false);
    handleAdduserPopupClose();
    setAddUserDetails({ mobileNo: '' });
    setAddOtp({ otp: '',
      device_id: '',
      name: '',
      email: '',
      gender: '',
      martialStatus: '',
      fathersName: '',
      dob: '', });
  };

  const addNewUserFailure = (errorRes) => {
    setErrorResponse(errorRes);
  };

  const sendotpfaliure = (errorRes) => {
    setErrorResponse(errorRes);
  };

  const handleAddUser = () => {
    setAddUserDetails(addUserDetails);

    const newUserDetails = addUserDetails;

    setValidated(true);
    if (
      addUserDetails.mobileNo
    ) {
      addNewUser(newUserDetails,addNewUserFailure,dispatch);
    }
  };

  const callToCustomer =(mob,publicId) =>{
    setUserPublicId(publicId)
    const  myoperatorUserId= localStorage.getItem('myoperatorUserId')

    if(myoperatorUserId!=='null'){
      callUsers(mob,dispatch);
      setAddCallLogs(true);
    }
  }

  const handleAddOtp = () => {
    setAddOtp(addOtp);

    const newUserDetails = {
      mobileNo: addUserDetails.mobileNo,
      device_id: uuid.v1(),
      otp: addOtp.otp,
      name: addOtp.name,
      email: addOtp.email,
      martialStatus: addOtp.martialStatus,
      fathersName: addOtp.fathersName,
      dob: addOtp.dob,
      gender: addOtp.gender,
    }

    setValidated(true);
    if (
      addUserDetails.mobileNo &&
      addOtp.otp &&
      addOtp.name &&
      addOtp.email &&
      addOtp.gender &&
      addOtp.martialStatus &&
      addOtp.fathersName &&
      addOtp.dob
    ) {
      sendOtpUser(newUserDetails,sendotpSuccess,sendotpfaliure,dispatch);
    }
  };

  // useEffect(() => {
  //   setOtp(getOtp)
  // },[getOtp]);

  useEffect(() => {
    const urlParams = {
      pageNumber: CURRENT_DEFAULT_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
      'sorting[0][direction]': ['DESC'],
      'sorting[0][key]': ['createdAt'],
    };

    if (location.search) {
      const filterKey = new URLSearchParams(location.search);
      const value = filterKey.get('value');
      const filterOnValue = filterKey.get('filterOnValue');
      const sortValue = filterKey.get('sortBy');

      if(sortValue){
        urlParams['sorting[0][key]'] = sortValue;
        getUsersData(urlParams);
      }
      setDefaultFilter(usersFilters, value, filterOnValue);
    } else {
      getUsersData(urlParams);
    }
  }, []);

  const userNameHandle = (cell, row) => {
    return <a
      // href={`/users/${row.publicId}`}
      target="_blank"
      rel="noopener noreferrer"
      onClick={() => openInNewTab(`/users/${row.publicId}`)}>
      {cell || '-'}
    </a>;
  };

  function userMobileNo(cell, row) {
    return <a onClick={() => callToCustomer(cell,row.publicId,)}>{cell}</a>;
  }

  function dateTimeFormatter(cell) {
    return <label>{cell?moment(cell).format('DD-MM-YYYY hh:mm:ss'):'-'}</label>;
  }

  function kycStatusFormatter(cell) {
    if (cell) {
      return <label className="label-status-paid">True</label>;
    }
    return <label className="status-unpaid">False</label>;
  }

  function financialStatusFormatter(cell) {
    if (cell) {
      return <label className="label-status-paid">True</label>;
    }
    return <label className="status-unpaid">False</label>;
  }

  function statusFormatter(cell) {
    if (cell === 'verified') {
      return <label className="label-status-paid">{cell}</label>;
    }
    if (cell === 'pending') {
      return <label className="label-status-paid">{cell}</label>;
    }
    return <label className="status-unpaid">{cell || 'not-uploaded'}</label>;
  }

  function callStatusFormatter(cell) {
    if (cell === 'verified') {
      return <label className="label-status-paid">{cell}</label>;
    }
    if (cell === 'pending') {
      return <label className="label-status-paid">{cell}</label>;
    }
    return <label className="status-unpaid">{cell || 'NULL'}</label>;
  }

  function registrationFeeExpiryStatus(cell) {
    if (cell === 'valid') {
      return <label className="label-status-paid">{cell}</label>;
    }
    if (cell === 'expired') {
      return <label className="label-status-paid">{cell}</label>;
    }
    return <label className="status-unpaid">{cell || 'NULL'}</label>;
  }

  function registrationFeeStatus(cell) {
    if (cell === 'captured') {
      return <label className="label-status-paid">{cell}</label>;
    }
    if (cell === 'failed') {
      return <label className="status-unpaid">{cell}</label>;
    }
    if (cell === 'incomplete') {
      return <label className="status-partialpaid">{cell}</label>;
    }
    if (cell === 'initiated') {
      return <label className="label-status-initiated">{cell}</label>;
    }
    return <label className="status-unpaid">{cell || 'NULL'}</label>;
  }

  const numberFormatter = (cell) => {
    if (cell) {
      return toNumberWithCommaAndDecimal(cell);
    }
    return '-';
  };

  const tableHeaderData = [
    {
      columnClassName: '',
      dataField: 'id',
      dataFormat: (cell) => cell,
      dataSort: false,
      isKey: true,
      name: '#',
      width: '30',
    },
    {
      columnClassName: '',
      dataField: 'name',
      dataFormat: userNameHandle,
      dataSort: true,
      isKey: false,
      name: 'Name',
      width: '180',
    },
    {
      columnClassName: '',
      dataField: 'mobileNumber',
      dataFormat: userMobileNo,
      dataSort: false,
      isKey: false,
      name: 'Mobile No',
      width: '100',
    },
    {
      columnClassName: '',
      dataField: 'employmentType',
      dataFormat: userMobileNo,
      dataSort: false,
      isKey: false,
      name: 'Emp. Type',
      width: '70',
    },
    {
      columnClassName: '',
      dataField: 'allocatedTo',
      dataFormat: userMobileNo,
      dataSort: false,
      isKey: false,
      name: 'Allocated TO',
      width: '150',
    },
    {
      columnClassName: '',
      dataField: 'primaryEmail',
      dataFormat: (cell)=>cell,
      dataSort: false,
      isKey: false,
      name: 'User Email',
      width: '150',
    },
    {
      columnClassName: '',
      dataField: 'callStatus',
      dataFormat: callStatusFormatter,
      dataSort: false,
      isKey: false,
      name: 'Call Status',
      width: '150',
    },
    {
      columnClassName: '',
      dataField: 'callCategory',
      dataFormat: (cell)=>cell,
      dataSort: false,
      isKey: false,
      name: 'Call Summary',
      width: '150',
    },
    {
      columnClassName: '',
      dataField: 'panDocumentStatus',
      dataFormat: statusFormatter,
      dataSort: false,
      isKey: false,
      name: 'Pan Status',
      width: '120',
    },
    {
      columnClassName: '',
      dataField: 'bankStatementDocumentStatus',
      dataFormat: statusFormatter,
      dataSort: false,
      isKey: false,
      name: 'Bankstatement Status',
      width: '120',
    },
    {
      columnClassName: '',
      dataField: 'gender',
      dataFormat: userMobileNo,
      dataSort: false,
      isKey: false,
      name: 'Gender',
      width: '70',
    },
    {
      columnClassName: '',
      dataField: 'crifScore',
      dataFormat: (cell) => cell,
      dataSort: true,
      isKey: false,
      name: 'Crif Score',
      width: '70',
    },
    // {
    //   columnClassName: 'col-grey',
    //   dataField: 'registrationFeeAmount',
    //   dataFormat: numberFormatter,
    //   dataSort: false,
    //   isKey: false,
    //   name: 'Registration Fee Amount',
    //   width: '120',
    // },
    // {
    //   columnClassName: 'col-grey',
    //   dataField: 'registrationFeeExpiry',
    //   dataFormat: dateTimeFormatter,
    //   dataSort: true,
    //   isKey: false,
    //   name: 'Registration Fee Expiry',
    //   width: '120',
    // },
    // {
    //   columnClassName: 'col-grey',
    //   dataField: 'registrationFeeExpiryStatus',
    //   dataFormat: registrationFeeExpiryStatus,
    //   dataSort: false,
    //   isKey: false,
    //   name: 'Registration Fee Expiry Status',
    //   width: '120',
    // },
    // {
    //   columnClassName: 'col-grey',
    //   dataField: 'registrationFeeStatus',
    //   dataFormat: registrationFeeStatus,
    //   dataSort: false,
    //   isKey: false,
    //   name: 'Registration Fee Status',
    //   width: '120',
    // },
    {
      columnClassName: '',
      dataField: 'isKycCompleted',
      dataFormat: kycStatusFormatter,
      dataSort: true,
      isKey: false,
      name: 'KYC',
      width: '100',
    },
    {
      columnClassName: '',
      dataField: 'isFinancialVerified',
      dataFormat: financialStatusFormatter,
      dataSort: true,
      isKey: false,
      name: 'Fin. Check',
      width: '100',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'createdAt',
      dataFormat: dateTimeFormatter,
      dataSort: true,
      isKey: false,
      name: 'Created At',
      width: '120',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'updatedAt',
      dataFormat: dateTimeFormatter,
      dataSort: true,
      isKey: false,
      name: 'Updated At',
      width: '120',
    },
  ];

  return (
    <div>
      <Header />
      <div className="common-container">
        {/* <SidebarIcon addClassCallBack={addClassCallBack} show={show} /> */}
        <div className={`common-wrapper ${show ? 'active' : ''} `}>
          <div className='row' style={{ justifyContent: 'space-evenly' }}>
            {data && data.map(({ status,count },index) => (
              <div className='col-md-3'>
                {
                  index <= 3 && <div className="chip">
                    <div className="chip-body">
                      <h5 className="num">{count}</h5>
                      <h3 className="heading">Application Status {status}</h3>
                      {status === "pending" && <img src={phonePending} alt='placeholder'/>}
                      {status === "rejected" && <img src={phoneDisabled} alt='placeholder'/>}
                      {status === "completed" && <img src={phoneEnabled} alt='placeholder'/>}
                      {status === "updated" && <img src={phoneSettings} alt='placeholder'/>}
                    </div>
                  </div>
                }
              </div>
            ))
            }
          </div>
          <div className="col-md-12 mpad">
            <div className="common-heading mt-4">
              <h1>Users</h1>
              <Breadcrumb>
                <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>Users</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          <div className="loan-container mar0">
            <div className="row">
              <div className="col-md-12 mpad ">
                <AddNewUser handleAdduserPopupShow={handleAdduserPopupShow} />
              </div>
              <div className="col-lg-12">
                <DataTable
                  tableData={usersList}
                  tableHeaderData={tableHeaderData}
                  totalRecords={totalRecords}
                  isFetching={isFetching}
                  getTableData={getUsersData}
                  exportUrl={userExportUrl}
                  tableTitle="USER"
                  pageFilters={usersFilters}
                  defaultSortKey={'createdAt'}
                  defaultSortDirection={'desc'}
                  TableLoader={TableLoader}
                />
              </div>
            </div>
          </div>
          <Footer show={show} />
          {/* <div className={`common-side-bar ${show ? 'active' : ''} `}>
          <SideBar addClassCallBack={addClassCallBack} show={show} />
        </div> */}
        </div>
      </div>

      {/* Add Call logs Modal */}
      <AddCallLogs
        addCallLogs={addCallLogsPopUP}
        setAddCallLogs={setAddCallLogs}
        closeaddCallLogsPopup={() => setAddCallLogs(false)}
        addCallLogsHandler={addCallLogsHandler}
      />

      <Modal
        show={adduserpopup}
        onHide={() => setAdduserpopup(false)}
        animation={false}
        className="add-user-popup"
      >
        <div className="common-image">
          <div className="common-img" onClick={() => setAdduserpopup(false)}>
            <img alt="close-icon-gray" src={CloseIconWhite} />
          </div>
        </div>
        <Modal.Body>
          <AddUser
            closeAddUserPopup={handleAdduserPopupClose}
            addUserDetails={addUserDetails}
            setAddUserDetails={setAddUserDetails}
            handleAddUser={handleAddUser}
            handleAddOtp={handleAddOtp}
            validated={validated}
            errorResponse={errorResponse}
            setErrorResponse={setErrorResponse}
            getOtp={getOtp}
            setAddOtp={setAddOtp}
            addOtp={addOtp}
            isLoading={isLoading}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Users;
