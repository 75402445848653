import {
  ADD_ADDRESS_REQUEST,
  ADD_ADDRESS_SUCCESS,
  ADD_ADDRESS_FAILURE,
  EDIT_ADDRESS_REQUEST,
  GET_ADDRESS_DETAILS_WITH_PINCODE,
  GET_ADDRESS_DETAILS_WITH_PINCODE_SUCCESS
} from './profile-constants';

const addAddressRequest = (id, addressDetails, callback) => ({
  id,
  addressDetails,
  callback,
  type: ADD_ADDRESS_REQUEST,
});

const editAddressRequest = (id, addressDetails, callback) => ({
  id,
  addressDetails,
  callback,
  type: EDIT_ADDRESS_REQUEST,
});

const getAddressDetailsWithPincodeRequest = (pincode) => ({
  pincode,
  type: GET_ADDRESS_DETAILS_WITH_PINCODE
})

export const getAddressDetailsWithPincodeSuccess = (data) => ({
  data,
  type: GET_ADDRESS_DETAILS_WITH_PINCODE_SUCCESS
})

export const addAddressSuccess = (data) => ({
  data,
  type: ADD_ADDRESS_SUCCESS,
});
export const addAddressFailure = () => ({
  type: ADD_ADDRESS_FAILURE,
});

export const addAddress = (id, addressDetails, dispatch, callback) => {
  dispatch(addAddressRequest(id, addressDetails, callback));
};

export const editAddress = (id, addressDetails, dispatch, callback) => {
  dispatch(editAddressRequest(id, addressDetails, callback));
};

export const getCityStateCountryByPincode = (pincode, dispatch) => {
  dispatch(getAddressDetailsWithPincodeRequest(pincode));
}