import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import CloseIconWhite from '../../../../images/icons/closeIconWhite.svg';
import {
  userBankKeyValueMap,
  editUserFormConstant,
} from './bank-details-constant';
import {
  setInitialFormValues,
  onChangeHandler,
  getFormDataValue,
  validateForm,
} from '../../../utils/form-helper';
import FormField from '../../form-elements';

const AddressDetails = ({
  viewBankDetails,
  bankData,
  bankDetails,
  setViewBankDetail,
  closeAndOpenAddBankPopup,
  closeBankDetailsPopup,
  editBankHandler,
}) => {
  const [editBankForm, setEditBankForm] = useState([]);
  const [currentBankToEdit, setCurrentBankToEdit] = useState({});
  const [defaultBankIndex, setDefaultBankIndex] = useState(-1);

  useEffect(() => {
    setInitialFormValues(
      editUserFormConstant,
      setEditBankForm,
      currentBankToEdit
    );
    setDefaultBankIndex(-1);
    setCurrentBankToEdit({});
  }, [viewBankDetails]);

  useEffect(() => {
    setInitialFormValues(
      editUserFormConstant,
      setEditBankForm,
      currentBankToEdit
    );
  }, [currentBankToEdit]);

  // To handle the edit of the existing address, Hide the details and open the form
  // @param index for the address on which click is pressed
  // const editHandler = (index) => {
  //   if (currentBankToEdit.editIndex === index) {
  //     setCurrentBankToEdit({});
  //   } else {
  //     const editBankNewObj = {
  //       publicId: bankDetails[index].publicId,
  //       accountHolderName: bankDetails[index].accountHolderName,
  //       accountNumber: bankDetails[index].accountNumber,
  //       accountType: bankDetails[index].accountType,
  //       ifsc: bankDetails[index].ifsc,
  //       bankId: bankDetails[index].bankId,
  //       bankName: bankDetails[index].bankName,
  //     };

  //     setCurrentBankToEdit(editBankNewObj);
  //   }
  // };
  // On click of the Ok button
  const okButtonHandler = () => {
    let isClosePopup = true;

    if (currentBankToEdit.editIndex > -1) {
      const newEditBankObj = getFormDataValue(editBankForm);

      if (defaultBankIndex === currentBankToEdit.editIndex) {
        newEditBankObj.isPrimary = true;
      }
      const [newEditBankForm, isValid] = validateForm(editBankForm);

      setEditBankForm(newEditBankForm);
      if (isValid) {
        newEditBankObj.publicId = currentBankToEdit.publicId;
        editBankHandler(newEditBankObj);
      } else {
        isClosePopup = false;
      }
    }
    if (
      defaultBankIndex > -1 &&
      defaultBankIndex !== currentBankToEdit.editIndex
    ) {
      const setPrimaryobj = {
        isPrimary: true,
        publicId: bankDetails[defaultBankIndex].publicId,
      };

      editBankHandler(setPrimaryobj);
    }
    if (isClosePopup) closeBankDetailsPopup();
  };

  return (
    <Modal
      show={viewBankDetails}
      onHide={() => setViewBankDetail(false)}
      animation={false}
      className="loan-eligible-reason-popup"
    >
      <div className="common-image">
        <div className="common-img" onClick={() => setViewBankDetail(false)}>
          <img alt="close" src={CloseIconWhite} />
        </div>
      </div>
      <Modal.Body>
        <div className="addressdetails-sec logs">
          <div className="filter-header">
            <h2>BANK DETAILS</h2>
            <button
              className="black-border-btn add-btn"
              onClick={() => {
                closeAndOpenAddBankPopup();
              }}
            >
              Add New Bank Details
            </button>
          </div>
          {bankDetails.map((bank, index) => (
            <div key={index}>
              {bank.isPrimary ? (
                <div className="addressdetails-sec-btn">
                  <button className="default-chip">Default</button>
                </div>
              ) : null}
              {/* User address details */}
              <div className="editaddress-visible-sec">
                {!(index === currentBankToEdit.editIndex) ? (
                  <h2>
                    {userBankKeyValueMap.map((item, newIndex) => (
                      <span key={newIndex}>{item.prefix + bank[item.key]}</span>
                    ))}
                  </h2>
                ) : (
                  // edit Address Form in place of address details if user wants to edit address
                  <div className="edit-address-form-wrapper">
                    {editBankForm.map((formItem, innerIndex) => (
                      <FormField
                        inputType={formItem.type}
                        key={innerIndex}
                        lableName={formItem.label}
                        name={formItem.name}
                        className={
                          innerIndex === 2 ? 'column-width-double' : ''
                        }
                        placeholder={formItem.placeholder}
                        value={formItem.value}
                        isDisabled={formItem.isDisabled}
                        options={
                          formItem.name === 'bankId'
                            ? bankData
                            : formItem.options
                        }
                        onChangeHandler={(targetValue) => {
                          onChangeHandler(
                            editBankForm,
                            formItem.name,
                            targetValue,
                            setEditBankForm
                          );
                        }}
                        isValid={formItem.isValid}
                        errorMsg={formItem.errorMsg}
                        isDisabled={formItem.isDisabled}
                        isTouched={formItem.isTouched}
                      />
                    ))}
                  </div>
                )}
                <div className="editaddress-visible-sec-btn">
                  {/* <button
                    onClick={() => {
                      editHandler(index);
                    }}
                    className="black-border-btn"
                  >
                    {index === currentBankToEdit.editIndex ? 'Cancel' : 'Edit'}
                  </button> */}
                  {/* Show set as default only in case the address is not default */}
                  {/* {!bank.isPrimary ? <button className={"black-border-btn" + ' ' +
                   (defaultBankIndex === index ? 'set-default-active' : '')}
                   onClick={() => { defaultBankIndex === index ? setDefaultBankIndex(-1)
                   : setDefaultBankIndex(index) }} >Set as Default</button> : null} */}
                </div>
              </div>
            </div>
          ))}

          <div className="filter-bottom">
            <button
              className="cancel-btn filter-btn"
              onClick={() => {
                closeBankDetailsPopup();
              }}
            >
              Cancel
            </button>
            <button
              className="black-border-btn filter-btn"
              onClick={() => {
                okButtonHandler();
              }}
            >
              OK
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddressDetails;
