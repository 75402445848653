import { call, put, takeEvery } from 'redux-saga/effects';
import * as Toastr from 'toastr';

import HttpHelper from '../../utils/http-helper';
import { profileDetailsUrl } from '../../utils/urls';
import { UPLOAD_DOCS_REQUEST } from './profile-constants';
import { uploadDocsSuccess, uploadDocsFailure } from './upload-docs-action';

const { patchRequest } = new HttpHelper();

export function* uploadDocs({ id, concurrencyStamp, documentIds, callback }) {
  try {
    const url = profileDetailsUrl(id, documentIds);
    let data = {};
    //selection of address proof document tyoe from dropdown pass extra payload (addressProofDocumentType)
    if (Object.keys(documentIds)[0] === 'addressProofDocumentId') {
      data = { ...documentIds, addressProofDocumentType: 'others' };
    } else {
      data = { ...documentIds };
    }
    Object.keys(documentIds)[0] === 'addressProofDocumentId';
    const headerParams = {
      'Content-Type': 'application/json',
      'x-coreplatform-concurrencystamp': concurrencyStamp,
    };

    const response = yield call(patchRequest, {
      data: data,
      headers: headerParams,
      url,
    });

    if (response.error) {
      const { data } = response.error.response;

      Toastr.error(data.details[0].message, 'Failure');
    } else {
      yield put(uploadDocsSuccess());
      callback();
    }
  } catch (error) {
    yield put(uploadDocsFailure());
  }
}

export function* watchUploadDocs() {
  yield takeEvery(UPLOAD_DOCS_REQUEST, uploadDocs);
}
