import { TEXT, URL, IMAGE,MONEY } from '../../constants';

export const VIEW_PARTNER_REQUEST = 'VIEW_PARTNER_REQUEST';
export const VIEW_PARTNER_SUCCESS = 'VIEW_PARTNER_SUCCESS';
export const VIEW_PARTNER_FAILURE = 'VIEW_PARTNER_FAILURE';

export const partnerDetailsKeylabelMap = [
  {
    label: "Partner Name",
    key: "name",
    type: TEXT
  },
  {
    label: "Partner Code",
    key: "code",
    type: TEXT
  },
  {
    label: "Partner Logo",
    key: "logoUrl",
    type: IMAGE
  },
  {
    label: "Website",
    key: "",
    type: URL
  },
  {
    label: "Terms & Conditions",
    key: "",
    type: TEXT
  },
  {
    label: "Product Type",
    key: "tags",
    type: TEXT
  },
  {
    label: "Description",
    key: "description",
    type: TEXT
  }
]

export const productTableData = [
  {
    label: "#",
    key: "index",
    type: MONEY
  },
  {
    label: "Product Code",
    key: "code"
  }, {
    label: "Product Name",
    key: "name"
  }, {
    label: "Min Loan Amount",
    key: "details>minLoanAmount",
    type: MONEY

  }, {
    label: "Max Loan Amount",
    key: "details>maxLoanAmount",
    type: MONEY
  }, {
    label: "Min Loan Day",
    key: "details>minLoanDays"
  }, {
    label: "Max Loan Day",
    key: "details>maxLoanDays"
  }, {
    label: "Min Processing Fee",
    key: "details>minProcessingFee",
    type: MONEY
  }, {
    label: "Actions",
    key: ""
  }
];

export const paytmSetting = [
  {
    label: "Business name",
    key: ""
  }, {
    label: "Payout account number",
    key: "payoutAccountNumber"
  }, {
    label: "Key",
    key: "mid"
  }, {
    label: "Hashed secret",
    key: ""
  }, {
    label: "Secret key",
    key: "secret"
  }, {
    label: "Hashed wallet id",
    key: "walletId"
  }
];

export const razorPaySetting = [
  {
    label: "Business name",
    key: ""
  }, {
    label: "Payout account number",
    key: "payoutAccountNumber"
  }, {
    label: "Key",
    key: "key"
  }, {
    label: "Hashed secret",
    key: "webHookSecret"
  }, {
    label: "Secret key",
    key: "secret"
  }, {
    label: "IFSC",
    key: "ifsc"
  }
]
