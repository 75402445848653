import {
  // ADD_NEW_USER_REQUEST,
  // ADD_NEW_USER_SUCCESS,
  // ADD_NEW_USER_FAILURE,
  // GET_USER_OTP_USER_SUCCESS,
  ADD_MANAGE_ALLOCATION_REQUEST,
  ADD_MANAGE_ALLOCATION_SUCCESS,
  ADD_MANAGE_ALLOCATION_FAILURE,
} from './manage-allocations-constants';

const initialState = {
  isLoading: false,
};
const addManageAllocation = (state = initialState, action) => {
  switch (action.type) {
    case ADD_MANAGE_ALLOCATION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ADD_MANAGE_ALLOCATION_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case ADD_MANAGE_ALLOCATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default addManageAllocation;
