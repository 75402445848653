import { call, put, takeEvery } from 'redux-saga/effects';

import HttpHelper from '../../utils/http-helper';
import { callLogStatus } from '../../utils/urls';
import { GET_USERS_REPORTS_REQUEST } from './users-constants';
import { getCallLogsRequestSuccess, getCallLogsRequestFailure } from './get-daily-reports-action';

const { getRequest } = new HttpHelper();

export function* getCallLogsReports(action) {

  try {
    const response = yield call(getRequest, {
      url: callLogStatus(action.urlParams),
    });

    yield put(getCallLogsRequestSuccess(response.data));
  } catch (error) {
    yield put(getCallLogsRequestFailure());
  }
}

export function* watchGetCallLogsReports() {
  yield takeEvery(GET_USERS_REPORTS_REQUEST, getCallLogsReports);
}
