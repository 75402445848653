
export const TEXT ="TEXT";
export const IMAGE ="IMAGE";
export const URL="URL";
export const MONEY="MONEY";
export const TEXT_EDITABLE_SELECT_BOX ='TEXT_EDITABLE_SELECT_BOX';
export const VERIFY_TEXT ="VERIFY_TEXT";
export const VERIFYIED_STATUS ='verified';
export const VERIFYIED_STATUS_2 ='true';
export const VERIFYIED_STATUS_3 = true;
export const DATE ="DATE";
export const VERIFY_PROFILE_TEXT ="VERIFY_PROFILE_TEXT";
export const TEXT_EDITABLE_SELECT_SEARCH_BOX = "TEXT_EDITABLE_SELECT_SEARCH_BOX";
