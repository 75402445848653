import React from 'react';
import CancelIcon from '@material-ui/icons/Cancel';

const ErrorAlertBox = ({ show, close, errorMsg, errorHeading })=>{
  return(
    <React.Fragment>
      {show && (
        <div className="personal-details-error-msg">
          <div className="personal-details-error-msg-heading">
            <h4>{errorHeading}</h4>
            <CancelIcon
              onClick={()=>{close()}}
              className="check-cancel-red error-check-cancel-red"
            />
          </div>
          <p>
            {errorMsg}
          </p>
        </div>
      )}
    </React.Fragment>
  )
}

export default ErrorAlertBox;