import { TEXTAREA } from '../../constants/form-type';

export const closeLoanFormFields = [
  // {
  //   label: 'Current Outstanding Amount',
  //   name: 'currentOutstandingAmount',
  //   placeholder: '0.00',
  //   validation: { isRequired: true },
  //   errMsg: "",
  //   type: NUMBER,
  // },
  {
    label: 'Remark',
    name: 'remarks',
    placeholder: 'Details goes here',
    validation: { isRequired: true },
    errMsg: "",
    type: TEXTAREA,
  }
];
