import {
  USERS_CALL_REQUEST,
  USERS_CALL_SUCCESS,
  USERS_CALL_FAILURE,
} from './users-constants';

const initialState = {
  error: false,
  isFetching: false,
  callUsersData: [],
};

const UsersCall = (state = initialState, action) => {
  switch (action.type) {
    case USERS_CALL_REQUEST:
      return {
        ...state,
        isFetching: true,
        callUsersData: [],
      };
    case USERS_CALL_FAILURE:
      return {
        ...state,
        error: true,
        isFetching: false,
        callUsersData: [],
      };
    case USERS_CALL_SUCCESS:
      return {
        ...state,
        error: false,
        isFetching: false,
        callUsersData: action.data,
      };
    default:
      return state;
  }
};

export default UsersCall;
