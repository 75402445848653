import {
  LOAN_ACTION_FAILURE,
  LOAN_ACTION_SUCCESS,
  LOAN_CANCEL_REQUEST,
  LOAN_CLOSE_REQUEST,
  LOAN_WAIVER_REQUEST,
} from './loan-actions-constants';

const cancelLoanRequest = (data, id) => ({
  data,
  id,
  type: LOAN_CANCEL_REQUEST,
});

const closeLoanRequest = (data, id) => ({
  data,
  id,
  type: LOAN_CLOSE_REQUEST,
});
const waiverLoanRequest = (data, id) => ({
  data,
  id,
  type: LOAN_WAIVER_REQUEST,
});
export const loanActionFailure = (data) => ({
  data,
  type: LOAN_ACTION_FAILURE,
});

export const loanActionSuccess = (data) => ({
  data,
  type: LOAN_ACTION_SUCCESS,
});

export const loanClose = async (data, id, dispatch) => {
  dispatch(closeLoanRequest(data, id));
};

export const loanCancel = async (data, id, dispatch) => {
  dispatch(cancelLoanRequest(data, id));
};
export const loanWaiver = async (data, id, dispatch) => {
  dispatch(waiverLoanRequest(data, id));
};
