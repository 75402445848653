import React, { useState, Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Breadcrumb } from 'react-bootstrap';

import Header from '../../components/header';
// import SideBar from '../../components/side-bar';
import SidebarIcon from '../../components/sidebar-icon';
import FileUpload from '../../components/Profile/file-upload';
import ProfileDetailsTab from '../../components/Profile/profile-details-tab';
import AddressDetails from '../../components/Address/address-details';
import AddressMap from '../../components/Address/address-details/addressMap';
import AddAddress from '../../components/Address/add-address';
import AddBank from '../../components/bank/add-bank';
import BankDetails from '../../components/bank/bank-details';
import EditBankStatement from '../../components/bank/edit-bank-statement';
import LoanEligibilityCheck from '../../components/Profile/loan-eligibility';
import DeleteWithReason from '../../components/Users/delete-with-reason';
import Footer from '../../components/footer';
import PageLoader from '../../components/Loaders/page-loader';
import LeftProfileInfo from '../../components/Profile/left-profile-info';
import ErrorAlertBox from '../../components/Profile/error-alert-box';
import KYCDetails from '../../components/Profile/kyc-details';
import BasicUserInfo from '../../components/Profile/user-basic-info';
import UserBankDetails from '../../components/Profile/user-bank-details';
import UserDetailsBasicExtra from '../../components/Profile/user-details-basic-extra';
import UserProfileAddressDetails from '../../components/Profile/user-profile-address-details';
// import CheckLoanEligibleForm from '../../components/Profile/check-loan-eligible-form';
import BankStatementInfo from '../../components/Profile/bank-statement-info';
import AddNewLoans from '../../components/Profile/add-new-loan';
import PreApprovedAmountComponent from '../../components/Profile/pre-approved-amount';
import FinbitSummary from '../../components/Profile/finbit-summary';
import DownloadSms from '../../components/Profile/download-sms';
import { userProfileDetails } from './user-profile-details-action';
import { userProfile } from './user-profile-action';
import { uploadDocs } from './upload-docs-action';
import { blockUser } from './block-unblock-action';
import { productSettings } from './get-product-settings-action';
import { getUserProfilePic, getUserReportUrl } from '../../utils/urls';
import { address } from '../../constants';
import { stateList } from './state-list-action';
import { partnerList } from './get-partner-action';
import { productList } from './get-product-action';
import { companyList } from './get-company-action';
import { loanPurposeList } from './loan-purpose-action';
import { getStateListOption, getBankListOption } from './common';
import { uploadFile } from './file-upload-action';
import { addCallLogs } from './add-call-logs-action';
import { addLoan } from './add-loan-action';
import { crifExtractionAction } from './get-crif-extraction-action';
import { preApprovedAmountAction } from './pre-approved-amount-action';
import { userProfileUpdate } from './user-profile-update-action';
import {
  addAddress,
  editAddress,
  getCityStateCountryByPincode,
} from './address-action';
import { userAddressLocation } from './user-address-location-action';
import {
  getBanksNameDetails,
  editBank,
  addBank,
  editBankStatement,
} from './bank-action';
import AddCallLogs from '../../components/Profile/add-call-logs';

const Profile = ({ match }) => {
  // File Upload
  const [fileUploadPopup, setFileUploadPopup] = useState(false);
  // Check Eligibility
  const [eligibility, setEligibility] = useState(false);
  // View, Add, Edit Address
  const [viewAddressDetail, setViewAddressDetail] = useState(false);
  const [addAddressPopUp, setAddAddressPopUp] = useState(false);
  const [viewMapDetail, setViewMapDetail] = useState(false);
  const [maplogLat, setMaplogLat] = useState(false);

  // View, Add, Edit Bank
  const [viewBankDetail, setViewBankDetail] = useState(false);
  const [addBankPopUp, setAddBankPopUp] = useState(false);
  const [editBankStatementPopup, setEditBankStatement] = useState(false);

  // Block User
  const [isUserBlocked, setIsUserBlocked] = useState(false);
  // Block user Reason pop up
  const [deleteWithReason, setDeleteWithReason] = useState(false);

  // Error Handle
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  // Side Bar
  const [show, setShow] = useState(false);
  // edit
  const [addressCrud] = useState(address);
  //  Store the State data when Add/Edit/View Address is called
  const [stateData, setStateData] = useState([]);

  // Get Partner List
  const [partnerData, setPartnerData] = useState([]);

  // Get Product List
  const [productData, setProductData] = useState([]);

  // Get Loan Purpose List
  const [loanPurposeData, setLoanPurposeData] = useState([]);

  // Store the Bank data when Add/Edit/View Bank is called
  const [bankData, setBankData] = useState([]);
  // Show/hide the Add Call Logs
  const [addCallLogsPopUP, setAddCallLogs] = useState(false);

  const [addnewLoanPopUP, setAddnewLoan] = useState(false);

  const [companyData, setCompanyData] = useState([]);
  const [crifDropdownValue, setCrifDropdownValue] = useState('');

  const dispatch = useDispatch();

  const { userProfileData } = useSelector((state) => state.userProfile);

  const { profileData, isFetching } = useSelector(
    (state) => state.profileDetails
  );

  const { productSettingsData } = useSelector((state) => state.productSettings);

  // addAddressDetails, error
  const { pincodeDetails } = useSelector((state) => state.addAddress);
  // Initial Data Load for User Profile

  const { userAddressLocationDetails } = useSelector(
    (state) => state.getUserAddressLocation
  );

  useEffect(() => {
    const path = window.location.pathname.split('/');
    const userId = path[path.length - 1];

    if (userId) {
      userProfileDetails(userId, dispatch);
      userProfile(userId, dispatch);
      userAddressLocation(userId, dispatch);
    } else if (match.params.id) {
      userProfileDetails(userId, dispatch);
      userProfile(userId, dispatch);
      userAddressLocation(userId, dispatch);
    }
  }, []);

  // update the state list in the form
  const updateStateList = (statesList) => {
    setStateData(getStateListOption(statesList));
  };

  // update the Bank list in the form
  const updateBankList = (bankList) => {
    setBankData(getBankListOption(bankList));
  };

  const updatePartnerList = (partnerList1) => {
    setPartnerData(partnerList1);
  };

  const updateProductList = (productList1) => {
    setProductData(productList1);
  };

  const updateCompanyList = (companyList1) => {
    setCompanyData(companyList1);
  };

  const updateLoanPurposeList = (LoanPurposeList1) => {
    setLoanPurposeData(LoanPurposeList1);
  };

  const getCrif = (userId) => {
    alert(userId);
    crifExtractionAction(userId, dispatch);
  };

  const getPreApprovedAmount = (userId) => {
    preApprovedAmountAction(userId, dispatch);
  };

  // Calling the GetState Api to get the state list

  useEffect(() => {
    if (stateData.length === 0 && (viewAddressDetail || addAddressPopUp))
      stateList(dispatch, updateStateList);
  }, [viewAddressDetail, addAddressPopUp]);

  useEffect(() => {
    partnerList(dispatch, updatePartnerList);
  }, []);

  useEffect(() => {
    loanPurposeList(dispatch, updateLoanPurposeList);
  }, []);

  useEffect(() => {
    productList(dispatch, updateProductList);
  }, []);

  useEffect(() => {
    companyList(dispatch, updateCompanyList);
  }, []);

  // Calling the GET Bank Api to get the bank list
  useEffect(() => {
    if (
      bankData.length === 0 &&
      (addBankPopUp || viewBankDetail || editBankStatementPopup)
    )
      getBanksNameDetails(dispatch, updateBankList);
  }, [addBankPopUp, viewBankDetail, editBankStatementPopup]);

  // To set user blocked/unblock
  useEffect(() => {
    if (userProfileData.status && userProfileData.status === 'blocked') {
      setIsUserBlocked(true);
    } else {
      setIsUserBlocked(false);
    }
  }, [userProfileData.status]);

  //  Function to reload the data
  const handleReload = () => {
    const path = window.location.pathname.split('/');
    const userId = path[path.length - 1];

    if (userId) {
      userProfile(userId, dispatch);
      userProfileDetails(userId, dispatch);
    } else if (match.params.id) {
      userProfile(userId, dispatch);
      userProfileDetails(userId, dispatch);
    }
  };

  // Function to update the user profile data and fetch new data once update success.
  const updateProfileDetails = (payload) => {
    Object.keys(payload)[0];
    uploadDocs(
      payload,
      Object.keys(payload)[0] === 'addressProofDocumentId'
        ? profileData.address[0].publicId
        : match.params.id,
      profileData.concurrencyStamp,
      dispatch,
      handleReload
    );
  };
  // Function to update user profile data and fetch new data once update success
  const updateUserProfileData = (payload) => {
    userProfileUpdate(
      payload,
      match.params.id,
      profileData.concurrencyStamp,
      handleReload,
      dispatch
    );
  };

  const updateCrifScore = () => {
    updateUserProfileData({ crifScore: parseInt(crifDropdownValue) });
  };
  const updateBankStatementToPending = () => {
    updateUserProfileData({ bankStatementDocumentStatus: 'pending' });
  };
  //  To toggle side bar
  const addClassCallBack = () => {
    setShow(!show);
  };
  //  close view address pop up
  const closeAddressDetailsPopup = () => {
    setViewAddressDetail(false);
  };
  const closemapDetailsPopup = () => {
    setViewMapDetail(false);
  };

  //  close Loan Eligibility pop up
  const closeEligibilityPopup = () => {
    setEligibility(false);
  };
  // Open Add Address in View Address
  const addAddressPopupShow = () => setAddAddressPopUp(true);
  // Close view/edit popup and open add address pop up
  const closeAndOpenAddAddressPopup = () => {
    setViewAddressDetail(false);
    addAddressPopupShow();
  };
  const mapPopupShow = () => setViewMapDetail(true);
  const closeAndOpenMapPopup = () => {
    setViewMapDetail(false);
    mapPopupShow();
  };
  const closeAndOpenAddBankPopup = () => {
    setViewBankDetail(false);
    setAddBankPopUp(true);
  };
  // To Upload file document related to the user
  const handleUploadFile = (uploadingFileKeyValue) => {
    setFileUploadPopup(!fileUploadPopup);
    updateProfileDetails({ ...uploadingFileKeyValue });
  };

  // Block user pop up tooggle method
  const blockUserSuccess = () => {
    setIsUserBlocked(!isUserBlocked);
  };
  //  Calling API to block/unblock user.
  const handleDeleteWithreason = (value) => {
    blockUser(match.params.id, value, blockUserSuccess, dispatch);
    setDeleteWithReason(false);
  };
  // Function to get the data of the newly added addresss
  const addAddressHandler = (addAddressDetails) => {
    addAddress(match.params.id, addAddressDetails, dispatch, handleReload);
  };
  // Function to get the data of the edited added addresss
  const editAddressHandler = (id, editaddressDetails) => {
    editAddress(id, editaddressDetails, dispatch, handleReload);
  };

  const editBankStatementHandler = (editBankStatementDetailsParam) => {
    const editBankStatementDetails = editBankStatementDetailsParam;

    editBankStatementDetails.userId = match.params.id;
    editBankStatement(editBankStatementDetails, dispatch, handleReload);
  };
  // Function to handle the add new bank request
  const addBankHandler = (bankDetailsParam) => {
    const bankDetails = bankDetailsParam;

    bankDetails.userId = match.params.id;
    addBank(bankDetails, dispatch, handleReload);
  };
  // Function to handle the edit of banks
  const editBankHandler = (bankDetails) => {
    editBank(match.params.id, bankDetails, dispatch, handleReload);
  };

  const getAddressDetailsWithPincode = (pincode) => {
    getCityStateCountryByPincode(pincode, dispatch);
  };
  // Add Call logs handler
  const addCallLogsHandler = (addCallLogsObjParam) => {
    const addCallLogsObj = addCallLogsObjParam;

    addCallLogsObj.userId = match.params.id;
    addCallLogs(addCallLogsObj, handleReload, dispatch);
  };

  const getProductSettings = (productId) => {
    productSettings(productId, dispatch);
  };
  // get crif from dropdown
  const onCrifScoreDropdownValue = (val) => {
    setCrifDropdownValue(val);
  };

  const addNewLoanshandler = (addNewLoansObjParam) => {
    const addCallLogsObj = addNewLoansObjParam;

    addCallLogsObj.userId = profileData.publicId;
    addCallLogsObj.bankId = profileData.bankId;
    addCallLogsObj.partnerId = partnerData[0].publicId;
    addCallLogsObj.amount = parseFloat(addCallLogsObj.amount).toFixed(2);
    addCallLogsObj.interestRate = parseFloat(
      addCallLogsObj.interestRate
    ).toFixed(2);
    addCallLogsObj.paidAmount = parseFloat(addCallLogsObj.paidAmount).toFixed(
      2
    );
    addCallLogsObj.interestAmount = parseFloat(
      addCallLogsObj.interestAmount
    ).toFixed(2);
    addCallLogsObj.netAmount = parseFloat(addCallLogsObj.netAmount).toFixed(2);
    addCallLogsObj.processingAmount = parseFloat(
      addCallLogsObj.processingAmount
    ).toFixed(2);
    addCallLogsObj.transferAmount = parseFloat(
      addCallLogsObj.transferAmount
    ).toFixed(2);
    addCallLogsObj.bounceCharges = parseFloat(
      addCallLogsObj.bounceCharges
    ).toFixed(2);
    addCallLogsObj.penalRate = parseFloat(addCallLogsObj.penalRate).toFixed(2);
    const data = loanPurposeData.find(
      (item) => item.name === addCallLogsObj.loanPurpose
    );

    addCallLogsObj.loanPurposeId = data.publicId;
    addLoan(addCallLogsObj, handleReload, dispatch);
  };

  return (
    <div>
      {isFetching || !Object.keys(userProfileData).length ? (
        <PageLoader />
      ) : (
        <Fragment>
          <Header />
          <div className="common-container">
            <SidebarIcon addClassCallBack={addClassCallBack} show={show} />
            <div className={`common-wrapper ${show ? 'active' : ''} `}>
              <div className="col-md-12 mpad">
                <div className="common-heading">
                  <h1>Profile</h1>
                  <Breadcrumb>
                    <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Profile</Breadcrumb.Item>
                  </Breadcrumb>
                </div>
                <div className="row">
                  <div className="mpad profile-details col-md-12 col-lg-3 ">
                    <LeftProfileInfo
                      profileData={profileData}
                      getUserProfilePic={getUserProfilePic}
                      setFileUploadPopup={setFileUploadPopup}
                      fileUploadPopup={fileUploadPopup}
                      setDeleteWithReason={setDeleteWithReason}
                      deleteWithReason={deleteWithReason}
                      isUserBlocked={isUserBlocked}
                      handleReload={handleReload}
                      getUserReportUrl={getUserReportUrl}
                      updateCrifScore={updateCrifScore}
                      getCrif={getCrif}
                      getPreApprovedAmount={getPreApprovedAmount}
                      onCrifScoreDropdownValue={onCrifScoreDropdownValue}
                    />
                  </div>
                  <div className="mpad personal-details col-md-12 col-lg-9">
                    <ErrorAlertBox
                      show={showErrorMessage}
                      errorMsg={
                        'Error message text goes here. Error message text goes here.'
                      }
                      close={() => setShowErrorMessage(false)}
                      errorHeading={'ERROR MESSAGE HEADING'}
                    />
                    <BasicUserInfo profileData={profileData} />
                    <BankStatementInfo
                      userbankStatementInfo={
                        profileData.bankStatementInfo || []
                      }
                      userProcessingStatus={
                        profileData.userProcessingStatus || []
                      }
                    />
                    <UserDetailsBasicExtra
                      userProfileData={profileData}
                      updateProfileDetails={updateProfileDetails}
                      companyData={companyData && companyData}
                    />
                    <KYCDetails profileData={profileData} />
                    <UserProfileAddressDetails
                      userAddressDetails={profileData.address}
                      userAddressLocationDetails={userAddressLocationDetails}
                      setViewAddressDetail={setViewAddressDetail}
                      setViewMapDetail={setViewMapDetail}
                      setMaplogLat={setMaplogLat}
                    />
                    <UserBankDetails
                      userBankDetails={profileData.bankDetail || []}
                      setViewBankDetail={setViewBankDetail}
                    />
                    <FinbitSummary
                      finbitSummary={profileData.finbitSummary || []}
                    />
                    <PreApprovedAmountComponent
                      preApprovedAmount={profileData.preApprovedAmount || []}
                    />
                    {/* <CheckLoanEligibleForm setEligibility={setEligibility} /> */}
                    <DownloadSms
                      smsData={userAddressLocationDetails || []}
                      profileData={profileData}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <ProfileDetailsTab
                      identityDocument={profileData.identityDocument || []}
                      callLogs={profileData.callLogs || []}
                      financialDocument={profileData.financialDocument || []}
                      loans={profileData.loans || []}
                      setEditBankStatement={setEditBankStatement}
                      documents={profileData.documents || []}
                      setAddCallLogs={setAddCallLogs}
                      setAddnewLoan={setAddnewLoan}
                      updateBankStatementToPending={
                        updateBankStatementToPending
                      }
                      addressDocuments={profileData.addressDocuments || []}
                      fraudReportAnalytics={
                        profileData.finbitSummary.fraudReportAnalytics || []
                      }
                      lastMonthLoan={
                        profileData.finbitSummary.lastMonthLoan || []
                      }
                      references={profileData.references || []}
                    />
                  </div>
                </div>
              </div>
            </div>
            <Footer show={show} />
            {/* <div className={`common-side-bar ${show ? 'active' : ''} `}>
              <SideBar addClassCallBack={addClassCallBack} show={show} />
            </div> */}
          </div>
        </Fragment>
      )}

      {/* File Upload Modal */}
      <FileUpload
        fileUploadPopup={fileUploadPopup}
        setFileUploadPopup={setFileUploadPopup}
        closeUploadDocumentPopup={() => setFileUploadPopup(!fileUploadPopup)}
        handleUploadFile={handleUploadFile}
        uploadFile={uploadFile}
        useDispatch={useDispatch}
      />

      {/* Check Eligibility Modal */}
      <LoanEligibilityCheck
        eligibility={eligibility}
        setEligibility={setEligibility}
        closeEligibilityPopup={closeEligibilityPopup}
      />

      {/* Add Address Details Modal */}
      <AddAddress
        closeNewAddressPopup={() => setAddAddressPopUp(false)}
        addAddress={addAddressPopUp}
        stateData={stateData}
        pincodeDetails={pincodeDetails}
        getAddressDetailsWithPincode={getAddressDetailsWithPincode}
        setAddAddress={setAddAddressPopUp}
        addAddressHandler={addAddressHandler}
      />
      {/* View Address Details Modal */}
      <AddressDetails
        closeAddressDetailsPopup={closeAddressDetailsPopup}
        closeAndOpenAddAddressPopup={closeAndOpenAddAddressPopup}
        address={profileData.address || []}
        addAddress={addressCrud}
        stateData={stateData}
        pincodeDetails={pincodeDetails}
        getAddressDetailsWithPincode={getAddressDetailsWithPincode}
        editAddressHandler={editAddressHandler}
        viewAddressDetail={viewAddressDetail}
        setViewAddressDetail={setViewAddressDetail}
      />
      {/* View Address Details Modal */}
      <AddressMap
        closemapDetailsPopup={closemapDetailsPopup}
        closeAndOpenMapPopup={closeAndOpenMapPopup}
        viewMapDetail={viewMapDetail}
        setViewMapDetail={setViewMapDetail}
        maplogLat={maplogLat}
      />

      {/* Bank details view pop up */}
      <BankDetails
        viewBankDetails={viewBankDetail}
        bankDetails={profileData.bankDetail || []}
        setViewBankDetail={setViewBankDetail}
        bankData={bankData || []}
        editBankHandler={editBankHandler}
        closeAndOpenAddBankPopup={closeAndOpenAddBankPopup}
        closeBankDetailsPopup={() => setViewBankDetail(false)}
      />
      {/* Add Bank details pop up */}
      <AddBank
        closeNewBankPopup={() => {
          setAddBankPopUp(false);
        }}
        addBankPopup={addBankPopUp}
        setAddBank={setAddBankPopUp}
        bankData={bankData || []}
        addBankHandler={addBankHandler}
      />
      {/* Edit Bank Statement */}
      <EditBankStatement
        closeEditBankPopup={() => {
          setEditBankStatement(false);
        }}
        editBankStatementPopup={editBankStatementPopup}
        setEditBankStatement={setEditBankStatement}
        financialDocument={profileData.financialDocument || []}
        bankData={bankData || []}
        editBankStatementHandler={editBankStatementHandler}
      />
      {/* Delete withreason Modal */}
      <DeleteWithReason
        closeDeleteWithReasonPopup={() => setDeleteWithReason(false)}
        handleDeleteWithreason={(value) => handleDeleteWithreason(value)}
        deleteWithReason={deleteWithReason}
        setDeleteWithReason={setDeleteWithReason}
      />
      {/* Add Call logs Modal */}
      <AddCallLogs
        addCallLogs={addCallLogsPopUP}
        setAddCallLogs={setAddCallLogs}
        closeaddCallLogsPopup={() => setAddCallLogs(false)}
        addCallLogsHandler={addCallLogsHandler}
      />
      {/* Add New Loan Modal */}
      <AddNewLoans
        addNewLoans={addnewLoanPopUP}
        setAddnewLoan={setAddnewLoan}
        closeaddNewLoansPopup={() => setAddnewLoan(false)}
        addNewLoanshandler={addNewLoanshandler}
        bankDetail={profileData.bankDetail || []}
        address={profileData.address || []}
        productData={productData || []}
        profileData={profileData}
        loanPurposeData={loanPurposeData || []}
        getProductSettings={getProductSettings}
        productSettingsData={productSettingsData}
      />
    </div>
  );
};

export default Profile;
