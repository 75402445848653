import React from 'react';
import { Alert } from 'react-bootstrap';
import AlertIconRed from '../../../images/svgComponents/errorAlertSmall';
import './alert-box.css';

const ErrorAlertBox = ({ show, errorMsg, close,children }) => {
  if (show) {
    return (
      <Alert onClose={() => close()} className="alert-box-wrapper" dismissible>
        <div className="error-container">
          <AlertIconRed/>
          <div>
            <div className="error-heading">
                            ERROR
            </div>
            <div className="error-msg">
              {errorMsg}
            </div>

            {children}
          </div>
        </div>
      </Alert>
    );
  } return null
}

export default ErrorAlertBox;