import React from 'react';
import { Link } from 'react-router-dom';

import { MONEY } from '../constants';
import { toNumberWithCommaAndDecimal } from './formattor';
import { getURLtoDownloadFile } from './urls';

export const getValueForKeyInObj = (newObj, key) => {
  if (key.indexOf('>') > -1) {
    let resultValue = '-';

    const keys = key.split('>');

    resultValue = keys.reduce((acc, currentItem) => {
      if (acc && acc[currentItem]) {
        const newAcc = acc[currentItem];

        return newAcc;
      }
      return '-';
    }, newObj);
    return resultValue;
  }
  return newObj[key];
};

export const getFieldValueByKeyType = (newObj, key, type) => {
  const result = getValueForKeyInObj(newObj, key);

  if (!result) return '-';
  switch (type) {
    case MONEY:
      return toNumberWithCommaAndDecimal(result);
    default:
      return result;
  }
};
export const dummyFunction = (...args) => {
  return args[0];
};

export const statusAndVerifiedMarker = (cell, tableRowData) => {
  dummyFunction(tableRowData);
  if (cell === 'closed' || cell === 'failed' || cell === null || cell === '') {
    return <label className="common-status-unpaid">{cell || 'Unknow'}</label>;
  } else if (cell === 'active' || cell === 'verified') {
    return <label className="common-label-status-paid">{cell}</label>;
  } else if (cell === 'Partial-Paid' || cell === 'pending') {
    return <label className="common-status-partialpaid">{cell}</label>;
  } else if (cell === 'initiated') {
    return <label className="common-label-status-initiated">{cell}</label>;
  }
  return <label className="common-label-status-initiated">{cell}</label>;
};

export const viewOrDownloadFile = (id, tableRowData) => {
  dummyFunction(tableRowData);
  return (
    <a
      style={{ color: ' #737373', fontSize: '12px', fontWeight: '500' }}
      href={getURLtoDownloadFile(id)}
      download
    >
      Download
    </a>
  );
};

export const viewOrDownloadFrontFile = (id, tableRowData) => {
  dummyFunction(tableRowData);
  if(tableRowData.frontDocumentId){
    return (
      <a
        style={{ color: ' #737373', fontSize: '12px', fontWeight: '500' }}
        href={getURLtoDownloadFile(tableRowData.frontDocumentId)}
        download
      >
        Download
      </a>
    );
  }
  return (
    <span
      style={{ color: ' #737373', fontSize: '12px', fontWeight: '500' }}
    >
      No Document Found
    </span>
  );
};

export const viewOrDownloadBackFile = (id, tableRowData) => {
  dummyFunction(tableRowData);
  if(tableRowData.backDocumentId){
    return (
      <a
        style={{ color: ' #737373', fontSize: '12px', fontWeight: '500' }}
        href={getURLtoDownloadFile(tableRowData.backDocumentId)}
        download
      >
        Download
      </a>
    );
  }
  return (
    <span
      style={{ color: ' #737373', fontSize: '12px', fontWeight: '500' }}
    >
      No Document Found
    </span>
  );
};

export const addLinkToField = (id, tableRowData) => {
  dummyFunction(tableRowData);
  return <Link to={`/users/${tableRowData.publicId}`}>{id || '-'}</Link>;
};
