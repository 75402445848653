import { TEXT, TEXT_EDITABLE_SELECT_BOX, DATE,VERIFY_PROFILE_TEXT ,TEXT_EDITABLE_SELECT_SEARCH_BOX  } from '../../../constants/field-value-key';

export const userDetailsKeyValueMap = [
  {
    label: 'User Type',
    key: 'employmentType',
    type: TEXT_EDITABLE_SELECT_BOX,
    options: [
      { value: 'salaried', name: 'Salaried' },
      { value: 'self-employed', name: 'Self-Employed' },
      { value: 'student', name: 'Student' },
      { value: 'retired', name: 'Retired' },
      { value: 'homemaker', name: 'Homemaker' },
      { value: 'pensioner', name: 'Pensioner' }

    ],
    name: 'employmentType',
    value: '',
  },
  {
    label: 'Salary Slab',
    key: 'minMonthlyIncome',
    key2: 'maxMonthlyIncome',
    type: TEXT_EDITABLE_SELECT_BOX,
    options: [
      { value: '10000-29999', name: '10000-29999' },
      { value: '30000-40000', name: '30000-40000' },
      { value: '40001-49999', name: '40001-49999' },
      { value: '50000-100000', name: '50000-100000' },
      { value: '100001-1000000', name: '100001-1000000' }
    ],
    name: 'minMonthlyIncome',
    name2: 'maxMonthlyIncome',
    value: ''
  },
  {
    label: 'Phone',
    key: 'mobileNumber',
    type: TEXT
  },
  {
    label: 'Primary Email',
    key: 'primaryEmail',
    type: TEXT
  },
  {
    label: 'Date of Birth',
    key: 'dob',
    type: DATE
  },
  {
    label: 'Work Email',
    key: 'workEmail',
    verifyStatusKey: 'isWorkEmailVerified',
    type: VERIFY_PROFILE_TEXT
  },
  {
    label: 'Qualification',
    key: 'qualification',
    type: TEXT_EDITABLE_SELECT_BOX,
    options: [
      { value: '20a55ff0-1479-11ec-92db-4905ff59c839', name: 'DOCTORATE' },
      { value: '20a55ff1-1479-11ec-92db-4905ff59c839', name: 'ENGINEER' },
      { value: '20a55ff2-1479-11ec-92db-4905ff59c839', name: 'GRADUATE' },
      { value: '20a55ff3-1479-11ec-92db-4905ff59c839', name: 'MATRIC' },
      { value: '20a55ff4-1479-11ec-92db-4905ff59c839', name: 'POST GRADUATE' },
      { value: '20a55ff5-1479-11ec-92db-4905ff59c839', name: 'PROFESSIONAL' },
      { value: '20a55ff6-1479-11ec-92db-4905ff59c839', name: 'UNDER GRADUATE' },
      { value: 'ea7aceb0-5b57-11e9-8094-8bcbf9f845e6', name: 'OTHERS' }
    ],
    name: 'qualificationId',
    value: ''
  },
  {
    label: 'Marital Status',
    key: 'maritalStatus',
    type: TEXT
  },
  {
    label: 'Company Name',
    key: 'companyName',
    type: TEXT_EDITABLE_SELECT_SEARCH_BOX,
    options: [],
    name: 'companyName',
    value: ''
  }
]