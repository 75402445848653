import {
  LEADS_REQUEST,
  LEADS_SUCCESS,
  LEADS_FAILURE,
} from './my-leads-constants';

const LeadsRequest = (urlParams) => ({
  type: LEADS_REQUEST,
  urlParams,
});

export const LeadsSuccess = (data, totalRecords) => ({
  data,
  totalRecords,
  type: LEADS_SUCCESS,
});
export const LeadsFailure = () => ({
  type: LEADS_FAILURE,
});

export const LeadsA = async (urlParams, dispatch) => {
  dispatch(LeadsRequest(urlParams));
};
