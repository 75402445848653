import { call, put, takeEvery } from 'redux-saga/effects';

import HttpHelper from '../../utils/http-helper';
import { dashboardReportsUrl } from '../../utils/urls';
import { DASHBOARD_REPORTS_REQUEST } from './dashboard-constants';
import { dashboardReportsSuccess, dashboardReportsFailure } from './dashboard-reports-actions';

const { getRequest } = new HttpHelper();

export function* dashboardReports(action) {
  try {
    const response = yield call(getRequest, {
      url: dashboardReportsUrl(action.urlParams),
    });

    yield put(dashboardReportsSuccess(response.data));
  } catch (error) {
    yield put(dashboardReportsFailure());
  }
}

export function* watchDashboardReports() {
  yield takeEvery(DASHBOARD_REPORTS_REQUEST, dashboardReports);
}
