import { GET_LOAN_PURPOSE_REQUEST } from './profile-constants';

const getLoanPurposeRequest = (data) => ({
  data,
  type: GET_LOAN_PURPOSE_REQUEST,
});

// eslint-disable-next-line import/prefer-default-export
export const loanPurposeList = (dispatch, loanPurposeListCallback) => {
  dispatch(getLoanPurposeRequest({ loanPurposeListCallback }));
};
