import {
    DISBURSMENT_CHIP_REQUEST,
    DISBURSMENT_CHIP_SUCCESS,
    DISBURSMENT_CHIP_FAILURE
} from './disbursment-constants';

const initialState = {
  disbursmentChipData:[],
  isFetching: false,
 
};

const disbursmentChip = (state = initialState, action) => {
  switch (action.type) {
    case DISBURSMENT_CHIP_REQUEST:
      return {
        ...state,
      //  disbursmentChipData:[],
        isFetching: true,
      };
    case DISBURSMENT_CHIP_FAILURE:
      return {
        ...state,
      //  disbursmentChipData:[],
        isFetching: false,
      };
    case DISBURSMENT_CHIP_SUCCESS:
      return {
        ...state,
        disbursmentChipData: action.data,
        isFetching: false,        
      };
    default:
      return state;
  }
};

export default disbursmentChip;


  