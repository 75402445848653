import {
  GET_LEADS_REPORTS_REQUEST,
  GET_LEADS_REPORTS_SUCCESS,
  GET_LEADS_REPORTS_FAILURE,
} from './my-leads-constants';

const initialState = {
  error: false,
  isFetchingLogs: false,
  data: [],
};

const getCallLogsLead = (state = initialState, action) => {
  switch (action.type) {
    case GET_LEADS_REPORTS_REQUEST:
      return {
        ...state,
        isFetchingLogs: true,
        data: [],
      };
    case GET_LEADS_REPORTS_FAILURE:
      return {
        ...state,
        error: true,
        isFetchingLogs: false,
        data: [],
      };
    case GET_LEADS_REPORTS_SUCCESS:
      return {
        ...state,
        error: false,
        isFetchingLogs: false,
        data: action.data,
      };
    default:
      return state;
  }
};

export default getCallLogsLead;
