import { TEXT } from '../../../constants/field-value-key';

export const bankStatementInfoMap = [
  {
    label: 'Average Salary',
    key: 'averageSalary',
    type: TEXT
  },
  {
    label: 'Last Month Loan',
    key: 'lastMonthLoan',
    type: TEXT
  },
  {
    label: 'Last Month Salary Date',
    key: 'lastMonthSalaryDate',
    type: TEXT
  },
  {
    label: 'Monthly Capacity',
    key: 'monthlyCapacity',
    type: TEXT
  },
  {
    label: 'Status',
    key: 'status',
    type: TEXT
  }
]