import { call, put, takeEvery } from 'redux-saga/effects';

import HttpHelper from '../../utils/http-helper';
import { disburseLoanUrl } from '../../utils/urls';
import { DISBURSE_LOAN_NOW_REQUEST } from './disburse-loan-constants';
import { disburseLoanFailure,disburseLoanSuccess  } from './disburse-loan-action';

const { postRequest } = new HttpHelper();

export function* disburseLoan(action) {
  try {
    const url = disburseLoanUrl();
    const response = yield call(postRequest, {
      data: action.data,
      url,
    });

    yield put(disburseLoanSuccess(response.data));
  } catch (error) {
    yield put(disburseLoanFailure(error));
  }
}

export function* watchDisburseLoanNow() {
  yield takeEvery(DISBURSE_LOAN_NOW_REQUEST, disburseLoan);
}
