import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import FormElement from '../../../components/form-elements';
import Button from '../../../components/form-elements/button';
import CloseIconWhite from '../../../../images/icons/closeIconWhite.svg';
import { manualPaymentFormFields } from './manual-payment-constant';
import { onChangeHandler, setInitialFormValues, getFormDataValue, validateForm } from '../../../utils/form-helper';
import { manualPayment } from './payment-action';

const ManualPayment = ({ showManualPayment, CloseManualPayment }) => {
  const [manualPaymentForm, setManualPaymentForm] = useState([]);

  useEffect(() => {
    setInitialFormValues(manualPaymentFormFields, setManualPaymentForm);
  }, []);

  const dispatch = useDispatch();

  const okPayButtonHandler = () => {
    const [newForm, isValid] = validateForm(manualPaymentForm);

    setManualPaymentForm(newForm);
    if (isValid) {
      const reqBody = getFormDataValue(manualPaymentForm);
      const path = window.location.pathname.split('/');
      const loanId = path[path.length - 1];

      manualPayment(loanId, reqBody, dispatch);
      CloseManualPayment();
    }
  }

  return (
    <React.Fragment>
      <Modal
        size="lg"
        show={showManualPayment}
        onHide={CloseManualPayment}
        backdropClassName="loan-modal-overlay-background"
        dialogClassName="manual-payment-modal-dialoag"
        aria-labelledby="manual-payment-pop-up"
        centered
      >
        <Modal.Body>
          <div className="manual-payment-modal-container">
            <span className="loan-modal-close-button" onClick={CloseManualPayment}> <img src={CloseIconWhite} /></span>
            <div className="loan-modal-title">
                            Manual Payment
            </div>
            <div>
              {manualPaymentForm.map((formItem, index) =>
                <FormElement
                  key={index}
                  inputType={formItem.type}
                  lableName={formItem.label}
                  name={formItem.name}
                  placeholder={formItem.placeholder}
                  value={formItem.value}
                  onChangeHandler={(targetValue) => { onChangeHandler(manualPaymentForm, formItem.name, targetValue, setManualPaymentForm) }}
                  isValid={formItem.isValid}
                  errorMsg={formItem.errorMsg}
                  isDisabled={formItem.isDisabled}
                  isTouched={formItem.isTouched}
                />
              )}
            </div>
            <div className="manual-payment-modal-button-container">
              <Button text={'Cancel'} click={CloseManualPayment} isGreyedOut={true} /><Button text={'OK'} click={okPayButtonHandler} isMarginLeft={true} />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}

export default ManualPayment;