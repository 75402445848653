import React, { useState } from 'react';
import Button from '../form-elements/button';
import ManualPayment from '../../containers/loan-information/loan-payment/manual-payment';
import AutoPayment from '../../containers/loan-information/loan-payment/auto-payment';

const LoanPaymentAutoManual = ({ totalDueAmount,emiAmount }) => {

  const [showManualPayment, setShowManualPayment] = useState(false);
  const [showAutoPayment, setShowAutoPayment] = useState(false);

  const toggleManualPaymentPopUp = () => {
    setShowManualPayment(!showManualPayment);
  }
  const toggleAutoPaymentPopUp = () => {
    setShowAutoPayment(!showAutoPayment);
  }

  return (
    <React.Fragment>
      <ManualPayment showManualPayment={showManualPayment}  CloseManualPayment={toggleManualPaymentPopUp}/>
      <AutoPayment showAutoPayment={showAutoPayment}  CloseAutoPayment={toggleAutoPaymentPopUp} totalDueAmount={totalDueAmount} emiAmount={emiAmount}/>

      <div className="loan-payment-auto-manual-container">
        <div className="loan-payment-text">
          Not yet pay your pending amount?? Pay your pending amount as you like.
        </div>
        <div className='loan-payment-button-wrapper'>
          <Button text={'MANUAL PAYMENT'} click={toggleManualPaymentPopUp} isMarginLeft={false} />
          <Button text={'AUTO PAYMENT'} click={toggleAutoPaymentPopUp} isMarginLeft={true} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default LoanPaymentAutoManual;
