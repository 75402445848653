import { call, put, takeEvery } from 'redux-saga/effects';

import HttpHelper from '../../utils/http-helper';
import { MyLeadsUrl } from '../../utils/urls';
import { LEADS_REQUEST } from './my-leads-constants';
import { LeadsSuccess, LeadsFailure } from './my-leads-action';

const { getRequest } = new HttpHelper();

export function* LeadsA(action) {

  try {
    const response = yield call(getRequest, {
      url: MyLeadsUrl(action.urlParams),
    });
    const totalRecords = response.headers['x-coreplatform-total-records'];

    yield put(LeadsSuccess(response.data, totalRecords));
  } catch (error) {
    yield put(LeadsFailure());
  }
}

export function* watchLeads() {
  yield takeEvery(LEADS_REQUEST, LeadsA);
}
