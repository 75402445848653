import { SELECT } from '../../../constants/form-type';

export const fileUploadFormForFileType = [
  {
    label: 'Document Type',
    name: 'docType',
    placeholder: '',
    options: [
      { name: 'Aadhaar Card', value: 'aadharCard' },
      { name: 'Pan Card', value: 'panCard' },
      { name: 'Passport', value: 'passport' },
      { name: 'Driving Licence', value: 'drivingLicence' },
      { name: 'Voter Card', value: 'voterCard' },
      { name: 'Bank Statement', value: 'bankStatement' },
      { name: 'Salary Slip', value: 'salarySlip' },
      { name: 'Appointment Letter', value: 'appointmentLetter' },
      { name: 'Profile Picture', value: 'profilePicture' },
      { name: 'Address Proof', value: 'addressProof' }

    ],
    validation: {},
    type: SELECT,
  }
]
export const uploadFileFormConst = {
  aadharCard: {
    frontPicKey: 'aadharFrontDocumentId',
    backPicKey: 'aadharBackDocumentId',
    documentKeyName: 'aadharNumber',
    markedDocmentStautsKey: 'aadharDocumentStatus',
    value: [],
    isMarkValid: true
  },
  panCard: {
    frontPicKey: 'panFrontDocumentId',
    backPicKey: 'panBackDocumentId',
    documentKeyName: 'panNumber',
    markedDocmentStautsKey: 'panDocumentStatus',
    value: [],
    isMarkValid: true
  },
  passport: {
    frontPicKey: 'passportFrontDocumentId',
    backPicKey: 'passportBackDocumentId',
    documentKeyName: 'passportNumber',
    markedDocmentStautsKey: 'passportDocumentStatus',
    value: [],
    isMarkValid: true
  },
  drivingLicence: {
    frontPicKey: 'drivingLicenceFrontDocumentId',
    backPicKey: 'drivingLicenceBackDocumentId',
    documentKeyName: 'drivingLicenceNumber',
    markedDocmentStautsKey: 'drivingLicenceDocumentStatus',
    value: [],
    isMarkValid: true
  },
  voterCard: {
    frontPicKey: 'voterCardFrontDocumentId',
    backPicKey: 'voterCardBackDocumentId',
    documentKeyName: 'voterCardNumber',
    markedDocmentStautsKey: 'voterCardDocumentStatus',
    value: [],
    isMarkValid: true
  },
  bankStatement: {
    frontPicKey: '',
    backPicKey: '',
    documentKeyName: 'bankStatementDocumentId',
    markedDocmentStautsKey: 'bankStatementDocumentPassword',
    value: [],
    isMarkValid: true
  },
  salarySlip: {
    frontPicKey: '',
    backPicKey: '',
    documentKeyName: 'salarySlipDocumentId',
    markedDocmentStautsKey: '',
    value: [],
    isMarkValid: false
  },
  appointmentLetter: {
    frontPicKey: '',
    backPicKey: '',
    documentKeyName: 'appointmentLetterDocumentId',
    markedDocmentStautsKey: '',
    value: [],
    isMarkValid: false
  },
  profilePicture: {
    frontPicKey: '',
    backPicKey: '',
    documentKeyName: 'profilePicId',
    markedDocmentStautsKey: '',
    value: [],
    isMarkValid: false
  },
  addressProof: {
    frontPicKey: '',
    backPicKey: '',
    documentKeyName: 'addressProofDocumentId',
    markedDocmentStautsKey: '',
    value: [],
    isMarkValid: false
  }
}