/* eslint-disable max-lines */
/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import DataTable from 'moneyloji-react-data-table';

import { Link } from 'react-router-dom';

import { Breadcrumb } from 'react-bootstrap';

import { useSelector, useDispatch } from 'react-redux';

import { managePartnerFilter } from './manage-partner-constants';
import { managePartner } from './manage-partner-action';

import Header from '../../components/header';
// import SideBar from '../../components/side-bar';
import SidebarIcon from '../../components/sidebar-icon';
import Footer from '../../components/footer';
import Button from '../../components/form-elements/button';

import { DEFAULT_PAGE_SIZE, CURRENT_DEFAULT_PAGE } from '../../constants';
import { partnerExportUrl } from '../../utils/urls';
import TableLoader from '../../components/Loaders/table-loader';
import './manage-partner.css';

const ManagePartner = () => {
  const [show, setShow] = useState(false);

  const dispatch = useDispatch();

  const getPartnerData = (urlParams) => {
    managePartner(urlParams, dispatch);
  };

  function buttonFormatter(cell) {
    if (cell === 'closed' || cell === 'failed') {
      return <label className="status-unpaid">{cell}</label>;
    } else if (cell === 'active') {
      return <label className="label-status-paid">{cell}</label>;
    } else if (cell === 'Partial-Paid') {
      return <label className="status-partialpaid">{cell}</label>;
    } else if (cell === 'initiate') {
      return <label className="label-status-initiated">{cell}</label>;
    }

    return '-';
  }

  useEffect(() => {
    const urlParams = {
      'filters[0][key]': 'status',
      'filters[0][nin][0]': 'initiated',
      'filters[0][nin][1]': 'reversed',
      'filters[0][nin][2]': 'canceled',
      pageNumber: CURRENT_DEFAULT_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
    };

    getPartnerData(urlParams);
  }, []);

  const { partnerDetails, totalRecords, isFetching } = useSelector(
    (state) => state.managePartner
  );
  const partnerList =
  partnerDetails &&
  partnerDetails.map((partnerData, index) => {
      const id = index + 1;

      return {
        name: partnerData.name,
        publicId: partnerData.publicId,
        id,
        code: partnerData.code,
        logoUrl: partnerData.logoUrl,
        description: partnerData.description,
        tags: partnerData.tags,
        status: partnerData.status
      };
    });

  function partnerNameLinkHandler(cell, row) {
    return <Link to={`/partner/${row.publicId}`}><img src={row.logoUrl} height={26}/>{cell || '-'}</Link>;
  }

  const addClassCallBack = () => {
    setShow(!show);
  };

  const tableHeaderData = [
    {
      columnClassName: '',
      dataField: 'id',
      dataFormat: (cell) => cell,
      dataSort: false,
      isKey: true,
      name: '#',
      width: '80',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'name',
      dataFormat: partnerNameLinkHandler,
      dataSort: true,
      isKey: false,
      name: 'Partner Name',
      width: '250',
    },
    {
      columnClassName: '',
      dataField: 'description',
      dataFormat: (cell)=>cell,
      dataSort: true,
      isKey: false,
      name: 'Description',
      width: '180',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'code',
      dataFormat: (cell) => cell,
      dataSort: false,
      isKey: false,
      name: 'Partner Code',
      width: '100',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'tags',
      dataFormat: (cell)=>cell,
      dataSort: false,
      isKey: false,
      name: 'Partner tags',
      width: '200',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'status',
      dataFormat: buttonFormatter,
      dataSort: false,
      isKey: false,
      name: 'Status',
      width: '100',
    },
    // {
    //   columnClassName: 'col-grey',
    //   dataField: 'details',
    //   dataFormat: (cell)=>cell,
    //   dataSort: false,
    //   isKey: false,
    //   name: 'Details',
    //   width: '100',
    // },
  ];

  return (
    <div>
      <Header />
      <div className="common-container">
        <SidebarIcon addClassCallBack={addClassCallBack} show={show} />
        <div className={`common-wrapper ${show ? 'active' : ''} `}>
          <div className="col-md-12 mpad">
            <div className="common-heading">
              <h1>Manage partner</h1>
              <Breadcrumb>
                <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>Report</Breadcrumb.Item>
                <Breadcrumb.Item active>Disbursments</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          <div className="manage-partner-list-container">
          <span className="add-partner-btn-wrapper"><Link to={'add-partner'}><Button click={()=>{}} text={"ADD PARTNER"}/></Link></span>
            <DataTable
              tableData={partnerList}
              tableHeaderData={tableHeaderData}
              totalRecords={totalRecords}
              isFetching={isFetching}
              getTableData={getPartnerData}
              exportUrl={partnerExportUrl}
              tableTitle=""
              pageFilters={managePartnerFilter}
              TableLoader={TableLoader}
            />
          </div>
        </div>
        <Footer show={show} />
        {/* <div className={`common-side-bar ${show ? 'active' : ''} `}>
          <SideBar addClassCallBack={addClassCallBack} show={show} />
        </div> */}
      </div>
    </div>
  );
};

export default ManagePartner;
