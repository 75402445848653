import React, { useEffect, useState } from 'react';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { userDetailsKeyValueMap } from './user-profile-address-details-constant';
import { userLocationDetailsKeyValueMap } from './user-profile-address-location-details-constant';
import FieldValue from '../../field-key-value';
import './user-profile-address-details.css';

const UserAddressDetails = ({
  userAddressDetails,
  userAddressLocationDetails,
  setViewAddressDetail,
  setViewMapDetail,
  setMaplogLat,
}) => {
  const [addressDetailPopup, setAddressDetailPopup] = useState(false);
  const [defaultAddress, setDefaultAddress] = useState({});

  useEffect(() => {
    let newDefaultAddress;

    userAddressDetails.map((item) => {
      if (item.isPrimary && item.addressType === 'current') {
        newDefaultAddress = item;
      }
      return false;
    });
    if (!newDefaultAddress) {
      userAddressDetails.map((item) => {
        if (item.addressType && item.addressType === 'current') {
          newDefaultAddress = item;
        }
      });
    }
    setDefaultAddress(newDefaultAddress);
  }, []);

  const addAddressDetailHandle = () => {
    setAddressDetailPopup(false);
    setViewAddressDetail(true);
  };

  const onSubmitHandler = () => {
    setViewMapDetail(true);
  };
  // const editAddressDetailHandle = () => {
  //     setAddressDetailPopup(false);
  //     setEditAddressDetail(true);
  // };

  const callGetLocationDetails = (lng, lat) => {
    const latlog = { lng, lat };
    setMaplogLat(latlog);
  };

  return (
    <div className="personal-address-details">
      <div className="personal-heading-container">
        <div className="d-flex align-items-baseline">
          <h4 className="personal-common-heading">Address Details</h4>
          <label className="ml-3 grey-label">Default</label>
        </div>
        <MoreVertIcon
          onClick={() => setAddressDetailPopup(!addressDetailPopup)}
          className="more-vertical-icon"
        />
      </div>
      {addressDetailPopup && (
        <div className="profile-edit-view">
          <p>
            <label onClick={addAddressDetailHandle}>View All</label>
            {/* <label onClick={addAddressDetailHandle}>
                            Edit
                        </label> */}
          </p>
        </div>
      )}
      <div className="user-profile-address-details-wrapper">
        {userDetailsKeyValueMap.map((item, index) => (
          <FieldValue
            key={index}
            fieldName={item.label}
            fieldValue={defaultAddress && defaultAddress[item.key]}
            type={item.type}
            setViewMapDetail={setViewMapDetail}
          />
        ))}
      </div>

      <div className="user-profile-address-details-wrapper">
        {userLocationDetailsKeyValueMap.map((item, index) => (
          <FieldValue
            key={index}
            fieldName={item.label}
            fieldValue={
              userAddressLocationDetails && userAddressLocationDetails[item.key]
            }
            type={item.type}
            onSubmitHandler={() => {
              onSubmitHandler();
              callGetLocationDetails(
                userAddressLocationDetails[item.longitudeKey],
                userAddressLocationDetails[item.latitudeKey]
              );
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default UserAddressDetails;
