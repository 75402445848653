import {
  LOAN_BLOCK_SUCCESS,
  LOAN_BLOCK_FAILURE,
  LOAN_BLOCK_REQUEST
} from './dashboard-constants';

const loanBlockRequest = (urlParams) => ({
  type: LOAN_BLOCK_REQUEST,
  urlParams,
});

export const loanBlockSuccess = (data) => ({
  data,
  type: LOAN_BLOCK_SUCCESS,
});

export const loanBlockFailure = () => ({
  type: LOAN_BLOCK_FAILURE,
});

export const getLoanBlock = async (urlParams, dispatch) => {
  dispatch(loanBlockRequest(urlParams));
};
