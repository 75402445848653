import {   TEXTAREA } from '../../constants/form-type';

export const cancelLoanFormFields = [
  {
    label: 'Remark',
    name: 'remarks',
    placeholder: 'Details goes here',
    validation: { isRequired: true },
    errMsg: "",
    type: TEXTAREA,
  }
];
