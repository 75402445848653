import { GET_PRODUCT_REQUEST } from './profile-constants';

const productListRequest = (data) => ({
  data,
  type: GET_PRODUCT_REQUEST,
});

// eslint-disable-next-line import/prefer-default-export
export const productList = (dispatch, productListCallback) => {
  dispatch(productListRequest({ productListCallback }));
};
