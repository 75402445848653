import { call, takeEvery } from 'redux-saga/effects';

import HttpHelper from '../../utils/http-helper';
import { GET_COMPANY_LIST } from '../../utils/urls';
import { GET_COMPANY_REQUEST } from './profile-constants';

const { getRequest } = new HttpHelper();

export function* companyList(action) {
  try {
    const response = yield call(getRequest, {
      url: GET_COMPANY_LIST,
    });

    action.data.companyListCallback(response.data);
  } catch (err) {
    throw new Error(err);
  }
}

export function* watchCompanyList() {
  yield takeEvery(GET_COMPANY_REQUEST, companyList);
}
