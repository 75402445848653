import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import CloseIconWhite from '../../../images/icons/closeIconWhite.svg';
import FormElement from '../form-elements';
// getFormDataValue
import { onChangeHandler, setInitialFormValues,validateForm } from '../../utils/form-helper';
import Button from '../form-elements/button';
import './wallet-setting-popup.css';

const WalletSettingPopup = ({ showModal,closeModal,title, formFieldConstant }) => {
  const [walletForm, setWalletForm] = useState([]);

  useEffect(() => {
    setInitialFormValues(formFieldConstant, setWalletForm);
  }, []);

  const okPayButtonHandler = () => {
    const [newForm,isValid] = validateForm(walletForm);

    setWalletForm(newForm);
    if(isValid){
      // const formData= getFormDataValue(walletForm);

      closeModal();
    }
  }

  return (
    <React.Fragment>
      <Modal
        size="lg"
        show={showModal}
        onHide={closeModal}
        backdropClassName="wallet-modal-overlay-background"
        dialogClassName="wallet-modal-dialoag"
        aria-labelledby="wallet-pop-up"
      >
        <Modal.Body>
          <div className="wallet-modal-container">
            <span className="wallet-modal-close-button" onClick={closeModal}> <img src={CloseIconWhite} /></span>
            <div className="wallet-modal-title">
              {title}
            </div>
            <div>
              {walletForm.map((formItem, index) =>
                <FormElement
                  key={index}
                  inputType={formItem.type}
                  lableName={formItem.label}
                  name={formItem.name}
                  placeholder={formItem.placeholder}
                  value={formItem.value}
                  onChangeHandler={(targetValue) => { onChangeHandler(walletForm, formItem.name, targetValue, setWalletForm) }}
                  isValid={formItem.isValid}
                  errorMsg={formItem.errorMsg}
                  isDisabled={formItem.isDisabled}
                  isTouched={formItem.isTouched}
                />
              )}
            </div>
            <div className="wallet-modal-button-container">
              <Button text={'Cancel'} click={closeModal} isGreyedOut={true} /><Button text={'OK'} click={okPayButtonHandler} isMarginLeft={true} />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}

export default WalletSettingPopup;