import {
  LEADS_REQUEST,
  LEADS_SUCCESS,
  LEADS_FAILURE,
} from './my-leads-constants';

const initialState = {
  error: false,
  isFetching: false,
  totalRecords: '',
  leads: [],
};

const leads = (state = initialState, action) => {
  switch (action.type) {
    case LEADS_REQUEST:
      return {
        ...state,
        isFetching: true,
        leads: [],
      };
    case LEADS_FAILURE:
      return {
        ...state,
        error: true,
        isFetching: false,
        leads: [],
      };
    case LEADS_SUCCESS:
      return {
        ...state,
        error: false,
        isFetching: false,
        totalRecords: action.totalRecords,
        leads: action.data,
      };
    default:
      return state;
  }
};

export default leads;
