import {
  USERS_REQUEST,
  USERS_SUCCESS,
  USERS_FAILURE,
} from './users-constants';

const UsersRequest = (urlParams) => ({
  type: USERS_REQUEST,
  urlParams,
});

export const UsersSuccess = (data, totalRecords) => ({
  data,
  totalRecords,
  type: USERS_SUCCESS,
});
export const UsersFailure = () => ({
  type: USERS_FAILURE,
});

export const UsersA = async (urlParams, dispatch) => {
  dispatch(UsersRequest(urlParams));
};
