/* eslint-disable max-len */
/* eslint-disable max-lines */
/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import DataTable from 'moneyloji-react-data-table';

import { Link } from 'react-router-dom';

import { Breadcrumb } from 'react-bootstrap';

import { useSelector, useDispatch } from 'react-redux';

import { emi } from './emi-action';
import { emiChip } from './emi-chip-action';
import { emiFilters } from './emi-constants';

import Header from '../../components/header';
// import SideBar from '../../components/side-bar';
import SidebarIcon from '../../components/sidebar-icon';
import Footer from '../../components/footer';
import {
  toNumberWithCommaAndDecimal,
  toDateFormat,
} from '../../utils/formattor';
import { loanDetailsExportUrl } from '../../utils/urls';
import { DEFAULT_PAGE_SIZE, CURRENT_DEFAULT_PAGE } from '../../constants';
import TableLoader from '../../components/Loaders/table-loader';
import DisbursementWeekIcon from '../../../images/icons/Disbursement1.png';
import DisbursementLastdayIcon from '../../../images/icons/Disbursement2.png';
import DisbursementThismonthIcon from '../../../images/icons/Disbursement3.png';
import DisbursementLastmonthIcon from '../../../images/icons/Disbursement4.png';
import DisbursementYearIcon from '../../../images/icons/Disbursement5.png';

const Emi = () => {
  // Filter State
  const [show, setShow] = useState(false);

  const dispatch = useDispatch();

  const getEmiData = (urlParams) => {
    emi(urlParams, dispatch);
  };

  useEffect(() => {
    const urlParams = {
      pageNumber: CURRENT_DEFAULT_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
      'sorting[0][direction]': ['DESC'],
      'sorting[0][key]': ['createdAt']
    };

    getEmiData(urlParams);
    emiChip(dispatch)
  }, []);

  const { emiDetails, totalRecords, isFetching } = useSelector(
    (state) => state.emi
  );
  const { emiChipData } = useSelector(
    (state) => state.emiChip
  );

  const emiList =
    emiDetails &&
    emiDetails.map((emiData, index) => {
      const id = index + 1;

      return {
        emiNumber: '-',
        id,
        ...emiData,
      };
    });

  function buttonFormatter(cell) {
    if (cell === 'unpaid' || cell === 'failed') {
      return <label className="status-unpaid">{cell}</label>;
    } else if (cell === 'paid') {
      return <label className="label-status-paid">{cell}</label>;
    } else if (cell === 'Partial-Paid') {
      return <label className="status-partialpaid">{cell}</label>;
    } else if (cell === 'Payment-Initiated') {
      return <label className="label-status-initiated">{cell}</label>;
    }

    return '-';
  }
  const numberFormatter = (cell) => {
    if (cell) {
      return toNumberWithCommaAndDecimal(cell);
    }
    return '-';
  };

  const dateFormatter = (cell) => {
    if (cell) {
      return toDateFormat(cell);
    }
    return '-';
  };

  function loanNumberHandle(cell, row) {
    return <Link to={`/loan/${row.loanId}`}>{cell || '-'}</Link>;
  }

  function userName(cell, row) {
    return <Link to={`/users/${row.userId}`}>{cell}</Link>;
  }

  function userMobileNo(cell, row) {
    return <Link to={`/users/${row.userId}`}>{cell}</Link>;
  }

  const addClassCallBack = () => {
    setShow(!show);
  };

  const tableHeaderData = [
    {
      columnClassName: '',
      dataField: 'id',
      dataFormat: (cell) => cell,
      dataSort: false,
      isKey: true,
      name: '#',
      width: '80',
    },
    {
      columnClassName: '',
      dataField: 'number',
      dataFormat: loanNumberHandle,
      dataSort: true,
      isKey: false,
      name: 'Loan No',
      width: '150',
    },
    {
      columnClassName: '',
      dataField: 'name',
      dataFormat: userName,
      dataSort: true,
      isKey: false,
      name: 'Name',
      width: '180',
    },
    {
      columnClassName: '',
      dataField: 'mobileNumber',
      dataFormat: userMobileNo,
      dataSort: false,
      isKey: false,
      name: 'Mobile No',
      width: '150',
    },
    {
      columnClassName: '',
      dataField: 'totalPaid',
      dataFormat: (cell) => cell,
      dataSort: false,
      isKey: false,
      name: 'Total Paid',
      width: '150',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'dueDate',
      dataFormat: dateFormatter,
      dataSort: true,
      isKey: false,
      name: 'EMI Due Date',
      width: '150',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'dueAmount',
      dataFormat: numberFormatter,
      dataSort: true,
      isKey: false,
      name: 'Value',
      width: '150',
    },
    {
      columnClassName: '',
      dataField: 'status',
      dataFormat: buttonFormatter,
      dataSort: true,
      isKey: false,
      name: 'Status',
      width: '140',
    },
  ];

  return (
    <div>
      <Header />
      <div className="common-container">
        <SidebarIcon addClassCallBack={addClassCallBack} show={show} />
        <div className={`common-wrapper ${show ? 'active' : ''} `}>
          <div className="col-md-12 mpad">
            <div className="common-heading">
              <h1>EMIs</h1>
              <Breadcrumb>
                <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>Report</Breadcrumb.Item>
                <Breadcrumb.Item active>EMIs</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>

          <div className='row' style={{ justifyContent: 'space-evenly' }}>
          <div className='col-md-2'>
                    <div className="chip">
                      <div className="chip-body">
                        <h5 className="num">{emiChipData && emiChipData.week? `${emiChipData.week}/${emiChipData.lastWeekUnpaid}` :'0.00'}</h5>
                        <h3 className="heading">Week till date EMIs</h3>
                        <img src={DisbursementWeekIcon} alt='placeholder' />
                        </div>
                    </div>
                  </div>
                  <div className='col-md-2'>
                    <div className="chip">
                      <div className="chip-body">
                        <h5 className="num">{emiChipData && emiChipData.lastDay?`${emiChipData.lastDay}/${emiChipData.lastDayUnpaid}`:'0.00'}</h5>
                        <h3 className="heading">Yesterday EMIs</h3>
                        <img src={DisbursementLastdayIcon} alt='placeholder' />
                        </div>
                    </div>
                  </div>

                   <div className='col-md-2'>
                    <div className="chip">
                      <div className="chip-body">
                        <h5 className="num">{emiChipData && emiChipData.thisMonth?`${emiChipData.thisMonth}/${emiChipData.thisMonthUnpaid}`:'0.00'}</h5>
                        <h3 className="heading">This Month EMIs</h3>
                        <img src={DisbursementThismonthIcon} alt='placeholder' />
                        </div>
                    </div>
                  </div>
                  <div className='col-md-2'>
                    <div className="chip">
                      <div className="chip-body">
                        <h5 className="num">{emiChipData && emiChipData.lastMonth? `${emiChipData.lastMonth}/${emiChipData.lastMonthUnpaid}`:'0.00'}</h5>
                        <h3 className="heading">Last Month EMIs </h3>
                        <img src={DisbursementLastmonthIcon} alt='placeholder' />
                        </div>
                    </div>
                  </div>
                  <div className='col-md-2'>
                    <div className="chip">
                      <div className="chip-body">
                        <h5 className="num">{emiChipData && emiChipData.calenderYear?`${emiChipData.calenderYear}/${emiChipData.calenderYearUnpaid}`:'0.00'}</h5>
                        <h3 className="heading">Year till date EMIs</h3>
                        <img src={DisbursementYearIcon}  alt='placeholder' />
                        </div>
                    </div>
                  </div>
              </div>
          <div className="loan-container">
          <DataTable
              tableData={emiList}
              tableHeaderData={tableHeaderData}
              totalRecords={totalRecords}
              isFetching={isFetching}
              getTableData={getEmiData}
              exportUrl={loanDetailsExportUrl}
              tableTitle=""
              pageFilters={emiFilters}
              defaultSortKey={'createdAt'}
              defaultSortDirection={'desc'}
              TableLoader={TableLoader}
            />
          </div>
        </div>
        <Footer show={show} />
        {/* <div className={`common-side-bar ${show ? 'active' : ''} `}>
          <SideBar addClassCallBack={addClassCallBack} show={show} />
        </div> */}
      </div>
    </div>
  );
};

export default Emi;
