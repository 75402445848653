import { GET_COMPANY_REQUEST } from './profile-constants';

const companyListRequest = (data) => ({
  data,
  type: GET_COMPANY_REQUEST,
});

// eslint-disable-next-line import/prefer-default-export
export const companyList = (dispatch, companyListCallback) => {
  dispatch(companyListRequest({ companyListCallback }));
};
