import { SELECT, TEXT } from '../../../constants/form-type';

export const addbankFormConstant = [
  {
    label: 'Account Holder Name',
    name: 'accountHolderName',
    placeholder: 'Account holder\'s name',
    validation: { isRequired: true },
    isDisabled: false,
    type: TEXT
  },
  {
    label: 'Account Type',
    name: 'accountType',
    placeholder: 'Account Type',
    validation: { isRequired: true },
    options: [
      { name: 'Saving', value: 'saving' },
      { name: 'Current', value: 'current' },
    ],
    isDisabled: false,
    type: SELECT
  },
  {
    label: 'accountNumber',
    name: 'accountNumber',
    placeholder: 'Account Number',
    validation: { isRequired: true },
    isDisabled: false,
    type: TEXT
  },
  {
    label: 'IFSC Code',
    name: 'ifsc',
    placeholder: 'IFSC code for your branch',
    validation: { isRequired: false },
    isDisabled: false,
    type: TEXT
  },
  {
    label: 'Bank',
    name: 'bankId',
    placeholder: 'Select your bank',
    validation: { isRequired: true },
    options: [],
    isDisabled: false,
    type: SELECT
  }
]