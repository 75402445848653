import {
  TEXT,
  NUMBER,
  NUMBER_RANGE_TYPE,
  DATE_RANGE_TYPE,
  SELECT,
} from '../../constants/data-table';

export const SECURED_LOANS_REQUEST = 'SECURED_LOANS_REQUEST';
export const SECURED_LOANS_SUCCESS = 'SECURED_LOANS_SUCCESS';
export const SECURED_LOANS_FAILURE = 'SECURED_LOANS_FAILURE';

export const usersFilters = [
  {
    name: 'Loan Status',
    value: 'status',
    filterCondition: 'eq',
    placeholder: 'Status',
    type: SELECT,
    options: [
      { name: 'Initiated', value: 'initiated' },
      { name: 'loan-agreement-signed', value: 'loan-agreement-signed' },
      { name: 'active', value: 'active' },
      { name: 'closed', value: 'closed' },
      { name: 'reversed', value: 'reversed' },
      { name: 'pre-closed', value: 'pre-closed' },
      { name: 'fore-closed', value: 'fore-closed' },
      { name: 'settlement', value: 'settlement' },
    ],
  }, 
  
  // {
  //   name: 'Crif Score',
  //   value: 'crifScore',
  //   filterCondition: 'eq',
  //   // defaultFilterValue:['677','700'],
  //   minPlaceholder: 'min value',
  //   maxPlaceholder: 'max value',
  //   minFilterCondition: 'gte',
  //   maxFilterCondition: 'lte',
  //   type: NUMBER_RANGE_TYPE,
  // },
  // {
  //   name: 'Name',
  //   value: 'name',
  //   filterCondition: 'ilike',
  //   placeholder: 'Rahul Kumar',
  //   type: TEXT,
  // },
  // {
  //   name: 'Mobile Number',
  //   value: 'mobileNumber',
  //   filterCondition: 'eq',
  //   placeholder: '9876543263',
  //   type: NUMBER,
  // },
  // {
  //   name: 'Bank Name',
  //   value: 'bankName',
  //   filterCondition: 'ilike',
  //   placeholder: 'HDFC Bank',
  //   type: TEXT,
  // },
  // {
  //   name: 'Email',
  //   value: 'primaryEmail',
  //   filterCondition: 'ilike',
  //   placeholder: 'User Email',
  //   type: TEXT,
  // },
  // {
  //   name: 'Employement Type',
  //   value: 'employmentType',
  //   filterCondition: 'eq',
  //   placeholder: 'User Email',
  //   type: SELECT,
  //   options: [
  //     { name: 'Salaried', value: 'salaried' },
  //     { name: 'Self Employed', value: 'self-employed' },
  //   ],
  // },
  // {
  //   name: 'Pan Document Status',
  //   value: 'panDocumentStatus',
  //   filterCondition: 'eq',
  //   placeholder: 'verified',
  //   type: SELECT,
  //   options: [
  //     { name: 'Verified', value: 'verified' },
  //     { name: 'Pending', value: 'pending' },
  //   ],
  // },
  // {
  //   name: 'Aadhar Document Status',
  //   value: 'aadharDocumentStatus',
  //   filterCondition: 'eq',
  //   placeholder: 'verified',
  //   type: SELECT,
  //   options: [
  //     { name: 'Verified', value: 'verified' },
  //     { name: 'Pending', value: 'pending' },
  //   ],
  // },
  // {
  //   name: 'KYC Status',
  //   value: 'isKycCompleted',
  //   filterCondition: 'eq',
  //   placeholder: 'verified',
  //   type: SELECT,
  //   options: [
  //     { name: 'Completed', value: 'true' },
  //     { name: 'Pending', value: 'false' },
  //   ],
  // },
  // {
  //   name: 'Bankstatement Status',
  //   value: 'bankStatementDocumentStatus',
  //   filterCondition: 'eq',
  //   placeholder: 'failed',
  //   type: SELECT,
  //   options: [
  //     { name: 'Verified', value: 'verified' },
  //     { name: 'Pending', value: 'pending' },
  //     { name: 'Failed', value: 'failed' },
  //     { name: 'Valid', value: 'valid' },
  //     { name: 'Invalid', value: 'invalid' },
  //     { name: 'Fraud', value: 'frauded' },
  //   ],
  // },
  // {
  //   name: 'Financial Check',
  //   value: 'isFinancialVerified',
  //   filterCondition: 'eq',
  //   placeholder: 'failed',
  //   type: SELECT,
  //   options: [
  //     { name: 'Verified', value: 'true' },
  //     { name: 'Un-verified', value: 'false' },
  //   ],
  // },
  // {
  //   name: 'Registration Fee Expiry Status',
  //   value: 'registrationFeeExpiryStatus',
  //   filterCondition: 'eq',
  //   placeholder: 'failed',
  //   type: SELECT,
  //   options: [
  //     { name: 'Valid', value: 'true' },
  //     { name: 'Expired', value: 'false' },
  //   ],
  // },
  // {
  //   name: 'Registration Fee Expiry',
  //   value: 'registrationFeeExpiry',
  //   filterCondition: 'eq',
  //   type: DATE_RANGE_TYPE,
  //   startDatevalue: 'registrationFeeExpiry',
  //   startDateFilterCondition: 'gte',
  //   endDateFilterCondition: 'lte',
  //   endDatevalue: 'registrationFeeExpiry',
  // },
  // {
  //   name: 'User Registered on',
  //   value: 'createdAt',
  //   filterCondition: 'eq',
  //   type: DATE_RANGE_TYPE,
  //   startDatevalue: 'createdAt',
  //   startDateFilterCondition: 'gte',
  //   endDateFilterCondition: 'lte',
  //   endDatevalue: 'createdAt',
  // },
];
