import {
  PAYMENTS_LOAN_REQUEST,
  PAYMENTS_LOAN_SUCCESS,
  PAYMENTS_LOAN_FAILURE,
} from './payments-constants';

const paymentsRequest = (urlParams) => ({
  type: PAYMENTS_LOAN_REQUEST,
  urlParams,
});

export const paymentsSuccess = (data, totalRecords) => ({
  data,
  totalRecords,
  type: PAYMENTS_LOAN_SUCCESS,
});

export const paymentsFailure = () => ({
  type: PAYMENTS_LOAN_FAILURE,
});

export const getPayments = async (urlParams, dispatch) => {
  dispatch(paymentsRequest(urlParams));
};
