import {
  USERS_CALL_REQUEST,
  USERS_CALL_SUCCESS,
  USERS_CALL_FAILURE,
} from './users-constants';

const UsersCallRequest = (mob) => ({
  type: USERS_CALL_REQUEST,
  mob,
});

export const UsersCallSuccess = (data) => ({
  data,
  type: USERS_CALL_SUCCESS,
});
export const UsersCallFailure = () => ({
  type: USERS_CALL_FAILURE,
});

export const callUsers = async (mob, dispatch) => {
  dispatch(UsersCallRequest(mob));
};
