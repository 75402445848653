import {
  PRE_APPROVED_AMOUNT_REQUEST,
  PRE_APPROVED_AMOUNT_SUCCESS,
  PRE_APPROVED_AMOUNT_FAILURE,
} from './profile-constants';

const preApprovedAmountRequest = (urlParams) => ({
  type: PRE_APPROVED_AMOUNT_REQUEST,
  urlParams,
});

export const preApprovedAmountSuccess = (data) => ({
  data,
  type: PRE_APPROVED_AMOUNT_SUCCESS,
});

export const preApprovedAmountFailure = () => ({
  type: PRE_APPROVED_AMOUNT_FAILURE,
});

export const preApprovedAmountAction = async (urlParams, dispatch) => {
  dispatch(preApprovedAmountRequest(urlParams));
};
