import {
  PAYMENTS_LOAN_REQUEST,
  PAYMENTS_LOAN_SUCCESS,
  PAYMENTS_LOAN_FAILURE,
} from './payments-constants';

const initialState = {
  isFetchingPayment: false,
  paymentDetails: [],
  totalRecordsPayment: '',
};

const paymentsLoan = (state = initialState, action) => {
  switch (action.type) {
    case PAYMENTS_LOAN_REQUEST:
      return {
        ...state,
        isFetchingPayment: true,
        paymentDetails: [],
      };
    case PAYMENTS_LOAN_FAILURE:
      return {
        ...state,
        isFetchingPayment: false,
        paymentDetails: [],
      };
    case PAYMENTS_LOAN_SUCCESS:
      return {
        ...state,
        isFetchingPayment: false,
        paymentDetails: action.data,
        totalRecordsPayment: action.totalRecords,
      };
    default:
      return state;
  }
};

export default paymentsLoan;
