import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';

import { useSelector, useDispatch } from 'react-redux';

import EllipseImage3 from '../../../images/Ellipse3.png';
import EllipseImage4 from '../../../images/Ellipse4.png';
import GroupImage15 from '../../../images/Group15.png';
import Hide from '../../../images/icons/hide.svg';
import Show from '../../../images/icons/show.svg';

import { signin } from './signin-action';

function usePrevious(value) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
}

const SignIn = (props) => {
  const { isLoading } = useSelector((state) => state.signin);

  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [validated, setValidated] = useState(false);
  const [passwordField, setPasswordField] = useState(true);
  const signinBtn = useRef();
  const prevEmail = usePrevious(email);
  const prevPassword = usePrevious(email);

  useEffect(() => {
    if (prevEmail !== email) {
      signinBtn.current.removeAttribute('disabled');
    }
    if (prevPassword !== password) {
      signinBtn.current.removeAttribute('disabled');
    }
  }, [email, password]);

  const loginDashboardCallback = () => {
    props.history.push('/dashboard');
  };

  const loginUsersCallback = () => {
    props.history.push('/users');
  };
  const loginResetPasswordCallback = () => {
    props.history.push('/resetpassword');
  };
  const triggerSignin = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.preventDefault();
    }
    setValidated(true);

    if (email && password) {
      signin(
        email,
        password,
        dispatch,
        loginDashboardCallback,
        loginUsersCallback,
        loginResetPasswordCallback
      );
      signinBtn.current.setAttribute('disabled', true);
    }
  };

  return (
    <section id="signIn " className="test">
      <div className="signIN-col left" style={{ backgroundColor: '#448AFA', position: 'relative' }}>
        <img src={EllipseImage4} alt style={{ position: 'absolute', right: 0 }} />
        <img src={GroupImage15} alt className="logo" />
        <img src={EllipseImage3} alt style={{ position: 'absolute', bottom: 0, left: 0 }} />
      </div>
      <div className="signIN-col signUp">
        <div className="onboarding-header">
          <h1>Sign In Your Account</h1>
          <h3>Create an account to enjoy all the services<br/> without any ads for free!</h3>
        </div>
        <Form noValidate validated={validated}  className='d-flex justify-content-center'>
          <div className="onboarding-fields signin-fileds">
            <div className="custom-input">
              <label>User name / Email id</label>
              <Form.Control
                required
                type="text"
                value={email}
                name="email"
                autoComplete="off"
                placeholder="User name / Email id"
                onChange={(e) => setEmail(e.target.value)}
              />
              {email === '' && (
                <Form.Control.Feedback type="invalid">
                    Please enter a username / email.
                </Form.Control.Feedback>
              )}
            </div>

            <div className="custom-input">
              <label>Password</label>
              <Link to="/forgotpassword">Forgot Password?</Link>
              {passwordField && (
                <Form.Control
                  required
                  type="password"
                  name="password"
                  value={password}
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              )}
              {!passwordField && (
                <Form.Control
                  required
                  type="text"
                  name="password"
                  value={password}
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              )}

              {passwordField && (
                <div
                  className="hide-img"
                  onClick={() => setPasswordField(!passwordField)}
                >
                  <img alt="hide" src={Hide} />
                </div>
              )}

              {!passwordField && (
                <div
                  className="show-img"
                  onClick={() => setPasswordField(!passwordField)}
                >
                  <img alt="show" src={Show} />
                </div>
              )}
              <Form.Control.Feedback type="invalid">
                  Please enter a password.
              </Form.Control.Feedback>
            </div>

            <div className="onboarding-btn">
              <button
                ref={signinBtn}
                onClick={triggerSignin}
                type="submit"
                className={`black-border-btn ${
                  isLoading && 'loading-button black-bg-loader'
                }`}
              >
                {!isLoading ? (
                  'Sign In'
                ) : (
                  <Fragment>
                    <div className="loader-button-gif-sec black-bg-loader-gif ">
                      <div className="loader-button-gif"></div>
                    </div>
                    <span className="laoder-span">Loading....</span>
                  </Fragment>
                )}
              </button>
            </div>
          </div>
        </Form>
      </div>
    </section>

  );
};

export default SignIn;
