export const setDefaultFilter = (filterParam, value, defaultFilterValue) => {
  let index = 0;
  const filters = filterParam

  filters.map((fitlerItem, loopIndex) => {
    if (fitlerItem.value === value) {
      index = loopIndex;
    }
    return false
  });
  filters[index].defaultFilterValue = defaultFilterValue;
  const [deletedFilter] = filters.splice(index, 1);

  filters.splice(0, 0, deletedFilter);
};

export const openInNewTab = (url) => {
  window.open(url, '_blank', 'noopener,noreferrer');
};
