import React, { useState, useEffect } from 'react';
import moment from 'moment';
import './bank-statement-info.css';
import { bankStatementInfoMap } from './bank-statement-info-constant';
import FieldValue from '../../field-key-value';

const BankStatementInfo = ({ userbankStatementInfo ,userProcessingStatus }) => {

  const [defaultBankStatment, setDefaultBank] = useState({});

  useEffect(() => {
    setDefaultBank(userbankStatementInfo);
  }, [userbankStatementInfo]);

  return (
    <React.Fragment>
      <div className="personal-bank-details">
        <div className="personal-heading-container">
          <div className="d-flex align-items-baseline">
            <h4 className="personal-common-heading">
                            Customer Summary
            </h4>
          </div>
        </div>
        <div className="bank-statement-info-wrapper">
          {bankStatementInfoMap.map((item, index) =>
            <FieldValue
              key={index}
              fieldName={item.label}
              fieldValue={defaultBankStatment &&
                // eslint-disable-next-line no-nested-ternary
                (item.key === 'lastMonthSalaryDate' ?
                  (defaultBankStatment[item.key] ? moment(defaultBankStatment[item.key]).format('DD-MM-YYYY hh:mm:ss') : '-')
                  : defaultBankStatment[item.key])}
              type={item.type}
            />
          )}
        </div>
        <div className='col-md-12' style={{ left: "-15px" }}>
          <div className="field-value-wrapper">
            <div className="field-name">Customer Summary Status</div>
            <div className="field-value">{userProcessingStatus && userProcessingStatus.message ? userProcessingStatus.message : '-'}</div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default BankStatementInfo;