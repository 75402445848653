import { TEXT_MAPICON , DATE } from '../../../constants/field-value-key';

export const userLocationDetailsKeyValueMap = [
  {
    label: 'Current Address',
    key: 'address',
    type: TEXT_MAPICON,
    longitudeKey:'longitude',
    latitudeKey:'latitude'
  },
  {
    label: 'Selfie Address',
    key: 'selfieAddress',
    type: TEXT_MAPICON,
    longitudeKey:'selfieLong',
    latitudeKey:'selfiLat'
  },
  {
    label: 'Agreement Address',
    key: 'agreementAddress',
    type: TEXT_MAPICON,
    longitudeKey:'agreementLong',
    latitudeKey:'agreementLat'
  }
]