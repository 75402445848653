import {
  ADD_LOAN_REQUEST,
  ADD_LOAN_SUCCESS,
  ADD_LOAN_FAILURE,
} from './profile-constants';

const addLoanRequest = (data, callLoanSuccessCallback) => ({
  callLoanSuccessCallback,
  data,
  type: ADD_LOAN_REQUEST,
});

export const addLoanSuccess = () => ({
  type: ADD_LOAN_SUCCESS,
});

export const addLoanFailure = () => ({
  type: ADD_LOAN_FAILURE,
});

export const addLoan = (
  callLoan,
  callLoanSuccessCallback,
  dispatch
) => {
  dispatch(addLoanRequest({ ...callLoan }, callLoanSuccessCallback));
};
