import React, { useState, useEffect } from 'react';
import { Form, Modal } from 'react-bootstrap';
import CloseIconWhite from '../../../../images/icons/closeIconWhite.svg';
import './add-call-logs.css';
import FormField from '../../form-elements';
import {
  setInitialFormValues,
  // onChangeHandler,
  getFormDataValue,
  validateForm,
  formFieldValidator,
} from '../../../utils/form-helper';
import { addCallLogsConstant, Updated, Rejected, Pending, Completed } from './add-call-logs-constant';

const AddCallLogs = ({
  closeaddCallLogsPopup,
  addCallLogs,
  setAddCallLogs,
  addCallLogsHandler,
}) => {
  const [addCallLogsForm, setAddCallLogsForm] = useState([]);

  useEffect(() => {
    setInitialFormValues(addCallLogsConstant, setAddCallLogsForm);
    
  }, [addCallLogs]);

  const handleAddAddress = () => {
    const [newAddCallLogForm, isValid] = validateForm(addCallLogsForm);
    setAddCallLogsForm(newAddCallLogForm);
    if (isValid) {
      const addCallObj = getFormDataValue(addCallLogsForm);

      addCallLogsHandler({ ...addCallObj });
      closeaddCallLogsPopup();
    }
  };

  const onChangeHandler = (form, formField, formFieldValue, setForm) => {
    const newForm = JSON.parse(JSON.stringify(form));

    if (newForm && Array.isArray(newForm)) {
      newForm.map((formItemParam) => {
        const formItem = formItemParam;

        if (formItem.name === formField) {
          formItem.value = formFieldValue;
          formItem.isTouched = true;
          const [isValid, result] = formFieldValidator(formItem.validation, formItem.value);

          formItem.isValid = isValid;
          formItem.errorMsg = result;
        }
          if(formItem.name === "status"){
            newForm[1].isDisabled = true
           if(formItem.value === "rejected"){
            newForm[2].options = Rejected;
          }else if(formItem.value === "updated"){
            newForm[2].options = Updated;
          }else if(formItem.value === "pending"){
            newForm[1].isDisabled = false;
            newForm[2].options = Pending;
          }else if(formItem.value === "completed"){
            newForm[2].options = Completed;
          }
        }
        return false;
      });
      setForm(newForm);
    }
  }

  return (
    <Modal
      show={addCallLogs}
      onHide={() => setAddCallLogs(false)}
      animation={false}
      className="delete-reason-popup"
    >
      <div className="common-image">
        <div className="common-img" onClick={() => setAddCallLogs(false)}>
          <img alt="close" src={CloseIconWhite} />
        </div>
      </div>
      <Modal.Body>
        <div className="editaddress-sec">
          <div className="editaddress-header">
            <h2>Call Logs</h2>
          </div>
          <div className="editaddress-form-sec">
            <Form className="add-new-address-form">
              <div className="add-call-log-form-wrapper">
                {addCallLogsForm.map((formItem, index) => (
                  <FormField
                    inputType={formItem.type}
                    key={index}
                    lableName={formItem.label}
                    name={formItem.name}
                    className={'no-bottom-margin'}
                    placeholder={formItem.placeholder}
                    value={formItem.value}
                    options={formItem.options}
                    onChangeHandler={(targetValue) => {
                      onChangeHandler(
                        addCallLogsForm,
                        formItem.name,
                        targetValue,
                        setAddCallLogsForm
                      );
                    }}
                    isValid={formItem.isValid}
                    errorMsg={formItem.errorMsg}
                    isDisabled={formItem.isDisabled}
                    isTouched={formItem.isTouched}
                  />
                ))}
              </div>
            </Form>
            {/* <span>*Field are required</span> */}
          </div>
          <div className="editaddress-bottom">
            <div className="editaddress-select">
              <button
                className="cancel-btn btn-align"
                onClick={closeaddCallLogsPopup}
              >
                Cancel
              </button>
              <button className="black-border-btn" onClick={handleAddAddress}>
                OK
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddCallLogs;
