import {
  CRIF_EXTRACTION_REQUEST,
  CRIF_EXTRACTION_SUCCESS,
  CRIF_EXTRACTION_FAILURE,
} from './profile-constants';

const crifExtractionRequest = (urlParams) => ({
  type: CRIF_EXTRACTION_REQUEST,
  urlParams,
});

export const crifExtractionSuccess = (data) => ({
  data,
  type: CRIF_EXTRACTION_SUCCESS,
});

export const crifExtractionFailure = () => ({
  type: CRIF_EXTRACTION_FAILURE,
});

export const crifExtractionAction = async (urlParams, dispatch) => {
  dispatch(crifExtractionRequest(urlParams));
};
