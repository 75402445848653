import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import CloseIconWhite from '../../../images/icons/closeIconWhite.svg';
import FormElement from '../../components/form-elements';
import { closeLoanFormFields } from './close-loan-constant';
import {
  onChangeHandler,
  setInitialFormValues,
  validateForm,
  getFormDataValue,
} from '../../utils/form-helper';
import Button from '../../components/form-elements/button';

const CloseLoan = ({ showCloseLoan, CloseCloseLoan,okButtonHandler }) => {
  const [closeLoanForm, setCloseLoanForm] = useState([]);

  useEffect(() => {
    setInitialFormValues(closeLoanFormFields, setCloseLoanForm);
  }, []);

  const okButtonHandlerInner = () => {
    const [newForm, isValid] = validateForm(closeLoanForm);

    setCloseLoanForm(newForm);
    if (isValid) {
      const formdata = getFormDataValue(newForm);

      formdata.status = 'closed';
      okButtonHandler(formdata);
      CloseCloseLoan();
    }
  };

  return (
    <React.Fragment>
      <Modal
        size="lg"
        show={showCloseLoan}
        onHide={CloseCloseLoan}
        backdropClassName="loan-modal-overlay-background"
        dialogClassName="close-loan-payment-modal-dialoag"
        aria-labelledby="close-loan-payment-pop-up"
        centered
      >
        <Modal.Body>
          <div className="close-loan-modal-container">
            <span className="loan-modal-close-button" onClick={CloseCloseLoan}>
              {' '}
              <img src={CloseIconWhite} />
            </span>
            <div className="loan-modal-title">Close Loan</div>
            <div>
              {closeLoanForm.map((formItem, index) => (
                <FormElement
                  key={index}
                  inputType={formItem.type}
                  lableName={formItem.label}
                  name={formItem.name}
                  placeholder={formItem.placeholder}
                  value={formItem.value}
                  onChangeHandler={(targetValue) => {
                    onChangeHandler(
                      closeLoanForm,
                      formItem.name,
                      targetValue,
                      setCloseLoanForm
                    );
                  }}
                  isValid={formItem.isValid}
                  errorMsg={formItem.errorMsg}
                  isDisabled={formItem.isDisabled}
                  isTouched={formItem.isTouched}
                />
              ))}
            </div>
            <div className="close-loan-modal-button-container">
              <Button
                text={'Cancel'}
                click={CloseCloseLoan}
                isGreyedOut={true}
              />
              <Button text={'OK'} click={okButtonHandlerInner} isMarginLeft={true} />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default CloseLoan;
