import React from 'react';
import { toDateTimeFormat } from '../../utils/formattor';

const SignAgreements = ({ loanAgreementInfo, getDownloadUrl }) => {
  return (
    <div className="sign-agreements">
      <h1>E-SIGN AGREEMENT</h1>
      <div className="agreements">
        <ul>
          <li>
            <label> File No</label>
            <span>{loanAgreementInfo && loanAgreementInfo.fileId?loanAgreementInfo.fileId:'-'}</span>
          </li>
          <li>
            <label>Document Type</label>
            <span>Loan Agreement</span>
          </li>
          <li>
            <label>Signed At</label>
            <span>{loanAgreementInfo && loanAgreementInfo.createdAt?toDateTimeFormat(loanAgreementInfo.createdAt):'-'}</span>
          </li>
        </ul>
        {loanAgreementInfo && loanAgreementInfo.fileId && <div className="download">
          <button className="black-border-btn">
            <a href={getDownloadUrl(loanAgreementInfo.fileId?loanAgreementInfo.fileId:"")} download className="download-e-agreement">Download</a>
          </button>
        </div>}
      </div>
    </div>
  );
};

export default SignAgreements;
