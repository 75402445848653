import { TEXT } from '../../../constants/form-type';

export const addUserFormConstant = [
  {
    label: 'User Name',
    name: 'addressLine1',
    placeholder: 'B-165, First Floor',
    validation: { isRequired: true },
    isDisabled: false,
    type: TEXT
  },
//   {
//     label: 'Socity Area Stree',
//     name: 'addressLine2',
//     placeholder: 'Building name',
//     validation: { isRequired: true },
//     isDisabled: false,
//     type: TEXT
//   },
//   {
//     label: 'landmark',
//     name: 'addressLine3',
//     placeholder: 'near Ashok Nagar',
//     validation: { isRequired: false },
//     isDisabled: false,
//     type: TEXT
//   },
//   {
//     label: 'Owner Ship',
//     name: 'ownership',
//     placeholder: 'owned-by-self/Spouse',
//     validation: { isRequired: true },
//     options: [
//       { name: 'Owned by self/Spouse', value: 'owned-by-self/Spouse' },
//       { name: 'Owned by parent/sibling', value: 'owned-by-parent/sibling' },
//       { name: 'Rented Staying Alone', value: 'rented-staying-alone' },
//       { name: 'Rented with family', value: 'rented-with-family' },
//       { name: 'Rented with friends', value: 'rented-with-friends' },
//       { name: 'Company provided', value: 'company-provided' },
//       { name: 'Hostel', value: 'hostel' },
//       { name: 'Leased', value: 'leased' },
//       { name: 'Paying Guest', value: 'paying-guest' },
//       { name: 'Bachelor Rented Accommodation', value: 'bachelor-rented-accommodation' },
//       { name: 'Mortgage', value: 'mortgage' },
//       { name: 'Others', value: 'others' },
//     ],
//     isDisabled: false,
//     type: SELECT
//   },
//   {
//     label: 'Address Type: ',
//     name: 'addressType',
//     placeholder: 'Haryana',
//     validation: { isRequired: true },
//     options: [
//       { name: 'Current', value: 'current' },
//       { name: 'Permanent', value: 'permanent' },
//       { name: 'Office', value: 'office' }
//     ],
//     isDisabled: false,
//     type: SELECT
//   },
//   {
//     label: 'Pin Code',
//     name: 'pincode',
//     placeholder: '124001',
//     validation: { isRequired: true },
//     isDisabled: false,
//     type: TEXT
//   },
//   {
//     label: 'City',
//     name: 'districtName',
//     placeholder: 'New Delhi',
//     validation: { isRequired: true },
//     isDisabled: true,
//     type: TEXT
//   },
//   {
//     label: 'State',
//     name: 'stateName',
//     placeholder: 'Haryana',
//     validation: { isRequired: true },
//     options: [],
//     isDisabled: true,
//     type: SELECT
//   },
//   {
//     label: 'Country',
//     name: 'country',
//     placeholder: 'India',
//     validation: { isRequired: true },
//     options: [
//       { name: 'India', value: 'India' },
//     ],
//     isDisabled: true,
//     type: SELECT
//   }
]

export const options = [
  { value: 'single', label: 'single' },
  { value: 'married', label: 'married' },
  { value: 'widowed', label: 'widowed' },
  { value: 'divorced', label: 'divorced' },
  { value: 'separated', label: 'separated' },
  { value: 'registered-partnership', label: 'registered-partnership' },
];

export const options1 = [
  { value: 'male', label: 'male' },
  { value: 'female', label: 'female' },
  { value: 'other', label: 'other' },
];