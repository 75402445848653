import React, { useState, useEffect } from 'react';
import { Form, Modal } from 'react-bootstrap';
import CloseIconGray from '../../../../images/icons/closeIconGray.svg';
import CloseIconWhite from '../../../../images/icons/closeIconWhite.svg';
import FormField from '../../form-elements';
import {
  fileUploadFormForFileType,
  uploadFileFormConst,
} from './file-upload-constant';
import {
  onChangeHandler,
  setInitialFormValues,
  getFormDataValue,
} from '../../../utils/form-helper';

const FileUpload = ({
  fileUploadPopup,
  setFileUploadPopup,
  closeUploadDocumentPopup,
  handleUploadFile,
  uploadFile,
  useDispatch,
}) => {
  const dispatch = useDispatch();
  const [fileUploadoptionForm, setFileUploadoptionForm] = useState([]);
  const [uploadFileFields, setUpoadFileFields] = useState({});
  const [uploadingFileKeyValue, setUploadingFileKeyValue] = useState({});
  const [markValid, setMarkValid] = useState(false);
  const [error, SetError] = useState('');
  // set initial form for selecting document type

  useEffect(() => {
    setInitialFormValues(fileUploadFormForFileType, setFileUploadoptionForm);
  }, [fileUploadPopup]);

  // getting the document upload properties which user selected to upload
  useEffect(() => {
    const { docType } = getFormDataValue(fileUploadoptionForm);

    setMarkValid(false);
    SetError('');
    setUploadingFileKeyValue({});
    if (docType && uploadFileFormConst[docType]) {
      const newUploadFormFields = JSON.parse(
        JSON.stringify(uploadFileFormConst[docType])
      );

      setUpoadFileFields(newUploadFormFields);
    } else {
      setUpoadFileFields({});
    }
  }, [fileUploadoptionForm]);
  // To set the obj with uploaded document id and key
  const uploadDocsCallBack = (picId, side) => {
    const newObjForUploadingKeyValue = { ...uploadingFileKeyValue };

    newObjForUploadingKeyValue[side] = picId;
    setUploadingFileKeyValue({
      ...newObjForUploadingKeyValue,
    });
  };
  // Function to upload the file to file service
  const handleFileSelect = (e) => {
    const formData = new FormData();

    formData.append('file', e.target.files[0]);
    uploadFile({ formData, side: e.target.name, uploadDocsCallBack }, dispatch);
    const newUploadFormFileFields = { ...uploadFileFields };

    newUploadFormFileFields.value.push(e.target.files[0]);
    setUpoadFileFields({ ...newUploadFormFileFields });
  };
  //  Set the document as valid with setting the number for the document
  const setVerifyDocNumber = (target) => {
    const newObjForUploadingKeyValue = { ...uploadingFileKeyValue };

    if (
      target &&
      markValid &&
      uploadFileFields.documentKeyName &&
      uploadFileFields.documentKeyName !== 'bankStatementDocumentId'
    ) {
      newObjForUploadingKeyValue[uploadFileFields.documentKeyName] = target;
    }
    if (
      markValid &&
      uploadFileFields.markedDocmentStautsKey &&
      uploadFileFields.documentKeyName !== 'bankStatementDocumentId'
    ) {
      newObjForUploadingKeyValue[uploadFileFields.markedDocmentStautsKey] =
        'verified';
    }
    if (
      markValid &&
      uploadFileFields.markedDocmentStautsKey &&
      uploadFileFields.documentKeyName === 'bankStatementDocumentId'
    ) {
      newObjForUploadingKeyValue[uploadFileFields.markedDocmentStautsKey] =
        target;
    }

    setUploadingFileKeyValue(newObjForUploadingKeyValue);
  };
  // To remove the pic from the upload list
  const removePic = (index) => {
    const newUploadFormFileFields = { ...uploadFileFields };

    newUploadFormFileFields.value.splice(index, 1);
    setUpoadFileFields({ ...newUploadFormFileFields });
  };
  // function to handle the Submit button
  const handleSubmit = () => {
    const { docType } = getFormDataValue(fileUploadoptionForm);
    let isValid = true;
    if (!docType) {
      isValid = false;
      SetError('Please Select a File type to upload');
      return 0;
    }
    // checking for the form value, if value is not present then set the error msg
    if (
      uploadFileFields.frontPicKey &&
      !uploadingFileKeyValue[uploadFileFields.frontPicKey]
    ) {
      isValid = false;
      SetError('Please upload the Front side pic of document');
      return 0;
    }

    if (uploadFileFields.documentKeyName !== 'bankStatementDocumentId') {
      if (
        uploadFileFields.isMarkValid &&
        markValid &&
        uploadFileFields.documentKeyName &&
        !uploadingFileKeyValue[uploadFileFields.documentKeyName]
      ) {
        isValid = false;
        SetError('Please Enter the Document Number');
        return 0;
      }
    }
    if (
      !uploadFileFields.isMarkValid &&
      uploadFileFields.documentKeyName &&
      !uploadingFileKeyValue[uploadFileFields.documentKeyName]
    ) {
      isValid = false;
      SetError('Please Upload Picture');
      return 0;
    }
    if (
      uploadFileFields.backPicKey &&
      !uploadingFileKeyValue[uploadFileFields.backPicKey]
    ) {
      isValid = false;
      SetError('Please upload the Back side Pic of document');
      return 0;
    }

    if (uploadFileFields.documentKeyName !== 'bankStatementDocumentId') {
      if (
        uploadFileFields.isMarkValid &&
        markValid &&
        uploadFileFields.markedDocmentStautsKey &&
        !uploadingFileKeyValue[uploadFileFields.markedDocmentStautsKey]
      ) {
        isValid = false;
        SetError('Please Enter the document Number');
        return 0;
      }
    }
    if (isValid) {
      handleUploadFile(uploadingFileKeyValue);
    }
    return false;
  };

  return (
    <Modal
      show={fileUploadPopup}
      onHide={() => setFileUploadPopup(false)}
      animation={false}
      className="loan-eligible-reason-popup"
    >
      <div className="common-image">
        <div className="common-img" onClick={() => setFileUploadPopup(false)}>
          <img alt="close" src={CloseIconWhite} />
        </div>
      </div>
      <Modal.Body>
        <div className="upload-doc">
          <h1>UPLOAD DOCUMENT</h1>
          <Form>
            <div className="upload-doc-input-container">
              <div className="filter-select-value">
                {fileUploadoptionForm.map((formItem, index) => (
                  <FormField
                    inputType={formItem.type}
                    key={index}
                    lableName={formItem.label}
                    name={formItem.name}
                    placeholder={formItem.placeholder}
                    value={formItem.value}
                    options={formItem.options}
                    onChangeHandler={(targetValue) => {
                      onChangeHandler(
                        fileUploadoptionForm,
                        formItem.name,
                        targetValue,
                        setFileUploadoptionForm
                      );
                    }}
                  />
                ))}
              </div>
            </div>
            {(uploadFileFields.frontPicKey ||
              uploadFileFields.documentKeyName) && (
              <div className="upload-doc-input-container upload-doc-text-container">
                <label className="filter-input-title">
                  Upload{' '}
                  {uploadFileFields.frontPicKey ? 'Front Side' : 'Picture'}
                </label>
                <Form.Control
                  type="file"
                  name={
                    uploadFileFields.frontPicKey ||
                    uploadFileFields.documentKeyName
                  }
                  id="file-front"
                  className="upload-doc-file-input"
                  onChange={(e) => handleFileSelect(e)}
                />
                <label htmlFor="file-front" className="upload-doc-text">
                  <p>
                    <span className="upload-doc-browse-file">Browse file</span>{' '}
                    or drop file here
                  </p>
                  Only <span className="upload-doc-file-type">.jpg, .png</span>{' '}
                  file allowed
                </label>
              </div>
            )}
            {uploadFileFields.backPicKey && (
              <div className="upload-doc-input-container upload-doc-text-container">
                <label className="filter-input-title">Upload Back Side</label>
                <Form.Control
                  type="file"
                  name={uploadFileFields.backPicKey}
                  id="file-back"
                  className="upload-doc-file-input"
                  onChange={(e) => handleFileSelect(e)}
                />
                <label htmlFor="file-back" className="upload-doc-text">
                  <p>
                    <span className="upload-doc-browse-file">Browse file</span>{' '}
                    or drop file here
                  </p>
                  Only <span className="upload-doc-file-type">.jpg, .png</span>{' '}
                  file allowed
                </label>
              </div>
            )}
            {uploadFileFields.value && uploadFileFields.value.length
              ? uploadFileFields.value.map((item, index) => (
                  <div className="uploaded-doc-message" key={index}>
                    <ul className="uploaded-doc-details">
                      <li>{item.name}</li>
                      <li>{`${Math.ceil(item.size / 1024)}kb`}</li>
                      <li>
                        <img
                          src={CloseIconGray}
                          onClick={() => removePic(index)}
                        />
                      </li>
                      <li style={{ visibility: 'hidden' }}>Retry</li>
                    </ul>
                  </div>
                ))
              : null}
            {markValid &&
              uploadFileFields.documentKeyName !==
                'bankStatementDocumentId' && (
                <div className="fliter-input-value">
                  <label className="filter-input-title">{`${fileUploadoptionForm[0].value} Number`}</label>
                  <Form.Control
                    required
                    type="text"
                    placeholder={`Enter ${fileUploadoptionForm[0].value} Number`}
                    value={
                      uploadingFileKeyValue[uploadFileFields.documentKeyName]
                    }
                    onChange={(e) => setVerifyDocNumber(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {`Please enter ${fileUploadoptionForm[0].value} Number.`}
                  </Form.Control.Feedback>
                </div>
              )}
            {markValid &&
              uploadFileFields.documentKeyName ===
                'bankStatementDocumentId' && (
                <div className="fliter-input-value">
                  <label className="filter-input-title">{`${fileUploadoptionForm[0].value} Number`}</label>
                  <Form.Control
                    required
                    type="text"
                    placeholder={`Enter BankStatment Password`}
                    value={
                      uploadingFileKeyValue[
                        uploadFileFields.markedDocmentStautsKey
                      ]
                    }
                    onChange={(e) => setVerifyDocNumber(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {`Please enter ${fileUploadoptionForm[0].value} Number.`}
                  </Form.Control.Feedback>
                </div>
              )}
          </Form>
          {error ? <div className="error-upload-document">*{error}</div> : null}
          <div className="upload-doc-buttons">
            {uploadFileFields.isMarkValid &&
              uploadFileFields.documentKeyName !==
                'bankStatementDocumentId' && (
                <button
                  className="black-border-btn file-upload-btn"
                  onClick={() => setMarkValid(!markValid)}
                >
                  {markValid ? 'CANCEL MARK VALID' : 'SUBMIT WITH MARK VALID'}
                </button>
              )}
            {uploadFileFields.isMarkValid &&
              uploadFileFields.documentKeyName ===
                'bankStatementDocumentId' && (
                <button
                  className="black-border-btn file-upload-btn"
                  onClick={() => setMarkValid(!markValid)}
                >
                  {markValid ? 'CANCEL' : 'SUBMIT WITH DOCUMENT PASSWORD'}
                </button>
              )}
            <button
              className="cancel-btn file-upload-btn"
              onClick={closeUploadDocumentPopup}
            >
              Cancel
            </button>
            <button
              className="black-border-btn file-upload-btn"
              onClick={() => handleSubmit()}
            >
              SUBMIT
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default FileUpload;
