import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import CloseIconWhite from '../../../images/icons/closeIconWhite.svg';
import FormElement from '../../components/form-elements';
import { cancelLoanFormFields } from './cancel-loan-constant';
import {
  onChangeHandler,
  setInitialFormValues,
  validateForm,
  getFormDataValue,
} from '../../utils/form-helper';
import Button from '../../components/form-elements/button';

const CancelLoan = ({ showCancelLoan, CloseCancelLoan,okButtonHandler }) => {
  const [cancelLoanForm, setCancelLoanForm] = useState([]);

  useEffect(() => {
    setInitialFormValues(cancelLoanFormFields, setCancelLoanForm);
  }, []);

  const okButtonHandlerInner = () => {
    const [newForm, isValid] = validateForm(cancelLoanForm);

    setCancelLoanForm(newForm);
    if (isValid) {
      const formdata = getFormDataValue(newForm);

      formdata.status = 'canceled';
      okButtonHandler(formdata)
      CloseCancelLoan();
    }
  };

  return (
    <React.Fragment>
      <Modal
        size="lg"
        show={showCancelLoan}
        onHide={CloseCancelLoan}
        backdropClassName="loan-modal-overlay-background"
        dialogClassName="close-loan-payment-modal-dialoag"
        aria-labelledby="close-loan-payment-pop-up"
        centered
      >
        <Modal.Body>
          <div className="close-loan-modal-container">
            <span className="loan-modal-close-button" onClick={CloseCancelLoan}>
              {' '}
              <img src={CloseIconWhite} />
            </span>
            <div className="loan-modal-title">Cancel Loan</div>
            <div>
              {cancelLoanForm.map((formItem, index) => (
                <FormElement
                  key={index}
                  inputType={formItem.type}
                  lableName={formItem.label}
                  name={formItem.name}
                  placeholder={formItem.placeholder}
                  value={formItem.value}
                  onChangeHandler={(targetValue) => {
                    onChangeHandler(
                      cancelLoanForm,
                      formItem.name,
                      targetValue,
                      setCancelLoanForm
                    );
                  }}
                  isValid={formItem.isValid}
                  errorMsg={formItem.errorMsg}
                  isDisabled={formItem.isDisabled}
                  isTouched={formItem.isTouched}
                />
              ))}
            </div>
            <div className="close-loan-modal-button-container">
              <Button
                text={'Cancel'}
                click={CloseCancelLoan}
                isGreyedOut={true}
              />
              <Button text={'OK'} click={okButtonHandlerInner} isMarginLeft={true} />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default CancelLoan;
