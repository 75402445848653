import { call, put, takeEvery } from 'redux-saga/effects';

import HttpHelper from '../../../utils/http-helper';
import { loanActionURL } from '../../../utils/urls';
import {
  LOAN_CANCEL_REQUEST,
  LOAN_CLOSE_REQUEST,
  LOAN_WAIVER_REQUEST,
} from './loan-actions-constants';
import { loanActionFailure, loanActionSuccess } from './loan-actions-action';

const { postRequest } = new HttpHelper();

export function* loanAction(action) {
  try {
    const url = loanActionURL(action);
    const response = yield call(postRequest, {
      data: action.data,
      url,
    });

    if (!response.error) yield put(loanActionSuccess(response.data));
    else yield put(loanActionFailure(response.error));
  } catch (error) {
    yield put(loanActionFailure(error));
  }
}

export function* watchLoanActions() {
  yield takeEvery(LOAN_CANCEL_REQUEST, loanAction);
  yield takeEvery(LOAN_CLOSE_REQUEST, loanAction);
  yield takeEvery(LOAN_WAIVER_REQUEST, loanAction);
}
