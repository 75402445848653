import React from 'react';

import HeaderLogo1 from './../../../images/header-logo-1.jpg';

const PageLoader = () => {
  return (
    <div className="page-loader-sec">
      <div className="loader-sec">
        <div className="loader-content">
          <div className="loader-img">
            <img width="40" height="40" alt="loader" src={HeaderLogo1} />
          </div>
          <div className="page-loader">
            <div className="loader"></div>
          </div>
          <div className="loader-text">
            <h1>Loading.....</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageLoader;
