import { TEXT , DATE } from '../../../constants/field-value-key';

export const userDetailsKeyValueMap = [
  {
    label: 'Type of Property',
    key: 'ownership',
    type: TEXT
  },
  {
    label: 'Flat, House, Building No.',
    key: 'addressLine1',
    type: TEXT
  },
  {
    label: 'Area, Locality, Street',
    key: 'addressLine2',
    type: TEXT
  },
  {
    label: 'Landmark',
    key: 'landmark',
    type: TEXT
  },
  {
    label: 'City',
    key: 'districtName',
    type: TEXT
  },
  {
    label: 'State',
    key: 'stateName',
    type: TEXT
  },
  {
    label: 'Pin Code',
    key: 'pincode',
    type: TEXT
  },
  {
    label: 'Country',
    key: 'country',
    type: TEXT
  },
  {
    label: 'Landline Number',
    key: 'telephoneNumber',
    type: TEXT
  },
  {
    label: 'Staying Since',
    key: 'stayingSince',
    type: DATE
  },
]