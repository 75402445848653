export const PROFILE_DETAILS_REQUEST = 'PROFILE_DETAILS_REQUEST';
export const PROFILE_DETAILS_SUCCESS = 'PROFILE_DETAILS_SUCCESS';
export const PROFILE_DETAILS_FAILURE = 'PROFILE_DETAILS_FAILURE';

export const USER_PROFILE_REQUEST = 'USER_PROFILE_REQUEST';
export const USER_PROFILE_SUCCESS = 'USER_PROFILE_SUCCESS';
export const USER_PROFILE_FAILURE = 'USER_PROFILE_FAILURE';

export const USER_PROFILE_UPDATE_REQUEST = 'USER_PROFILE_UPDATE_REQUEST';
export const USER_PROFILE_UPDATE_SUCCESS = 'USER_PROFILE_UPDATE_SUCCESS';
export const USER_PROFILE_UPDATE_FAILURE = 'USER_PROFILE_UPDATE_FAILURE';

export const ADD_ADDRESS_REQUEST = 'ADD_ADDRESS_REQUEST';
export const EDIT_ADDRESS_REQUEST = 'EDIT_ADDRESS_REQUEST';
export const GET_ADDRESS_DETAILS_WITH_PINCODE = 'GET_ADDRESS_DETAILS_WITH_PINCODE';
export const GET_ADDRESS_DETAILS_WITH_PINCODE_SUCCESS = 'GET_ADDRESS_DETAILS_WITH_PINCODE_SUCCESS';

export const GET_BANK_NAME_DETAILS_REQUEST = 'GET_BANK_NAME_DETAILS_REQUEST';
export const ADD_NEW_BANK_DETAILS_REQUEST = 'ADD_NEW_BANK_DETAILS_REQUEST';
export const EDIT_BANK_DETAILS_REQUEST = 'EDIT_BANK_DETAILS_REQUEST';
export const EDIT_BANK_STATEMENT_REQUEST = 'EDIT_BANK_STATEMENT_REQUEST';

export const BANK_REQUEST_FAILURE = 'BANK_REQUEST_FAILURE';
export const BANK_REQUEST_SUCCESS = 'BANK_REQUEST_SUCCESS';

export const ADD_ADDRESS_SUCCESS = 'ADD_ADDRESS_SUCCESS';
export const ADD_ADDRESS_FAILURE = 'ADD_ADDRESS_FAILURE';

export const FILE_UPLOAD = 'FILE_UPLOAD';

export const UPLOAD_PROFILE_PIC_REQUEST = 'UPLOAD_PROFILE_PIC__REQUEST';
export const UPLOAD_PROFILE_PIC_SUCCESS = 'UPLOAD_PROFILE_PIC__SUCCESS';
export const UPLOAD_PROFILE_PIC_FAILURE = 'UPLOAD_PROFILE_PIC__FAILURE';

export const UPLOAD_DOCS_REQUEST = 'UPLOAD_DOCS__REQUEST';
export const UPLOAD_DOCS_SUCCESS = 'UPLOAD_DOCS__SUCCESS';
export const UPLOAD_DOCS_FAILURE = 'UPLOAD_DOCS__FAILURE';

export const USER_ADDRESS_REQUEST = 'USER_ADDRESS_REQUEST';
export const USER_ADDRESS_SUCCESS = 'USER_ADDRESS_SUCCESS';
export const USER_ADDRESS_FAILURE = 'USER_ADDRESS_FAILURE';

export const CALL_LOGS_REQUEST = 'CALL_LOGS_REQUEST';
export const CALL_LOGS_SUCCESS = 'CALL_LOGS_SUCCESS';
export const CALL_LOGS_FAILURE = 'CALL_LOGS_FAILURE';

export const ADD_CALL_LOGS_REQUEST = 'ADD_CALL_LOGS_REQUEST';
export const ADD_CALL_LOGS_SUCCESS = 'ADD_CALL_LOGS_SUCCESS';
export const ADD_CALL_LOGS_FAILURE = 'ADD_CALL_LOGS_FAILURE';

export const BLOCK_UNBLOCK_REQUEST = 'BLOCK_UNBLOCK_REQUEST';
export const BLOCK_UNBLOCK_SUCCESS = 'BLOCK_UNBLOCK_SUCCESS';
export const BLOCK_UNBLOCK_FAILURE = 'ADD_CALL_LOGS_FAILURE';

export const ADD_LOAN_REQUEST = 'ADD_LOAN_REQUEST';
export const ADD_LOAN_SUCCESS = 'ADD_LOAN_SUCCESS';
export const ADD_LOAN_FAILURE = 'ADD_LOAN_FAILURE';

export const GET_PRODUCT_SETTINGS_REQUEST = 'GET_PRODUCT_SETTINGS_REQUEST';
export const GET_PRODUCT_SETTINGS_FAILURE = 'GET_PRODUCT_SETTINGS_FAILURE';
export const GET_PRODUCT_SETTINGS_SUCCESS = 'GET_PRODUCT_SETTINGS_SUCCESS';

export const GET_PARTNER_REQUEST = 'GET_PARTNER_REQUEST';

export const GET_PRODUCT_REQUEST = 'GET_PRODUCT_REQUEST';

export const GET_LOAN_PURPOSE_REQUEST = 'GET_LOAN_PURPOSE_REQUEST';

export const GET_COMPANY_REQUEST = 'GET_COMPANY_REQUEST';

export const STATE_LIST_REQUEST = 'STATE_LIST_REQUEST';

export const USER_ADDRESS_LOCATION_REQUEST = 'USER_ADDRESS_LOCATION_REQUEST';
export const USER_ADDRESS_LOCATION_SUCCESS = 'USER_ADDRESS_LOCATION_SUCCESS';
export const USER_ADDRESS_LOCATION_FAILURE = 'USER_ADDRESS_LOCATION_FAILURE';

export const CRIF_EXTRACTION_REQUEST = 'CRIF_EXTRACTION_REQUEST';
export const CRIF_EXTRACTION_SUCCESS = 'CRIF_EXTRACTION_SUCCESS';
export const CRIF_EXTRACTION_FAILURE = 'CRIF_EXTRACTION_FAILURE';

export const PRE_APPROVED_AMOUNT_REQUEST = 'PRE_APPROVED_AMOUNT_REQUEST';
export const PRE_APPROVED_AMOUNT_SUCCESS = 'PRE_APPROVED_AMOUNT_SUCCESS';
export const PRE_APPROVED_AMOUNT_FAILURE = 'PRE_APPROVED_AMOUNT_FAILURE';