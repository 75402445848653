import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { Breadcrumb } from 'react-bootstrap';

import { viewPartnerInfo } from './view-partner-action';

import Header from '../../components/header';
// import SideBar from '../../components/side-bar';
import SidebarIcon from '../../components/sidebar-icon';
import Footer from '../../components/footer';
import PartnerDetails from '../../components/partner-details';
import ProductTalbe from '../../components/product-table';
import {
  paytmSetting,
  razorPaySetting,
  partnerDetailsKeylabelMap,
  productTableData,
} from './view-partner-constants';
import {
  paytmSettingsForm,
  razorPaysettingsForm,
} from './wallet-setting-constants';
import './view-partner.css';
import WalletSettingShow from '../../components/view-wallet-settings';
import Button from '../../components/form-elements/button';
import EditWalletSettings from '../../components/wallet-setting-popup';
// import SuccessAlertBox from '../../components/alert-box/success-alert-box';
// import ErrorAlertBox from '../../components/alert-box/error-alert-box';
// import SuccessPopup from '../../components/alert-popup/success-alert-popup';
// import ErrorPopup from '../../components/alert-popup/error-alert-popup';

const ViewPartner = ({ match }) => {
  const [show, setShow] = useState(false);
  const [showPaytmWalletSetting, setShowPaytmWalletSetting] = useState(false);
  const [showRazorPayWalletSetting, setShowRazorPayWalletSetting] = useState(
    false
  );

  // const [showAlertBox, setShowAlertBox] = useState(false);
  // const [showSuccessPopUp, setShowSuccessPopUp] = useState(false);
  // const [showErrorPopUp, setShowErrorPopUp] = useState(false);

  const dispatch = useDispatch();

  // eslint-disable-next-line no-unused-vars
  const { viewPartnerDetails, partnerProduct, razorPay, paytm } = useSelector(
    (state) => state.viewPartner
  );
  // const { isSuccess, isFailure, errorObj } = useSelector((state) => state.addProduct)

  useEffect(() => {
    const path = window.location.pathname.split('/');
    const partnerId = path[path.length - 1];

    if (match.params) {
      viewPartnerInfo(match.params.id, dispatch);
    } else if (partnerId) {
      viewPartnerInfo(partnerId, dispatch);
    }
  }, []);

  // useEffect(() => {
  //   if (isSuccess || isFailure) {
  //     setShowAlertBox(true);
  //   }
  // }, [isSuccess, isFailure, errorObj])

  const addClassCallBack = () => {
    setShow(!show);
  };

  return (
    <div>
      {/* <SuccessPopup close={()=>{setShowSuccessPopUp(false)}}
       msg="You have successfully closed loan"
       show={showSuccessPopUp}></SuccessPopup>
      <ErrorPopup close={()=>{setShowErrorPopUp(false)}}
      msg="Before closing loan you must have to pay/waive-off loan amount.
       Click on Manual Payment button to pay/waive off loan amount."
       show={showErrorPopUp}></ErrorPopup> */}
      <EditWalletSettings
        showModal={showPaytmWalletSetting}
        closeModal={() => setShowPaytmWalletSetting(false)}
        title={'Paytm'}
        formFieldConstant={paytmSettingsForm}
      />
      <EditWalletSettings
        showModal={showRazorPayWalletSetting}
        closeModal={() => setShowRazorPayWalletSetting(false)}
        title={'Razor Pay'}
        formFieldConstant={razorPaysettingsForm}
      />

      <Header />
      <div className="container common-container">
        <SidebarIcon addClassCallBack={addClassCallBack} show={show} />
        <div className={`common-wrapper ${show ? 'active' : ''} `}>
          <div className="col-md-12 mpad">
            <div className="common-heading">
              <h1>Manage partner</h1>
              <Breadcrumb>
                <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>Settings</Breadcrumb.Item>
                <Breadcrumb.Item active>Manage Partner</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          <div className="Loan-info-sec-container row">
            <div className="col-md-12 mpad">
              {/* {showAlertBox ? <div>
                <div className="col-md-12 mpad">
                  {isSuccess ? <SuccessAlertBox show={isSuccess}
                  close={() => { setShowAlertBox(false) }}
                  successMsg={"Loan amount successfully disbursed."} /> : null}
                  {isFailure ? <ErrorAlertBox show={isFailure}
                   close={() => setShowAlertBox(false)}
                    errorMsg={"There is some issue to disburse amount,
                     please click disburse now button to disburse loan amount."}>
                    <Button click={()=>{}}  text={"DISBURSE NOW"}/><Button click={()=>{}} isMarginLeft={true}  text="CANCEL LOAN"/>
                  </ErrorAlertBox> : null}
                </div>
              </div>:null} */}
              <PartnerDetails
                partnerDetails={viewPartnerDetails}
                partnerDetailsKeylabelMap={partnerDetailsKeylabelMap}
              />
              <div className="product-table-add-button-wrapper">
                <span className="add-product-button">
                  <Button click={() => {}} text={'Add Product'} />
                </span>
                <ProductTalbe
                  productTableDetails={partnerProduct}
                  productTableConstant={productTableData}
                />
              </div>
              <div className="financial-partner-container">
                <div className="financial-partner-title">Financial PARTNER</div>
                <div className="paytm-razor-wrapper">
                  <WalletSettingShow
                    title={'Paytm'}
                    settings={paytmSetting}
                    value={paytm}
                    editHandler={() => {
                      setShowPaytmWalletSetting(true);
                    }}
                  />
                  <WalletSettingShow
                    title={'Razorpay'}
                    settings={razorPaySetting}
                    value={razorPay}
                    editHandler={() => {
                      setShowRazorPayWalletSetting(true);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer show={show} />
        {/* <div className={`common-side-bar ${show ? 'active' : ''} `}>
          <SideBar addClassCallBack={addClassCallBack} show={show} />
        </div> */}
      </div>
    </div>
  );
};

export default ViewPartner;
