import { NUMBER,CONSTANT_RUPEE } from '../../../constants/form-type';

export const autoPaymentFormFields = [
  {
    label: 'EMI Amount:',
    name: 'emiAmount',
    placeholder: '',
    validation: {},
    type: CONSTANT_RUPEE,
  }, {
    label: 'Total Due Amount:',
    name: 'totalDueAmount',
    placeholder: '',
    validation: {},
    type: CONSTANT_RUPEE,
  },{
    label: 'Amount',
    name: 'amount',
    placeholder: 'Enter amount',
    validation: { isRequired: true,isEmail: false, isMobile: false },
    errMsg: "",
    type: NUMBER,
  },
];
