import { call, put, takeEvery } from 'redux-saga/effects';

import HttpHelper from '../../utils/http-helper';
import { gstUrl } from '../../utils/urls';

import { GST_REQUEST } from './gst-constants';

import { gstSuccess, gstFailure } from './gst-action';

const { getRequest } = new HttpHelper();

export function* gst(action) {
  try {
    const response = yield call(getRequest, {
      url: gstUrl(action.urlParams),
    });

    const totalRecords = response.headers['x-coreplatform-total-records'];

    yield put(gstSuccess(response.data, totalRecords));
  } catch (error) {
    yield put(gstFailure());
  }
}

export function* watchGst() {
  yield takeEvery(GST_REQUEST, gst);
}
