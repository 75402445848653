import {
  SET_OTP_SUCCESS,
  SET_OTP_FALIURE,
  SET_OTP_REQUEST,
} from './users-constants';

const sendotpRequest = (data) => ({
  data,
  type: SET_OTP_REQUEST,
});

export const sendotpSuccess = () => ({
  type: SET_OTP_SUCCESS,
});

export const sendotpfaliure = (data) => ({
  data,
  type: SET_OTP_FALIURE,
});

export const sendOtpUser = (
  userDetails,
  sendOtpUserSuccessCallBack,
  sendOtpUserFailureCallBack,
  dispatch
) => {
  dispatch(
    sendotpRequest({
      ...userDetails,
      sendOtpUserSuccessCallBack,
      sendOtpUserFailureCallBack,
    })
  );
};
