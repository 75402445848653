import React from 'react';

const SuccessSmall = () => (
    <svg width="23px" height="23px" viewBox="0 0 23 23" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
        <title>tick-3</title>
        <desc>Created with Sketch.</desc>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="desktopUserDetailsRemarkPopupAfter" transform="translate(-323.000000, -156.000000)" fill="#1BC69E" fill-rule="nonzero">
                <g id="Group-7-Copy-3" transform="translate(311.000000, 144.000000)">
                    <g id="tick-3" transform="translate(12.000000, 12.000000)">
                        <g id="Group-5" transform="translate(0.390704, 0.136947)">
                            <path d="M10.1718183,15.0901347 L10.1718183,15.0901347 C9.93953057,15.09218 9.71819475,14.9901029 9.56697645,14.8111898 L5.41569254,10.2370222 C5.11903153,9.89724794 5.14666744,9.37863739 5.47770557,9.07329673 C5.80874371,8.76795607 6.32019943,8.789323 6.62537615,9.12124261 L10.1991808,13.0542335 L20.0960709,3.04077261 C20.2465349,2.88042203 20.4552078,2.78966269 20.6734199,2.78966269 C20.8916321,2.78966269 21.1003049,2.88042203 21.2507689,3.04077261 C21.5670913,3.365355 21.5670913,3.8877588 21.2507689,4.21234119 L10.7487764,14.8394809 C10.6019208,15.004735 10.3910376,15.0963511 10.1718183,15.0901347 Z" id="Path_5602"></path>
                            <path d="M10.9416169,22.2030974 C4.89872875,22.2030974 3.55271368e-13,17.2327963 3.55271368e-13,11.1016192 C3.55271368e-13,4.97044209 4.89872875,0 10.9416169,0 C11.1615865,-0.00399108172 11.3737482,0.0828476434 11.5293169,0.240689223 C11.6848855,0.398530802 11.7704738,0.613792245 11.7664012,0.836975699 C11.7704738,1.06015915 11.6848855,1.2754206 11.5293169,1.43326218 C11.3737482,1.59110376 11.1615865,1.67794248 10.9416169,1.6738104 C5.80976031,1.6738104 1.64956856,5.89478417 1.64956856,11.1016192 C1.64956856,16.3084542 5.80976031,20.529428 10.9416169,20.529428 C16.0734735,20.529428 20.2336653,16.3084542 20.2336653,11.1016192 C20.2416602,10.6453187 20.6085184,10.2796588 21.0583192,10.2796588 C21.5081201,10.2796588 21.8749783,10.6453187 21.8829732,11.1016192 C21.8667939,17.2258807 16.9776886,22.1865358 10.9416169,22.2030974 Z" id="Path_5603"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

export default SuccessSmall;