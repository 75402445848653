import { call, put, takeEvery } from 'redux-saga/effects';
import HttpHelper from '../../utils/http-helper';
import { CALL_USERS } from '../../utils/urls';
import { LEADS_CALL_REQUEST } from './my-leads-constants';
import { LeadsCallSuccess, LeadsCallFailure } from './my-leads-call-action';
import * as Toastr from 'toastr';

const { postRequest } = new HttpHelper();

export function* callLeads(action) {

  try {
    const response = yield call(postRequest, {
      data: {
        mobileNumber: action.mob,
        myOperatorUserID: localStorage.getItem('myoperatorUserId'),
      },
      url: CALL_USERS,
    });

    if(response.data.status==="success")
    {
      Toastr.success('Request accepted successfully', 'Success');
    }
    yield put(LeadsCallSuccess(response.data));
  } catch (error) {
    yield put(LeadsCallFailure());
  }
}

export function* watchcallLeads() {
  yield takeEvery(LEADS_CALL_REQUEST, callLeads);
}
