import { call, put, takeEvery } from 'redux-saga/effects';

import HttpHelper from '../../utils/http-helper';
import { partnerUrl } from '../../utils/urls';
import { MANAGE_PARTNER_REQUEST } from './manage-partner-constants';
import { managePartnerSuccess,managePartnerFailure } from './manage-partner-action';

const { getRequest } = new HttpHelper();

export function* managePartner(action) {
  try {
    const response = yield call(getRequest, {
      url: partnerUrl(action.urlParams),
    });

    const totalRecords = response.headers['x-coreplatform-total-records'] || 20;

    yield put(managePartnerSuccess(response.data, totalRecords));
  } catch (error) {
    yield put(managePartnerFailure());
  }
}

export function* watchManagePartner() {
  yield takeEvery(MANAGE_PARTNER_REQUEST, managePartner);
}
