import {
  BANK_REQUEST_FAILURE,
  BANK_REQUEST_SUCCESS,
  ADD_NEW_BANK_DETAILS_REQUEST,
  EDIT_BANK_DETAILS_REQUEST,
  GET_BANK_NAME_DETAILS_REQUEST,
  EDIT_BANK_STATEMENT_REQUEST
} from './profile-constants';

const addBankRequest = ( bankDetails, callback) => ({
  bankDetails,
  callback,
  type: ADD_NEW_BANK_DETAILS_REQUEST,
});

const editBankRequest = ( bankDetails, callback) => ({
  bankDetails,
  callback,
  type: EDIT_BANK_DETAILS_REQUEST,
});

const editBankStatementRequest = (bankDetails, callback) => ({
  bankDetails,
  callback,
  type: EDIT_BANK_STATEMENT_REQUEST,
});

const getBanksNameDetailsRequest = (callback) => ({
  callback,
  type: GET_BANK_NAME_DETAILS_REQUEST
})

export const bankRequestSuccess = (data) => ({
  data,
  type: BANK_REQUEST_SUCCESS,
});
export const bankRequestFailure = (data) => ({
  data,
  type: BANK_REQUEST_FAILURE,
});

export const addBank = (bankDetails, dispatch, callback) => {
  dispatch(addBankRequest(bankDetails, callback));
};

export const editBank = (bankDetails, dispatch, callback) => {
  dispatch(editBankRequest(bankDetails, callback));
};
export const editBankStatement = ( bankDetails, dispatch, callback) => {
  dispatch(editBankStatementRequest(bankDetails, callback));
};
export const getBanksNameDetails = ( dispatch,callback) => {
  dispatch(getBanksNameDetailsRequest(callback));
}