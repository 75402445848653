import { call, put,takeEvery } from 'redux-saga/effects';

import HttpHelper from '../../utils/http-helper';
import { productSettingsUrl } from '../../utils/urls';
import { GET_PRODUCT_SETTINGS_REQUEST } from './profile-constants';
import { productSettingsSuccess,productSettingsFailure } from './get-product-settings-action';

const { getRequest } = new HttpHelper();

export function* productSettings(action) {
  try {
    const url = productSettingsUrl(action.id);

    const response = yield call(getRequest, {
      url,
    });

    yield put(productSettingsSuccess(response.data));
  } catch (error) {
    yield put(productSettingsFailure());
  }
}

export function* watchProductSettingsRequest() {
  yield takeEvery(GET_PRODUCT_SETTINGS_REQUEST, productSettings);
}
