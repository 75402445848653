/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SelectSearch, { fuzzySearch } from 'react-select-search';
import CancelIcon from '@material-ui/icons/Cancel';
import Edit from '../../../images/icons/editIconBlack.svg';
import markerMapIcon from '../../../images/icons/markerMapIcon.svg';
import Done from '../../../images/success-green-icon-small.svg';
import './field-key-value.css';
import {
  TEXT,
  TEXT_MAPICON,
  IMAGE,
  URL,
  VERIFY_TEXT,
  VERIFYIED_STATUS,
  VERIFYIED_STATUS_2,
  VERIFYIED_STATUS_3,
  DATE,
  TEXT_EDITABLE_SELECT_BOX,
  VERIFY_PROFILE_TEXT,
  TEXT_EDITABLE_SELECT_SEARCH_BOX,
} from '../../constants/field-value-key';
import { toDateFormat, toCapitalizeFirstLetter } from '../../utils/formattor';
import FormElement from '../form-elements';
import { SELECT, EMAIL } from '../../constants/form-type';
// import Utils from '../../utils/index'

const FieldValue = ({
  fieldName,
  fieldValue,
  type,
  verifyStatus,
  options,
  valueForForm,
  onChangeHandler,
  onSubmitHandler,
}) => {
  const [isEdit, setIsEdit] = useState(false);

  // const [isAuthorized ,setIsAuthorized] = useState(false);
  // const [role ,setRole] = useState('');

  // useEffect(() => {
  //   const userrole = new Utils();
  //   const Role = userrole.getUserRole();

  //   setRole(Role);
  // },[]);

  // useEffect(() => {

  //   if(role === 'system-admin' || role === 'credit-manager'){
  //     setIsAuthorized(true);
  //   }
  // },[role])

  const getFieldValue = (typeParam) => {
    switch (typeParam) {
      case TEXT:
        return (
          <div className="field-value-wrapper">
            <div className="field-name">{fieldName}</div>
            <div className="field-value">{fieldValue || '-'}</div>
          </div>
        );
      case TEXT_MAPICON:
        return (
          <div className="field-value-wrapper">
            <div className="field-name">{fieldName}</div>
            <div className="field-value">
              {fieldValue || '-'}{' '}
              {fieldValue ? (
                <img
                  alt="show"
                  src={markerMapIcon}
                  onClick={() => {
                    onSubmitHandler();
                  }}
                />
              ) : (
                ''
              )}
            </div>
            <div className="edit-button-wrapper"></div>
          </div>
        );
      case IMAGE:
        return (
          <div className="field-value-wrapper">
            <div className="field-name">{fieldName}</div>
            <div className="field-value">
              <img src={fieldValue} alt={fieldName} height={40}></img>
            </div>
          </div>
        );
      case URL:
        return (
          <div className="field-value-wrapper">
            <div className="field-name">{fieldName}</div>
            <div className="field-value">
              <a href={fieldValue}>{fieldValue || '-'}</a>
            </div>
          </div>
        );
      case VERIFY_TEXT:
        return (
          <div className="field-value-wrapper">
            <div className="field-name">{fieldName}</div>
            <div className="field-value">
              {verifyStatus === VERIFYIED_STATUS ||
              verifyStatus === VERIFYIED_STATUS_2 ||
              verifyStatus === VERIFYIED_STATUS_3 ? (
                <CheckCircleIcon className="check-circle-green" />
              ) : (
                <CancelIcon className="check-cancel-red" />
              )}{' '}
              {fieldValue || '-'}
            </div>
          </div>
        );
      case VERIFY_PROFILE_TEXT:
        return (
          <div className="field-value-wrapper">
            <div className="field-name">{fieldName}</div>
            {!isEdit ? (
              <div className="field-value">
                {verifyStatus === VERIFYIED_STATUS ||
                verifyStatus === VERIFYIED_STATUS_2 ||
                verifyStatus === VERIFYIED_STATUS_3 ? (
                  <CheckCircleIcon className="check-circle-green" />
                ) : (
                  <CancelIcon className="check-cancel-red" />
                )}{' '}
                {fieldValue || '-'}
              </div>
            ) : (
              <div className="form-outline">
                <FormElement
                  inputType={EMAIL}
                  name=""
                  placeholder={fieldName}
                  value={valueForForm}
                  onChangeHandler={onChangeHandler}
                  className="form-control1"
                />
              </div>
            )}
            <div className="edit-button-wrapper">
              <img
                alt="show"
                src={isEdit ? Done : Edit}
                onClick={() => {
                  setIsEdit(!isEdit);
                  onSubmitHandler();
                }}
              />
            </div>
          </div>
        );
      case TEXT_EDITABLE_SELECT_BOX:
        return (
          <div className="field-value-wrapper">
            <div className="field-name">{fieldName}</div>
            <div className="field-value">
              {!isEdit ? (
                valueForForm ? (
                  toCapitalizeFirstLetter(valueForForm)
                ) : (
                  toCapitalizeFirstLetter(fieldValue) || '-'
                )
              ) : (
                <div className="select-form-wrapper">
                  <FormElement
                    inputType={SELECT}
                    name=""
                    placeholder={fieldName}
                    value={valueForForm}
                    onChangeHandler={onChangeHandler}
                    options={options}
                  />
                </div>
              )}
            </div>
            <div className="edit-button-wrapper">
              <img
                alt="show"
                src={isEdit ? Done : Edit}
                onClick={() => {
                  setIsEdit(!isEdit);
                }}
              />
            </div>
          </div>
        );
      case TEXT_EDITABLE_SELECT_SEARCH_BOX:
        return (
          <div className="field-value-wrapper">
            <div className="field-name">{fieldName}</div>
            <div className="field-value">
              {!isEdit ? (
                valueForForm ? (
                  toCapitalizeFirstLetter(valueForForm)
                ) : (
                  toCapitalizeFirstLetter(fieldValue) || '-'
                )
              ) : (
                <div>
                  <SelectSearch
                    className="select-search select-search--multiple"
                    name=""
                    search
                    filterOptions={fuzzySearch}
                    placeholder={fieldName}
                    value={valueForForm}
                    onChange={onChangeHandler}
                    options={options}
                  />
                </div>
              )}
            </div>
            <div className="edit-button-wrapper">
              <img
                alt="show"
                src={isEdit ? Done : Edit}
                onClick={() => {
                  setIsEdit(!isEdit);
                }}
              />
            </div>
          </div>
        );
      case DATE:
        return (
          <div className="field-value-wrapper">
            <div className="field-name">{fieldName}</div>
            <div className="field-value">
              {(fieldValue && toDateFormat(fieldValue)) || '-'}
            </div>
          </div>
        );
      default:
        return (
          <div className="field-value-wrapper">
            <div className="field-name">{fieldName}</div>
            <div className="field-value">{fieldValue || '-'}</div>
          </div>
        );
    }
  };

  return <React.Fragment>{getFieldValue(type)}</React.Fragment>;
};

export default FieldValue;
