import { call, put, takeEvery } from 'redux-saga/effects';

import HttpHelper from '../../utils/http-helper';
import { userAddressLocationUrl } from '../../utils/urls';
import { USER_ADDRESS_LOCATION_REQUEST } from './profile-constants';
import { userAddressLocationSuccess, userAddressLocationFailure } from './user-address-location-action';

const { getRequest } = new HttpHelper();

export function* userAddressLocation(action) {
  try {
    const url = userAddressLocationUrl(action.id);

    const response = yield call(getRequest, {
      url,
    });

    yield put(userAddressLocationSuccess(response.data));
  } catch (error) {
    yield put(userAddressLocationFailure());
  }
}

export function* watchuserAddressLocation() {
  yield takeEvery(USER_ADDRESS_LOCATION_REQUEST, userAddressLocation);
}
