export const LINK = 'LINK';
export const DROPDOWN = 'DROPDOWN';

export const HeadLinksAdmin = [
  {
    type: LINK,
    href: '/dashboard',
    label: 'Dashboard',
  },
  {
    type: LINK,
    href: '/users',
    label: 'User',
  },
  
  {
    type: LINK,
    href: '/loan',
    label: 'Loans',
  },
  {
    type: LINK,
    href: '/secured-loans',
    label: 'Secured Loans',
  },
  {
    type: DROPDOWN,
    label: 'Reports',
    dropdownItem: [
      {
        type: LINK,
        href: '#',
        label: 'DPD',
      },
      {
        type: LINK,
        href: '#',
        label: 'CRIF',
      },
      {
        type: LINK,
        href: '/disbursment',
        label: 'Disbursements',
      },
      {
        type: LINK,
        href: '/collections',
        label: 'Collections',
      },
      {
        type: LINK,
        href: '/defaults',
        label: 'Defaults',
      },
      {
        type: LINK,
        href: '/allocations',
        label: 'Allocations',
      },
      {
        type: LINK,
        href: '/report/loan',
        label: 'Loans Report',
      },
      {
        type: LINK,
        href: '/report/users',
        label: 'Users Report',
      },
      {
        type: LINK,
        href: '/report/users?sortBy=updatedAt',
        label: 'Users With Recent Updates',
      },
      {
        type: LINK,
        href: '/emi',
        label: 'EMIs',
      },
      {
        type: LINK,
        href: '/payments',
        label: 'Payments',
      },
      {
        type: LINK,
        href: '/gst',
        label: 'GST',
      },
    ],
  },
  {
    type: DROPDOWN,
    label: 'Settings',
    dropdownItem: [
      {
        type: LINK,
        href: '/manageusers',
        label: 'Manage Users',
      },
      {
        type: LINK,
        href: '/managepincode',
        label: 'Manage Pincode',
      },
      {
        type: LINK,
        href: '/managebanks',
        label: 'Manage Banks',
      },
      {
        type: LINK,
        href: '/manage-partner',
        label: 'Manage Partner',
      },
      {
        type: LINK,
        href: '/manageprofession',
        label: 'Manage Profession',
      },
    ],
  },
];

export const HeaderLinksCreditManager = [
  {
    type: LINK,
    href: '/dashboard',
    label: 'Dashboard',
  },
  {
    type: LINK,
    href: '/users',
    label: 'User',
  },
  {
    type: LINK,
    href: '/loan',
    label: 'Loans',
  },
  {
    type: LINK,
    href: '/secured-loans',
    label: 'Secured Loans',
  },
  {
    type: DROPDOWN,
    label: 'Reports',
    dropdownItem: [
      {
        type: LINK,
        href: '#',
        label: 'DPD',
      },
      {
        type: LINK,
        href: '#',
        label: 'CRIF',
      },
      {
        type: LINK,
        href: '/disbursment',
        label: 'Disbursements',
      },
      {
        type: LINK,
        href: '/collections',
        label: 'Collections',
      },
      {
        type: LINK,
        href: '/defaults',
        label: 'Defaults',
      },
      {
        type: LINK,
        href: '/report/loan',
        label: 'Loans Report',
      },
      {
        type: LINK,
        href: '/report/users',
        label: 'Users Report',
      },
      {
        type: LINK,
        href: '/report/users?sortBy=updatedAt',
        label: 'Users With Recent Updates',
      },
      {
        type: LINK,
        href: '/emi',
        label: 'EMIs',
      },
      {
        type: LINK,
        href: '/payments',
        label: 'Payments',
      },
      {
        type: LINK,
        href: '/gst',
        label: 'GST',
      },
    ],
  },
  {
    type: DROPDOWN,
    label: 'Settings',
    dropdownItem: [
      {
        type: LINK,
        href: '/settings/manage-allocations',
        label: 'Manage Allocations',
      }
    ],
  },
];

export const HeaderLinksCashCollection = [
  {
    type: LINK,
    href: '/dashboard',
    label: 'Dashboard',
  },
  {
    type: LINK,
    href: '/users',
    label: 'User',
  },
  {
    type: LINK,
    href: '/loan',
    label: 'Loans',
  },
  {
    type: LINK,
    href: '/secured-loans',
    label: 'Secured Loans',
  },
  {
    type: DROPDOWN,
    label: 'Reports',
    dropdownItem: [
      {
        type: LINK,
        href: '#',
        label: 'DPD',
      },
      {
        type: LINK,
        href: '#',
        label: 'CRIF',
      },
      {
        type: LINK,
        href: '/disbursment',
        label: 'Disbursements',
      },
      {
        type: LINK,
        href: '/collections',
        label: 'Collections',
      },
      {
        type: LINK,
        href: '/defaults',
        label: 'Defaults',
      },
      {
        type: LINK,
        href: '/report/loan',
        label: 'Loans Report',
      },
      {
        type: LINK,
        href: '/report/users',
        label: 'Users Report',
      },
      {
        type: LINK,
        href: '/report/users?sortBy=updatedAt',
        label: 'Users With Recent Updates',
      },
      {
        type: LINK,
        href: '/emi',
        label: 'EMIs',
      },
      {
        type: LINK,
        href: '/payments',
        label: 'Payments',
      },
      {
        type: LINK,
        href: '/gst',
        label: 'GST',
      },
    ],
  },
];
export const HeaderLinksCallCenterStaff = [
  {
    type: LINK,
    href: '/users',
    label: 'User',
  },
  {
    type: LINK,
    href: '/my-leads',
    label: 'My Leads',
  },
  {
    type: LINK,
    href: '/loan',
    label: 'Loans',
  },
  {
    type: LINK,
    href: '/secured-loans',
    label: 'Secured Loans',
  },
  {
    type: DROPDOWN,
    label: 'Reports',
    //dropdownItem: [
      // {
      //   type: LINK,
      //   href: '/defaults',
      //   label: 'Defaults',
      // },
      // {
      //   type: LINK,
      //   href: '/report/loan',
      //   label: 'Loans Report',
      // },
      // {
      //   type: LINK,
      //   href: '/report/users',
      //   label: 'Users Report',
      // },
      // {
      //   type: LINK,
      //   href: '/report/users?sortBy=updatedAt',
      //   label: 'Users With Recent Updates',
      // },
    //],
      dropdownItem: [
        {
          type: LINK,
          href: '#',
          label: 'DPD',
        },
        {
          type: LINK,
          href: '#',
          label: 'CRIF',
        },
        {
          type: LINK,
          href: '/disbursment',
          label: 'Disbursements',
        },
        {
          type: LINK,
          href: '/collections',
          label: 'Collections',
        },
        {
          type: LINK,
          href: '/defaults',
          label: 'Defaults',
        },
        {
          type: LINK,
          href: '/allocations',
          label: 'Allocations',
        },
        {
          type: LINK,
          href: '/report/loan',
          label: 'Loans Report',
        },
        {
          type: LINK,
          href: '/report/users',
          label: 'Users Report',
        },
        {
          type: LINK,
          href: '/report/users?sortBy=updatedAt',
          label: 'Users With Recent Updates',
        },
        {
          type: LINK,
          href: '/emi',
          label: 'EMIs',
        },
        {
          type: LINK,
          href: '/payments',
          label: 'Payments',
        },
        {
          type: LINK,
          href: '/gst',
          label: 'GST',
        },
      ]
    
  },
];
