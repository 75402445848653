import React, { useState, useEffect } from 'react';
import { Form, Modal } from 'react-bootstrap';
import CloseIconWhite from '../../../../images/icons/closeIconWhite.svg';

import FormField from '../../form-elements';
import {
  setInitialFormValues,
  onChangeHandler,
  getFormDataValue,
  setFormWithValues,
  validateForm,
} from '../../../utils/form-helper';
import { addAddressFormConstant } from './add-address-constant';

const AddAddress = ({
  closeNewAddressPopup,
  addAddress,
  setAddAddress,
  stateData,
  addAddressHandler,
  getAddressDetailsWithPincode,
  pincodeDetails,
}) => {
  const [addAddressForm, setAddAddressForm] = useState([]);
  const [isDefaultAddress, setIsDefaultAddress] = useState(false);

  useEffect(() => {
    setInitialFormValues(addAddressFormConstant, setAddAddressForm);
    setIsDefaultAddress(false);
  }, [addAddress]);

  useEffect(() => {
    const newPincodeDetails = { ...pincodeDetails };

    newPincodeDetails.country = 'India';
    setFormWithValues(addAddressForm, setAddAddressForm, newPincodeDetails);
  }, [pincodeDetails]);

  const getPincodeDetails = (pincode) => {
    const regex = new RegExp('^[1-9][0-9]{5}$');
    const isValidPin = regex.test(pincode);

    if (isValidPin) {
      getAddressDetailsWithPincode(pincode);
    }
  };

  const handleAddAddress = () => {
    const [newAddAddressForm, isValid] = validateForm(addAddressForm);

    setAddAddressForm(newAddAddressForm);
    if (isValid) {
      const newAddressObj = getFormDataValue(addAddressForm);

      newAddressObj.isPrimary = isDefaultAddress;
      const {
        addressLine1,
        addressLine2,
        addressLine3,
        ownership,
        addressType,
        pincode,
        isPrimary,
        telephoneNumber,
        stayingSince,

      } = newAddressObj;

      addAddressHandler({
        addressLine1,
        addressLine2,
        addressLine3,
        ownership,
        addressType,
        pincode,
        isPrimary,
        telephoneNumber,
        stayingSince,
      });
      closeNewAddressPopup();
    }
  };

  const callGetPinCodeDetails = (formName,targetValue) => {
    if (formName === 'pincode') getPincodeDetails(targetValue);
  };

  return (
    <Modal
      show={addAddress}
      onHide={() => setAddAddress(false)}
      animation={false}
      className="delete-reason-popup"
    >
      <div className="common-image">
        <div className="common-img" onClick={() => setAddAddress(false)}>
          <img alt="close" src={CloseIconWhite} />
        </div>
      </div>
      <Modal.Body>
        <div className="editaddress-sec">
          <div className="editaddress-header">
            <h2>ADD NEW ADDRESS</h2>
          </div>
          <div className="editaddress-form-sec">
            <Form className="add-new-address-form">
              <div className="edit-address-form-wrapper">
                {addAddressForm.map((formItem, index) => (
                  <FormField
                    inputType={formItem.type}
                    key={index}
                    lableName={formItem.label}
                    name={formItem.name}
                    className={index === 2 ? 'column-width-double' : ''}
                    placeholder={formItem.placeholder}
                    value={formItem.value}
                    options={
                      formItem.name === 'stateName'
                        ? stateData
                        : formItem.options
                    }
                    onChangeHandler={(targetValue) => {
                      onChangeHandler(
                        addAddressForm,
                        formItem.name,
                        targetValue,
                        setAddAddressForm
                      );
                      callGetPinCodeDetails(formItem.name,targetValue)
                    }}
                    isValid={formItem.isValid}
                    errorMsg={formItem.errorMsg}
                    isDisabled={formItem.isDisabled}
                    isTouched={formItem.isTouched}
                  />
                ))}
              </div>
            </Form>
            {/* <span>*Field are required</span> */}
          </div>
          <div className="editaddress-bottom">
            <button
              className={`black-border-btn' ${
                isDefaultAddress ? 'set-default-active' : ''
              }`}
              onClick={() => {
                setIsDefaultAddress(!isDefaultAddress);
              }}
              style={{
                borderRadius: "4px",
                border: "solid 1px #448AFA",
                backgroundColor: "#ffffff",
                textAlign: "center",
                padding: "8px 12px",
                fontSize: "14px",
                fontWeight: "500",
                letterSpacing: "normal",
                color: "#448AFA",
                textDecoration: "none",
              }}>
              Set as Default
            </button>
            <div className="editaddress-select">
              <button
                className="cancel-btn btn-align"
                onClick={closeNewAddressPopup}
              >
                Cancel
              </button>
              <button className="black-border-btn" onClick={handleAddAddress}>
                OK
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddAddress;
