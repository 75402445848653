import {
  USERS_REQUEST,
  USERS_SUCCESS,
  USERS_FAILURE,
} from './users-constants';

const initialState = {
  error: false,
  isFetching: false,
  totalRecords: '',
  users: [],
};

const users = (state = initialState, action) => {
  switch (action.type) {
    case USERS_REQUEST:
      return {
        ...state,
        isFetching: true,
        users: [],
      };
    case USERS_FAILURE:
      return {
        ...state,
        error: true,
        isFetching: false,
        users: [],
      };
    case USERS_SUCCESS:
      return {
        ...state,
        error: false,
        isFetching: false,
        totalRecords: action.totalRecords,
        users: action.data,
      };
    default:
      return state;
  }
};

export default users;
