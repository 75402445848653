import { call, put, takeEvery } from 'redux-saga/effects';
import * as Toastr from 'toastr';

import HttpHelper from '../../utils/http-helper';
import { addNewLoanUrl } from '../../utils/urls';
import { ADD_LOAN_REQUEST } from './profile-constants';
import { addLoanSuccess, addLoanFailure } from './add-loan-action';

const { postRequest } = new HttpHelper();

export function* addNewLoan(action) {

  try {
    const response = yield call(postRequest, {
      data: action.data,
      url: addNewLoanUrl(),
    });

    if (response.error) {
      const { data } = response.error.response;

      yield put(addLoanFailure());

      Toastr.error(data.details[0].message,'Failure');
    } else {
      // eslint-disable-next-line dot-notation
      Toastr.success(response.headers['message'],'Success');
      yield put(addLoanSuccess());
    }
  } catch (error) {
    Toastr.error(error, 'Failure Response');
  }
}

export function* watchAddnewLoan() {
  yield takeEvery(ADD_LOAN_REQUEST, addNewLoan);
}
