import React,{useState} from 'react';
import Header from '../../components/header';
import { Breadcrumb, Modal } from 'react-bootstrap';
import AddManageAllocation from '../../components/manage-allocations/add-manage-allocation';
import CloseIconWhite from '../../../images/icons/closeIconWhite.svg';
import { addManageAllocation } from './add-manage-allocaton-action';
import { useSelector, useDispatch } from 'react-redux';

const ManageAllocations = ()=>{
    const [addManageAllocationpopup, setAddManageAllocationpopup] = useState(true);
    const [validated, setValidated] = useState(false);
    const [addUserDetails, setAddUserDetails] = useState({
        pastDays: '1',
        emailAddress:''
      });
    const [errorResponse, setErrorResponse] = useState({});
    const { isLoading }= useSelector((state) => state);
    const dispatch = useDispatch();
    const addManageAllocationFailure = (errorRes) => {
        setErrorResponse(errorRes);
      };

    const handleManageAllocationPopupClose = () => {
      setAddManageAllocationpopup(false)
        window.location.href = "/dashboard";
    };
    const handleCloseIconGray = ()=>{
      setAddManageAllocationpopup(false)
        window.location.href = "/dashboard";
    }

    // const handleAdduserPopupShow = () => setAdduserpopup(true);
    const handleManageAllocation = () => {
        setAddUserDetails(addUserDetails);
    
        const newUserDetails = addUserDetails;
    
        setValidated(true);
        if (
          addUserDetails.pastDays
        ) {
          addManageAllocation(newUserDetails,addManageAllocationFailure,dispatch);
        }
      };
    return <div>
        <Header />
        <Modal
        show={addManageAllocationpopup}
        onHide={() => setAddManageAllocationpopup(false)}
        animation={false}
        className="add-user-popup"
      >
        <div className="common-image">
          <div className="common-img" onClick={handleCloseIconGray}>
            <img alt="close-icon-gray" src={CloseIconWhite} />
          </div>
        </div>
        <Modal.Body>
          <AddManageAllocation
            closeManageAllocationPopup={handleManageAllocationPopupClose}
            addUserDetails={addUserDetails}
            setAddUserDetails={setAddUserDetails}
            handleManageAllocation={handleManageAllocation}
            validated={validated}
            errorResponse={errorResponse}
            setErrorResponse={setErrorResponse}
            isLoading={isLoading}
          />
        </Modal.Body>
      </Modal>
    </div>
}
export default ManageAllocations;