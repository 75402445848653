/* eslint-disable max-lines */
/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import DataTable from 'moneyloji-react-data-table';

import { Link } from 'react-router-dom';

import { Breadcrumb } from 'react-bootstrap';

import { useSelector, useDispatch } from 'react-redux';

import { loan } from './loan-action';
import { loanFilters } from './loan-constants';
import { dashboardReportsAction } from '../dashboard/dashboard-reports-actions';
import Header from '../../components/header';
// import SideBar from '../../components/side-bar';
import SidebarIcon from '../../components/sidebar-icon';
// import ProgressBar from '../../components/progress-bar';
import Footer from '../../components/footer';
import {
  toNumberWithCommaAndDecimal,
  toDateTimeFormat,
  toNumberWithComma,
} from '../../utils/formattor';
import UsersIcon from '../../../images/icons/users.png';
import RupeesIcon from '../../../images/icons/rupees.png';
import SavingsIcon from '../../../images/icons/savings.png';

import { DEFAULT_PAGE_SIZE, CURRENT_DEFAULT_PAGE } from '../../constants';
import { loanExportUrl } from '../../utils/urls';
import { setDefaultFilter, openInNewTab } from '../../utils/default-filter-helper';
import TableLoader from '../../components/Loaders/table-loader';

const Loan = () => {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();

  const getLoanData = (urlParams) => {
    loan(urlParams, dispatch);
    if(urlParams['filters[0][key]'] === "createdAt" || urlParams['filters[0][key]'] === undefined){
      dashboardReportsAction(urlParams, dispatch);
    }
  };

  useEffect(() => {
    const urlParams = {
      pageNumber: CURRENT_DEFAULT_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
      'sorting[0][direction]': ['DESC'],
      'sorting[0][key]': ['createdAt'],
    };
    // eslint-disable-next-line no-restricted-globals
    const searchParam = location.search;

    if (searchParam) {
      const filterKey = new URLSearchParams(searchParam);
      const value = filterKey.get('value');
      const filterOnValue = filterKey.get('filterOnValue');

      setDefaultFilter(loanFilters, value, filterOnValue);
    } else {
      getLoanData(urlParams);
    }
    // dashboardCard(dispatch);
  }, []);

  const { loanDetails, totalRecords, isFetching } = useSelector(
    (state) => state.loan
  );
  const { dashboardDetailsData } = useSelector((state) => state.dashboardReportsReducer);

 // const { dashboardCardDetails } = useSelector((state) => state.dashboardCard);

  const loanList =
    loanDetails &&
    loanDetails.map((loanData, index) => {
      const id = index + 1;

      return {
        ...loanData,
        id,
      };
    });

  function loanNumberHandle(cell, row) {
    return <a
    // href={`/loan/${row.publicId}`}
    target="_blank"
    rel="noopener noreferrer"
    onClick={() => openInNewTab(`/loan/${row.publicId}`)}>
    {cell || '-'}
  </a>;
  }

  function userName(cell, row) {
    return <a
    // href={`/users/${row.userId}`}
    target="_blank"
    rel="noopener noreferrer"
    onClick={() => openInNewTab(`/users/${row.userId}`)}>
    {cell || '-'}
  </a>;
  }

  function userMobileNo(cell, row) {
    return <Link to={`/users/${row.userId}`}>{cell}</Link>;
  }

  function buttonFormatter(cell) {
    if (cell === 'closed') {
      return <label className="status-unpaid">{cell}</label>;
    } else if (cell === 'active') {
      return <label className="label-status-paid">{cell}</label>;
    }
    return <label className="label-status-initiated">{cell}</label>;
  }
  const dateFormatter = (cell) => {
    if (cell) {
      return toDateTimeFormat(cell);
    }
    return '-';
  };

  const daysFormatter = (cell) => {
    return <label className="status-partialpaid">{cell}</label>;
  };

  const numberFormatter = (cell) => {
    if (cell) {
      return toNumberWithCommaAndDecimal(cell);
    }
    return '-';
  };

  const addClassCallBack = () => {
    setShow(!show);
  };

  const tableHeaderData = [
    {
      columnClassName: '',
      dataField: 'id',
      dataFormat: (cell) => cell,
      dataSort: false,
      isKey: true,
      name: '#',
      width: '40',
    },
    {
      columnClassName: '',
      dataField: 'number',
      dataFormat: loanNumberHandle,
      dataSort: true,
      isKey: false,
      name: 'Loan No',
      width: '100',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'startDate',
      dataFormat: dateFormatter,
      dataSort: true,
      isKey: false,
      name: 'Date',
      width: '130',
    },
    {
      columnClassName: '',
      dataField: 'name',
      dataFormat: userName,
      dataSort: true,
      isKey: false,
      name: 'Name',
      width: '170',
    },
    {
      columnClassName: '',
      dataField: 'mobileNumber',
      dataFormat: userMobileNo,
      dataSort: false,
      isKey: false,
      name: 'Mobile No',
      width: '100',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'amount',
      dataFormat: numberFormatter,
      dataSort: true,
      isKey: false,
      name: 'Loan Amount',
      width: '150',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'emiAmount',
      dataFormat: numberFormatter,
      dataSort: true,
      isKey: false,
      name: 'Emi Amount',
      width: '150',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'totalPaid',
      dataFormat: (cell) => cell,
      dataSort: true,
      isKey: false,
      name: 'Total Paid',
      width: '80',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'dpd',
      dataFormat: (cell) => cell,
      dataSort: true,
      isKey: false,
      name: 'DPD',
      width: '80',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'tenure',
      dataFormat: daysFormatter,
      dataSort: true,
      isKey: false,
      name: 'tenure',
      width: '100',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'interestRate',
      dataFormat: numberFormatter,
      dataSort: true,
      isKey: false,
      name: 'ROI',
      width: '70',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'paidAmount',
      dataFormat: numberFormatter,
      dataSort: true,
      isKey: false,
      name: 'Paid Amount',
      width: '100',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'netAmount',
      dataFormat: numberFormatter,
      dataSort: true,
      isKey: false,
      name: 'Net Amount',
      width: '100',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'balanceDueAmount',
      dataFormat: numberFormatter,
      dataSort: true,
      isKey: false,
      name: 'Bal. Due Amount',
      width: '100',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'bounceAmountAccrued',
      dataFormat: numberFormatter,
      dataSort: true,
      isKey: false,
      name: 'Balance Amount Accrued',
      width: '100',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'balanceBounceAmount',
      dataFormat: numberFormatter,
      dataSort: true,
      isKey: false,
      name: 'Balance Bounce Amount',
      width: '150',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'penalAmountAccrued',
      dataFormat: numberFormatter,
      dataSort: true,
      isKey: false,
      name: 'Penal Amount Accrued',
      width: '150',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'balancePenalAmount',
      dataFormat: numberFormatter,
      dataSort: true,
      isKey: false,
      name: 'Balance Penal Amount',
      width: '150',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'lastPaymentDate',
      dataFormat: dateFormatter,
      dataSort: true,
      isKey: false,
      name: 'Last Payment Date',
      width: '100',
    },
    {
      columnClassName: '',
      dataField: 'status',
      dataFormat: buttonFormatter,
      dataSort: true,
      isKey: false,
      name: 'Status',
      width: '140',
    },
    {
      columnClassName: '',
      dataField: 'partnerName',
      dataFormat: buttonFormatter,
      dataSort: false,
      isKey: false,
      name: 'Partner Name',
      width: '140',
    },
  ];

  // const { loans } = dashboardCardDetails;

  return (
    <div>
      <Header />
      <div className="common-container">
        <SidebarIcon addClassCallBack={addClassCallBack} show={show} />
        <div className={`common-wrapper ${show ? 'active' : ''} `}>
          <div className="col-md-12 mpad">
            <div className="common-heading">
              <h1>Loans</h1>
              <Breadcrumb>
                <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>Loans</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          <div className='row' style={{ justifyContent: 'space-evenly' }}>
                  <div className='col-md-3'>
                    <div className="chip">
                      <div className="chip-body">
                        <h5 className="num-defaults">{dashboardDetailsData && (dashboardDetailsData.activeLoans ? dashboardDetailsData.activeLoans : 0)}</h5>
                        <h3 className="heading">Active Loan</h3>
                        <p className='para'>No of Active loans in our system</p>
                        <img src={UsersIcon} alt='placeholder' />
                        </div>
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className="chip">
                      <div className="chip-body">
                         <h5 className="num-defaults">
                           &#8377;{dashboardDetailsData && (dashboardDetailsData.principalDisbursed !== null ? toNumberWithComma(dashboardDetailsData.principalDisbursed) : 0)}
                          </h5>
                        <h3 className="heading">Principal Disbursed Amount</h3>
                        <p className='para'>Total No of Amount Disbursed in our system</p>
                        <img src={RupeesIcon} alt='placeholder'/>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className="chip">
                      <div className="chip-body">
                        <h5 className="num-defaults">
                            &#8377;{dashboardDetailsData && (dashboardDetailsData.totalPrincipalCollected !== null ? toNumberWithComma(dashboardDetailsData.totalPrincipalCollected) : 0)}
                        </h5>
                        <h3 className="heading">Total Principal Collected Amount</h3>
                        <p className='para'>Total No of Principal Amount Collected in our system</p>
                        <img src={SavingsIcon} alt='placeholder'/>
                      </div>
                    </div>
                  </div>
          </div>
          <div className='row' style={{ justifyContent: 'space-evenly' }}>
                  <div className='col-md-3'>
                    <div className="chip">
                      <div className="chip-body">
                        <h5 className="num-defaults">{dashboardDetailsData && (dashboardDetailsData.miniLoanCount ? dashboardDetailsData.miniLoanCount : 0)}</h5>
                        <h3 className="heading">No of Mini Loan</h3>
                        <img src={SavingsIcon} alt='placeholder' />
                        </div>
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className="chip">
                      <div className="chip-body">
                         <h5 className="num-defaults">
                           {dashboardDetailsData && (dashboardDetailsData.jumboLoanCount !== null ? dashboardDetailsData.jumboLoanCount : 0)}
                          </h5>
                        <h3 className="heading">No of Jumbo Loan</h3>
                        <img src={SavingsIcon} alt='placeholder'/>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className="chip">
                      <div className="chip-body">
                        <h5 className="num-defaults">
                          {dashboardDetailsData && (dashboardDetailsData.bikeLoanCount !== null ? dashboardDetailsData.bikeLoanCount : 0)}
                        </h5>
                        <h3 className="heading">No of Bike Loan</h3>
                        <img src={SavingsIcon} alt='placeholder'/>
                      </div>
                    </div>
                  </div>
          </div>
          <div className="loan-container">
            <DataTable
              tableData={loanList}
              tableHeaderData={tableHeaderData}
              totalRecords={totalRecords}
              isFetching={isFetching}
              getTableData={getLoanData}
              exportUrl={loanExportUrl}
              tableTitle="LOANS"
              pageFilters={loanFilters}
              defaultSortKey={'createdAt'}
              defaultSortDirection={'desc'}
              TableLoader={TableLoader}
            />
          </div>
        </div>
        <Footer show={show} />
        {/* <div className={`common-side-bar ${show ? 'active' : ''} `}>
          <SideBar addClassCallBack={addClassCallBack} show={show} />
        </div> */}
      </div>
    </div>
  );
};

export default Loan;
