import React from 'react';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import PrivateRoutes from './custom-routes/private-routes';

import SignIn from './containers/sign-in';
import ForgotPassword from './containers/forgot-password';
import ChangePassword from './containers/change-password';
import ResetPassword from './containers/reset-password';

import Dashboard from './containers/dashboard';
import Profile from './containers/profile';
import Loan from './containers/loan';
import LeadsLoan from './containers/leads-loan';
import LoanInformation from './containers/loan-information';
import Users from './containers/users';
import ManageUsers from './containers/manage-users';
import Disbursment from './containers/disbursment';
import Payments from './containers/payments';
import Defaults from './containers/defaults';
import Emi from './containers/emi';
import Collections from './containers/collections';
import NotFound404 from './public/not-found-404';
import ManageBank from './containers/manage-bank';
import ManageProfession from './containers/manage-profession';
import ManagePincode from './containers/manage-pincode';
import AddPartner from './containers/manage-partner/add-partner';
import AddProduct from './containers/manage-partner/add-product';

import ManagePartner from './containers/manage-partner';
import ViewPartner from './containers/view-partner';

import SecuredLoans from './containers/secured-loans';
import SecuredLoanInformation from './containers/secured-loan-information';
import Allocation from './containers/allocation';
import GstReport from './containers/gst';
import MyLeads from './containers/my-leads';
import ManageAllocations from './containers/manage-allocations';

const Routes = () => (
  <Router>
    <Switch>
      <Route path="/signin" component={(props) => <SignIn {...props} />} />
      <Route exact path="/forgotpassword" component={ForgotPassword} />
      <PrivateRoutes exact path="/changepassword" permissions={['All']} component={ChangePassword} />
      <PrivateRoutes exact path="/resetpassword" permissions={['All']} component={ResetPassword} />

      <PrivateRoutes exact exact path="/" permissions={['system-admin','credit-manager','credit-head']} component={Dashboard} />
      <PrivateRoutes exact path="/dashboard" permissions = {['system-admin','credit-manager','collection-staff','credit-head']} component={Dashboard} />
      <PrivateRoutes exact path="/users/:id" permissions={['All']} component={Profile} />
      <PrivateRoutes exact path="/loan" permissions={['All']} component={Loan} />
      <PrivateRoutes exact path="/loan-leads" permissions={['All']} component={LeadsLoan} />

      <PrivateRoutes
        exact
        path="/loan/:id"
        permissions={['All']}
        component={LoanInformation}
      />
      <PrivateRoutes exact path="/users" permissions={['All']} component={Users} />
      <PrivateRoutes exact path="/my-leads" permissions={['All']} component={MyLeads} />

      <PrivateRoutes exact path="/manageusers" permissions={['system-admin']} component={ManageUsers} />
      <PrivateRoutes exact path="/disbursment" permissions={['system-admin','credit-manager','collection-staff','credit-head']} component={Disbursment} />
      <PrivateRoutes exact path="/payments" permissions={['system-admin','credit-manager','collection-staff','credit-head']} component={Payments} />
      <PrivateRoutes exact path="/defaults" permissions={['All']} component={Defaults} />
      <PrivateRoutes exact path="/emi" permissions={['system-admin','credit-manager','collection-staff','credit-head']} component={Emi} />
      <PrivateRoutes exact path="/collections" permissions={['system-admin','credit-manager','collection-staff','credit-head']} component={Collections} />
      <PrivateRoutes exact path="/managebanks" permissions={['system-admin']} component={ManageBank} />

      <PrivateRoutes
        exact
        path="/manageprofession"
        permissions={['system-admin']}
        component={ManageProfession}
      />

      <PrivateRoutes
        exact
        path="/secured-loan-information/:id"
        permissions={['All']}
        component={SecuredLoanInformation}
      />

      <PrivateRoutes exact path="/managepincode" permissions={['system-admin']} component={ManagePincode} />
      <PrivateRoutes exact path="/secured-loans" permissions={['system-admin']} component={SecuredLoans} />
      <PrivateRoutes exact path="/add-partner" permissions={['All']} component={AddPartner} />
      <PrivateRoutes exact path="/add-product" permissions={['All']} component={AddProduct} />
      <PrivateRoutes exact path="/manage-partner" permissions={['system-admin']} component={ManagePartner} />
      <PrivateRoutes exact path="/allocations" permissions={['system-admin']} component={Allocation} />
      <PrivateRoutes exact path="/partner/:id" permissions={['All']} component={ViewPartner} />

      <PrivateRoutes exact path="/report/users" permissions={['All']} component={Users} />
      <PrivateRoutes exact path="/report/loan" permissions={['All']} component={Loan} />
      <PrivateRoutes exact path="/gst" permissions={['All']} component={GstReport} />
      <PrivateRoutes exact path="/settings/manage-allocations" permissions={['All']} component={ManageAllocations} />
      <Route exact path="*" component={NotFound404} />
    </Switch>
  </Router>
);

export default Routes;
