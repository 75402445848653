import {
  BANK_REQUEST_FAILURE,
  BANK_REQUEST_SUCCESS,
  ADD_NEW_BANK_DETAILS_REQUEST,
  EDIT_BANK_DETAILS_REQUEST,
  GET_BANK_NAME_DETAILS_REQUEST,
} from './profile-constants';

const initialState = {
  isBankerror: false,
  isBankSuccess: false,
  successRes: {},
  errorRes: {},
  isFetching: false,
};

const addAndEditBank = (state = initialState, action) => {
  switch (action.type) {
    case ADD_NEW_BANK_DETAILS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case EDIT_BANK_DETAILS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case GET_BANK_NAME_DETAILS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case BANK_REQUEST_FAILURE:
      return {
        ...state,
        errorRes: action.data,
        isBankerror: true,
        isFetching: false,
      };
    case BANK_REQUEST_SUCCESS:
      return {
        ...state,
        successRes: action.data,
        isBankSuccess: true,
        isBankerror: false,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default addAndEditBank;
