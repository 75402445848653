import { all } from 'redux-saga/effects';

import { watchManagePincode } from '../containers/manage-pincode/manage-pincode-saga';
import { watchManageUsers } from '../containers/manage-users/manage-users-saga';
import { watchManageProfession } from '../containers/manage-profession/manage-profession-saga';
import { watchManageBank } from '../containers/manage-bank/manage-bank-saga';
import { watchprofileDetails } from '../containers/profile/profile-details-saga';
import { watchSignin } from '../containers/sign-in/signin-saga';
import { watchchangePassword } from '../containers/change-password/change-password-saga';
import { watchRoleList } from '../containers/manage-users/role-list-saga';
import { watchaddUser } from '../containers/manage-users/add-user-saga';
import { watchaddNewUser } from '../containers/users/add-user-saga';
import { watchAddCallLogs } from '../containers/profile/add-call-logs-saga';
import { watchEditUser } from '../containers/manage-users/edit-user-saga';
import { watchGeneratePassword } from '../containers/manage-users/generate-password-saga';
import { watchEditBank } from '../containers/manage-bank/edit-bank-saga';
import { watchBankDetails } from '../containers/manage-bank/bank-details-saga';
import { watchuserAddress } from '../containers/profile/user-address-saga';
import { watchuserAddressLocation } from '../containers/profile/user-address-location-saga';
import { watchStateList } from '../containers/profile/state-list-saga';
import { watchAddAddress } from '../containers/profile/address-saga';
import { watchEditPincode } from '../containers/manage-pincode/edit-pincode-saga';
import { watchResetPassword } from '../containers/reset-password/reset-password-saga';
import { watchFileUpload } from '../containers/profile/file-upload-saga';
import { watchCallLogs } from '../containers/profile/call-logs-saga';
import { watchUserProfile } from '../containers/profile/user-profile-saga';
import { watchEmi } from '../containers/emi/emi-saga';
import { watchEmiChip } from '../containers/emi/emi-chip-saga';
import { watchLoan } from '../containers/loan/loan-saga';
import { watchLeadsLoan  } from '../containers/leads-loan/loan-saga';
import { watchLoanInfo } from '../containers/loan-information/loan-information-saga';
import { watchDisburseLoanNow } from '../containers/loan-information/disbuse-loan-saga';
import { watchCollection } from '../containers/collections/collections-saga';
import { watchCollectionChip } from '../containers/collections/collections-chip-saga';
import { watchDefaults } from '../containers/defaults/defaults-saga';
import { watchPayments } from '../containers/payments/payments-saga';
import { watchDisbursment } from '../containers/disbursment/disbursment-saga';
import { watchDisbursmentChip } from '../containers/disbursment/disbursment-chip-saga';
import { watchDashboard } from '../containers/dashboard/dashboard-saga';
import { watchRecentActivity } from '../containers/dashboard/recent-activity-saga';
import { watchNewRegistration } from '../containers/dashboard/new-registration-saga';
import { watchExportCsv } from '../containers/dashboard/export-csv-saga';
import { watchUploadDocs } from '../containers/profile/upload-docs-saga';
import { watchBlockUser } from '../containers/profile/block-unblock-saga';
import { watchViewFile } from '../containers/dashboard/view-file-saga';
import { watchPaymentDetails } from '../components/side-bar/payment-details-saga';
import { watchPayment } from '../containers/loan-information/loan-payment/payment-sega';
import { watchManagePartner } from '../containers/manage-partner/manage-partner-saga';
import { watchUsers } from '../containers/users/users-saga';
import { watchSecured } from '../containers/secured-loans/secured-loans-saga';
import { watchViewPartner } from '../containers/view-partner/view-partner-saga';
import { watchAddBank } from '../containers/profile/bank-saga';
import { watchUserBlock } from '../containers/dashboard/user-block-sega';
import { watchLoanBlock } from '../containers/dashboard/loan-block-sega';
import { watchLoanActions } from '../containers/loan-information/loan-actions/loan-actions-saga';
import { watchPaymentsLoan } from '../containers/loan-information/payment-details/payments-saga';
import { watchsendOtpUser } from '../containers/users/send-otp-saga';
import { watchAddnewLoan } from '../containers/profile/add-loan-saga';
import { watchpartnerList } from '../containers/profile/get-partner-saga';
import { watchproductList } from '../containers/profile/get-product-saga';
import { watchLoanPurposeList } from '../containers/profile/loan-purpose-saga';
import { watchSecuredLoanInfo } from '../containers/secured-loan-information/secured-loan-information-saga';
import { watchCompanyList } from '../containers/profile/get-company-saga';
import { watchGetCallLogsReports } from '../containers/users/get-daily-reports-saga';
import { watchProductSettingsRequest } from '../containers/profile/get-product-settings-saga';
import { watchDashboardReports } from '../containers/dashboard/dashboard-reports-saga';
import { watchEmandateNow } from '../containers/loan-information/emandate-saga';
import { watchGetDefaultReports } from '../containers/defaults/defaults-report-saga';
import { watchcallUsers } from '../containers/users/users-call-saga';
import { watchCrifExtraction } from '../containers/profile/get-crif-extraction-saga';
import { watchPreApprovedAmount } from '../containers/profile/pre-approved-amount-saga';
import { watchGst } from '../containers/gst/gst-saga';
import { watchLeads } from '../containers/my-leads/my-leads-saga';
import { watchGetCallLogsReportsLead } from '../containers/my-leads/get-daily-reports-saga';
import { watchcallLeads } from '../containers/my-leads/my-leads-call-saga';
import { watchaddManageAllocation } from '../containers/manage-allocations/add-manage-allocation-saga';

const sagas = function* sagas() {
  yield all([
    watchManagePincode(),
    watchManageUsers(),
    watchManageProfession(),
    watchManageBank(),
    watchprofileDetails(),
    watchSignin(),
    watchchangePassword(),
    watchRoleList(),
    watchaddUser(),
    watchEditUser(),
    watchGeneratePassword(),
    watchEditBank(),
    watchBankDetails(),
    watchuserAddress(),
    watchuserAddressLocation(),
    watchStateList(),
    watchAddAddress(),
    watchAddCallLogs(),
    watchCallLogs(),
    watchEditPincode(),
    watchResetPassword(),
    watchFileUpload(),
    watchEmi(),
    watchLoan(),
    watchLeadsLoan(),
    watchLoanInfo(),
    watchCollection(),
    watchDefaults(),
    watchPayments(),
    watchDisbursment(),
    watchEmiChip(),
    watchCollectionChip(),
    watchDisbursmentChip(),
    watchDashboard(),
    watchRecentActivity(),
    watchNewRegistration(),
    watchExportCsv(),
    watchUploadDocs(),
    watchBlockUser(),
    watchUserProfile(),
    watchViewFile(),
    watchPaymentDetails(),
    watchPayment(),
    watchManagePartner(),
    watchViewPartner(),
    watchDisburseLoanNow(),
    watchUsers(),
    watchSecured(),
    watchAddBank(),
    watchUserBlock(),
    watchLoanBlock(),
    watchLoanActions(),
    watchPaymentsLoan(),
    watchaddNewUser(),
    watchsendOtpUser(),
    watchAddnewLoan(),
    watchpartnerList(),
    watchproductList(),
    watchLoanPurposeList(),
    watchSecuredLoanInfo(),
    watchCompanyList(),
    watchGetCallLogsReports(),
    watchProductSettingsRequest(),
    watchDashboardReports(),
    watchEmandateNow(),
    watchGetDefaultReports(),
    watchcallUsers(),
    watchCrifExtraction(),
    watchPreApprovedAmount(),
    watchGst(),
    watchLeads(),
    watchGetCallLogsReportsLead(),
    watchcallLeads(),
    watchaddManageAllocation()
  ]);
};

export default sagas;
