import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import CloseIconWhite from '../../../../images/icons/closeIconWhite.svg';
import Button from '../../../components/form-elements/button';
import FormElement from '../../../components/form-elements';
import { autoPaymentFormFields } from './auto-payment-constant';
import { onChangeHandler, setInitialFormValues } from '../../../utils/form-helper';
import { autoPayment } from './payment-action';

const AutoPayment = ({ showAutoPayment, CloseAutoPayment, emiAmount, totalDueAmount }) => {
  const [autoPaymentForm, setAutoPaymentForm] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    setInitialFormValues(autoPaymentFormFields, setAutoPaymentForm);
  }, []);

  useEffect(() => {
    autoPaymentForm.map((formItemParam) => {
      const formItem = formItemParam;

      if (formItem.name === 'emiAmount') {
        formItem.value = emiAmount
      }
      if (formItem.name === 'totalDueAmount') {
        formItem.value = totalDueAmount
      }
      return false;
    })
  }, [autoPaymentForm, emiAmount, totalDueAmount]);

  const payButtonHandler = (amountParam) => {
    let amount = amountParam;

    if (amount) {
      amount = parseFloat(amount);
      const reqBody = { amount };
      const path = window.location.pathname.split('/');
      const loanId = path[path.length - 1];

      autoPayment(loanId, reqBody, dispatch);
      CloseAutoPayment();
    }
  }

  return (
    <React.Fragment>
      <Modal
        size="lg"
        show={showAutoPayment}
        onHide={CloseAutoPayment}
        backdropClassName="loan-modal-overlay-background"
        dialogClassName="auto-payment-modal-dialoag"
        aria-labelledby="auto-payment-pop-up"
        centered
      >
        <Modal.Body>
          <div className="auto-payment-modal-container">
            <span className="loan-modal-close-button" onClick={CloseAutoPayment}> <img src={CloseIconWhite} /></span>
            <div className="loan-modal-title">
                            Auto Payment
            </div>
            {autoPaymentForm.map((formItem, index) =>
              <div className={`auto-payment-form-field-wrapper${  index === autoPaymentForm.length - 1 ? ' auto-payment-no-border-bottom' : ''}`} key={index}>
                <FormElement
                  inputType={formItem.type}
                  lableName={formItem.label}
                  name={formItem.name}
                  placeholder={formItem.placeholder}
                  value={formItem.value}
                  onChangeHandler={(targetValue) => { onChangeHandler(autoPaymentForm, formItem.name, targetValue, setAutoPaymentForm) }}
                  isValid={formItem.isValid}
                  errorMsg={formItem.errorMsg}
                  isDisabled={formItem.isDisabled}
                  isTouched={formItem.isTouched}
                />
                <Button text="PAY" click={() => { payButtonHandler(formItem.value) }} />
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}

export default AutoPayment;